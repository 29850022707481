import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParamsBillingId, useParamsCancerOrderId } from '@hooks/params';

import { selectCancerOrderPaymentPage } from './selector';
import { cancerOrderFetch, billingFetch } from './action';
import { CancerOrderFetchPayload } from './cancerOrderFetch';
import { BillingFetchPayload } from './billingFetch';

export const useCancerOrderFetch = () => {
  const dispatch = useDispatch();
  const cancerOrderId = useParamsCancerOrderId();
  const { cancerOrder } = useSelector(selectCancerOrderPaymentPage);

  useEffect(() => {
    const payload: CancerOrderFetchPayload = { id: cancerOrderId };
    dispatch(cancerOrderFetch(payload));
  }, []);

  return cancerOrder;
};

export const useBillingFetch = () => {
  const dispatch = useDispatch();
  const billingId = useParamsBillingId();
  const { billing } = useSelector(selectCancerOrderPaymentPage);

  useEffect(() => {
    const payload: BillingFetchPayload = { id: billingId };
    dispatch(billingFetch(payload));
  }, []);

  return billing;
};
