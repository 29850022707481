import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button, Alert } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormGroupControl } from '@components/Base';
import { useCarFetch } from '@components/Feature/Car';

import { useInitialValues, useValidationSchema } from './hook';
import { FormValues } from './type';
import { carQuoteSendEmail } from '../action';
import { CarQuoteSendEmailPayload } from '../type';
import { useCarQuoteDetailsPageParams } from '../hook';
import { getCarQuoteDetailsPageRoute } from '../route';

const GROUP_PROPS = { className: 'mb-3' };

const CarQuoteSendEmailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useCarQuoteDetailsPageParams();
  const validationSchema = useValidationSchema();
  const initialValues = useInitialValues();
  const car = useCarFetch();

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuoteSendEmailPayload = { ...params, ...values };
    dispatch(carQuoteSendEmail(payload));
    setSubmitting(false);
    navigate(getCarQuoteDetailsPageRoute(params));
  };

  return (
    <Card>
      {car && (
        <Card.Body>
          <Alert variant="secondary">
            <span className="d-block">{car.name}</span>
            <span className="d-block fw-light">
              {t('common:privateCar')} . {car.manufacturedYear}
            </span>
          </Alert>
        </Card.Body>
      )}

      <Card.Body>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormGroupControl name="firstName" label={t('user.firstName')} required groupProps={GROUP_PROPS} />
            <FormGroupControl name="lastName" label={t('user.lastName')} required groupProps={GROUP_PROPS} />
            <FormGroupControl name="email" label={t('user.email')} required groupProps={GROUP_PROPS} />
            <FormGroupControl name="note" label={t('common:note')} groupProps={GROUP_PROPS} as="textarea" rows={4} />

            <Button type="submit" variant="primary" size="lg">
              {t('common:sendNow')}
            </Button>
          </Form>
        </Formik>
      </Card.Body>
    </Card>
  );
};

export { CarQuoteSendEmailPage };
