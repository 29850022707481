import { reject } from 'lodash';
import { handleActions } from 'redux-actions';
import { CarQuote } from '@models/CarQuote';
import {
  carQuoteComparableAdd,
  carQuoteComparableRemove,
  carQuoteComparableRemoveAll,
  stateReset,
  stateUpdate,
} from './action';
import {
  State,
  CarQuoteComparableAddPayload,
  CarQuoteComparableRemovePayload,
} from './type';

const initialState: State = {
  carQuotes: undefined,
  comparableCarQuotes: [],
};

const reducer = handleActions(
  {
    [String(carQuoteComparableAdd)]: (state, { payload }) => {
      const { carQuote } = payload as unknown as CarQuoteComparableAddPayload;

      return {
        ...state,
        comparableCarQuotes: [...state.comparableCarQuotes, carQuote] as CarQuote[],
      };
    },

    [String(carQuoteComparableRemove)]: (state, { payload }) => {
      const { carQuote } = payload as unknown as CarQuoteComparableRemovePayload;

      return {
        ...state,
        comparableCarQuotes: reject(state.comparableCarQuotes, ({ insurer }) => insurer.id === carQuote.insurer.id),
      };
    },

    [String(carQuoteComparableRemoveAll)]: (state) => {
      return {
        ...state,
        comparableCarQuotes: [],
      };
    },

    [String(stateUpdate)]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },

    [String(stateReset)]: () => {
      return initialState;
    },
  },

  initialState,
);

export default reducer;
