import { handleActions } from 'redux-actions';
import { CarClaim } from '@models/CarClaim';
import { carClaimSet } from './action';

export interface State {
  carClaim?: CarClaim;
}

const initialState: State = {};

const reducer = handleActions(
  {
    [String(carClaimSet)]: (state, { payload }) => {
      return {
        ...state,
        carClaim: payload.carClaim,
      };
    },
  },

  initialState,
);

export default reducer;
