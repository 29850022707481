import React from 'react';
import { Card } from 'react-bootstrap';
import { lowerCase, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import percentage from 'percentage';

import { amountToUsd, amountToKhr } from '@utils/currency';
import { HealthQuote } from '@models/HealthQuote';
import { PersonalDetails } from '@models/HealthOrder';

import style from './style.module.scss';
import { tOrdinalItem } from '@components/i18n';
import { KeysToCamelCase } from '@utils/typescript';

type Props = Pick<
  HealthQuote,
  'healthPlanName' | 'totalPremium' | 'totalPremiumKhr' | 'discountPercentage' | 'insurer' | 'adminFeeAmount' | 'people'
>;

const HealthQuotePremiumCardBody = ({
  healthPlanName,
  totalPremium,
  totalPremiumKhr,
  discountPercentage,
  adminFeeAmount,
  insurer,
  people,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <div className={classNames('position-relative mb-4', style.insurerLogoWrapper)}>
        <img
          src={insurer.logo}
          className={classNames('position-absolute top-50 start-0 translate-middle-y', style.insurerLogo)}
        />
      </div>

      <ul className={classNames('list-unstyled pb-2 border-bottom', style.borderBottomDash)}>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:policyTerm')}:</span>
          <span>1 {t('common:year')}</span>
        </li>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:premiumPaymentTerm')}:</span>
          <span>1 {t('common:year')}</span>
        </li>
      </ul>

      <ul className={classNames('list-unstyled pb-2 border-bottom', style.borderBottomDash)}>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:planType')}:</span>
          <span>{healthPlanName}</span>
        </li>
      </ul>

      {!!discountPercentage && (
        <ul className={classNames('list-unstyled pb-2 border-bottom', style.borderBottomDash)}>
          <li className="d-flex justify-content-between">
            <span className="fw-bold">{t('carInsuranceQuote.discounts.basicDiscount')}:</span>
            <span>{percentage(discountPercentage / 100)}</span>
          </li>
        </ul>
      )}

      <ul className={classNames('list-unstyled pb-2 border-bottom', style.borderBottomDash)}>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:adminFee')}:</span>
          <span>{amountToUsd(adminFeeAmount)}</span>
        </li>
      </ul>

      <ul className={classNames('list-unstyled pb-2 border-bottom', style.borderBottomDash)}>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:quoteDetails')}:</span>
          <span>&nbsp;</span>
        </li>

        {people.me && (
          <li className="d-flex justify-content-between">
            <span>{t('common:myself')}</span>
            <span>{amountToUsd(people.me.chargeFinalAmount)}</span>
          </li>
        )}

        {people.spouse && (
          <li className="d-flex justify-content-between">
            <span>{t('common:spouse')}</span>
            <span>{amountToUsd((people.spouse as unknown as KeysToCamelCase<PersonalDetails>).chargeFinalAmount)}</span>
          </li>
        )}

        {people.sons &&
          map(people.sons, (son: KeysToCamelCase<PersonalDetails>, idx) => (
            <li key={`son-${idx}`} className="d-flex justify-content-between">
              <span>{tOrdinalItem(idx + 1, 'common:son')}</span>
              <span>{amountToUsd(son.chargeFinalAmount)}</span>
            </li>
          ))}

        {people.daughters &&
          map(people.daughters, (daughter: KeysToCamelCase<PersonalDetails>, idx) => (
            <li key={`daughter-${idx}`} className="d-flex justify-content-between">
              <span>{tOrdinalItem(idx + 1, 'common:daughter')}</span>
              <span>{amountToUsd(daughter.chargeFinalAmount)}</span>
            </li>
          ))}
      </ul>

      <ul className={classNames('list-unstyled pb-2 mb-0')}>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:youWillPay')}:</span>
          <span>
            {amountToUsd(totalPremium)}/{lowerCase(t('common:year'))}
          </span>
        </li>
        <li className="d-flex justify-content-between">
          <span>&nbsp;</span>
          <span>
            {amountToKhr(totalPremiumKhr)}/{lowerCase(t('common:year'))}
          </span>
        </li>
      </ul>
    </Card.Body>
  );
};

export { HealthQuotePremiumCardBody };
