import React, { useEffect, useState } from 'react';
import { useHref } from 'react-router-dom';
import { Row, Col, Card, Button, Stack, ToggleButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { CancerQuote } from '@models/CancerQuote';
import { amountToUsd } from '@utils/currency';
import { getCancerQuoteDetailsPageRoute } from '@components/CancerQuoteDetailsRoute/route';

import { compareAdd, compareRemove } from '../action';
import { useCancerQuoteResultPageParams } from '../hook';
import { selectCancerQuoteResultPage } from '../selector';

interface Props {
  cancerQuote: CancerQuote;
}

const CancerQuoteItem = ({ cancerQuote }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useCancerQuoteResultPageParams();
  const [checked, setChecked] = useState(false);
  const { comparableCancerQuotes } = useSelector(selectCancerQuoteResultPage);
  const { id, cancerPlanId, cancerPlanName, geographicCoverage, annualLimitAmount, totalPremium } = cancerQuote;
  const cancerQuoteDetailsPageHref = useHref(getCancerQuoteDetailsPageRoute({ ...params, cancerPlanId }));

  const handleCompare = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    if (currentTarget.checked) {
      dispatch(compareAdd({ cancerQuote }));
    } else {
      dispatch(compareRemove({ cancerQuote }));
    }
  };

  useEffect(() => {
    setChecked(some(comparableCancerQuotes, { id }));
  }, [comparableCancerQuotes]);

  return (
    <Row>
      <Col lg={4}>
        <Card.Title>{cancerPlanName}</Card.Title>
        <Card.Text>
          <ToggleButton
            id={`quote-${cancerQuote.cancerPlanId}`}
            type="checkbox"
            variant="outline-secondary"
            value={cancerQuote.cancerPlanId}
            checked={checked}
            onChange={handleCompare}
          >
            {t('common:compare')}
          </ToggleButton>
        </Card.Text>
      </Col>

      <Col lg={4}>
        <Card.Text>
          <strong>{t('cancerQuote.annualLimit')} - </strong>
          {amountToUsd(annualLimitAmount)}
        </Card.Text>
        <Card.Text>
          <strong>{t('cancerQuote.geographicCoverage')} - </strong>
          {geographicCoverage}
        </Card.Text>
      </Col>

      <Col lg={4}>
        <Stack gap={3} className="text-center">
          <Card.Text className="mb-0">
            <strong>
              {t('common:premium')}: <span className="fs-5">{amountToUsd(totalPremium)}</span>
            </strong>
          </Card.Text>
          <Button variant="primary" size="lg" className="w-100">
            {t('common:buyNow')}
          </Button>
          <Card.Link href={cancerQuoteDetailsPageHref} className="text-decoration-none">
            {t('common:planDetails')}
            <FontAwesomeIcon icon={faArrowRight} size="xs" className="ms-2" />
          </Card.Link>
        </Stack>
      </Col>
    </Row>
  );
};

export { CancerQuoteItem };
