import React from 'react';
import { map } from 'lodash';
import { Modal, ListGroup, FormCheck, ModalProps } from 'react-bootstrap';
import { getCarPlanTypeCover, tCarPlanType, CarPlanTypes } from '@models/CarPlanType';

type Props = Pick<ModalProps, 'show' | 'onHide'> & {
  value: CarPlanTypes;
  onConfirm: (planType: CarPlanTypes) => void;
};

const PlanTypeModal = ({ show, onHide, onConfirm, value }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onConfirm(e.target.value as CarPlanTypes);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <ListGroup variant="flush">
          {map(CarPlanTypes, (planType) => (
            <ListGroup.Item key={planType} className="d-flex justify-content-between align-items-start">
              <FormCheck
                type="radio"
                id={planType}
                name="plan_type"
                value={planType}
                checked={value == planType}
                onChange={handleChange}
                label={
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">{tCarPlanType(planType)}</div>
                    <p>{getCarPlanTypeCover(planType)}</p>
                  </div>
                }
              />
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export { PlanTypeModal };
