import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';

type Response = {
  data: {
    names: string[];
  };
};

export class ProvinceApi {
  static getAll = async () => {
    return await authAxios
      .get(`${API_V1_BASE}/provinces`)
      .then((response) => humps((response as Response).data.names) as string[]);
  };
}
