import React, { useMemo } from 'react';
import { useHref } from 'react-router-dom';
import { Button, Col, Container, Row, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { map, times, startCase } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import carModelImg from './car-model.png';
import style from './style.module.scss';

const CallToActionSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(4, (n) => t(`carInsurancePage.headerSection.item${n + 1}`));
  }, []);

  return (
    <section className={classNames(style.bg, 'py-5')}>
      <Container className="my-5">
        <Row>
          <Col lg={10} className="mx-auto">
            <Row className="gy-5 align-items-center">
              <Col md={10} lg={5} className="order-lg-2">
                <Image src={carModelImg as string} fluid alt="MGA Cover car insurance family model" />
              </Col>
              <Col lg={7} className="order-lg-1">
                <h1 className="text-primary">{startCase(t('carInsurancePage.headerSection.title'))}</h1>
                <div className="my-4">
                  {map(items, (item) => (
                    <div key={item} className="fs-5 d-flex flex-row align-items-center mb-2">
                      <FontAwesomeIcon icon={faCircleCheck} size="sm" className="me-3 text-primary" />
                      <span className="d-block">{item}</span>
                    </div>
                  ))}
                </div>
                <Row>
                  <Col lg={6} md={6}>
                    <Button
                      href={useHref('/car-insurance-quote')}
                      variant="primary"
                      size="lg"
                      className="fw-bold p-3 w-100"
                    >
                      {t('banner.cta')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { CallToActionSection };
