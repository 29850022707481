import { put, call, takeLatest } from 'redux-saga/effects';

import { PersonalAccidentQuoteApi } from '@apis/PersonalAccidentQuoteApi';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { SagaPayload } from '@utils/typescript';

import {
  personalAccidentQuotesFetch,
  PersonalAccidentQuotesFetchPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handlePersonalAccidentQuotesFetch({ payload }: SagaPayload<PersonalAccidentQuotesFetchPayload>) {
  const state: StateUpdatePayload = { personalAccidentQuotes: null };
  try {
    state.personalAccidentQuotes = (yield call(PersonalAccidentQuoteApi.getAll, payload)) as PersonalAccidentQuote[];
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(personalAccidentQuotesFetch, handlePersonalAccidentQuotesFetch);
}
