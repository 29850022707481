import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { FormGroupControl } from '@components/Base';
import { selectIsCustomer } from '@components/Auth';

const GROUP_PROPS = { className: 'mb-3' };

const OrderCardBody = () => {
  const { t } = useTranslation();
  const isCustomer = useSelector(selectIsCustomer);

  if (!isCustomer) return null;

  return (
    <Card.Body>
      <Card.Title>{t('common:fillPolicyDetails')}</Card.Title>
      <FormGroupControl
        type="date"
        name="activationDate"
        label={t('car.activationDate')}
        groupProps={GROUP_PROPS}
        min={moment().format('YYYY-MM-DD').toString()}
        max={moment().add(3, 'month').format('YYYY-MM-DD').toString()}
      />
    </Card.Body>
  );
};

export { OrderCardBody };
