import i18n from '@components/i18n';
import { camelCase } from 'lodash';
import { KeysToCamelCase } from '@utils/typescript';

export enum UserDocumentTypes {
  IdCard = 'ID Card',
  Passport = 'Passport',
  DrivingLicense = 'Driving License',
}

export enum UserDocumentStatuses {
  Unverified = 'unverified',
  Reviewing = 'reviewing',
  Rejected = 'rejected',
  Verified = 'verified',
}

export interface UserDocumentResponse {
  id: number;
  status: UserDocumentStatuses;
  document_type: string;
  document: string;
  rejected_reason?: string;
}

export type UserDocument = KeysToCamelCase<UserDocumentResponse>;

const USER_DOCUMENT_STATUSES_VARIANTS_MAPPING = {
  [UserDocumentStatuses.Unverified]: 'warning',
  [UserDocumentStatuses.Reviewing]: 'info',
  [UserDocumentStatuses.Rejected]: 'danger',
  [UserDocumentStatuses.Verified]: 'success',
};

export const getUserDocumentStatusVariant = (status: UserDocumentStatuses) => {
  return USER_DOCUMENT_STATUSES_VARIANTS_MAPPING[status];
};

export const getUserDocumentStatusText = (status: UserDocumentStatuses) => {
  return i18n.t(`userDocumentStatuses.${status}`);
};

export const getUserDocumentType = (type: string) => {
  return i18n.t(`userDocumentTypes.${camelCase(type)}`);
};
