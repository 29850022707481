import React, { useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button, Image } from 'react-bootstrap';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { BackButton, RowItem } from '@components/Base';
import { formatDateTime } from '@utils/datetime';
import { selectCarClaim } from './selector';
import { carClaimFetch } from './action';

const CarClaimDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { claimableId } = useParams();
  const { carClaim } = useSelector(selectCarClaim);

  useEffect(() => {
    dispatch(carClaimFetch({ carClaimId: claimableId }));
  }, [claimableId]);

  const handleBackClick = useCallback(() => {
    navigate('/user-dashboard/claim');
  }, []);

  const handleViewDetailsPoliceReport = useCallback((policeReportUrl?: string) => {
    window.open(policeReportUrl, '_blank', 'noreferrer noopener');
  }, []);

  return (
    <>
      <BackButton onClick={handleBackClick} className="mb-4" />
      {carClaim && (
        <Card>
          <Card.Header>{t('details', { ns: 'common' })}</Card.Header>
          <Card.Body>
            <Card.Title>{t('theInsured', { ns: 'common' })}</Card.Title>
            <RowItem label={t('car.brand')} value={carClaim.vehicleMake} className="mb-2" />
            <RowItem label={t('car.model')} value={carClaim.vehicleModel} className="mb-2" />
            <RowItem
              label={t('car.yearOfManufacture')}
              value={carClaim.vehicleYearOfManufacture}
              className="mb-2"
            />
            <RowItem label={t('car.engineNumber')} value={carClaim.vehicleEngineNumber} className="mb-2" />
            <RowItem label={t('car.chassisNumber')} value={carClaim.vehicleChassisNumber} className="mb-2" />
            <RowItem
              label={t('car.registrationNumber')}
              value={carClaim.vehicleRegistrationNumber}
              className="mb-2"
            />
          </Card.Body>
          <Card.Body>
            <Card.Title>{t('carInsuranceClaimForm.driverAccident.title')}</Card.Title>
            <RowItem label={t('user.fullName')} value={carClaim.driverFullName} className="mb-2" />
            <RowItem label={t('user.occupation')} value={carClaim.driverOccupation} className="mb-2" />
            <RowItem label={t('user.mobileNumber')} value={carClaim.driverMobileNumber} className="mb-2" />
            <RowItem label={t('user.address')} value={carClaim.driverAddress} className="mb-2" />
            <RowItem
              label={t('carInsuranceClaimForm.driverHasDrivingLicense.label')}
              value={carClaim.driverHasDrivingLicense ? 'Yes' : 'No'}
              className="mb-2"
            />
            <RowItem
              label={t('carInsuranceClaimForm.driverLicenseCategory.label')}
              value={carClaim.driverLicenseCategory}
              className="mb-2"
            />
            <RowItem
              label={t('carInsuranceClaimForm.driverRelationWithInsured.label')}
              value={carClaim.driverRelationWithInsured}
              className="mb-2"
            />
            <RowItem
              label={t('carInsuranceClaimForm.driverPurposeOfVehicleUsage.label')}
              value={carClaim.driverPurposeOfVehicleUsage}
              className="mb-2"
            />
            <RowItem
              label={t('carInsuranceClaimForm.driverUsedDrugOrAlcohol.groupLabel')}
              value={carClaim.driverUsedDrugOrAlcohol ? 'Yes' : 'No'}
              className="mb-2"
            />
            <RowItem
              label={t('carInsuranceClaimForm.isDriverOrYourselfResponsibility.groupLabel')}
              value={carClaim.isDriverOrYourselfResponsibility ? 'Yes' : 'No'}
              className="mb-2"
            />
            <RowItem
              label={t('carInsuranceClaimForm.driverHasOtherInsuranceForAccident.groupLabel')}
              value={carClaim.driverHasOtherInsuranceForAccident ? 'Yes' : 'No'}
              className="mb-2"
            />
            <RowItem
              label={t('insurer', { ns: 'common' })}
              value={carClaim.otherInsurerName}
              className="mb-2"
            />
            <RowItem
              label={t('policyType', { ns: 'common' })}
              value={carClaim.otherInsurerPolicyType}
              className="mb-2"
            />
          </Card.Body>
          <Card.Body>
            <Card.Title>{t('carInsuranceClaimForm.accidentDetails.title')}</Card.Title>
            <RowItem
              label={t('carInsuranceClaimForm.accidentHappenedAt.label')}
              value={formatDateTime(carClaim.accidentHappenedAt)}
              className="mb-2"
            />
            <RowItem
              label={t('carInsuranceClaimForm.accidentPlace.label')}
              value={carClaim.accidentPlace}
              className="mb-2"
            />
            <RowItem
              label={t('carInsuranceClaimForm.accidentDesc.label')}
              value={carClaim.accidentDesc}
              className="mb-2"
            />
            <Row>
              <Col xs={4} sm={4} md={3}>
                {t('carInsuranceClaimForm.accidentPhotos.label')}
              </Col>
              <Col xs="auto">:</Col>
              <Col>
                {map(carClaim.accidentPhotos, (photoUrl: string) => (
                  <Image key={photoUrl} src={photoUrl} thumbnail />
                ))}
              </Col>
            </Row>
          </Card.Body>
          <Card.Body>
            <Card.Title>{t('carInsuranceClaimForm.damageVehicleInsured.title')}</Card.Title>
            <RowItem
              label={t('carInsuranceClaimForm.damageDesc.label')}
              value={carClaim.damageDesc}
              className="mb-2"
            />
            <RowItem
              label={t('carInsuranceClaimForm.damageEstimatedCost.label')}
              value={carClaim.damageEstimatedCostAmount}
              className="mb-2"
            />
            <RowItem
              label={t('carInsuranceClaimForm.ownDamageRepairGarage.label')}
              value={carClaim.damageRepairGarage}
              className="mb-2"
            />
          </Card.Body>
          <Card.Body>
            <Card.Title>{t('carInsuranceClaimForm.driverDetailsInjury.title')}</Card.Title>
            <RowItem
              label={t('carInsuranceClaimForm.driverDetailsInjury.label')}
              value={carClaim.driverDetailsInjury}
              className="mb-2"
            />
          </Card.Body>
          <Card.Body>
            <Card.Title>{t('carInsuranceClaimForm.passengersThirdParties.title')}</Card.Title>
            <RowItem
              label={t('carInsuranceClaimForm.passengersThirdParties.title')}
              value={carClaim.passengersAndThirdPartiesInjured ? 'Yes' : 'No'}
              className="mb-2"
            />
            <Row>
              <Col xs={4} sm={4} md={3}>
                {t('carInsuranceClaimForm.passengersAndThirdPartiesPoliceReport.label')}
              </Col>
              <Col xs="auto">:</Col>
              <Col>
                <Button
                  variant="link"
                  onClick={() => handleViewDetailsPoliceReport(carClaim.passengersAndThirdPartiesPoliceReport)}
                >
                  {t('clickToViewDetails', { ns: 'common' })}
                </Button>
              </Col>
            </Row>
          </Card.Body>
          <Card.Body>
            <Card.Title>{t('carInsuranceClaimForm.thirdPartyClaim.title')}</Card.Title>
            <RowItem label={t('car.brand')} value={carClaim.thirdPartyVehicleMake} className="mb-2" />
            <RowItem label={t('car.model')} value={carClaim.thirdPartyVehicleModel} className="mb-2" />
            <RowItem
              label={t('car.yearOfManufacture')}
              value={carClaim.thirdPartyVehicleYearOfMfg}
              className="mb-2"
            />
            <RowItem
              label={t('car.engineNumber')}
              value={carClaim.thirdPartyVehicleEngineNumber}
              className="mb-2"
            />
            <RowItem
              label={t('car.chassisNumber')}
              value={carClaim.thirdPartyVehicleChassisNumber}
              className="mb-2"
            />
            <RowItem
              label={t('car.registrationNumber')}
              value={carClaim.thirdPartyVehicleRegNumber}
              className="mb-2"
            />
            <RowItem
              label={t('carInsuranceClaimForm.thirdPartyVehicleDamageDetails.label')}
              value={carClaim.thirdPartyVehicleDamageDetails}
              className="mb-2"
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export { CarClaimDetails };
