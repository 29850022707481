import React from 'react';

import HeaderSecondary from '@components/HeaderSecondary';
import SubHeader from '@components/HealthQuoteResultPage/SubHeader';
import PersonalAccidentQuoteList from './PersonalAccidentQuoteList';
import ComparablePersonalAccidentQuote from './ComparablePersonalAccidentQuote';

const PersonalAccidentQuoteResultPage = () => {
  return (
    <>
      <HeaderSecondary />
      <SubHeader />
      <PersonalAccidentQuoteList />
      <ComparablePersonalAccidentQuote />
    </>
  );
};

export { PersonalAccidentQuoteResultPage };
