import React from 'react';
import { map, startCase } from 'lodash';
import { useHref } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { InsuranceTypes, tInsuranceType } from '@models/InsuranceType';

const ArticleNavDropdown = () => {
  const { t } = useTranslation();
  const insuranceTypes = Object.values(InsuranceTypes);

  return (
    <NavDropdown title={startCase(t('common:articles'))}>
      <NavDropdown.Item href={useHref('/articles')}>{t('common:all')}</NavDropdown.Item>

      {map(insuranceTypes, (insuranceType) => (
        <NavDropdown.Item key={insuranceType} href={useHref(`/articles/${insuranceType}`)}>
          {startCase(tInsuranceType(insuranceType))}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

export { ArticleNavDropdown };
