import React, { useState } from 'react';
import { Container, Row, Col, Collapse, Button } from 'react-bootstrap';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { getCancerQuoteComparePageRoute } from '@components/CancerQuoteComparePage/route';

import { compareRemoveAll } from '../action';
import { selectCancerQuoteResultPage } from '../selector';
import { useCancerQuoteResultPageParams } from '../hook';
import { CancerQuoteItem } from './cancer-quote-item';
import { CancerQuote } from '@models/CancerQuote';

const ComparableCancerQuote = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useCancerQuoteResultPageParams();
  const { comparableCancerQuotes: cancerQuotes } = useSelector(selectCancerQuoteResultPage);
  const [open, setOpen] = useState(false);
  const hasCancerQuotes = cancerQuotes.length > 0;

  const handleRemoveAll = () => {
    dispatch(compareRemoveAll());
  };

  const handleCompare = () => {
    navigate(
      getCancerQuoteComparePageRoute({
        ...params,
        cancerPlanIds: map(cancerQuotes, 'id'),
      }),
    );
  };

  if (!hasCancerQuotes) return null;

  return (
    <div className="p-3 w-100 fixed-bottom shadow-lg bg-white" data-testid="comparable-cancer-quote">
      <Container>
        <h6 className="m-0 text-center c-pointer" onClick={() => setOpen(!open)}>
          <span className="me-2">
            {t('common:compareQuotes')} ({cancerQuotes.length})
          </span>
          <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
        </h6>
        <Collapse in={open}>
          <Row className="mt-3">
            {map(cancerQuotes, (quote: CancerQuote) => (
              <Col className="d-flex align-items-center border-end" key={quote.cancerPlanId}>
                <CancerQuoteItem {...quote} />
              </Col>
            ))}

            {cancerQuotes.length === 1 && (
              <Col>
                <div className="d-flex flex-column justify-content-center align-items-center h-100 border-end">
                  <span className="text-danger">{t('common:compareGuide', { max: 2 })}</span>
                </div>
              </Col>
            )}

            <Col lg={2}>
              <div className="d-flex flex-column">
                <Button className="mb-3" onClick={handleCompare}>
                  {t('common:compare')}
                </Button>
                <Button variant="light" onClick={handleRemoveAll}>
                  {t('common:removeAll')}
                </Button>
              </div>
            </Col>
          </Row>
        </Collapse>
      </Container>
    </div>
  );
};

export { ComparableCancerQuote };
