import { isUndefined } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParamsCarOrderId } from '@hooks/params';
import { useParseParams } from '@hooks/search-params';

import {
  carQuoteEdit,
  CarQuoteEditPayload,
  carQuoteFetch,
  CarQuoteFetchPayload,
  carRegistrationPlacesFetch,
} from './action';
import { selectCarQuoteBuy } from './selector';
import { CarQuoteBuyNewPageParams, carQuoteBuyNewPageParamsSchema } from './route';

export const useCarQuoteBuyNewPageParams = () => useParseParams<CarQuoteBuyNewPageParams>(carQuoteBuyNewPageParamsSchema);

export const useCarRegistrationPlaces = () => {
  const dispatch = useDispatch();
  const { carRegistrationPlaces } = useSelector(selectCarQuoteBuy);

  useEffect(() => {
    if (isUndefined(carRegistrationPlaces)) {
      dispatch(carRegistrationPlacesFetch());
    }
  }, []);

  return carRegistrationPlaces;
};

export const useCarQuote = () => {
  const dispatch = useDispatch();
  const payload:CarQuoteFetchPayload  = useCarQuoteBuyNewPageParams();
  const { carQuote } = useSelector(selectCarQuoteBuy);

  useEffect(() => {
    dispatch(carQuoteFetch(payload));
  }, []);

  return carQuote;
};

export const useCarQuoteEdit = () => {
  const dispatch = useDispatch();
  const carOrderId = useParamsCarOrderId();
  const { carQuote } = useSelector(selectCarQuoteBuy);

  useEffect(() => {
    const payload: CarQuoteEditPayload = { carOrderId };
    dispatch(carQuoteEdit(payload));
  }, []);

  return carQuote;
};
