import React, { useState } from 'react';
import Footer from '@components/Footer';
import HeaderPrimary from '@components/HeaderPrimary';
import { Col, Container, Row, Button, Card, Alert } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { FormGroupControl } from '@components/Base';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { useDispatch } from 'react-redux';
import 'yup-phone-lite';

import i18n from '@components/i18n';
import CallToActionSection from './CallToActionSection';
import { userLeadCreate, UserLeadCreatePayload } from '@components/UserLead';

const GROUP_PROPS = { className: 'mb-3' };

type FormValues = UserLeadCreatePayload & {
  email: string;
  companyName: string;
  insuranceType: string;
};

const validationSchema = object({
  fullName: string().required(i18n.t('validation:required')),
  mobileNumber: string()
    .required(i18n.t('validation:required'))
    .phone('KH', i18n.t('validation:validPhone', { countryName: i18n.t('common:cambodia') })),
  email: string().email().required(i18n.t('validation:required')),
  companyName: string().required(i18n.t('validation:required')),
  insuranceType: string().required(i18n.t('validation:required')),
  message: string().required(i18n.t('validation:required')),
});

const initialValues = {
  fullName: '',
  email: '',
  companyName: '',
  mobileNumber: '',
  insuranceType: '',
  message: '',
};

const CorporateInsurance = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleSubmit = async (
    { mobileNumber, fullName, insuranceType, ...rest }: FormValues,
    { setSubmitting, resetForm, validateForm }: FormikHelpers<FormValues>,
  ) => {
    const payload: UserLeadCreatePayload = {
      mobileNumber,
      fullName,
      message: `${insuranceType}: ${rest.email}, ${rest.companyName}, ${rest.message}`,
    };
    dispatch(userLeadCreate(payload));
    setShow(true);
    setSubmitting(false);
    resetForm();
    await validateForm();
  };

  return (
    <>
      <HeaderPrimary />
      <CallToActionSection />

      <Container className="my-3 my-md-5">
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <Card>
              <Card.Header>
                <strong>{t('common:corporateInsurance')}</strong>
              </Card.Header>
              <Card.Body>
                <Card.Text>{t('corporateInsurance.benefits')}</Card.Text>
                <Card.Text>{t('corporateInsurance.howto')}</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12} md={6}>
            <Formik
              validateOnMount
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isValid }) => (
                <Form>
                  {show && (
                    <Alert variant="success" onClose={() => setShow(false)} dismissible>
                      {t('insurance.quoteRequest')}
                    </Alert>
                  )}

                  <FormGroupControl name="companyName" label={t('company.name')} required groupProps={GROUP_PROPS} />
                  <FormGroupControl name="fullName" label={t('user.fullName')} groupProps={GROUP_PROPS} required />
                  <FormGroupControl
                    type="tel"
                    name="mobileNumber"
                    label={t('user.mobileNumber')}
                    required
                    groupProps={GROUP_PROPS}
                  />
                  <FormGroupControl
                    type="email"
                    name="email"
                    label={t('user.email')}
                    required
                    groupProps={GROUP_PROPS}
                  />
                  <FormGroupControl
                    name="insuranceType"
                    label={t('insurance.type')}
                    groupProps={GROUP_PROPS}
                    required
                  />
                  <FormGroupControl
                    as="textarea"
                    name="message"
                    label={t('common:information')}
                    groupProps={GROUP_PROPS}
                    required
                  />

                  <Button type="submit" disabled={!isValid} variant="primary" size="lg">
                    {t('common:submit')}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export { CorporateInsurance };
