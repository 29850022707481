import React, { useMemo } from 'react';
import { map, times } from 'lodash';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import signInAccountImg from './sign-in-account.png';
import userDashboardImg from './user-dashboard.png';
import fillInFormImg from './fill-in-form.png';
import trackingImg from './tracking.png';

const IMAGES = [signInAccountImg, userDashboardImg, fillInFormImg, trackingImg];

export interface Props {
  desc: string;
  image: string;
}

const ClaimCard = ({ desc, image }: Props) => {
  return (
    <Card className="shadow bg-body border-0 h-100">
      <div className="text-center pt-3">
        <Card.Img src={image} className="w-50" alt="How to file a car insurance claim on MGA Cover" />
      </div>
      <Card.Body>
        <Card.Text className="text-center">{desc}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const HowToFileClaimSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(4, (n) => ({
      desc: t(`carInsurancePage.howToFileClaimSection.item${n + 1}`),
      image: IMAGES[n] as string,
    }));
  }, []);

  return (
    <section className="py-5">
      <Container>
        <h2 className="text-center">{t('carInsurancePage.howToFileClaimSection.title')}</h2>
        <p className="text-center text-secondary w-75 mx-auto">
          {t('carInsurancePage.howToFileClaimSection.subTitle')}
        </p>
        <Row className="gy-4 mt-5">
          {map(items, (item) => (
            <Col lg={3} key={item.desc}>
              <ClaimCard {...item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export { HowToFileClaimSection };
