import { isUndefined } from 'lodash';
import { useParams, useSearchParams } from 'react-router-dom';

export const useParamsCarOrderId = () => {
  const { carOrderId } = useParams<'carOrderId'>();
  if (isUndefined(carOrderId)) throw new Error('useParamsCarOrderId must be used within a routeParam :carOrderId');
  return Number(carOrderId);
};

export const useParamsHealthQuoteId = () => {
  const { healthQuoteId } = useParams<'healthQuoteId'>();
  if (isUndefined(healthQuoteId))
    throw new Error('useParamsHealthQuoteId must be used within a routeParam :healthQuoteId');
  return Number(healthQuoteId);
};

export const useParamsHealthOrderId = () => {
  const { healthOrderId } = useParams<'healthOrderId'>();
  if (isUndefined(healthOrderId))
    throw new Error('useParamsHealthOrderId must be used within a routeParam :healthOrderId');
  return Number(healthOrderId);
};

export const useParamsPersonalAccidentQuoteId = () => {
  const { personalAccidentQuoteId } = useParams<'personalAccidentQuoteId'>();
  if (isUndefined(personalAccidentQuoteId))
    throw new Error('useParamsPersonalAccidentQuoteId must be used within a routeParam :personalAccidentQuoteId');
  return Number(personalAccidentQuoteId);
};

export const useParamsPersonalAccidentOrderId = () => {
  const { personalAccidentOrderId } = useParams<'personalAccidentOrderId'>();
  if (isUndefined(personalAccidentOrderId))
    throw new Error('useParamsPersonalAccidentOrderId must be used within a routeParam :personalAccidentOrderId');
  return Number(personalAccidentOrderId);
};

export const useParamsCancerOrderId = () => {
  const { cancerOrderId } = useParams<'cancerOrderId'>();
  if (isUndefined(cancerOrderId)) throw new Error('useCancerOrderId must be used within a routeParam :cancerOrderId');
  return Number(cancerOrderId);
};

export const useParamsCancerQuoteId = () => {
  const { cancerQuoteId } = useParams<'cancerQuoteId'>();
  if (isUndefined(cancerQuoteId))
    throw new Error('useParamsCancerQuoteId must be used within a routeParam :cancerQuoteId');
  return Number(cancerQuoteId);
};

export const useParamsBillingId = () => {
  const { billingId } = useParams<'billingId'>();
  if (isUndefined(billingId)) throw new Error('useParamsBillingId must be used within a routeParam :billingId');
  return Number(billingId);
};

export const useParamsPage = () => {
  const [searchParams] = useSearchParams();
  return Number(searchParams.get('page') || 1);
};
