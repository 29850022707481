import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { Genders, tGender } from '@models/User';
import { formatDate } from '@utils/datetime';
import { PersonalDetails } from '@models/HealthOrder';
import { KeysToCamelCase } from '@utils/typescript';
import { HealthQuote } from '@models/HealthQuote';

interface RowItemProps {
  label: string;
  value?: string | number;
}

const RowItem = ({ label, value }: RowItemProps) => (
  <Row className="mb-2">
    <Col xs={7} sm={7} md={4}>
      {label}
    </Col>
    <Col xs="auto">:</Col>
    <Col>{value}</Col>
  </Row>
);

interface MemberItemProps {
  member: KeysToCamelCase<PersonalDetails> & {
    gender?: string;
    mobileNumber?: string;
    email?: string;
    address?: string;
  };
  gender: Genders;
}

const MemberItem = ({ member, gender }: MemberItemProps) => {
  const { t } = useTranslation();
  const isMe = !!member.mobileNumber;

  return (
    <Card className="mb-3">
      <Card.Header className="bg-white fs-5">{member.fullName}</Card.Header>
      <Card.Body>
        <RowItem label="Full name in khmer" value={member.fullNameKm} />
        <RowItem label={t('user.gender')} value={tGender(gender)} />
        <RowItem label={t('user.dob')} value={formatDate(member.dob)} />
        <RowItem label="Age" value={member.age} />
        <RowItem label={t('user.personalCode')} value={member.personalCode ?? ''} />
        <RowItem label={t('user.occupation')} value={member.occupation} />
        <RowItem label={t('common:weight')} value={`${member.weight}Kg`} />
        <RowItem label={t('common:height')} value={`${member.height}cm`} />

        {isMe && (
          <>
            <RowItem label={t('user.mobileNumber')} value={member.mobileNumber} />
            <RowItem label={t('user.email')} value={member.email} />
            <RowItem label={t('user.address')} value={member.address} />
          </>
        )}
      </Card.Body>
    </Card>
  );
};

type Props = HealthQuote['people'] & {
  onEdit: () => void;
};

const MemberCardBody = ({ me, spouse, sons, daughters, onEdit }: Props) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <div className="d-flex justify-content-between mb-3">
        <Card.Title>Members Details</Card.Title>
        <Button onClick={onEdit} variant="outline-primary" size="sm">
          {t('common:edit')}
        </Button>
      </div>

      <MemberItem member={me} gender={me.gender} />

      {!!spouse && (
        <MemberItem
          member={spouse as unknown as KeysToCamelCase<PersonalDetails>}
          gender={me.gender === 'male' ? Genders.Female : Genders.Male}
        />
      )}

      {!!sons &&
        map(sons, (son: KeysToCamelCase<PersonalDetails>, idx: number) => (
          <MemberItem key={`sons.${idx}`} member={son} gender={Genders.Male} />
        ))}

      {!!daughters &&
        map(daughters, (daughter: KeysToCamelCase<PersonalDetails>, idx: number) => (
          <MemberItem key={`daughters.${idx}`} member={daughter} gender={Genders.Female} />
        ))}
    </Card.Body>
  );
};

export { MemberCardBody };
