import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import { useParamsCarOrderId } from '@hooks/params';

import { selectCarPolicyRenewal } from './selector';
import { carOrderPreview, CarOrderPreviewPayload } from './action';

export const useCarOrderPreview = () => {
  const dispatch = useDispatch();
  const carOrderId = useParamsCarOrderId();
  const { carOrder } = useSelector(selectCarPolicyRenewal);

  useEffect(() => {
    if (carOrderId && isUndefined(carOrder)) {
      const payload: CarOrderPreviewPayload = { id: carOrderId };
      dispatch(carOrderPreview(payload));
    }
  }, [carOrderId, carOrder]);

  return carOrder;
};
