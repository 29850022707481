import { object, number } from 'yup';
import { getRoute } from '@utils/route';
import { carQuoteResultPageParamsSchema } from '@components/CarQuoteResultPage';
import { CarQuoteDetailsPageParams, CarQuoteSendEmailPageParams } from './type';

export const carQuoteDetailsPageParamsSchema = carQuoteResultPageParamsSchema.concat(
  object({
    carPlanId: number(),
  }),
);

export const carQuoteSendEmailPageParamsSchema = carQuoteDetailsPageParamsSchema;

export const CAR_QUOTE_DETAILS_ROUTE_URL = '/car-insurance-quote-details/*';
export const CAR_QUOTE_DETAILS_PAGE_URL = '/car-insurance-quote-details';
export const CAR_QUOTE_SEND_MAIL_PAGE_URL = '/car-insurance-quote-details/send-email';

export const getCarQuoteDetailsPageRoute = (params: CarQuoteDetailsPageParams) =>
  getRoute(CAR_QUOTE_DETAILS_PAGE_URL, params);
export const getCarQuoteSendEmailPageRoute = (params: CarQuoteSendEmailPageParams) =>
  getRoute(CAR_QUOTE_SEND_MAIL_PAGE_URL, params);
