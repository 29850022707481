import { createActions } from 'redux-actions';

import { CarQuote } from '@models/CarQuote';
import { GetAllCarQuoteParams } from '@apis/CarQuoteApi';

export type State = {
  carQuotes: CarQuote[];
};

export type CarQuotesFetchPayload = Required<GetAllCarQuoteParams>;

export type StateUpdatePayload = Partial<State>;

export const {
  carQuotesFetch,

  stateUpdate,
} = createActions(
  'CAR_QUOTES_FETCH',

  'STATE_UPDATE',
  {
    prefix: 'CAR_QUOTE_COMPARE_PAGE',
  },
);
