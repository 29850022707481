import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { map, isUndefined } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { NoData, Loading } from '@components/Base';
import { HealthQuoteGroup } from '@models/HealthQuoteGroup';

import { selectHealthQuoteResultPage } from '../selector';
import { useHealthQuoteResultPageParams } from '../hook';
import { healthQuotesFetch, HealthQuotesFetchPayload } from '../action';
import { useFilterContext } from '../FilterProvider';
import { HealthQuoteGroupItem } from './health-quote-group-item';

const HealthQuoteList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useHealthQuoteResultPageParams();
  const { filters } = useFilterContext();
  const { totalQuotes, healthQuoteGroups } = useSelector(selectHealthQuoteResultPage);

  useEffect(() => {
    const payload: HealthQuotesFetchPayload = { ...params, filters };
    dispatch(healthQuotesFetch(payload));
  }, [filters]);

  return (
    <Container className="mt-5">
      {isUndefined(healthQuoteGroups) && <Loading />}
      {healthQuoteGroups?.length === 0 && <NoData />}

      {!!totalQuotes && <h4 className="mb-5">{t('healthQuote.totalPlans', { count: totalQuotes })}</h4>}

      {healthQuoteGroups && (
        <>
          {map(healthQuoteGroups, (quoteGroup: HealthQuoteGroup) => (
            <Row key={quoteGroup.insurer.id} className="mb-4" data-testid="health-group-item">
              <Col>
                <HealthQuoteGroupItem {...quoteGroup} />
              </Col>
            </Row>
          ))}

          <div className="my-5">&nbsp;</div>
        </>
      )}
    </Container>
  );
};

export { HealthQuoteList };
