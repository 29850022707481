import { array, number, object, string } from 'yup';

import { Genders } from '@models/User';
import { stringify } from 'qs';

export type PeopleParams = {
  me: {
    gender: Genders;
    dob: string;
    age: number;
  };
  spouse?: {
    dob: string;
    age: number;
  };
  sons?: {
    dob: string;
    age: number;
  }[];
  daughters?: {
    dob: string;
    age: number;
  }[];
};

export type CancerQuoteResultPageParams = PeopleParams;

export const peopleParamsSchema = object({
  me: object({
    gender: string(),
    dob: string(),
    age: number(),
  }),
  spouse: object({
    dob: string(),
    age: number(),
  }).default(undefined),
  sons: array().of(
    object({
      dob: string(),
      age: number(),
    }),
  ),
  daughters: array().of(
    object({
      dob: string(),
      age: number(),
    }),
  ),
});

export const cancerQuoteResultPageParamsSchema = peopleParamsSchema;

export const CANCER_QUOTE_RESULT_PAGE_URL = '/cancer-insurance-quote-results';

export const getCancerQuoteResultPageRoute = (params: CancerQuoteResultPageParams) => {
  return {
    pathname: CANCER_QUOTE_RESULT_PAGE_URL,
    search: stringify(params),
  };
};
