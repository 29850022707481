import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { FormGroupControl, FormGroupSelect } from '@components/Base';

import { selectCarQuoteBuy } from '../selector';

const GROUP_PROPS = { className: 'mb-3' };

const VehicleCardBody = () => {
  const { t } = useTranslation();
  const { carQuote, carRegistrationPlaces } = useSelector(selectCarQuoteBuy);

  return (
    <Card.Body>
      <Card.Title>{t('carQuoteBuyPage.carQuoteBuyForm.vehicleTitle')}</Card.Title>
      <Alert variant="secondary">
        <span className="d-block">{carQuote?.car?.name}</span>
        <span className="d-block fw-light">
          {t('common:privateCar')} . {carQuote?.manufacturedYear}
        </span>
      </Alert>

      <FormGroupControl name="engineNumber" label={t('car.engineNumber')} required groupProps={GROUP_PROPS} />
      <FormGroupControl name="chassisNumber" label={t('car.chassisNumber')} required groupProps={GROUP_PROPS} />

      <FormGroupSelect name="registrationPlace" label={t('car.registrationPlace')} required groupProps={GROUP_PROPS}>
        <option></option>
        {map(carRegistrationPlaces, (place) => (
          <option key={place} value={place}>
            {place}
          </option>
        ))}
      </FormGroupSelect>

      <FormGroupControl
        name="registrationNumber"
        label={t('car.registrationNumber')}
        required
        groupProps={GROUP_PROPS}
      />
    </Card.Body>
  );
};

export { VehicleCardBody };
