import { forEach, map, isEmpty, isArray, merge } from 'lodash';
import { CancerPlanQuestion } from '@models/CancerQuote';
import { CancerQuotePeopleParams } from '@apis/CancerQuoteApi';

export const appendCancerPlanQuestionsToFormValues = (
  values: CancerQuotePeopleParams,
  questions: CancerPlanQuestion[],
) => {
  forEach(values, (value) => {
    if (!isEmpty(value)) {
      if (isArray(value)) {
        forEach(value, (item) => {
          merge(item, {
            medicalQuestionnaire: map(questions, ({ question }) => ({ question: question, answer: '', remark: '' })),
          });
        });
      } else {
        merge(value, {
          medicalQuestionnaire: map(questions, ({ question }) => ({ question: question, answer: '', remark: '' })),
        });
      }
    }
  });

  return values;
};
