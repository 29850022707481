import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { stringify } from 'qs';

import { ConfirmModal } from '@components/Base';

import { useHealthQuoteResultPageParams, useInsurancePage } from '../hook';

const SubHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useHealthQuoteResultPageParams();
  const [showDialog, setShowDialog] = useState(false);
  const { current, others } = useInsurancePage();

  return (
    <Container className="d-flex justify-content-between align-items-center my-3">
      <div className="d-flex align-items-center w-100">
        <div className="me-5">
          <strong>{t('healthQuote.myAge', { age: params.me.age })}</strong>
        </div>

        <Button variant="outline-primary" onClick={() => setShowDialog(true)}>
          {t('common:change')}
        </Button>

        <div className="me-auto">&nbsp;</div>

        <Dropdown>
          <Dropdown.Toggle>{current.name}</Dropdown.Toggle>
          <Dropdown.Menu>
            {map(others, ({ name, href }) => (
              <Dropdown.Item key={name} href={href} target="_blank">
                {name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <ConfirmModal
          show={showDialog}
          onHide={() => setShowDialog(false)}
          onConfirm={() =>
            navigate(
              {
                pathname: current.homePath,
                search: stringify(params),
              },
              { replace: true },
            )
          }
        />
      </div>
    </Container>
  );
};

export { SubHeader };
