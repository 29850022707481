import { useEffect } from 'react';
import { FormikErrors, useFormikContext } from 'formik';

type ServerErrorProps<T> = {
  errors: FormikErrors<T>;
};

export const ServerError = <T,>({ errors }: ServerErrorProps<T>) => {
  const { setErrors } = useFormikContext<T>();

  useEffect(() => {
    setErrors(errors);
  }, [errors, setErrors]);

  return null;
};

interface ResetFormProps {
  reset: boolean;
}

export const ResetForm = ({ reset }: ResetFormProps) => {
  const { resetForm } = useFormikContext();

  useEffect(() => {
    if (reset) {
      resetForm();
    }
  }, [reset, resetForm]);

  return null;
};
