import React from 'react';
import { Card, Button, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SUPPORTS_WITHOUT_EMAIL } from './config';

const QuoteSupportCard = () => {
  const { t } = useTranslation();

  return (
    <Card body text="white" bg="primary">
      <Card.Text>{t('quoteSupportCard.text')}</Card.Text>

      <Stack gap={1} direction="horizontal">
        {map(SUPPORTS_WITHOUT_EMAIL, ({ type, href, value, icon, label }) => (
          <Button
            key={value}
            variant="light"
            href={href}
            target={type === 'social' ? '_blank' : undefined}
            className="text-start"
          >
            <FontAwesomeIcon icon={icon} className="fa-fw me-2" />
            {label}
          </Button>
        ))}
      </Stack>
    </Card>
  );
};

export { QuoteSupportCard };
