import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { StepFormProps, FormGroupControl } from '@components/Base';

import { CardHeader } from './card-header';

const GROUP_PROPS = { className: 'mb-3' };

const UserStepForm = ({ onPrev, onNext }: StepFormProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t('userStepForm.title')} onClick={() => onPrev?.()} />
      <Card.Body>
        <Card.Text>{t('userStepForm.support')}</Card.Text>

        <FormGroupControl
          type="tel"
          name="mobileNumber"
          label={t('user.mobileNumber')}
          required
          groupProps={GROUP_PROPS}
        />
        <FormGroupControl name="fullName" label={t('user.fullName')} required groupProps={GROUP_PROPS} />

        <Card.Text>{t('userStepForm.authorized')}</Card.Text>
      </Card.Body>
      <Card.Footer>
        <div className="text-center">
          <Button size="lg" onClick={() => onNext()}>
            {t('common:getQuote')}
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export { UserStepForm };
