import React from 'react';
import { useField } from 'formik';
import { FormControl, FormControlProps } from 'react-bootstrap';

type FormikControlProps = FormControlProps &
  JSX.IntrinsicElements['input'] &
  JSX.IntrinsicElements['textarea'] & {
    name: string;
    feedback?: string;
  };

const FormikControl = ({ name, feedback, ...props }: FormikControlProps) => {
  const [field, meta, helpers] = useField(name);
  const { type, multiple } = props;
  const isInvalid = meta.touched && !!meta.error;
  const isValid = meta.touched && !meta.error;

  const fileField =
    type === 'file'
      ? {
          value: undefined,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            helpers.setValue(multiple ? event.currentTarget.files : event.currentTarget.files?.[0]);
          },
        }
      : {};

  return (
    <>
      <FormControl {...field} {...fileField} {...props} {...{ isValid, isInvalid }} />
      {feedback && <FormControl.Feedback type="valid">{feedback}</FormControl.Feedback>}
      {meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
    </>
  );
};

export { FormikControl, FormikControlProps };
