import { KeysToCamelCase, NumericString } from '@utils/typescript';

export enum PaymentProviders {
  PayWay = 'payway',
}

export enum PaymentMethods {
  AbaPay = 'abapay',
  Bakong = 'bakong',
};

export enum PaymentTransactionStatuses {
  Pending = 'pending',
  Failed = 'failed',
  Success = 'success',
}

export type PaymentTransactionResponse = {
  id: number;
  status: PaymentTransactionStatuses;
  charge_amount: NumericString;
  payment_provider: PaymentProviders;
  payment_method: PaymentMethods;
  agree_to_terms_and_conditions: boolean;
}

export type PaymentTransaction = KeysToCamelCase<PaymentTransactionResponse>;
