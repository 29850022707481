import { handleActions } from 'redux-actions';

import { carModelsSet, carVariantsSet, stateUpdate } from './action';
import { State } from './type';

const initialState: State = {
  carModelGroups: {},
  carVariantGroups: {},
};

const reducer = handleActions(
  {
    [String(carModelsSet)]: (state, { payload }) => {
      return {
        ...state,
        carModelGroups: {
          ...state.carModelGroups,
          ...payload,
        },
      };
    },

    [String(carVariantsSet)]: (state, { payload }) => {
      return {
        ...state,
        carVariantGroups: {
          ...state.carVariantGroups,
          ...payload,
        },
      };
    },

    [String(stateUpdate)]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  initialState,
);

export default reducer;
