import { call, put, takeLatest } from 'redux-saga/effects';

import { HealthQuoteApi } from '@apis/HealthQuoteApi';
import { HealthQuote } from '@models/HealthQuote';
import { SagaPayload } from '@utils/typescript';

import {
  healthQuoteFetch,
  HealthQuoteFetchPayload,
  healthQuoteSendEmail,
  HealthQuoteSendEmailPayload,
  stateUpdate,
  StateUpdatePayload
} from './action';

function* handleHealthQuoteFetch({ payload }: SagaPayload<HealthQuoteFetchPayload>) {
  const state: StateUpdatePayload = { healthQuote: null };
  try {
    state.healthQuote = (yield call(HealthQuoteApi.get, payload)) as HealthQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleHealthQuoteSendEmail({ payload }: SagaPayload<HealthQuoteSendEmailPayload>) {
  yield call(HealthQuoteApi.sendEmail, payload);
}

export default function* () {
  yield takeLatest(healthQuoteFetch, handleHealthQuoteFetch);
  yield takeLatest(healthQuoteSendEmail, handleHealthQuoteSendEmail);
}
