import React from 'react';
import Footer from '@components/Footer';
import HeaderPrimary from '@components/HeaderPrimary';
import SupportSection from '@components/SupportSection';

import CallToActionSection from './CallToActionSection';
import Benefit from './Benefit';
import HowItWorks from './HowItWorks';
import InsurerPartner from './InsurerPartner';
import TestimonialOwlCarousel from './TestimonialOwlCarousel';
import FrequentlyAskedQuestion from './FrequentlyAskedQuestion';
import FeaturedInSection from './FeaturedInSection';
import ArticleSection from './ArticleSection';

const Home = () => {
  return (
    <>
      <HeaderPrimary />
      <CallToActionSection />
      <Benefit />
      <HowItWorks />
      <InsurerPartner />
      <TestimonialOwlCarousel />
      <FrequentlyAskedQuestion />
      <ArticleSection />
      <SupportSection />
      <FeaturedInSection />
      <Footer />
    </>
  );
};

export { Home };
