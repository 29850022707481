import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HeaderSecondary from '@components/HeaderSecondary';

import PersonalAccidentQuoteBuyNewPage from './PersonalAccidentQuoteBuyNewPage';
import PersonalAccidentQuoteReviewPage from './PersonalAccidentQuoteReviewPage';
import PersonalAccidentQuoteBuyEditPage from './PersonalAccidentQuoteBuyEditPage';

const PersonalAccidentQuoteBuyRoute = () => {
  return (
    <>
      <HeaderSecondary />
      <Routes>
        <Route path="/*" element={<PersonalAccidentQuoteBuyNewPage />} />
        <Route path=":personalAccidentQuoteId/edit/*" element={<PersonalAccidentQuoteBuyEditPage />} />
        <Route path=":personalAccidentQuoteId/review" element={<PersonalAccidentQuoteReviewPage />} />
      </Routes>
    </>
  );
};

export { PersonalAccidentQuoteBuyRoute };
