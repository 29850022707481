import { mergeInitialValues } from '@utils/formik';
import { useParseParams } from '@hooks/search-params';

import { formValuesParamsSchema, FormValues } from '../CancerQuoteBuyForm';
import { useCancerQuoteEdit } from '../hook';

export const useCancerQuoteReview = () => {
  const cancerQuote = useCancerQuoteEdit();
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);
  const values = mergeInitialValues(initialValues, cancerQuote?.people);

  return { cancerQuote, values };
};
