import { object, number, string, boolean } from 'yup';
import { getRoute } from '@utils/route';
import { CarPlanTypes } from '@models/CarPlanType';
import { carFetchParamsSchema } from '@components/Feature/Car';
import { CarQuoteResultPageParams } from './type';

export const carQuotesFilterParamsSchema = object({
  idvAmount: number().default(0),
  planType: string().default(CarPlanTypes.ThirdParty),
  accidentToDriver: boolean().default(false),
  passengerLiability: boolean().default(false),
  promotionCode: string().default(''),
});

export const carQuoteResultPageParamsSchema = carFetchParamsSchema.concat(carQuotesFilterParamsSchema);

export const CAR_QUOTE_RESULT_PAGE_URL = '/car-insurance-quote-results';

export const getCarQuoteResultPageRoute = (params: CarQuoteResultPageParams) => getRoute(CAR_QUOTE_RESULT_PAGE_URL, params);
