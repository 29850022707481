import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isUndefined, isNull } from 'lodash';

import { useCurrentUser } from '@components/Auth';
import HealthQuotePremiumCardBody from '@components/HealthQuote/HealthQuotePremiumCardBody';
import { Loading, LoadingError } from '@components/Base';
import { SupportCard } from '@components/Feature/Support/support-card';

import HealthQuoteBuyForm from '../HealthQuoteBuyForm';
import { FormValues } from '../HealthQuoteBuyForm/schema';
import { healthQuoteCreate, HealthQuoteCreatePayload } from '../action';
import { useHealthQuote } from '../hook';
import { useInitialValues } from './hook';

const HealthQuoteBuyNewPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const healthQuote = useHealthQuote();
  useCurrentUser();
  const isLoading = isUndefined(healthQuote);
  const isError = isNull(healthQuote);
  const initialValues = useInitialValues();

  const handleSubmit = (values: FormValues) => {
    if (healthQuote) {
      const payload: HealthQuoteCreatePayload = {
        healthPlanId: healthQuote.healthPlanId,
        ...values
      };

      dispatch(healthQuoteCreate(payload));
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {isError && <LoadingError />}
      {healthQuote && (
        <Container className="my-5">
          <Row>
            <h2>{t('healthQuoteBuyPage.title')}</h2>
            <p>{t('healthQuoteBuyPage.subTitle')}</p>
            <Col lg={7}>
              {initialValues && <HealthQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} />}
            </Col>
            <Col lg={5}>
              <Card className="mb-4">
                <HealthQuotePremiumCardBody {...healthQuote} />
              </Card>
              <SupportCard />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export { HealthQuoteBuyNewPage };
