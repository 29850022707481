import React, { useMemo } from 'react';
import { map, times } from 'lodash';
import { Card, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { renderToString } from 'react-dom/server';

import instantQuotesImg from './instant-quotes.png';
import affordablePremiumImg from './affordable-premium.png';
import transparencyImg from './transparency.png';
import compareQuoteImg from './compare-quote.png';
import buyingProcessImg from './buying-process.png';
import instantCover from './instant-cover.png';
import claimProcessImg from './claim-process.png';
import customerSupportImg from './customer-support.png';

import '@components/Base/CustomOwlCarousel/style.scss';

import { Title } from '../title';

const IMAGES = [
  instantQuotesImg,
  affordablePremiumImg,
  transparencyImg,
  compareQuoteImg,
  buyingProcessImg,
  instantCover,
  claimProcessImg,
  customerSupportImg,
];

export interface Props {
  label: string;
  desc: string;
  image: string;
}

const ReasonCard = ({ label, desc, image }: Props) => {
  return (
    <Card className="centered-owl-item-body shadow-sm" style={{ height: '462px' }}>
      <div className="pt-3">
        <Card.Img src={image} className="w-50 mx-auto" alt="Reasons to buy car insurance on MGA Cover" />
      </div>
      <Card.Body>
        <Card.Title className="my-4 text-center">
          <h4 className="fw-bold">{label}</h4>
        </Card.Title>
        <Card.Text>{desc}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const options = {
  loop: true,
  center: true,
  items: 3,
  margin: 0,
  autoplay: false,
  dots: true,
  autoplayTimeout: 8500,
  smartSpeed: 450,
  nav: true,
  navText: [
    renderToString(<FontAwesomeIcon icon={faChevronLeft} size="3x" />),
    renderToString(<FontAwesomeIcon icon={faChevronRight} size="3x" />),
  ],
  responsive: {
    0: {
      items: 1,
    },
    720: {
      items: 2,
    },
    992: {
      items: 3,
    },
  },
};

const BuyReasonSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(8, (n) => ({
      label: t(`carInsurancePage.buyReasonSection.item${n + 1}.label`),
      desc: t(`carInsurancePage.buyReasonSection.item${n + 1}.desc`),
      image: IMAGES[n] as string,
    }));
  }, []);

  return (
    <section className="py-5">
      <Title title={t('carInsurancePage.buyReasonSection.title')}></Title>
      <Container>
        <OwlCarousel className="custom-owl-carousel" {...options}>
          {map(items, (item) => (
            <ReasonCard key={item.label} {...item} />
          ))}
        </OwlCarousel>
      </Container>
    </section>
  );
};

export { BuyReasonSection };
