import { createActions } from 'redux-actions';

import { PersonalAccidentOrder } from '@models/PersonalAccidentOrder';
import { PaymentTransaction } from '@models/PaymentTransaction';
import { Billing } from '@models/Billing';

export type State = {
  personalAccidentOrder: PersonalAccidentOrder | null | undefined;
  paymentTransaction: PaymentTransaction | null | undefined;
  billing: Billing | null | undefined;
};

export type StateUpdatePayload = Partial<State>;

export const {
  personalAccidentOrderFetch,

  paymentTransactionCreate,

  billingFetch,

  stateUpdate,
} = createActions(
  'PERSONAL_ACCIDENT_ORDER_FETCH',

  'PAYMENT_TRANSACTION_CREATE',

  'BILLING_FETCH',

  'STATE_UPDATE',
  {
    prefix: 'PERSONAL_ACCIDENT_ORDER_PAYMENT_PAGE',
  },
);
