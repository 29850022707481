import { stringify } from 'qs';

import { CarQuoteDetailsPageParams, carQuoteDetailsPageParamsSchema } from '@components/CarQuoteDetailsRoute/route';
import { replaceRouteParams } from '@utils/route';

export type CarQuoteBuyNewPageParams = CarQuoteDetailsPageParams;

export type CarQuoteBuyEditPageParams = {
  carOrderId: number;
};

export type CarQuoteBuyReviewPageParams = CarQuoteBuyEditPageParams;

export type CarQuoteBuyCustomPageParams = CarQuoteBuyEditPageParams;

export type CarQuoteBuyLockPageParams = CarQuoteBuyEditPageParams;

export const carQuoteBuyNewPageParamsSchema = carQuoteDetailsPageParamsSchema;

export const CAR_QUOTE_BUY_ROUTE_URL = '/car-insurance-quote-buy/*';

export const CAR_QUOTE_BUY_NEW_PAGE_URL = '/car-insurance-quote-buy';

export const CAR_QUOTE_BUY_EDIT_PAGE_URL = '/car-insurance-quote-buy/:carOrderId/edit';

export const CAR_QUOTE_BUY_REVIEW_PAGE_URL = '/car-insurance-quote-buy/:carOrderId/review';

export const CAR_QUOTE_BUY_CUSTOM_PAGE_URL = '/car-insurance-quote-buy/:carOrderId/custom';

export const CAR_QUOTE_BUY_LOCK_PAGE_URL = '/car-insurance-quote-buy/:carOrderId/lock';

export const CAR_QUOTE_BUY_PAY_BY_CASH_PAGE_URL = '/car-insurance-quote-buy/:carOrderId/pay-by-cash';

export const getCarQuoteBuyNewPageRoute = (params: CarQuoteBuyNewPageParams) => {
  return {
    pathname: CAR_QUOTE_BUY_NEW_PAGE_URL,
    search: stringify(params),
  };
};

export const getCarQuoteBuyEditPageRoute = (params: CarQuoteBuyEditPageParams) => {
  return {
    pathname: replaceRouteParams(CAR_QUOTE_BUY_EDIT_PAGE_URL, params),
  };
};

export const getCarQuoteBuyReviewPageRoute = (params: CarQuoteBuyReviewPageParams) => {
  return {
    pathname: replaceRouteParams(CAR_QUOTE_BUY_REVIEW_PAGE_URL, params),
  };
};

export const getCarQuoteBuyCustomPageRoute = (params: CarQuoteBuyReviewPageParams) => {
  return {
    pathname: replaceRouteParams(CAR_QUOTE_BUY_CUSTOM_PAGE_URL, params),
  };
};

export const getCarQuoteBuyLockPageRoute = (params: CarQuoteBuyReviewPageParams) => {
  return {
    pathname: replaceRouteParams(CAR_QUOTE_BUY_LOCK_PAGE_URL, params),
  };
};

export const getCarQuoteBuyPayByCashPageRoute = (params: CarQuoteBuyReviewPageParams) => {
  return {
    pathname: replaceRouteParams(CAR_QUOTE_BUY_PAY_BY_CASH_PAGE_URL, params),
  };
};
