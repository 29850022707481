import React from 'react';
import { Badge } from 'react-bootstrap';
import { tOrderStatus, getOrderStatusVariant, OrderStatuses } from '@models/Order';

const OrderBadge = ({ status }: { status: OrderStatuses }) => {
  const variant = getOrderStatusVariant(status);
  const text = tOrderStatus(status);

  return (
    <Badge data-testid={`order-badge ${variant}`} bg={variant}>
      {text}
    </Badge>
  );
};

export { OrderBadge };
