import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/search-params';

import { selectHealthQuoteComparePage } from './selector';
import { healthQuotesFetch, HealthQuotesFetchPayload } from './action';
import { HealthQuoteComparePageParams, HealthQuoteComparePageParamsSchema } from './route';

export const useHealthQuotesComparePageParams = () =>
  useParseParams<HealthQuoteComparePageParams>(HealthQuoteComparePageParamsSchema);

export const useHealthQuotesFetch = () => {
  const dispatch = useDispatch();
  const payload: HealthQuotesFetchPayload = useHealthQuotesComparePageParams();
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  useEffect(() => {
    dispatch(healthQuotesFetch(payload));
  }, []);

  return healthQuotes;
};
