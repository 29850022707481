import { call, takeLatest } from 'redux-saga/effects';

import { UserLeadApi } from '@apis/UserLeadApi';
import { SagaPayload } from '@utils/typescript';

import { userLeadCreate } from './action';
import { UserLeadCreatePayload } from './type';

function* handleUserLeadCreate({ payload }: SagaPayload<UserLeadCreatePayload>) {
  try {
    yield call(UserLeadApi.create, payload);
  } catch {
  }
}

export default function* () {
  yield takeLatest(userLeadCreate, handleUserLeadCreate);
}
