import { call, put, takeLatest } from 'redux-saga/effects';
import { UserDocument } from '@models/UserDocument';
import { UserDocumentApi } from '@apis/UserDocumentApi';
import { userDocumentsFetch, userDocumentsSet } from './action';

function* handleUserDocumentsFetch() {
  try {
    const userDocuments = (yield call(UserDocumentApi.getAll)) as UserDocument[];
    yield put(userDocumentsSet({ userDocuments }));
  } catch {
    yield put(userDocumentsSet({ userDocuments: [] }));
  }
}

export default function* () {
  yield takeLatest(userDocumentsFetch, handleUserDocumentsFetch);
}
