import React from 'react';
import { Container, Row, Col, Accordion, useAccordionButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';
import parse from 'html-react-parser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { HealthQuoteBenefitAccordion } from '@components/Feature/HealthQuote/health-quote-benefit-accordion';
import { HealthQuote } from '@models/HealthQuote';

import { Title } from './title';

const Toggle = ({ children, eventKey }: { children: React.ReactNode; eventKey: string }) => {
  const handleClick = useAccordionButton(eventKey);

  return (
    <a className="c-pointer link-info" onClick={handleClick}>
      {children}
    </a>
  );
};

const DocumentItem = ({ name, fileUrl }: { name: string; fileUrl?: string }) => {
  return (
    <a href={fileUrl} className="link-dark text-decoration-none" target="_blank" rel="noreferrer">
      <span className="d-inline-block fs-5">
        <FontAwesomeIcon icon={faDownload} size="xl" className="text-primary me-2" />
        {name}
      </span>
    </a>
  );
};

const BenefitSection = (healthQuote: HealthQuote) => {
  const { t } = useTranslation();
  const { exclusionHtml, productDocument } = healthQuote;

  return (
    <section className="py-5 bg-light">
      <Title>{pluralize(t('common:benefit'), 2)}</Title>

      <Container className="mb-5">
        <Row>
          <Col lg={10} className="mx-auto">
            <HealthQuoteBenefitAccordion {...healthQuote} />
          </Col>
        </Row>
      </Container>

      <Accordion defaultActiveKey={['documents']} flush alwaysOpen>
        <Title>
          <Toggle eventKey="documents">{pluralize(t('common:document'), 2)}</Toggle>
        </Title>

        <Accordion.Collapse eventKey="documents">
          <Container className="mb-5">
            <Row>
              <Col lg={10} className="mx-auto">
                <Row>
                  {productDocument?.policyWordingFile && (
                    <Col className="text-center">
                      <DocumentItem
                        name={t('healthQuoteDetailsPage.policyWording')}
                        fileUrl={productDocument?.policyWordingFile}
                      />
                    </Col>
                  )}
                  {productDocument?.brochureFile && (
                    <Col className="text-center">
                      <DocumentItem
                        name={t('healthQuoteDetailsPage.planBrochure')}
                        fileUrl={productDocument?.brochureFile}
                      />
                    </Col>
                  )}
                  {productDocument?.termsAndConditionsFile && (
                    <Col className="text-center">
                      <DocumentItem
                        name={t('common:termsAndConditions')}
                        fileUrl={productDocument?.termsAndConditionsFile}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </Accordion.Collapse>

        <Title>
          <Toggle eventKey="exclusions">{pluralize(t('common:exclusion'), 2)}</Toggle>
        </Title>

        <Accordion.Collapse eventKey="exclusions">
          <Container className="mb-5">
            <Row>
              <Col lg={10} className="mx-auto">
                {parse(exclusionHtml)}
              </Col>
            </Row>
          </Container>
        </Accordion.Collapse>
      </Accordion>
    </section>
  );
};

export { BenefitSection };
