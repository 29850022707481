import React, { useMemo } from 'react';
import { map, times } from 'lodash';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import carDamageImg from './car-damage.png';
import theftImg from './theft.png';
import authorizedDriverAccidentImg from './authorized-driver-accident.png';
import cashlessServicesImg from './cashless-services.png';
import thirdPartyLossesImg from './third-party-losses.png';
import peacefulMindImg from './peaceful-mind.png';

const IMAGES = [
  carDamageImg,
  theftImg,
  authorizedDriverAccidentImg,
  cashlessServicesImg,
  thirdPartyLossesImg,
  peacefulMindImg,
];

export interface Props {
  label: string;
  desc: string;
  image: string;
}

const WhyNeedCard = ({ label, desc, image }: Props) => {
  return (
    <Card className="bg-primary text-white border-0 h-100">
      <div className="text-center pt-3">
        <Card.Img src={image} className="w-50" alt="MGA Cover car insurance benefits" />
      </div>
      <Card.Body>
        <Card.Title className="my-4 text-center">
          <h4 className="fw-bold">{label}</h4>
        </Card.Title>
        <Card.Text>{desc}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const WhyNeedSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(6, (n) => ({
      label: t(`carInsurancePage.whyNeedSection.item${n + 1}.label`),
      desc: t(`carInsurancePage.whyNeedSection.item${n + 1}.desc`),
      image: IMAGES[n] as string,
    }));
  }, []);

  return (
    <section className="py-5">
      <h2 className="text-center">{t('carInsurancePage.whyNeedSection.title')}</h2>
      <p className="text-center text-secondary w-75 mx-auto">{t('carInsurancePage.whyNeedSection.subTitle')}</p>
      <Container>
        <Row className="gy-4 mt-5">
          {map(items, (item) => (
            <Col lg={4} key={item.label}>
              <WhyNeedCard {...item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export { WhyNeedSection };
