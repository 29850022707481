import { createActions } from 'redux-actions';

export const {
  passwordUpdate,
  passwordUpdateSuccess,
  passwordUpdateError,
} =
  createActions(
    'PASSWORD_UPDATE',
    'PASSWORD_UPDATE_SUCCESS',
    'PASSWORD_UPDATE_ERROR',
    {
      prefix: 'PASSWORD',
    },
  );
