import { handleActions } from 'redux-actions';
import { Pagination } from '@models/Pagination';
import { userCarsSet } from './action';
import { UserCar } from '@models/UserCar';

export interface State {
  userCars?: UserCar[];
  pagination?: Pagination;
}

const initialState: State = {};

const reducer = handleActions(
  {
    [String(userCarsSet)]: (state, { payload }) => {
      return {
        ...state,
        userCars: payload.userCars,
        pagination: payload.pagination,
      };
    },
  },

  initialState,
);

export default reducer;
