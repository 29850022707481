import humps from 'lodash-humps-ts';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';
import { HealthOrder, People } from '@models/HealthOrder';

type PeopleParams = People;

export type CreateHealthOrderParams = {
  healthQuoteId: number;
};

export type GetHealthOrderParams = {
  id: number;
};

export type UpdateHealthOrderParams = PeopleParams & GetHealthOrderParams;

export class HealthOrderApi {
  static create = async (params: CreateHealthOrderParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/health_orders`, snakes(params))
      .then((response) => humps(response.data) as HealthOrder);
  };

  static get = async (params: GetHealthOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/health_orders/${params.id}`)
      .then((response) => humps(response.data) as HealthOrder);
  };
}
