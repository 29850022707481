import { object, number } from 'yup';
import { stringify } from 'qs';

import {
  PersonalAccidentQuoteResultPageParams,
  personalAccidentQuoteResultPageParamsSchema,
} from '@components/PersonalAccidentQuoteResultPage/route';

export type PersonalAccidentQuoteDetailsPageParams = Required<
Omit<PersonalAccidentQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'>
> &
Pick<PersonalAccidentQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'> & {
  personalAccidentPlanId: number;
};

export const personalAccidentQuoteDetailsPageParamsSchema = personalAccidentQuoteResultPageParamsSchema.concat(
  object({
    personalAccidentPlanId: number(),
  }),
);

export type PersonalAccidentQuoteSendEmailPageParams = PersonalAccidentQuoteDetailsPageParams;

export const PERSONAL_ACCIDENT_QUOTE_DETAILS_ROUTE_URL = '/personal-accident-insurance-quote-details/*';

export const PERSONAL_ACCIDENT_QUOTE_DETAILS_PAGE_URL = '/personal-accident-insurance-quote-details';

export const PERSONAL_ACCIDENT_QUOTE_SEND_MAIL_PAGE = '/personal-accident-insurance-quote-details/send-email';

export const getPersonalAccidentQuoteDetailsPageRoute = (params: PersonalAccidentQuoteDetailsPageParams) => {
  return {
    pathname: PERSONAL_ACCIDENT_QUOTE_DETAILS_PAGE_URL,
    search: stringify(params),
  };
};

export const getPersonalAccidentQuoteSendEmailPageRoute = (params: PersonalAccidentQuoteSendEmailPageParams) => {
  return {
    pathname: PERSONAL_ACCIDENT_QUOTE_SEND_MAIL_PAGE,
    search: stringify(params),
  };
};
