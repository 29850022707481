import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { CancerQuote } from '@models/CancerQuote';

interface RowItemProps {
  label: string;
  value?: string | number;
}

const RowItem = ({ label, value }: RowItemProps) => {
  return (
    <Row className="mb-2">
      <Col xs={6} sm={6} md={6}>
        {label}
      </Col>
      <Col>
        <div className="d-flex justify-content-end">{value}</div>
      </Col>
    </Row>
  );
};

const FeatureCardBody = ({ geographicCoverage, hospitals }: CancerQuote) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{pluralize(t('common:feature'), 2)}</Card.Title>
      <RowItem label={t('healthQuote.geographicCoverage')} value={geographicCoverage} />
      <RowItem label={t('healthQuote.cashlessHospitals')} value={hospitals.length} />
    </Card.Body>
  );
};

export { FeatureCardBody };
