// 2024-05-10 10:05:33 +0700

export default {
  required: 'can\'t be blank',
  validPhone: 'must be a valid phone number for region {{countryName}}',
  min: 'must be at least ${min} characters',
  length: 'must be exactly ${length} characters',
  notANumber: 'is not a number',
  invalidFormat: 'has an invalid format',
  limitOutOfRange: 'total number is out of range of {{max}}',
  fileSizeOutOfRange: 'size is not between required range',
  inclusion: 'does not match',
  invalidDobWithAge: 'does not match with age',
  positive: 'must be a positive number',
};
