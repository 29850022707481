import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';

import { selectCurrentUser } from '@components/Auth';
import { BackButton } from '@components/Base';
import { useInitialValue } from '@hooks/formik';
import { ServerError } from '@components/Helper';
import UserFormControl from '@components/Feature/User/UserFormControl';

import { validationSchema } from './schema';
import { selectPersonalInfoEdit } from './selector';
import { FormValues, UserUpdatePayload } from './type';
import { userUpdate } from './action';

const PersonalInfoEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const { errors } = useSelector(selectPersonalInfoEdit);
  const initialValues = useInitialValue<FormValues>(currentUser, validationSchema);

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: UserUpdatePayload = values;
    dispatch(userUpdate(payload))
    setSubmitting(false);
  };

  if (!currentUser) return null;

  return (
    <>
      <BackButton onClick={() => navigate('./../', { replace: true })} className="mb-4" />
      <Card>
        <Card.Header className="fs-5 fw-bold">
          {t('common:personalInformation')}
        </Card.Header>
        <Card.Body>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <UserFormControl validationSchema={validationSchema} />

                <Button type="submit" disabled={isSubmitting}>
                  {t('common:update')}
                </Button>

                <ServerError errors={errors} />
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};

export { PersonalInfoEdit };
