import React, { useState, useEffect } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Card, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ResetForm, ServerError } from '@components/Helper';
import { FormGroupControl } from '@components/Base';
import OtpCodeInputGroup from '@components/OtpCodeInputGroup';
import { currentUserOtpCodeRequest } from '@components/Auth';

import { selectPassword } from './selector';
import { passwordUpdate } from './action';
import { FormValues } from './type';
import { validationSchema } from './schema';

const initialValues: FormValues = {
  password: '',
  passwordConfirmation: '',
  otpCode: '',
};

const groupProps = { className: 'mb-3' };

const Password = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { errors, isSuccess } = useSelector(selectPassword);
  const [shouldRenderAlert, setShouldRenderAlert] = useState(isSuccess);

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    dispatch(passwordUpdate(values));
    setSubmitting(false);
  };

  const handleOtpCodeRequest = () => {
    dispatch(currentUserOtpCodeRequest());
  };

  useEffect(() => {
    setShouldRenderAlert(isSuccess);
  }, [isSuccess]);

  return (
    <section>
      <h5 className="mb-4">{t('password', { ns: 'common' })}</h5>
      {shouldRenderAlert && (
        <Alert variant="success" onClose={() => setShouldRenderAlert(false)} dismissible>
          Your password has been updated.
        </Alert>
      )}
      <Card>
        <Card.Body>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <FormGroupControl
                  type="password"
                  name="password"
                  label={t('common:password')}
                  groupProps={groupProps}
                />
                <FormGroupControl
                  type="password"
                  name="passwordConfirmation"
                  label="Password confirmation"
                  groupProps={groupProps}
                />
                <OtpCodeInputGroup onRequest={handleOtpCodeRequest} />
                <Button type="submit" disabled={isSubmitting}>
                  {t('common:update')}
                </Button>
                <ServerError errors={errors} />
                <ResetForm reset={isSuccess} />
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </section>
  );
};

export { Password };
