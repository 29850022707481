import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/search-params';

import { CarFetchPayload, CarFetchParams } from './type';
import { carFetch } from './action';
import { selectFeatureCar } from './selector';
import { carFetchParamsSchema } from './route';

export const useCarFetchParams = () => {
  return useParseParams<CarFetchParams>(carFetchParamsSchema);
};

export const useCarFetch = () => {
  const dispatch = useDispatch();
  const params = useCarFetchParams();
  const { car } = useSelector(selectFeatureCar);

  useEffect(() => {
    if (car && car.id === params.carId) return;
    const payload: CarFetchPayload = params;
    dispatch(carFetch(payload));
  }, [car]);

  return car;
};
