import React from 'react';
import { useTranslation } from 'react-i18next';
import { amountToUsd } from '@utils/currency';
import { InsurerLogo } from '@components/Feature/Insurer';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';

const PersonalAccidentQuoteItem = ({ totalPremium, insurer }: PersonalAccidentQuote) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex justify-content-center align-items-center border bg-white p-1 rounded mb-1">
        <InsurerLogo insurer={insurer} fluid rounded />
      </div>
      <div>
        {t('healthQuote.premium')}: <span className="text-primary">{amountToUsd(totalPremium)}</span>
      </div>
    </div>
  );
};

export { PersonalAccidentQuoteItem };
