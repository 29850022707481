import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/search-params';

import { selectCancerQuoteComparePage } from './selector';
import { cancerQuotesFetch } from './action';
import { CancerQuoteComparePageParams, CancerQuoteComparePageParamsSchema } from './route';

export const useCancerQuotesComparePageParams = () =>
  useParseParams<CancerQuoteComparePageParams>(CancerQuoteComparePageParamsSchema);

export const useCancerQuotesFetch = () => {
  const dispatch = useDispatch();
  const payload: CancerQuoteComparePageParams = useCancerQuotesComparePageParams();
  const cancerQuotes = useSelector(selectCancerQuoteComparePage);

  useEffect(() => {
    dispatch(cancerQuotesFetch(payload));
  }, []);

  return cancerQuotes;
};
