import { put, call, takeLatest } from 'redux-saga/effects';

import { CarQuoteApi } from '@apis/CarQuoteApi';
import { CarQuote } from '@models/CarQuote';
import { SagaPayload } from '@utils/typescript';

import {
  carQuotesFetch,
  CarQuotesFetchPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleCarQuotesFetch({ payload }: SagaPayload<CarQuotesFetchPayload>) {
  const state: StateUpdatePayload = { carQuotes: [] };
  try {
    state.carQuotes = (yield call(CarQuoteApi.getAll, payload)) as CarQuote[];
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(carQuotesFetch, handleCarQuotesFetch);
}
