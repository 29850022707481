import React from 'react';

interface Props {
  title: string;
}

const Title = ({ title }: Props) => {
  return <h2 className="text-center mb-5">{title}</h2>;
};

export { Title };
