import i18n from '@components/i18n';

export enum InsuranceTypes {
  Car = 'car',
  Health = 'health',
  PersonalAccident = 'personal_accident',
  Cancer = 'cancer',
}

export const tInsuranceType = (type: InsuranceTypes) => {
  return i18n.t(`insuranceTypes.${type}`);
};
