import React from 'react';
import { Card } from 'react-bootstrap';

import { CarQuote } from '@models/CarQuote';
import { CarQuoteCoverCardBody } from '@components/Feature/CarQuote/car-quote-cover-card-body';
import { CarQuoteExclusionCardBody } from '@components/Feature/CarQuote/car-quote-exclusion-card-body';
import { CarQuoteDeductibleCardBody } from '@components/Feature/CarQuote/car-quote-deductible-card-body';
import { CarQuoteEndorsementCardBody } from '@components/Feature/CarQuote/car-quote-endorsement-card-body';

interface Props {
  carQuote: CarQuote;
}

const CoverageSummaryCard = ({ carQuote }: Props) => {
  return (
    <Card className="mb-3">
      <CarQuoteCoverCardBody {...carQuote} />
      <CarQuoteDeductibleCardBody {...carQuote} />
      <CarQuoteExclusionCardBody {...carQuote} />
      <CarQuoteEndorsementCardBody {...carQuote} />
    </Card>
  );
};

export { CoverageSummaryCard };
