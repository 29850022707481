import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Loading, NoData, ConfirmModal } from '@components/Base';
import { Photo } from '@models/Photo';
import { photoManagerDialogPhotosFetch, photoManagerDialogPhotoDelete } from '../action';
import { selectPhotoManagerDialog } from '../selector';
import { PhotoManagerDialogPhotoListProps } from '../type';

const PhotoItem = ({ id, url }: Photo) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleDelete = () => {
    dispatch(photoManagerDialogPhotoDelete({ id }));
  };

  return (
    <Card className="text-center">
      <Card.Img variant="top" src={url} />
      <Card.Body>
        <Button onClick={() => setShow(true)} variant="outline-primary">
          {t('common:delete')}
        </Button>
      </Card.Body>
      <ConfirmModal show={show} onHide={() => setShow(false)} onConfirm={handleDelete} />
    </Card>
  );
};

const PhotoList = ({ recordId, recordType, name }: PhotoManagerDialogPhotoListProps) => {
  const dispatch = useDispatch();
  const { photos } = useSelector(selectPhotoManagerDialog);
  const isLoaded = !isUndefined(photos);
  const hasPhotos = isLoaded && photos.length > 0;

  useEffect(() => {
    // reload photos every time its state changes
    if (!photos) {
      dispatch(photoManagerDialogPhotosFetch({ recordId, recordType, name }));
    }
  }, [photos]);

  return (
    <>
      {!isLoaded && <Loading />}
      {isLoaded && !hasPhotos && <NoData />}
      {hasPhotos && (
        <Container>
          <Row className="gy-4">
            {map(photos, (photo) => (
              <Col xs={6} md={4} key={photo.id}>
                <PhotoItem {...photo} />
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};

export { PhotoList };
