import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isNull, isUndefined } from 'lodash';

import MetaTag from '@components/MetaTag';
import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import { Loading } from '@components/Base';
import { formatDateTime } from '@utils/datetime';
import { ArticleBreadcrumb } from '@components/Feature/Article/article-breadcrumb';

import { selectArticleDetailsPage } from './selector';
import { articleFetch } from './action';

const ArticleDetailsPage = () => {
  const dispatch = useDispatch();
  const { articleId } = useParams();
  const { article } = useSelector(selectArticleDetailsPage);
  const isLoading = isUndefined(article);
  const isError = isNull(article);

  useEffect(() => {
    dispatch(articleFetch({ id: articleId }));
  }, []);

  return (
    <>
      {article && (
        <MetaTag
          pageName="articleDetailsPage"
          title={article.title}
          description={article.shortDescription}
          image={article.coverPhoto}
        />
      )}
      <HeaderPrimary />
      <Container className="mt-5">
        {isLoading && <Loading />}
        {isError && <p>We cannot find the article.</p>}
        {article && (
          <>
            <ArticleBreadcrumb article={article} />

            <Row>
              <Col lg={8}>
                <article className="my-5">
                  <h1>{article.title}</h1>
                  <div>{formatDateTime(article.publishedAt)}</div>
                  <div className="my-5 text-center">
                    <Image src={article.coverPhoto} fluid />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: article.content }} />
                </article>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};

export { ArticleDetailsPage };
