import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HeaderSecondary from '@components/HeaderSecondary';

import PersonalAccidentQuoteDetailsPage from './PersonalAccidentQuoteDetailsPage';
import PersonalAccidentQuoteSendEmailPage from './PersonalAccidentQuoteSendEmailPage';

const PersonalAccidentQuoteDetailsRoute = () => {
  return (
    <>
      <HeaderSecondary />
      <Routes>
        <Route index element={<PersonalAccidentQuoteDetailsPage />} />
        <Route path="send-email" element={<PersonalAccidentQuoteSendEmailPage />} />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </>
  );
};

export { PersonalAccidentQuoteDetailsRoute };
