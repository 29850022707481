import { call, put, takeLatest } from 'redux-saga/effects';

import { CancerQuoteApi } from '@apis/CancerQuoteApi';
import { CancerQuote } from '@models/CancerQuote';
import { SagaPayload } from '@utils/typescript';

import {
  cancerQuoteFetch,
  CancerQuoteFetchPayload,
  cancerQuoteSendEmail,
  CancerQuoteSendEmailPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleCancerQuoteFetch({ payload }: SagaPayload<CancerQuoteFetchPayload>) {
  const state: StateUpdatePayload = { cancerQuote: null };
  try {
    state.cancerQuote = (yield call(CancerQuoteApi.get, payload)) as CancerQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCancerQuoteSendEmail({ payload }: SagaPayload<CancerQuoteSendEmailPayload>) {
  yield call(CancerQuoteApi.sendEmail, payload);
}

export default function* () {
  yield takeLatest(cancerQuoteFetch, handleCancerQuoteFetch);
  yield takeLatest(cancerQuoteSendEmail, handleCancerQuoteSendEmail);
}
