import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import HeaderSecondary from '@components/HeaderSecondary';

import CancerQuoteDetailsPage from './CancerQuoteDetailsPage';
import CancerQuoteSendEmailPage from './CancerQuoteSendEmailPage';

const CancerQuoteDetailsRoute = () => {
  return (
    <>
      <HeaderSecondary />
      <Routes>
        <Route index element={<CancerQuoteDetailsPage />} />
        <Route path="send-email" element={<CancerQuoteSendEmailPage />} />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </>
  );
};

export { CancerQuoteDetailsRoute };
