import { Article } from '@models/Article';
import { handleActions } from 'redux-actions';
import { articleSet } from './action';

export interface State {
  article?: Article;
}

const initialState: State = {};

const reducer = handleActions(
  {
    [String(articleSet)]: (state, { payload }) => {
      return {
        ...state,
        article: payload.article,
      };
    },
  },

  initialState,
);

export default reducer;
