import { createActions } from 'redux-actions';
import { Occupation } from '@models/Occupation';

export type State = {
  occupations: Occupation[];
};

export const { occupationsFetch, occupationsSet } = createActions('OCCUPATIONS_FETCH', 'OCCUPATIONS_SET', {
  prefix: 'OCCUPATION',
});
