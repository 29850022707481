import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { isUndefined, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Loading, NoData, Pagination } from '@components/Base';
import { isPaginated } from '@utils/pagination';
import { customerOrdersFetch, ordersFetch } from './action';
import { selectPolicy } from './selector';
import PolicyItem from './PolicyItem';

type Props = {
  clientPolicy?: boolean;
};

const Policy = ({ clientPolicy: isClientPolicy = false }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { orders, pagination } = useSelector(selectPolicy);
  const isLoaded = !isUndefined(orders);
  const hasOrders = isLoaded && orders.length > 0;

  useEffect(() => {
    if (isClientPolicy) {
      dispatch(customerOrdersFetch({ page: searchParams.get('page') }));
      return;
    }

    dispatch(ordersFetch({ page: searchParams.get('page') }));
  }, []);

  return (
    <section>
      <h5 className="mb-4">{t('policy', { ns: 'common' })}</h5>
      {!isLoaded && <Loading />}
      {isLoaded && !hasOrders && <NoData />}

      {hasOrders && (
        <>
          <Row className="mb-4 gy-5">
            {map(orders, (order) => (
              <Col xl={9} key={order.id}>
                <PolicyItem order={order} clientPolicy={isClientPolicy} />
              </Col>
            ))}
          </Row>
          {isPaginated(pagination) && (
            <div className="d-flex justify-content-center">
              <br />
              <Pagination
                url="/user-dashboard/policy"
                page={pagination?.page ?? 1}
                totalPages={pagination?.totalPages ?? 1}
              />
            </div>
          )}
        </>
      )}
    </section>
  );
};

export { Policy };
