import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map, slice } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'usehooks-ts';

export type ExpandProps<T> = {
  items: T[];
  display?: number;
  expand?: boolean;
  renderItem: (item: T) => React.ReactNode;
  wrapper: React.ReactNode | React.ElementType;
  wrapperProps: object;
  onExpand: () => void;
};

const Expand = <T,>({
  expand: isExpanded = false,
  display = 12,
  items,
  renderItem,
  wrapper: Wrapper,
  wrapperProps,
  onExpand,
}: ExpandProps<T>) => {
  const { t } = useTranslation();
  const slicedItems = useMemo(() => {
    return isExpanded ? items : slice(items, 0, display);
  }, [isExpanded, items, display]);

  useEffectOnce(() => {
    if (!isExpanded && items.length <= display) {
      onExpand();
    }
  });

  const handleClick = useCallback(() => {
    onExpand();
  }, []);

  return (
    <>
      <Wrapper {...wrapperProps}>{map(slicedItems, renderItem)}</Wrapper>
      {!isExpanded && (
        <div className="mt-4 text-center">
          <Button className="w-50" onClick={handleClick} variant="primary">
            <span className="me-2">{t('common:viewMore')}</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </Button>
        </div>
      )}
    </>
  );
};

export { Expand };
