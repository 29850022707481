import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MetaTag from '@components/MetaTag';
import Footer from '@components/Footer';
import HeaderPrimary from '@components/HeaderPrimary';
import classNames from 'classnames';
import style from './style.module.scss';

const AboutUsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <MetaTag pageName="aboutUsPage" />
      <HeaderPrimary />
      <main>
        <section className={classNames(style.bg, 'mb-5 vh-100')}>
          <div className="w-50 mx-auto text-center pt-5 mb-5">
            <h1>{t('aboutUsPage.heading')}</h1>
            <span className="fw-light">{t('aboutUsPage.subHeading')}</span>
          </div>
        </section>
        <Container className="w-50 mx-auto">
          <Row>
            <Col className="text-center mb-5">
              <h2>{t('aboutUsPage.vision')}</h2>
              <p className="fw-light">{t('aboutUsPage.visionStatement')}</p>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mb-5">
              <h2>{t('aboutUsPage.mission')}</h2>
              <p className="fw-light">{t('aboutUsPage.missionStatement')}</p>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mb-5">
              <h2>{t('aboutUsPage.whoAreWe')}</h2>
              <p className="fw-light">{t('aboutUsPage.whoAreWeDescription')}</p>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export { AboutUsPage };
