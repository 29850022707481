import { call, takeLatest, put } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { getResponseError } from '@utils/error';
import { history } from '@utils/history';
import { UserDocumentApi, CreateUserDocumentParams } from '@apis/UserDocumentApi';
import i18n from '@components/i18n';
import { toastShow } from '@components/ToastProvider';
import { userDocumentCreate, userDocumentCreateError } from './action';
import { SagaPayload } from '@utils/typescript';

type Error = {
  documentType: string[];
};

function* handleUserDocumentCreate({ payload }: SagaPayload<CreateUserDocumentParams>) {
  try {
    yield call(UserDocumentApi.create, payload);
    history.navigate(`/user-dashboard/document`, { replace: true });
    yield put(toastShow({ status: 'success', body: i18n.t('toast.userDocument.create.success') }));
  } catch (e) {
    const { errors } = getResponseError(e as AxiosError);
    yield put(userDocumentCreateError({ errors: { documentType: (errors as Error).documentType[0] } }));
  }
}

export default function* () {
  yield takeLatest(userDocumentCreate, handleUserDocumentCreate);
}
