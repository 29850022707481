import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListGroup, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useField } from 'formik';

type Props = {
  label: string;
  name: string;
  age?: number | '';
};

const MemberItem = ({ label, age, name }: Props) => {
  const { t } = useTranslation();
  const [field, ,] = useField(name);

  return (
    <ListGroup.Item className="text-center">
      <h6 className="m-0">{label}</h6>
      <small className="d-block">
        ({age} {t('common:year')})
      </small>

      <ButtonGroup size="sm">
        <ToggleButton
          {...field}
          type="radio"
          id={`${name}.yes`}
          checked={field.value == 'yes'}
          variant="outline-secondary"
          value="yes"
        >
          {t('common:yes')}
        </ToggleButton>

        <ToggleButton
          {...field}
          type="radio"
          id={`${name}.no`}
          checked={field.value == 'no'}
          variant="outline-secondary"
          value="no"
        >
          {t('common:no')}
        </ToggleButton>
      </ButtonGroup>
    </ListGroup.Item>
  );
};

export { MemberItem };
