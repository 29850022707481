import { all, fork } from 'redux-saga/effects';
import { homeSaga } from '@components/Home';

import { carQuotePageSaga } from '@components/CarQuotePage';
import { carQuoteResultPageSaga } from '@components/CarQuoteResultPage';
import { carQuoteComparePageSaga } from '@components/CarQuoteComparePage';
import { carQuoteDetailsRouteSaga } from '@components/CarQuoteDetailsRoute';
import { carQuoteBuyRouteSaga } from '@components/CarQuoteBuyRoute';
import { carOrderPaymentPageSaga } from '@components/CarOrderPaymentPage';

import { healthQuoteResultPageSaga } from '@components/HealthQuoteResultPage';
import { healthQuoteComparePageSaga } from '@components/HealthQuoteComparePage';
import { healthQuoteDetailsRouteSaga } from '@components/HealthQuoteDetailsRoute';
import { healthQuoteBuyRouteSaga } from '@components/HealthQuoteBuyRoute';
import { healthOrderPaymentPageSaga } from '@components/HealthOrderPaymentPage';

import { personalAccidentQuoteResultPageSaga } from '@components/PersonalAccidentQuoteResultPage';
import { personalAccidentQuoteDetailsPageSaga } from '@components/PersonalAccidentQuoteDetailsRoute';
import { personalAccidentQuoteBuyRouteSaga } from '@components/PersonalAccidentQuoteBuyRoute';
import { personalAccidentOrderPaymentPageSaga } from '@components/PersonalAccidentOrderPaymentPage';
import { personalAccidentQuoteComparePageSaga } from '@components/PersonalAccidentQuoteComparePage';

import { cancerQuoteResultPageSaga } from '@components/CancerQuoteResultPage';
import { cancerQuoteDetailsRouteSaga } from '@components/CancerQuoteDetailsRoute';
import { cancerQuoteBuyRouteSaga } from '@components/CancerQuoteBuyRoute';
import { cancerOrderPaymentPageSaga } from '@components/CancerOrderPaymentPage';
import { cancerQuoteComparePageSaga } from '@components/CancerQuoteComparePage';

import { userRegistrationSaga } from '@components/UserRegistration';
import { userDashboardSaga } from '@components/UserDashboard';
import { authSaga } from '@components/Auth';
import { abaPaywaySaga } from '@components/AbaPayway';
import { camDxSaga } from '@components/CamDx';
import { articlePageSaga } from '@components/ArticlePage';
import { articleDetailsPageSaga } from '@components/ArticleDetailsPage';
import { photoManagerDialogSaga } from '@components/PhotoManagerDialog';
import { fakePaymentSaga } from '@components/FakePayment';
import { occupationSaga } from '@components/Occupation';
import { userLeadSaga } from '@components/UserLead';

import { featureCarSaga } from '@components/Feature/Car';

export default function* () {
  yield all([
    fork(homeSaga),

    fork(carQuotePageSaga),
    fork(carQuoteResultPageSaga),
    fork(carQuoteComparePageSaga),
    fork(carQuoteDetailsRouteSaga),
    fork(userRegistrationSaga),
    fork(carQuoteBuyRouteSaga),
    fork(carOrderPaymentPageSaga),

    fork(healthQuoteResultPageSaga),
    fork(healthQuoteComparePageSaga),
    fork(healthQuoteDetailsRouteSaga),
    fork(healthQuoteBuyRouteSaga),
    fork(healthOrderPaymentPageSaga),

    fork(personalAccidentQuoteResultPageSaga),
    fork(personalAccidentQuoteDetailsPageSaga),
    fork(personalAccidentQuoteBuyRouteSaga),
    fork(personalAccidentOrderPaymentPageSaga),
    fork(personalAccidentQuoteComparePageSaga),

    fork(cancerQuoteResultPageSaga),
    fork(cancerQuoteDetailsRouteSaga),
    fork(cancerQuoteBuyRouteSaga),
    fork(cancerOrderPaymentPageSaga),
    fork(cancerQuoteComparePageSaga),

    fork(userDashboardSaga),
    fork(authSaga),
    fork(abaPaywaySaga),
    fork(camDxSaga),
    fork(articlePageSaga),
    fork(articleDetailsPageSaga),
    fork(photoManagerDialogSaga),
    fork(fakePaymentSaga),
    fork(occupationSaga),
    fork(userLeadSaga),

    fork(featureCarSaga),
  ]);
}
