import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getResponseError } from '@utils/error';
import { passwordUpdate, passwordUpdateSuccess, passwordUpdateError } from './action';
import { UpdatePasswordPayload } from './type';
import { SagaPayload } from '@utils/typescript';
import { AuthApi } from '@apis/AuthApi';

function* handlePasswordUpdate({ payload }: SagaPayload<UpdatePasswordPayload>) {
  try {
    yield call(AuthApi.updateUserPassword, payload);
    yield put(passwordUpdateSuccess());
  } catch (e) {
    const { errors } = getResponseError(e as AxiosError);
    yield put(passwordUpdateError({ errors }));
  }
}

export default function* () {
  yield takeLatest(passwordUpdate, handlePasswordUpdate);
}
