import { handleActions } from 'redux-actions';
import { userCarSet } from './action';
import { UserCar } from '@models/UserCar';

export interface State {
  userCar?: UserCar;
}

const initialState: State = {};

const reducer = handleActions(
  {
    [String(userCarSet)]: (state, { payload }) => {
      return {
        ...state,
        userCar: payload.userCar,
      };
    },
  },

  initialState,
);

export default reducer;
