import React from 'react';
import { Container } from 'react-bootstrap';
import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';

const PrivacyPolicy = () => (
  <>
    <HeaderPrimary />
    <Container>
      <main className="w-75">
        <h1 className="pt-3 pb-5">Privacy Policy</h1>
        <section>
          <h3>Introduction</h3>
          <p>
            Please read the following terms and conditions carefully. By accessing this website and any pages, you are
            agreeing to be bound by the terms and conditions below. If you do not agree to the terms and conditions
            below, please do not access this website, or any pages thereof.
          </p>
          <p>
            This Privacy Policy explains how we collect, use, disclose, and security your information when you visit our
            website.
          </p>
          <p>
            We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert
            you about any changes by updating the “Last Updated” date of this Privacy Policy. Any changes or
            modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you
            waive the right to receive specific notice of each such change or modification.
          </p>
          <p>
            You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be
            deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in
            any revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy is
            posted.
          </p>
        </section>
        <section>
          <h3>Personal data collected</h3>
          <p>
            We may collect personal data information from Users in a variety of ways, including, but not limited to,
            when Users visit our site, register on the site, place an order, subscribe to the newsletter or request a
            quote via email, fill out a form, and resources we make available on our Site. Users may be asked to sign up
            with a phone number and verify by one time password (OTP), fill information before purchase such as full
            name, occupation, identity card, gender, email address, mailing address, and phone number. We will collect
            personal data information from Users only if they voluntarily submit such information to us.
          </p>
        </section>
        <section>
          <h3>How we use collected information</h3>
          <p>We may collect and use Users personal information for the following purposes:</p>
          <ul>
            <li>
              To process your interest/request for any of our products, to respond to any enquiries and to deliver
              notices in relation to your dealings with us and to deliver notices in relation to our products and to
              market such products whether present or future, to you.
            </li>
            <li>
              To process payments We may use the information Users provide about themselves when placing an order only
              to provide service to that order. We do not share this information with outside parties except to the
              extent necessary to provide the service.
            </li>
            <li>To fully deliver any particular product offered by us and to manage your relationship with us.</li>
            <li>
              To improve customer service the information you provide helps us respond to your customer service requests
              and support needs more efficiently.
            </li>
            <li>To run a promotion, contest, survey or other Site feature</li>
            <li>To send Users information they agreed to receive about topics we think will be of interest to them.</li>
            <li>To send periodic emails</li>
          </ul>
          <p>
            We may use the email address to send User information and updates pertaining to their order. It may also be
            used to respond to their inquiries, questions, notifications, and/or other requests. If User decides to
            opt-in to our mailing list, they will receive emails that may include company news, updates, related product
            information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include
            detailed unsubscribe instructions at the bottom of each email or User may contact us via our Site.
          </p>
        </section>
        <section>
          <h3>Disclosure or transfer of personal data</h3>
          <p>
            Your personal data provided to us will be kept confidential and where we consider it necessary or
            appropriate for the purposes of data storage or processing or providing any product on our behalf to you, we
            may transfer your personal data to insurance companies under conditions of confidentiality and similar
            levels of security safeguards. In addition, your personal data may be disclosed or transferred to the
            following categories:
          </p>
          <ul>
            <li>
              auditors, consultants, lawyers, insurance companies, banks and other information technology software
              vendors, event organizers and other professional advisers appointed by us to provide services to us or on
              our behalf.
            </li>
            <li>any government agencies, statutory authorities, local councils, industry; or</li>
            <li>
              any person to whom we are compelled or required to disclose to under the laws or in response to any
              regulatory or governmental agency.
            </li>
          </ul>
        </section>
        <section>
          <h3>Web browser cookies</h3>
          <p>
            Our Site may use “cookies” to enhance User experience. User’s web browser places cookies on their hard drive
            for record-keeping purposes and sometimes to track information about them. User may choose to set their web
            browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts
            of the Site may not function properly.
          </p>
        </section>
        <section>
          <h3>Security of your information</h3>
          <p>
            We use administrative, technical, and physical security measures to help protect your personal information.
            While we have taken reasonable steps to secure the personal information you provide to us, please be aware
            that despite our efforts, no security measures are perfect or impenetrable, and no method of data
            transmission can be guaranteed against any interception or other type of misuse. Any information disclosed
            online is vulnerable to interception and misuse by unauthorized parties. Therefore, while we use reasonable
            efforts to protect your personal information, we cannot guarantee its absolute security. If applicable law
            imposes any non-disclaimable duty to protect your personal information, you agree that national or
            intentional misconduct will be the standards used to measure our compliance with that duty.
          </p>
        </section>
      </main>
    </Container>
    <Footer />
  </>
);

export { PrivacyPolicy };
