import React, { useState } from 'react';
import { Container, Row, Col, Collapse, Button } from 'react-bootstrap';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { getHealthQuoteComparePageRoute } from '@components/HealthQuoteComparePage/route';

import { compareRemoveAll } from '../action';
import { selectHealthQuoteResultPage } from '../selector';
import { useHealthQuoteResultPageParams } from '../hook';
import { useFilterContext } from '../FilterProvider';
import { HealthQuoteItem } from './health-quote-item';

const ComparableHealthQuote = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useHealthQuoteResultPageParams();
  const { filters } = useFilterContext();
  const { comparableHealthQuotes: healthQuotes } = useSelector(selectHealthQuoteResultPage);
  const [open, setOpen] = useState(false);
  const hasHealthQuotes = healthQuotes.length > 0;

  const handleRemoveAll = () => {
    dispatch(compareRemoveAll());
  };

  const handleCompare = () => {
    navigate(getHealthQuoteComparePageRoute({
      ...params,
      ...filters,
      healthPlanIds: map(healthQuotes, 'healthPlanId'),
    }));
  };

  if (!hasHealthQuotes) return null;

  return (
    <div className="p-3 w-100 fixed-bottom shadow-lg bg-white" data-testid="comparable-health-quote">
      <Container>
        <h6 className="m-0 text-center c-pointer" onClick={() => setOpen(!open)}>
          <span className="me-2">
            {t('common:compareQuotes')} ({healthQuotes.length})
          </span>
          <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
        </h6>
        <Collapse in={open}>
          <Row className="mt-3">
            {map(healthQuotes, (quote) => (
              <Col className="d-flex align-items-center border-end" key={quote.healthPlanId}>
                <HealthQuoteItem {...quote} />
              </Col>
            ))}

            {healthQuotes.length === 1 && (
              <Col>
                <div className="d-flex flex-column justify-content-center align-items-center h-100 border-end">
                  <span className="text-danger">{t('common:compareGuide', { max: 2 })}</span>
                </div>
              </Col>
            )}

            <Col lg={2}>
              <div className="d-flex flex-column">
                <Button className="mb-3" onClick={handleCompare}>
                  {t('common:compare')}
                </Button>
                <Button variant="light" onClick={handleRemoveAll}>
                  {t('common:removeAll')}
                </Button>
              </div>
            </Col>
          </Row>
        </Collapse>
      </Container>
    </div>
  );
};

export { ComparableHealthQuote };
