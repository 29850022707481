import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/search-params';

import { PersonalAccidentQuoteDetailsPageParams, personalAccidentQuoteDetailsPageParamsSchema } from './route';
import { selectPersonalAccidentQuoteDetailsPage } from './selector';
import { personalAccidentQuoteFetch } from './action';
import { PersonalAccidentQuoteFetchPayload } from './personalAccidentQuoteFetch';

export const usePersonalAccidentQuoteDetailsParams = () =>
  useParseParams<PersonalAccidentQuoteDetailsPageParams>(personalAccidentQuoteDetailsPageParamsSchema);

export const usePersonalAccidentQuoteFetch = () => {
  const dispatch = useDispatch();
  const payload: PersonalAccidentQuoteFetchPayload = usePersonalAccidentQuoteDetailsParams();
  const { personalAccidentQuote } = useSelector(selectPersonalAccidentQuoteDetailsPage);

  useEffect(() => {
    dispatch(personalAccidentQuoteFetch(payload));
  }, []);

  return personalAccidentQuote;
};
