import { call, put, takeLatest } from 'redux-saga/effects';

import { PersonalAccidentQuoteApi } from '@apis/PersonalAccidentQuoteApi';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { SagaPayload } from '@utils/typescript';

import {
  personalAccidentQuoteFetch,
  personalAccidentQuoteSendEmail,
  PersonalAccidentQuoteSendEmailPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';
import { PersonalAccidentQuoteFetchPayload } from './personalAccidentQuoteFetch';

function* handlePersonalAccidentQuoteFetch({ payload }: SagaPayload<PersonalAccidentQuoteFetchPayload>) {
  const state: StateUpdatePayload = { personalAccidentQuote: null };
  try {
    state.personalAccidentQuote = (yield call(PersonalAccidentQuoteApi.get, payload)) as PersonalAccidentQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handlePersonalAccidentQuoteSendEmail({ payload }: SagaPayload<PersonalAccidentQuoteSendEmailPayload>) {
  yield call(PersonalAccidentQuoteApi.sendEmail, payload);
}

export default function* () {
  yield takeLatest(personalAccidentQuoteFetch, handlePersonalAccidentQuoteFetch);
  yield takeLatest(personalAccidentQuoteSendEmail, handlePersonalAccidentQuoteSendEmail);
}
