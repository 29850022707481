import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import HeaderSecondary from '@components/HeaderSecondary';

import CarQuoteForm from './CarQuoteForm';

const CarQuotePage = () => {
  return (
    <>
      <HeaderSecondary />
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col md={8}>
            <CarQuoteForm />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { CarQuotePage };
