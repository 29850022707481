import { call, put, takeLatest } from 'redux-saga/effects';
import { OrderApi, GetOrderParams } from '@apis/OrderApi';
import { CustomerOrderApi } from '@apis/CustomerOrderApi';
import { Order } from '@models/Order';
import { customerPolicyFetch, policyFetch, policySet } from './action';
import { SagaPayload } from '@utils/typescript';

function* handlePolicyFetch({ payload }: SagaPayload<GetOrderParams>) {
  try {
    const policy = (yield call(OrderApi.get, payload)) as Order;
    yield put(policySet({ policy }));
  } catch {
    yield put(policySet({ policy: null }));
  }
}

function* handleOriderizedPolicyFetch({ payload }: SagaPayload<GetOrderParams>) {
  try {
    const policy = (yield call(CustomerOrderApi.get, payload)) as Order;
    yield put(policySet({ policy }));
  } catch {
    yield put(policySet({ policy: null }));
  }
}

export default function* () {
  yield takeLatest(policyFetch, handlePolicyFetch);
  yield takeLatest(customerPolicyFetch, handleOriderizedPolicyFetch);
}
