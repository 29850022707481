import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map, times } from 'lodash';

const ChooseRightPlanSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <h2 className="text-center">{t('cancerInsurancePage.chooseRightPlanSection.title')}</h2>
            <p className="text-center text-secondary">{t('cancerInsurancePage.chooseRightPlanSection.subTitle')}</p>

            <h4>{t('cancerInsurancePage.chooseRightPlanSection.understandNeeds.title')}</h4>
            <ul>
              {map(times(3), (idx) => (
                <li key={`understandNeedsItem${idx + 1}`}>
                  <strong>
                    {t(`cancerInsurancePage.chooseRightPlanSection.understandNeeds.item${idx + 1}.label`)}
                  </strong>
                  : {t(`cancerInsurancePage.chooseRightPlanSection.understandNeeds.item${idx + 1}.desc`)}
                </li>
              ))}
            </ul>

            <h4>{t('cancerInsurancePage.chooseRightPlanSection.keyFeatures.title')}</h4>
            <ul>
              {map(times(5), (idx) => (
                <li key={`keyFeaturesItem${idx + 1}`}>
                  <strong>{t(`cancerInsurancePage.chooseRightPlanSection.keyFeatures.item${idx + 1}.label`)}</strong>:{' '}
                  {t(`cancerInsurancePage.chooseRightPlanSection.keyFeatures.item${idx + 1}.desc`)}
                </li>
              ))}
            </ul>

            <h4>{t('cancerInsurancePage.chooseRightPlanSection.comparePolicies.title')}</h4>
            <ul>
              {map(times(3), (idx) => (
                <li key={`comparePoliciesItem${idx + 1}`}>
                  <strong>
                    {t(`cancerInsurancePage.chooseRightPlanSection.comparePolicies.item${idx + 1}.label`)}
                  </strong>
                  : {t(`cancerInsurancePage.chooseRightPlanSection.comparePolicies.item${idx + 1}.desc`)}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { ChooseRightPlanSection };
