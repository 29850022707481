import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@components/Auth';
import { tGender } from '@models/User';
import { UserRoles } from '@models/User';
import { formatDate } from '@utils/datetime';
import { useNavigate } from 'react-router-dom';

interface ItemProps {
  label: string;
  value: string;
}

const Item = ({ label, value }: ItemProps) => (
  <Row className="mb-2">
    <Col xs={4} sm={4}>
      {label}
    </Col>
    <Col xs="auto" className="p-0">
      :
    </Col>
    <Col>{value}</Col>
  </Row>
);

const PersonalInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);

  if (!currentUser) return null;

  const { email, dob, gender, mobileNumber, address, fullName, fullNameKm, personalCode, role, occupation } =
    currentUser;

  const shouldRenderAccountInformation = role === UserRoles.Frontline;

  return (
    <>
      <section>
        <div className="d-flex justify-content-between mb-4">
          <h5>{t('common:personalInformation')}</h5>
          <Button variant="primary" size="sm" onClick={() => navigate('edit')}>
            {t('common:edit')}
          </Button>
        </div>
        <Card>
          <Card.Body>
            <Item label={t('user.fullName')} value={fullName} />
            <Item label={t('user.fullNameKm')} value={fullNameKm} />
            <Item label={t('user.mobileNumber')} value={mobileNumber} />
            <Item label={t('user.email')} value={email} />
            <Item label={t('user.gender')} value={tGender(gender)} />
            <Item label={t('user.dob')} value={formatDate(dob)} />
            <Item label={t('user.personalCode')} value={personalCode} />
            <Item label={t('user.occupation')} value={occupation} />
            <Item label={t('user.address')} value={address} />
          </Card.Body>
        </Card>
      </section>

      {shouldRenderAccountInformation && (
        <section className="mt-4">
          <h5 className="mb-4">{t('common:accountInformation')}</h5>
          <Card>
            <Card.Body>
              <Item label={t('common:role')} value={role} />
            </Card.Body>
          </Card>
        </section>
      )}
    </>
  );
};

export { PersonalInfo };
