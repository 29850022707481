import humps from 'lodash-humps-ts';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';
import { UserDocument } from '@models/UserDocument';

export interface GetUserDocumentParams {
  id: number;
}

export interface CreateUserDocumentParams {
  documentType: string;
  document: File;
}

export type UpdateUserDocumentParams = GetUserDocumentParams & Pick<CreateUserDocumentParams, 'document'>;

export class UserDocumentApi {
  static create = async (params: CreateUserDocumentParams) => {
    return await authAxios
      .postForm(`${API_V1_BASE}/user/user_documents`, snakes(params))
      .then((response) => humps(response.data) as UserDocument);
  };

  static getAll = async () => {
    return await authAxios
      .get(`${API_V1_BASE}/user/user_documents`)
      .then((response) => humps(response.data) as UserDocument[]);
  };

  static get = async ({ id }: GetUserDocumentParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/user_documents/${id}`)
      .then((response) => humps(response.data) as UserDocument);
  };

  static update = async ({ id, document }: UpdateUserDocumentParams) => {
    return await authAxios
      .putForm(`${API_V1_BASE}/user/user_documents/${id}`, { document })
      .then((response) => humps(response.data) as UserDocument);
  };
}
