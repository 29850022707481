import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserFetch } from './action';
import { selectCurrentUser } from './selector';

export const useCurrentUser = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    // After redirect login, current user is null
    if (!currentUser) {
      dispatch(currentUserFetch());
    }
  }, [currentUser]);

  return currentUser;
}
