import React, { useMemo } from 'react';
import { Col, Container, Row, ListGroup } from 'react-bootstrap';
import { map, times } from 'lodash';
import { useTranslation } from 'react-i18next';

const DocumentClaimSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(6, (n) => t(`carInsurancePage.documentClaimSection.item${n + 1}`));
  }, []);

  return (
    <section className="py-5">
      <Container>
        <h2 className="text-center">{t('carInsurancePage.documentClaimSection.title')}</h2>
        <p className="text-center text-secondary w-75 mx-auto">{t('carInsurancePage.documentClaimSection.subTitle')}</p>
        <Row>
          <Col lg={10} className="mx-auto">
            <ListGroup variant="flush">
              {map(items, (item, index) => (
                <ListGroup.Item key={item} className="position-relative p-3">
                  <span
                    className="position-absolute top-50 start-0 translate-middle-y btn btn-sm btn-primary rounded-pill pe-none"
                    style={{ width: '2rem', height: '2rem' }}
                  >
                    {++index}
                  </span>
                  <span className="ms-4">{item}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { DocumentClaimSection };
