import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '@components/Base';
import CancerQuotePremiumCardBody from '@components/Feature/CancerQuote/CancerQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';

import { cancerOrderCreate } from '../action';
import { CancerOrderCreatePayload } from '../cancerOrderCreate';
import { getCancerQuoteBuyEditPageRoute } from '../route';
import { FeatureCardBody } from './feature-card-body';
import { BenefitCardBody } from './benefit-card-body';
import { MemberCardBody } from './member-card-body';
import { selectIsFrontline } from '@components/Auth';

import { useCancerQuoteReview } from './hook';

const CancerQuoteReviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { cancerQuote, values } = useCancerQuoteReview();
  const isFrontline = useSelector(selectIsFrontline);

  const handlePay = () => {
    if (!cancerQuote) return null;

    const payload: CancerOrderCreatePayload = { cancerQuoteId: cancerQuote.cancerOrderId };
    dispatch(cancerOrderCreate(payload));
  };

  const handleEdit = () => {
    if (!cancerQuote) return null;

    navigate({
      ...getCancerQuoteBuyEditPageRoute({ cancerQuoteId: cancerQuote.cancerOrderId }),
      search: stringify({
        ...values,
        cancerPlanId: cancerQuote.cancerPlanId,
      }),
    });
  };

  return (
    <>
      {isUndefined(cancerQuote) && <Loading />}

      {cancerQuote && (
        <Container className="my-5">
          <Row>
            <Col lg={7}>
              <Card>
                <Card.Header>{t('common:summary')}</Card.Header>
                <FeatureCardBody {...cancerQuote} />
                <BenefitCardBody {...cancerQuote} />
                <MemberCardBody {...cancerQuote.people} onEdit={handleEdit} />
              </Card>
            </Col>
            <Col lg={5}>
              <Card className="mb-4">
                <CancerQuotePremiumCardBody {...cancerQuote} />

                <Card.Body>
                  {!cancerQuote.isValidMedicalQuestionnaire && (
                    <Alert variant="warning">{t('common:policyUnderReview')}</Alert>
                  )}

                  {isFrontline && (
                    <div className="text-end">
                      <Link
                        to={`/invoices/${cancerQuote.orderEncryptedId}.pdf`}
                        target="_blank"
                        className="d-inline-block my-3"
                      >
                        <FontAwesomeIcon icon={faDownload} className="me-2" />
                        {t('common:downloadInvoice')}
                      </Link>
                    </div>
                  )}

                  <Button
                    disabled={!cancerQuote.isValidMedicalQuestionnaire}
                    onClick={handlePay}
                    className="btn-primary btn-lg w-100"
                    size="lg"
                  >
                    <span className="me-2">{t('common:payNow')}</span>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </Card.Body>
              </Card>

              <SupportCard />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export { CancerQuoteReviewPage };
