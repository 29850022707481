import React from 'react';
import { FormGroup, FormGroupProps, FormLabel, FormLabelProps, FormText, FormTextProps } from 'react-bootstrap';
import { FormikSelect, FormikSelectProps } from '../';
import { useLabel, useControlId } from '../FormGroupBase';

type FormGroupSelectProps = FormikSelectProps & {
  name: string;
  label?: string;
  feedback?: string;
  help?: string;
  labelProps?: FormLabelProps;
  groupProps?: FormGroupProps;
  helpProps?: FormTextProps;
};

const FormGroupSelect = ({ label, help, labelProps, groupProps, helpProps, ...props }: FormGroupSelectProps) => {
  const { required, name } = props;
  const controlId = useControlId(name);
  const labelText = useLabel(label, required);

  return (
    <FormGroup {...groupProps} controlId={controlId}>
      {labelText && <FormLabel {...labelProps}>{labelText}</FormLabel>}
      <FormikSelect {...props} />
      {help && (
        <FormText muted {...helpProps}>
          {help}
        </FormText>
      )}
    </FormGroup>
  );
};

export { FormGroupSelect, FormGroupSelectProps };
