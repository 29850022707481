import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Container, Row } from 'react-bootstrap';

import { SupportCard } from '@components/Feature/Support/support-card';
import PersonalAccidentQuotePremiumCardBody from '@components/Feature/PersonalAccidentQuote/PersonalAccidentQuotePremiumCardBody';

import { personalAccidentQuoteUpdate } from '../action';
import { PersonalAccidentQuoteUpdatePayload } from '../personalAccidentQuoteUpdate';
import { usePersonalAccidentQuoteEdit } from '../hook';
import PersonalAccidentQuoteBuyForm from '../PersonalAccidentQuoteBuyForm';
import { FormValues } from '../PersonalAccidentQuoteBuyForm/schema';
import { useInitialValues } from './hook';

const PersonalAccidentQuoteBuyEditPage = () => {
  const dispatch = useDispatch();
  const personalAccidentQuote = usePersonalAccidentQuoteEdit();
  const initialValues = useInitialValues();

  const handleSubmit = (values: FormValues) => {
    if (personalAccidentQuote) {
      const payload: PersonalAccidentQuoteUpdatePayload = {
        ...values,
        id: personalAccidentQuote.personalAccidentOrderId,
      };

      dispatch(personalAccidentQuoteUpdate(payload));
    }
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          {initialValues && <PersonalAccidentQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} />}
        </Col>
        <Col lg={4}>
          {personalAccidentQuote && (
            <Card className="mb-4">
              <PersonalAccidentQuotePremiumCardBody {...personalAccidentQuote} />
            </Card>
          )}
          <SupportCard />
        </Col>
      </Row>
    </Container>
  );
};

export { PersonalAccidentQuoteBuyEditPage };
