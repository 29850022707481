import { combineActions, handleActions } from 'redux-actions';
import { camDxSignInError, passwordSignInError } from './action';
import { FormValues } from './view';

interface State {
  errors: Partial<FormValues> & { camDx?: string };
}

const initialState: State = {
  errors: {},
};

const reducer = handleActions(
  {
    [String(combineActions(passwordSignInError, camDxSignInError))]: (state, { payload }) => {
      return { ...state, errors: payload.errors };
    },
  },
  initialState,
);

export default reducer;
