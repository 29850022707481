import React, { useCallback } from 'react';
import { selectToastProvider, toastHide } from '@components/ToastProvider';
import { Toast as SystemToast } from '@models/Toast';
import { Toast, ToastContainer } from 'react-bootstrap';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

export const ToastProvider = () => {
  const dispatch = useDispatch();
  const { toasts } = useSelector(selectToastProvider);

  const handleClose = useCallback((id: string) => dispatch(toastHide({ id })), []);

  return (
    <ToastContainer position="top-center" containerPosition="fixed" className="mt-3 opacity-100" style={{ zIndex: 1 }}>
      {map(toasts, ({ id, status, body }: SystemToast) => (
        <Toast
          show
          autohide
          animation
          key={id}
          bg={status}
          delay={10000}
          onClose={() => handleClose(id)}
          data-testid={`toast ${status}`}
        >
          <Toast.Header>
            <FontAwesomeIcon icon={faBell} className="fa-fw me-2" />
            <strong className="me-auto">MGA COVER</strong>
          </Toast.Header>
          <Toast.Body>{body}</Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
};
