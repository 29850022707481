import { useMemo } from 'react';

export const useLabel = (label?: string, required?: boolean) => {
  return useMemo(() => {
    if (label) return required ? `${label}*` : label;
  }, [label, required]);
};

export const useControlId = (name: string) => {
  return useMemo(() => name.split('.').join('-'), [name]);
};
