import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import HeaderPrimary from '@components/HeaderPrimary';

import CarQuoteDetailsPage from './CarQuoteDetailsPage';
import CarQuoteSendEmailPage from './CarQuoteSendEmailPage';

const CarQuoteDetailsRoute = () => {
  return (
    <>
      <HeaderPrimary />
      <Container className="mt-4">
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <Routes>
              <Route index element={<CarQuoteDetailsPage />} />
              <Route path="send-email" element={<CarQuoteSendEmailPage />} />
              <Route path="*" element={<Navigate to="" />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { CarQuoteDetailsRoute };
