import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { map } from 'lodash';
import pluralize from 'pluralize';
import { stringify } from 'qs';

import { ConfirmModal } from '@components/Base';
import { useHealthQuoteResultPageParams } from '@components/HealthQuoteResultPage';

import { Title } from './title';

interface MemberItemProps {
  name: string;
  age: number;
}

const MemberItem = ({ name, age }: MemberItemProps) => {
  const { t } = useTranslation();

  return (
    <Card.Text className="d-flex justify-content-between mb-3">
      <strong>{name}</strong>
      <span>
        {t('healthQuoteDetailsPage.membersCovered.age', { age: age, pluralizedYear: pluralize('year', age) })}
      </span>
    </Card.Text>
  );
};

const MemberSection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useHealthQuoteResultPageParams();
  const { me, spouse, sons, daughters } = params;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const shouldRenderSons = !!sons && sons.length > 0;
  const shouldRenderDaughters = !!daughters && daughters.length > 0;

  return (
    <section className="py-5 bg-light">
      <Title>{t('healthQuoteDetailsPage.membersCovered.title')}</Title>

      <Container>
        <Row>
          <Col lg={5} className="mx-auto">
            <Card>
              <Card.Body>
                <MemberItem name={t('common:myself')} age={me.age} />
                {!!spouse && <MemberItem name={t('common:spouse')} age={spouse.age} />}

                {shouldRenderSons &&
                  map(sons, (son) => <MemberItem key={`son-${son.age}`} name={t('common:son')} age={son.age} />)}
                {shouldRenderDaughters &&
                  map(daughters, (daughter) => (
                    <MemberItem key={`daughter-${daughter.age}`} name={t('common:daughter')} age={daughter.age} />
                  ))}

                <div className="text-center mt-5">
                  <Button onClick={() => setShowDialog(true)} variant="primary">
                    {t('healthQuoteDetailsPage.membersCovered.edit')}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <ConfirmModal
        show={showDialog}
        onHide={() => setShowDialog(false)}
        onConfirm={() =>
          navigate({ pathname: '/health-insurance/members', search: stringify(params) }, { replace: true })
        }
      />
    </section>
  );
};

export { MemberSection };
