import { createActions } from 'redux-actions';

import { CancerOrder } from '@models/CancerOrder';
import { PaymentTransaction } from '@models/PaymentTransaction';
import { Billing } from '@models/Billing';

export type State = {
  cancerOrder: CancerOrder | null | undefined;
  paymentTransaction: PaymentTransaction | null | undefined;
  billing: Billing | null | undefined;
};

export type StateUpdatePayload = Partial<State>;

export const {
  cancerOrderFetch,

  paymentTransactionCreate,

  billingFetch,

  stateUpdate,
} = createActions(
  'CANCER_ORDER_FETCH',

  'PAYMENT_TRANSACTION_CREATE',

  'BILLING_FETCH',

  'STATE_UPDATE',
  {
    prefix: 'CANCER_ORDER_PAYMENT_PAGE',
  },
);
