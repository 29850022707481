import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNull, isUndefined } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { selectIsCustomer, selectIsFrontline } from '@components/Auth';
import CarQuotePremiumCardBody from '@components/Feature/CarQuote/CarQuotePremiumCardBody';
import { Loading, CopyLinkButton } from '@components/Base';
import { CarQuoteExclusionCardBody } from '@components/Feature/CarQuote/car-quote-exclusion-card-body';
import { CarQuoteCoverCardBody } from '@components/Feature/CarQuote/car-quote-cover-card-body';
import { CarQuoteDeductibleCardBody } from '@components/Feature/CarQuote/car-quote-deductible-card-body';
import { CarQuoteEndorsementCardBody } from '@components/Feature/CarQuote/car-quote-endorsement-card-body';
import { useParamsCarOrderId } from '@hooks/params';
import { OrderStatuses } from '@models/Order';

import { useCarQuoteEdit } from '../hook';
import { carOrderCreate, CarOrderCreateBillingPayload } from '../action';
import { OwnerCardBody } from './owner-card-body';
import { VehicleCardBody } from './vehicle-card-body';
import { OrderCardBody } from './order-card-body';
import {
  getCarQuoteBuyEditPageRoute,
  getCarQuoteBuyCustomPageRoute,
  getCarQuoteBuyLockPageRoute,
  getCarQuoteBuyPayByCashPageRoute,
} from '../route';

const CarQuoteBuyReviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const carOrderId = useParamsCarOrderId();
  const carQuote = useCarQuoteEdit();
  const isCustomer = useSelector(selectIsCustomer);
  const isFrontline = useSelector(selectIsFrontline);

  if (isNull(carQuote)) return null;
  if (isUndefined(carQuote)) return <Loading />;

  const handlePay = () => {
    const payload: CarOrderCreateBillingPayload = { id: carOrderId };
    dispatch(carOrderCreate(payload));
  };

  const handleEdit = () => {
    navigate(getCarQuoteBuyEditPageRoute({ carOrderId }));
  };

  const handleCustom = () => {
    navigate(getCarQuoteBuyCustomPageRoute({ carOrderId }));
  };

  const handleLock = () => {
    navigate(getCarQuoteBuyLockPageRoute({ carOrderId }));
  };

  const handlePayByCash = () => {
    navigate(getCarQuoteBuyPayByCashPageRoute({ carOrderId }));
  };

  const { user, userCar, status, policyNumber } = carQuote;

  const isPending = status === OrderStatuses.Pending;
  const isLocked = status === OrderStatuses.Locked;
  const shouldEdit = isPending && !policyNumber;
  const shouldLock = isFrontline && isPending;
  const shouldCustomPremium = isFrontline && isPending;
  const shouldPay = isCustomer && (shouldEdit || isLocked);
  const shouldPayByCash = isLocked && isFrontline;
  const shouldAction = shouldPay || shouldCustomPremium || shouldLock || shouldPayByCash;

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={7}>
          <div className="d-flex align-items-center justify-content-sm-end my-3">
            <CopyLinkButton />
          </div>

          <Card>
            <Card.Header>{t('common:summary')}</Card.Header>
            <VehicleCardBody userCar={userCar} onEdit={shouldEdit ? handleEdit : undefined} />
            <OrderCardBody {...carQuote} />
            <OwnerCardBody user={user} />
            <CarQuoteCoverCardBody {...carQuote} />
            <CarQuoteExclusionCardBody {...carQuote} />
            <CarQuoteDeductibleCardBody {...carQuote} />
            <CarQuoteEndorsementCardBody {...carQuote} />
          </Card>
        </Col>

        <Col lg={5}>
          <Card>
            <CarQuotePremiumCardBody {...carQuote} />

            {shouldAction && (
              <Card.Body>
                <div className="d-grid gap-2">
                  {isFrontline && (
                    <div className="text-end">
                      <Link
                        to={`/invoices/${carQuote.orderEncryptedId}.pdf`}
                        target="_blank"
                        className="d-inline-block my-3"
                      >
                        <FontAwesomeIcon icon={faDownload} className="me-2" />
                        {t('common:downloadInvoice')}
                      </Link>
                    </div>
                  )}

                  {shouldPay && (
                    <Button onClick={handlePay} className="btn-primary btn-lg w-100" size="lg">
                      <span className="me-2">{t('common:payNow')}</span>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  )}

                  {shouldCustomPremium && (
                    <Button onClick={handleCustom} className="btn-primary btn-lg w-100" size="lg">
                      Custom
                    </Button>
                  )}

                  {shouldLock && (
                    <Button onClick={handleLock} className="btn-primary btn-lg w-100" size="lg">
                      Confirm
                    </Button>
                  )}

                  {shouldPayByCash && (
                    <Button onClick={handlePayByCash} className="btn-primary btn-lg w-100" size="lg">
                      Pay By Cash
                    </Button>
                  )}
                </div>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export { CarQuoteBuyReviewPage };
