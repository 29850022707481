import humps from 'lodash-humps-ts';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';
import { CancerOrder, People } from '@models/CancerOrder';

type PeopleParams = People;

export type CreateCancerOrderParams = {
  cancerQuoteId: number;
};

export type GetCancerOrderParams = {
  id: number;
};

export type UpdateCancerOrderParams = PeopleParams & GetCancerOrderParams;

export class CancerOrderApi {
  static create = async (params: CreateCancerOrderParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/cancer_orders`, snakes(params))
      .then((response) => humps(response.data) as CancerOrder);
  };

  static get = async (params: GetCancerOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/cancer_orders/${params.id}`)
      .then((response) => humps(response.data) as CancerOrder);
  };
}
