import { createActions } from 'redux-actions';

export const {
  carQuotesFetch,

  carQuoteComparableAdd,
  carQuoteComparableRemove,
  carQuoteComparableRemoveAll,

  stateUpdate,
  stateReset,
} = createActions(
  'CAR_QUOTES_FETCH',

  'CAR_QUOTE_COMPARABLE_ADD',
  'CAR_QUOTE_COMPARABLE_REMOVE',
  'CAR_QUOTE_COMPARABLE_REMOVE_ALL',

  'STATE_UPDATE',
  'STATE_RESET',
  {
    prefix: 'CAR_QUOTE_RESULT_PAGE',
  },
);
