import React from 'react';
import { Card } from 'react-bootstrap';

type Props = {
  testID?: string;
  children: React.ReactNode;
};

const PersonCard = ({ testID, children }: Props) => {
  return (
    <Card data-testid={testID}>
      <Card.Body>
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: 100 }}>
          {children}
        </div>
      </Card.Body>
    </Card>
  );
};

export { PersonCard };
