import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { selectCancerQuoteComparePage } from './selector';
import { TableRowHeader } from './table-row-header';
import { TableRowItem } from './table-row-item';
import { ListGroup } from 'react-bootstrap';

const TableBenefit = () => {
  const { t } = useTranslation();
  const { cancerQuotes } = useSelector(selectCancerQuoteComparePage);

  if (!cancerQuotes) return null;

  return (
    <>
      <TableRowHeader header={t('cancerQuote.planBenefits')} />

      <TableRowItem>
        {map(cancerQuotes, ({ id, cancerPlanBenefits }) => (
          <td key={id}>
            <ListGroup variant="flush">
              {map(cancerPlanBenefits, ({ title, description }) => (
                <ListGroup.Item key={title}>
                  {title}: {description}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </td>
        ))}
      </TableRowItem>
    </>
  );
};

export { TableBenefit };
