import { ArticleApi, GetArticleParams } from '@apis/ArticleApi';
import { Article } from '@models/Article';
import { SagaPayload } from '@utils/typescript';
import { call, put, takeLatest } from 'redux-saga/effects';
import { articleFetch, articleSet } from './action';

function* handleArticleFetch({ payload }: SagaPayload<GetArticleParams>) {
  try {
    const article = (yield call(ArticleApi.get, payload)) as Article;
    yield put(articleSet({ article }));
  } catch {
    yield put(articleSet({ article: null }));
  }
}

export default function* () {
  yield takeLatest(articleFetch, handleArticleFetch);
}
