import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull, isUndefined, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import { NoData, Loading } from '@components/Base';
import { tCarPlanType } from '@models/CarPlanType';
import { selectFeatureCar } from '@components/Feature/Car';

import { CarQuotesFetchPayload } from './type';
import { carQuotesFetch } from './action';
import { useFilterContext } from './FilterProvider';
import { selectCarQuoteResultPage } from './selector';
import { CarQuoteItem } from './car-quote-item';

const CarQuoteList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { carQuotes } = useSelector(selectCarQuoteResultPage);
  const { car } = useSelector(selectFeatureCar);
  const { filters } = useFilterContext();
  const isLoading = isUndefined(carQuotes);
  const isError = isNull(carQuotes);
  const isReady = !isLoading && !isError;
  const hasCarQuotes = carQuotes && carQuotes.length > 0;

  useEffect(() => {
    if (car) {
      const payload: CarQuotesFetchPayload = {
        carId: car.id,
        manufacturedYear: car.manufacturedYear,
        ...filters,
        idvAmount: filters.idvAmount || car.recommendedAmount,
      };

      dispatch(carQuotesFetch(payload));
    }
  }, [car, filters]);

  return (
    <Container className="my-3">
      {isLoading && <Loading />}
      {isReady && !hasCarQuotes && <NoData />}
      {isReady && hasCarQuotes && (
        <>
          <h4>
            {t('carInsuranceQuote.plansTitle', {
              quotesCount: carQuotes.length,
              planTypeName: tCarPlanType(filters.planType),
            })}
          </h4>
          <Row className="gy-4">
            {map(carQuotes, (carQuote) => (
              <Col key={carQuote.carPlanId} xs={12} md={6} lg={4} xl={3}>
                <CarQuoteItem carQuote={carQuote} />
              </Col>
            ))}
          </Row>
        </>
      )}

      <div className="my-4">&nbsp;</div>
    </Container>
  );
};

export { CarQuoteList };
