import { call, put, takeLatest } from 'redux-saga/effects';

import { history } from '@utils/history';
import { UserDocumentApi, UpdateUserDocumentParams } from '@apis/UserDocumentApi';
import i18n from '@components/i18n';
import { toastShow } from '@components/ToastProvider';
import { userDocumentUpdate } from './action';
import { SagaPayload } from '@utils/typescript';

function* handleUserDocumentUpdate({ payload }: SagaPayload<UpdateUserDocumentParams>) {
  try {
    yield call(UserDocumentApi.update, payload);
    history.navigate(`/user-dashboard/document/${payload.id}`, { replace: true });
    yield put(toastShow({ status: 'success', body: i18n.t('toast.userDocument.update.success') }));
  } catch {}
}

export default function* () {
  yield takeLatest(userDocumentUpdate, handleUserDocumentUpdate);
}
