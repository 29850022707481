import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/search-params';

import { selectHealthQuoteDetailsPage } from './selector';
import { healthQuoteFetch, HealthQuoteFetchPayload } from './action';
import { HealthQuoteDetailsPageParams, healthQuoteDetailsPageParamsSchema } from './route';

export const useHealthQuoteDetailsPageParams = () =>
  useParseParams<HealthQuoteDetailsPageParams>(healthQuoteDetailsPageParamsSchema);

export const useHealthQuoteFetch = () => {
  const dispatch = useDispatch();
  const payload: HealthQuoteFetchPayload = useHealthQuoteDetailsPageParams();
  const { healthQuote } = useSelector(selectHealthQuoteDetailsPage);

  useEffect(() => {
    dispatch(healthQuoteFetch(payload));
  }, []);

  return healthQuote;
};
