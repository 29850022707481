import React from 'react';
import { map, times } from 'lodash';
import { Card, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import { useTranslation } from 'react-i18next';
import { renderToString } from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import '@components/Base/CustomOwlCarousel/style.scss';

const options = {
  loop: true,
  center: true,
  items: 3,
  margin: 0,
  autoplay: false,
  dots: true,
  autoplayTimeout: 8500,
  smartSpeed: 450,
  nav: true,
  navText: [
    renderToString(<FontAwesomeIcon icon={faChevronLeft} size="2x" />),
    renderToString(<FontAwesomeIcon icon={faChevronRight} size="2x" />),
  ],
  responsive: {
    0: {
      items: 1,
    },
    720: {
      items: 2,
    },
    992: {
      items: 3,
    },
  },
};

const WhenBuySection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5 bg-light">
      <h2 className="text-center">{t('cancerInsurancePage.whenBuySection.title')}</h2>
      <Container>
        <OwlCarousel {...options} className="custom-owl-carousel">
          {map(times(5), (idx) => (
            <Card key={`whenBuyItem${idx}`} className="p-4 m-4 shadow-sm text-center" style={{ height: '200px' }}>
              <Card.Text>{t(`cancerInsurancePage.whenBuySection.item${idx + 1}`)}</Card.Text>
            </Card>
          ))}
        </OwlCarousel>
      </Container>
    </section>
  );
};

export { WhenBuySection };
