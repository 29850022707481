import { call, put, takeLatest } from 'redux-saga/effects';

import { HealthOrderApi } from '@apis/HealthOrderApi';
import { BillingApi } from '@apis/BillingApi';
import { PaymentTransactionApi } from '@apis/PaymentTransactionApi';
import { HealthOrder } from '@models/HealthOrder';
import { Billing } from '@models/Billing';
import { PaymentTransaction } from '@models/PaymentTransaction';
import { SagaPayload } from '@utils/typescript';

import {
  healthOrderFetch,
  HealthOrderFetchPayload,
  paymentTransactionCreate,
  PaymentTransactionCreatePayload,
  billingFetch,
  BillingFetchPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';


function* handleHealthOrderFetch({ payload }: SagaPayload<HealthOrderFetchPayload>) {
  const state: StateUpdatePayload = { healthOrder: null };
  try {
    state.healthOrder = (yield call(HealthOrderApi.get, payload)) as HealthOrder;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handlePaymentTransactionCreate({ payload }: SagaPayload<PaymentTransactionCreatePayload>) {
  const state: StateUpdatePayload = { paymentTransaction: null };
  try {
    state.paymentTransaction = (yield call(PaymentTransactionApi.create, payload)) as PaymentTransaction;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleBillingFetch({ payload }: SagaPayload<BillingFetchPayload>) {
  const state: StateUpdatePayload = { billing: null };
  try {
    state.billing = (yield call(BillingApi.get, payload)) as Billing;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(healthOrderFetch, handleHealthOrderFetch);
  yield takeLatest(paymentTransactionCreate, handlePaymentTransactionCreate);
  yield takeLatest(billingFetch, handleBillingFetch);
}
