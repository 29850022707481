import { put, call, takeLatest } from 'redux-saga/effects';

import { HealthQuoteApi } from '@apis/HealthQuoteApi';
import { HealthQuote } from '@models/HealthQuote';
import { SagaPayload } from '@utils/typescript';

import {
  healthQuotesFetch,
  HealthQuotesFetchPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleHealthQuotesFetch({ payload }: SagaPayload<HealthQuotesFetchPayload>) {
  const state: StateUpdatePayload = { healthQuotes: null };
  try {
    state.healthQuotes = (yield call(HealthQuoteApi.getAll, payload)) as HealthQuote[];
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(healthQuotesFetch, handleHealthQuotesFetch);
}
