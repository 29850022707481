import { put, call, takeLatest } from 'redux-saga/effects';

import { Car } from '@models/Car';
import { CarApi } from '@apis/CarApi';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';

import { carFetch, stateUpdate } from './action';
import { CarFetchPayload, StateUpdatePayload } from './type';

function* handleCarFetch({ payload }: SagaPayload<CarFetchPayload>) {
  const state: StateUpdatePayload = {};
  try {
    state.car = (yield call(CarApi.getIdv, payload)) as Car;
    yield put(stateUpdate(state));
  } catch {
    history.navigate('/car-insurance-quote', { replace: true });
  }
}

export default function* () {
  yield takeLatest(carFetch, handleCarFetch);
}
