import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { FeaturedPersonalAccidentPlanBenefits } from '@models/PersonalAccidentPlanBenefit';
import { getPersonalAccidentPlanBenefitDescription } from '@utils/personal-accident-quote';
import { amountToUsd } from '@utils/currency';

interface RowItemProps {
  label: string;
  value?: string | number;
}

const RowItem = ({ label, value }: RowItemProps) => {
  return (
    <Row className="mb-2">
      <Col xs={6} sm={6} md={6}>
        {label}
      </Col>
      <Col>
        <div className="d-flex justify-content-end">{value}</div>
      </Col>
    </Row>
  );
};

const FeatureCardBody = ({
  geographicCoverage,
  annualLimitAmount,
  hospitals,
  personalAccidentPlanBenefits,
}: PersonalAccidentQuote) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{pluralize(t('common:feature'), 2)}</Card.Title>
      <RowItem label={t('personalAccidentQuote.geographicCoverage')} value={geographicCoverage} />
      <RowItem label={t('personalAccidentQuote.annualLimit')} value={amountToUsd(annualLimitAmount)} />
      <RowItem label={t('personalAccidentQuote.cashlessHospitals')} value={hospitals.length} />
      <RowItem
        label={t('personalAccidentPlanBenefit.basicBenefitsDeathDueToAccident')}
        value={getPersonalAccidentPlanBenefitDescription(
          personalAccidentPlanBenefits,
          FeaturedPersonalAccidentPlanBenefits.DeathDueToAccident,
        )}
      />
      <RowItem
        label={t('personalAccidentPlanBenefit.basicBenefitsPermanentAndTotalDisablementDueToAccident')}
        value={getPersonalAccidentPlanBenefitDescription(
          personalAccidentPlanBenefits,
          FeaturedPersonalAccidentPlanBenefits.PermanentAndTotalDisablementDueToAccident,
        )}
      />
      <RowItem
        label={t('personalAccidentPlanBenefit.basicBenefitsPermanentAndPartialDisablementDueToAccident')}
        value={getPersonalAccidentPlanBenefitDescription(
          personalAccidentPlanBenefits,
          FeaturedPersonalAccidentPlanBenefits.PermanentAndPartialDisablementDueToAccident,
        )}
      />
      <RowItem
        label={t('personalAccidentPlanBenefit.basicBenefitsMedicalExpenseDueToAccident')}
        value={getPersonalAccidentPlanBenefitDescription(
          personalAccidentPlanBenefits,
          FeaturedPersonalAccidentPlanBenefits.MedicalExpenseDueToAccident,
        )}
      />
    </Card.Body>
  );
};

export { FeatureCardBody };
