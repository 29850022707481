import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Order } from '@models/Order';
import { ProductDocument } from '@models/ProductDocument';

const CoverNoteItem = ({
  coverageSummary,
  coverageSummaryKm,
}: Pick<Order, 'coverageSummary' | 'coverageSummaryKm'>) => {
  const { t } = useTranslation();

  return (
    <ListGroup.Item>
      <div className="ms-2 me-auto">
        <a href={coverageSummary} className="text-decoration-none me-3" target="_blank" rel="noreferrer">
          {t('policyDetails.coverageSummaryEn')}
        </a>
        <a href={coverageSummaryKm} className="text-decoration-none me-3" target="_blank" rel="noreferrer">
          {t('policyDetails.coverageSummaryKm')}
        </a>
      </div>
    </ListGroup.Item>
  );
};

type Props = Pick<
Order,
| 'coverageSummary'
| 'coverageSummaryKm'
| 'policySchedule'
| 'insuranceCertificate'
| 'insuranceCertificateKm'
| 'invoice'
| 'invoiceKm'
> &
Pick<ProductDocument, 'claimProcedureFile' | 'policyWordingFile'>;

const DocumentListGroup = ({
  coverageSummary,
  coverageSummaryKm,
  policySchedule,
  policyWordingFile,
  claimProcedureFile,
  insuranceCertificate,
  insuranceCertificateKm,
  invoice,
  invoiceKm,
}: Props) => {
  const { t } = useTranslation();
  const hasCoverageSummary = !isEmpty(coverageSummary) || !isEmpty(coverageSummaryKm);
  const hasPolicySchedule = !isEmpty(policySchedule);
  const hasPolicyWording = !isEmpty(policyWordingFile);
  const hasClaimProcedure = !isEmpty(claimProcedureFile);
  const hasInsuranceCertificate = !isEmpty(insuranceCertificate) || !isEmpty(insuranceCertificateKm);
  const hasInvoice = !isEmpty(invoice) || !isEmpty(invoiceKm);

  return (
    <ListGroup variant="flush">
      {hasPolicySchedule && (
        <ListGroup.Item>
          <div className="ms-2 me-auto">
            <a href={policySchedule} className="text-decoration-none" target="_blank" rel="noreferrer">
              {t('policyDetails.policySchedule')}
            </a>
          </div>
        </ListGroup.Item>
      )}
      {!hasPolicySchedule && hasCoverageSummary && <CoverNoteItem {...{ coverageSummary, coverageSummaryKm }} />}
      {hasPolicyWording && (
        <ListGroup.Item>
          <div className="ms-2 me-auto">
            <a href={policyWordingFile} className="text-decoration-none me-3" target="_blank" rel="noreferrer">
              {t('policyDetails.policyWording')}
            </a>
          </div>
        </ListGroup.Item>
      )}
      {hasClaimProcedure && (
        <ListGroup.Item>
          <div className="ms-2 me-auto">
            <a href={claimProcedureFile} className="text-decoration-none" target="_blank" rel="noreferrer">
              {t('policyDetails.claimProcedure')}
            </a>
          </div>
        </ListGroup.Item>
      )}
      {hasPolicySchedule && hasCoverageSummary && <CoverNoteItem {...{ coverageSummary, coverageSummaryKm }} />}
      {hasInsuranceCertificate && (
        <ListGroup.Item>
          <div className="ms-2 me-auto">
            <a href={insuranceCertificate} className="text-decoration-none me-3" target="_blank" rel="noreferrer">
              {t('policyDetails.certificateOfInsuranceEn')}
            </a>
            <a href={insuranceCertificateKm} className="text-decoration-none me-3" target="_blank" rel="noreferrer">
              {t('policyDetails.certificateOfInsuranceKm')}
            </a>
          </div>
        </ListGroup.Item>
      )}
      {hasInvoice && (
        <ListGroup.Item>
          <div className="ms-2 me-auto">
            <a href={invoice} className="text-decoration-none me-3" target="_blank" rel="noreferrer">
              {t('policyDetails.invoiceEn')}
            </a>
            <a href={invoiceKm} className="text-decoration-none me-3" target="_blank" rel="noreferrer">
              {t('policyDetails.invoiceKm')}
            </a>
          </div>
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export { DocumentListGroup };
