import humps from 'lodash-humps-ts';
import { omit } from 'lodash';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { CarClaim } from '@models/CarClaim';
import { API_V1_BASE } from '@config';

export interface CreateCarClaimParams {
  carOrderId: number;
  accidentHappenedAt: string;
  accidentPhotos: File[];
  completedClaimForm: File;
  supportingDocuments: File[];
  passengersAndThirdPartiesPoliceReport: File;
  acceptTermsAndConditions: boolean;
}

export interface GetCarClaimParams {
  carClaimId: number;
}

export class CarClaimApi {
  static create = async (params: CreateCarClaimParams) => {
    return await authAxios
      .postForm(`${API_V1_BASE}/user/car_claims`, {
        ...omit(snakes(params), ['accident_photos']),
        'accident_photos[]': params.accidentPhotos,
      })
      .then((response) => humps(response.data) as CarClaim);
  };

  static get = async (params: GetCarClaimParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/car_claims/${params.carClaimId}`)
      .then((response) => humps(response.data) as CarClaim);
  };
}
