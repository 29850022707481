import React from 'react';

import HeaderSecondary from '@components/HeaderSecondary';

import SubHeader from './SubHeader';
import FilterSection from './FilterSection';
import HealthQuoteList from './HealthQuoteList';
import FilterProvider from './FilterProvider';
import ComparableHealthQuote from './ComparableHealthQuote';

const HealthQuoteResultPage = () => {
  return (
    <FilterProvider>
      <HeaderSecondary />
      <SubHeader />
      <FilterSection />
      <HealthQuoteList />
      <ComparableHealthQuote />
    </FilterProvider>
  );
};

export { HealthQuoteResultPage };
