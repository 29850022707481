import { createActions } from 'redux-actions';

import {
  CreateCarQuoteParams,
  EditCarQuoteParams,
  GetCarQuoteParams,
  UpdateCarQuoteParams,
  CustomCarQuoteParams,
  LockCarQuoteParams,
  PayByCashCarQuoteParams,
} from '@apis/CarQuoteApi';
import { CreateBillingCarOrderParams } from '@apis/CarOrderApi';
import { CarQuote } from '@models/CarQuote';
import { CarOrder } from '@models/CarOrder';

export type State = {
  carQuote: CarQuote | null | undefined;
  carOrder: CarOrder | null | undefined;
  carRegistrationPlaces: string[] | undefined;
};

export type CarQuoteFetchPayload = Required<GetCarQuoteParams>;
export type CarQuoteEditPayload = EditCarQuoteParams;
export type CarQuoteCreatePayload = CreateCarQuoteParams;
export type CarQuoteUpdatePayload = UpdateCarQuoteParams;
export type CarQuoteCustomPayload = CustomCarQuoteParams;
export type CarQuoteLockPayload = LockCarQuoteParams;
export type CarQuotePayByCashPayload = PayByCashCarQuoteParams;

export type CarOrderCreateBillingPayload = CreateBillingCarOrderParams;

export type StateUpdatePayload = Partial<State>;

export const {
  carQuoteFetch,
  carQuoteEdit,
  carQuoteCreate,
  carQuoteUpdate,
  carQuoteCustom,
  carQuoteLock,
  carQuotePayByCash,

  carRegistrationPlacesFetch,

  carOrderCreate,

  stateUpdate,
} = createActions(
  'CAR_QUOTE_FETCH',
  'CAR_QUOTE_EDIT',
  'CAR_QUOTE_CREATE',
  'CAR_QUOTE_UPDATE',
  'CAR_QUOTE_CUSTOM',
  'CAR_QUOTE_LOCK',
  'CAR_QUOTE_PAY_BY_CASH',

  'CAR_REGISTRATION_PLACES_FETCH',

  'CAR_ORDER_CREATE',

  'STATE_UPDATE',
  {
    prefix: 'CAR_QUOTE_BUY_PAGE',
  },
);
