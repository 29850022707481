import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Container, Row } from 'react-bootstrap';

import CancerQuotePremiumCardBody from '@components/Feature/CancerQuote/CancerQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';

import { cancerQuoteUpdate } from '../action';
import { CancerQuoteUpdatePayload } from '../cancerQuoteUpdate';
import { useCancerQuoteEdit } from '../hook';
import CancerQuoteBuyForm from '../CancerQuoteBuyForm';
import { FormValues } from '../CancerQuoteBuyForm/schema';
import { useInitialValues } from './hook';

const CancerQuoteBuyEditPage = () => {
  const dispatch = useDispatch();
  const cancerQuote = useCancerQuoteEdit();
  const initialValues = useInitialValues();

  const handleSubmit = (values: FormValues) => {
    if (cancerQuote) {
      const payload: CancerQuoteUpdatePayload = {
        ...values,
        id: cancerQuote.cancerOrderId,
      };

      dispatch(cancerQuoteUpdate(payload));
    }
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          {initialValues && <CancerQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} />}
        </Col>
        <Col lg={4}>
          {cancerQuote && (
            <Card className="mb-4">
              <CancerQuotePremiumCardBody {...cancerQuote} />
            </Card>
          )}
          <SupportCard />
        </Col>
      </Row>
    </Container>
  );
};

export { CancerQuoteBuyEditPage };
