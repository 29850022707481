import { stringify } from 'qs';
import { array, number, object } from 'yup';

import { HealthQuoteResultPageParams, healthQuoteResultPageParamsSchema } from '@components/HealthQuoteResultPage/route';

export type HealthQuoteComparePageParams = Required<Omit<HealthQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'>> &
  Pick<HealthQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'> & {
    healthPlanIds: number[];
  };

export const HEALTH_QUOTE_COMPARE_PAGE_URL = '/health-insurance-quote-compare';

export const HealthQuoteComparePageParamsSchema = healthQuoteResultPageParamsSchema.concat(
  object({
    healthPlanIds: array().of(number()),
  })
);

export const getHealthQuoteComparePageRoute = (params: HealthQuoteComparePageParams) => {
  return {
    pathname: HEALTH_QUOTE_COMPARE_PAGE_URL,
    search: stringify(params),
  };
}
