import React from 'react';
import { Container } from 'react-bootstrap';
import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import { MGA_PHONE_NUMBER_LINE_1, MGA_PHONE_NUMBER_LINE_2, MGA_INFO_EMAIL } from '@config';

const TermsAndConditions = () => (
  <>
    <HeaderPrimary />
    <Container>
      <main className="w-75">
        <h1 className="pt-3 pb-5">Terms and Conditions</h1>
        <p>
          Mgacover.com (the “Website”) is owned and operated by MGA Asia Insurance Brokers Co., Ltd (hereafter called
          “MGA Cover”). The website <a href="https://mgacover.com">https://mgacover.com</a> provides consumers choices
          and an easy way for users to search, compare, purchase, claim and renew policies from multiple Insurance
          companies. MGA Cover is not an insurance company. MGA Asia Insurance Brokers is a licensed Insurance Broking
          Company holding a Broking license under the Insurance Regulator of Cambodia of non-Bank Financial Services
          Authority of Cambodia.
        </p>
        <p>
          MGA Cover may pass on your personal information to the relevant participating provider if you apply to
          purchase a product through <a href="https://mgacover.com">https://mgacover.com</a>, however, MGA Cover does
          not guarantee when or if you will actually acquire the product that you have chosen. MGACover does not accept
          any liability arising out of circumstances where there is delay in you acquiring the product you have chosen.
        </p>
        <p>
          Please note that MGA Cover is only collecting or assisting in collecting the premium deposit on behalf of the
          insurer you have chosen to buy the policy. The acceptance of the deposit as premium and final issuance of the
          policy is subject to the underwriting norms and discretion of the Insurer whose policy you have chosen to buy
          on which MGA Cover has no control. MGA Cover will ensure that the amount is refunded in case there is no
          ultimate issuance of policy.
        </p>
        <p>
          By visiting our website and accessing the information, resources, services, products, and tools we provide,
          you understand and agree to accept and adhere to the following terms and conditions as stated in this policy
          (hereafter referred to as ‘User Agreement’), along with the terms and conditions as stated in our Privacy
          Policy (please refer to the Privacy Policy for more information).
        </p>
        <p>
          We reserve the right to change this User Agreement from time to time without notice. You acknowledge and agree
          that it is your responsibility to review this User Agreement periodically to familiarize yourself with any
          modifications. Your continued use of this site after such modifications will constitute acknowledgment and
          agreement of the modified terms and conditions.
        </p>
        <section>
          <h3>General Terms</h3>
          <ul>
            <li>
              In order to access our website, you may be required to provide certain information about yourself (such as
              identification, contact details, etc.) as part of the registration process, or as part of your ability to
              use the website. You agree that any information you provide will always be accurate, correct, and up to
              date.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of any login information associated with any
              account you use to access our website. Accordingly, you are responsible for all activities that occur
              under your account/s.
            </li>
            <li>
              Engaging in any activity that disrupts or interferes with our website, including the servers and/or
              networks to which our website is located or connected, is strictly prohibited.
            </li>
            <li>
              Attempting to copy, duplicate, reproduce, sell, trade, or resell our website is strictly prohibited.
            </li>
            <li>MGA Cover may not be able to provide matches for every Registered User seeking to use its services.</li>
            <li>
              You agree to not use the website for any illegal purpose, not upload any software that contains viruses or
              malicious code which may impair or corrupt the site’s data or another user’s devices.
            </li>
            <li>
              MGA Cover reserves the right to monitor and control any activity or content on the site, and may, in our
              sole discretion, investigate and take action any violation of these terms and conditions, which includes
              but is not limited to: prevention of access to the site and/or our services, and reporting any suspicious
              activity to the appropriate authorities.
            </li>
            <li>
              You agree that MGA Cover shall be entitled to use or disclose any information or data disclosed by you in
              accordance with our Privacy Policy. You are entitled to withdraw such consent according to the procedure
              we may prescribe from time to time.
            </li>
          </ul>
        </section>
        <section>
          <h3>Privacy</h3>
          <p>
            Your privacy is very important to us, which is why we’ve created a separate Privacy Policy in order to
            explain in detail how we collect, use, disclose, and secure your information when you visit our website.
          </p>
        </section>
        <section>
          <h3>Your Account</h3>
          <p>
            The site contains password-protected and/or other areas which are restricted only to users with accounts.
            You agree not to attempt to obtain unauthorized access to such parts of this site, or to any other protected
            information, through any means not intentionally made available by us for your specific use.
          </p>
          <ul>
            <li>
              By signing up to create an account, you may provide us with a Username and One Time Password, subject to
              the guidelines that we may impose to keep your account secure.
            </li>
            <li>
              Your account is to be used only by you. You may not authorize others to use your account, and you may not
              assign or otherwise transfer your account to any other person or entity. By using our services, you agree
              that MGA Cover is not responsible for third-party access to your account that results from theft or
              misappropriation of your user name and passwords.
            </li>
            <li>
              You shall be responsible for the security of your account and for any disclosure or use of your Username
              and/or Password. Notice should be provided to us as soon as you obtain knowledge or have reason for
              suspecting that the security or confidentiality of your Username and/or Password has been compromised.
            </li>
          </ul>
        </section>
        <section>
          <h3>Copyrights/Trademarks</h3>
          <p>
            All content and materials available on <a href="https://mgacover.com">https://mgacover.com</a>, including
            but not limited to text, graphics, website name, code, images and logos are the intellectual property of MGA
            Cover and are protected by applicable copyright and trademark law. Any inappropriate use, including but not
            limited to the reproduction, distribution, display or transmission of any content on this site is strictly
            prohibited, unless specifically authorized by MGA Asia Insurance Brokers Co., Ltd.
          </p>
        </section>
        <section>
          <h3>Termination</h3>
          <p>
            Both parties may terminate these Terms and conditions at any time. Without limiting the foregoing, MGA Cover
            shall have the right to immediately restrict, suspend or terminate your access to Website and/or its
            services in the event of any conduct by you, which MGA Cover, in its sole discretion, consider to be
            unacceptable, or in the event of any breach by you of this Terms and conditions.
          </p>
        </section>
        <section>
          <h3>Disclaimer</h3>
          <p>
            MGA Cover does not represent or warrant that this site, its products, services, and/or content will be
            secure, timely, uninterrupted or error-free or operate in combination with any other hardware, software,
            system or data, result will meet your requirements or expectations. You understand and agree that use of
            this Website is at your sole risk.
          </p>
          <p>
            You agree that any and all insurance products marketed or quoted on this Site are the products of
            third-party insurers. We make no representations or warranties with respect to such products, except those
            that were provided to us by the third-party insurers and we accept no liability in connection with such
            products. All such products are provided to you in accordance with the terms and conditions of the
            third-party insurance provider who issues such products.
          </p>
        </section>
        <section>
          <h3>Governing law</h3>
          <p>
            These Terms of Use and your use of the Website are governed under the laws of Cambodia and will be subject
            to the exclusive jurisdiction and venue of the trial courts within Cambodia.
          </p>
          <p>
            We do not represent that materials on this Website are appropriate or available for use in locations outside
            Cambodia. If you access this Website remotely, you do so on your own initiative, and you are responsible for
            compliance with the local laws, if and to the extent local laws are applicable. All parties to this Terms
            and conditions waive their respective rights to a trial by jury.
          </p>
        </section>
        <section>
          <h3>Contact Information</h3>
          <p>
            If you have any questions or comments about these our Terms and conditions as outlined above, you can
            contact us at:
          </p>
          <span className="d-block">MGA Asia Insurance Brokers</span>
          <span className="d-block">
            Address: Building No. 466, Mao Tse Toung Boulevard (245) corner, St 150, Phnom Penh
          </span>
          <span className="d-block">
            Telephone: {MGA_PHONE_NUMBER_LINE_1} | {MGA_PHONE_NUMBER_LINE_2}
          </span>
          <span className="d-block">Email: {MGA_INFO_EMAIL}</span>
        </section>
      </main>
    </Container>
    <Footer />
  </>
);

export { TermsAndConditions };
