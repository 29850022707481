import { createActions } from 'redux-actions';

import { SendEmailPersonalAccidentQuoteParams } from '@apis/PersonalAccidentQuoteApi';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';

export type State = {
  personalAccidentQuote: PersonalAccidentQuote | null | undefined;
};
export type StateUpdatePayload = Partial<State>;

export type PersonalAccidentQuoteSendEmailPayload = SendEmailPersonalAccidentQuoteParams;

export const { personalAccidentQuoteSendEmail, personalAccidentQuoteFetch, stateUpdate } = createActions(
  'PERSONAL_ACCIDENT_QUOTE_SEND_EMAIL',
  'PERSONAL_ACCIDENT_QUOTE_FETCH',
  'STATE_UPDATE',
  {
    prefix: 'PERSONAL_ACCIDENT_QUOTE_DETAILS_PAGE',
  },
);
