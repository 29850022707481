import React from 'react';
import { useParams } from 'react-router-dom';
import { NoData } from '@components/Base';
import { OrderableTypes } from '@models/Order';
import CarPolicyDetails from './CarPolicyDetails';
import HealthPolicyDetails from './HealthPolicyDetails';
import PersonalAccidentPolicyDetails from './PersonalAccidentPolicyDetails';
import CancerPolicyDetails from './CancerPolicyDetails';

type Props = {
  clientPolicy?: boolean;
};

const PolicyDetails = ({ clientPolicy = false }: Props) => {
  const { orderableType } = useParams();

  switch (orderableType) {
    case OrderableTypes.Car:
      return <CarPolicyDetails clientPolicy={clientPolicy} />;
    case OrderableTypes.Health:
      return <HealthPolicyDetails />;
    case OrderableTypes.PersonalAccident:
      return <PersonalAccidentPolicyDetails />;
    case OrderableTypes.Cancer:
      return <CancerPolicyDetails />;
    default:
      return <NoData />;
  }
};

export { PolicyDetails };
