import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { User } from '@models/User';
import { snakes } from '@utils/lodash';
import { API_V1_BASE } from '@config';
import { UserProfile } from '@models/User';

export interface SignInWithOtpCodeAuthParams {
  mobileNumber: string;
}

export interface SignInWithPasswordAuthParams {
  mobileNumber: string;
}

export interface RequestOtpCodeAuthParams {
  mobileNumber: string;
}

export interface VerifyWithOtpCodeAuthParams extends SignInWithOtpCodeAuthParams {
  otpCode: string;
}

export interface VerifyWithPasswordAuthParams extends SignInWithPasswordAuthParams {
  password: string;
}

export type UpdateUserProfileAuthParams = Pick<
  UserProfile,
  | 'firstName'
  | 'firstNameKm'
  | 'lastName'
  | 'lastNameKm'
  | 'gender'
  | 'dob'
  | 'personalCode'
  | 'occupation'
  | 'email'
  | 'mobileNumber'
  | 'address'
>;

export type UpdateUserPasswordAuthParams = {
  password: string;
  passwordConfirmation: string;
  otpCode: string;
};

export class AuthApi {
  static getUserProfile = async () => {
    return await authAxios.get(`${API_V1_BASE}/user/profile`).then((response) => humps(response.data) as User);
  };

  static updateUserProfile = async (params: UpdateUserProfileAuthParams) => {
    return await authAxios
      .put(`${API_V1_BASE}/user/profile`, snakes(params))
      .then((response) => humps(response.data) as UserProfile);
  };

  static updateUserPassword = async (params: UpdateUserPasswordAuthParams) => {
    return await authAxios.put(`${API_V1_BASE}/user/password`, snakes(params));
  };

  static requestUserOtpCode = async () => {
    return await authAxios.post(`${API_V1_BASE}/user/otp_code`);
  };

  static requestOtpCode = async ({ mobileNumber }: RequestOtpCodeAuthParams) => {
    return await authAxios.post(`${API_V1_BASE}/auth/otp_code/${mobileNumber}/request`);
  };

  static signInWithOtpCode = async ({ mobileNumber }: SignInWithOtpCodeAuthParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/auth/otp_code/${mobileNumber}/register`)
      .then((response) => humps(response.data) as User);
  };

  static signInWithPassword = async ({ mobileNumber }: SignInWithPasswordAuthParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/auth/password/${mobileNumber}/check`)
      .then((response) => humps(response.data) as User);
  };

  static verifyWithOtpCode = async ({ mobileNumber, otpCode }: VerifyWithOtpCodeAuthParams) => {
    return await authAxios
      .put(`${API_V1_BASE}/auth/otp_code/${mobileNumber}/verify`, snakes({ otpCode }))
      .then((response) => humps(response.data) as User);
  };

  static verifyWithPassword = async ({ mobileNumber, password }: VerifyWithPasswordAuthParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/auth/password/${mobileNumber}/verify`, snakes({ password }))
      .then((response) => humps(response.data) as User);
  };
}
