import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { tGender } from '@models/User';
import { formatDate } from '@utils/datetime';
import { CarOrder } from '@models/CarOrder';

type Props = {
  user: CarOrder['user'];
};

const OwnerCardBody = ({ user }: Props) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{t('carQuoteBuyPage.carQuoteReview.ownerTitle')}</Card.Title>
      <ul className="list-unstyled mb-0">
        <li className="fw-bold">{user.fullName}</li>
        <li>{user.fullNameKm}</li>
        <li>
          {t('user.email')}: {user.email}
        </li>
        <li>
          {t('user.gender')}: {tGender(user.gender)}
        </li>
        <li>
          {t('user.dob')}: {formatDate(user.dob)}
        </li>
        <li>
          {t('user.personalCode')}: {user.personalCode}
        </li>
        <li>
          {t('user.occupation')}: {user.occupation}
        </li>
        <li>
          {t('user.mobileNumber')}: {user.mobileNumber}
        </li>
        <li>
          {t('user.address')}: {user.address}
        </li>
      </ul>
    </Card.Body>
  );
};

export { OwnerCardBody };
