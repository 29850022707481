import { flatMap, map } from 'lodash';
import { Car } from './Car';
import { CarSubModel } from './CarSubModel';

export type CarVariant = Pick<Car, 'engineCapacity'> &
Pick<CarSubModel, 'unknown'> & {
  carSubModelId: CarSubModel['id'];
  carSubModelName: CarSubModel['name'];
};

export const composeCarVariants = (carSubModels: CarSubModel[]): CarVariant[] => {
  return flatMap(carSubModels, ({ id, name, unknown, engineCapacities }) => {
    return map(engineCapacities, (engineCapacity) => ({
      engineCapacity,
      carSubModelId: id,
      carSubModelName: name,
      unknown,
    }));
  });
};

export const getCarVariantName = ({ unknown, carSubModelName, engineCapacity }: CarVariant) => {
  if (unknown) return `${engineCapacity} CC`;
  return `${carSubModelName} - (${engineCapacity} CC)`;
};
