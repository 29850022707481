import { stringify } from 'qs';

import {
  HealthQuoteDetailsPageParams,
  healthQuoteDetailsPageParamsSchema,
} from '@components/HealthQuoteDetailsRoute/route';
import { replaceRouteParams } from '@utils/route';

export type HealthQuoteBuyNewPageParams = HealthQuoteDetailsPageParams;

export type HealthQuoteBuyEditPageParams = {
  healthQuoteId: number;
};

export type HealthQuoteBuyReviewPageParams = HealthQuoteBuyEditPageParams;

export type HealthQuoteBuyCustomPageParams = {
  healthOrderId: number;
};

export const healthQuoteBuyNewPageParamsSchema = healthQuoteDetailsPageParamsSchema;

export const HEALTH_QUOTE_BUY_ROUTE_URL = '/health-insurance-quote-buy/*';

export const HEALTH_QUOTE_BUY_NEW_PAGE_URL = '/health-insurance-quote-buy';

export const HEALTH_QUOTE_BUY_EDIT_PAGE_URL = '/health-insurance-quote-buy/:healthQuoteId/edit';

export const HEALTH_QUOTE_BUY_REVIEW_PAGE_URL = '/health-insurance-quote-buy/:healthQuoteId/review';

export const HEALTH_QUOTE_BUY_CUSTOM_PAGE_URL = '/health-insurance-quote-buy/:healthOrderId/custom';

export const getHealthQuoteBuyNewPageRoute = (params: HealthQuoteBuyNewPageParams) => {
  return {
    pathname: HEALTH_QUOTE_BUY_NEW_PAGE_URL,
    search: stringify(params),
  };
};

export const getHealthQuoteBuyEditPageRoute = (params: HealthQuoteBuyEditPageParams) => {
  return {
    pathname: replaceRouteParams(HEALTH_QUOTE_BUY_EDIT_PAGE_URL, params),
  };
};

export const getHealthQuoteBuyReviewPageRoute = (params: HealthQuoteBuyReviewPageParams) => {
  return {
    pathname: replaceRouteParams(HEALTH_QUOTE_BUY_REVIEW_PAGE_URL, params),
  };
};

export const getHealthQuoteBuyCustomPageRoute = (params: HealthQuoteBuyCustomPageParams) => {
  return {
    pathname: replaceRouteParams(HEALTH_QUOTE_BUY_CUSTOM_PAGE_URL, params),
  };
};
