import humps from 'lodash-humps-ts';
import { API_V1_BASE } from '@config';
import { authAxios } from '@components/Auth';
import { CarModel } from '@models/CarModel';
import { CarBrand } from '@models/CarBrand';

export type GetAllCarModelParams = {
  carBrandId: CarBrand['id'];
};

export type GetCarModelParams = Pick<CarModel, 'id'>;

export class CarModelApi {
  static getAll = async ({ carBrandId }: GetAllCarModelParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/car_brands/${carBrandId}/car_models`)
      .then((response) => humps(response.data) as CarModel[]);
  };

  static get = async ({ id }: GetCarModelParams) => {
    return await authAxios.get(`${API_V1_BASE}/car_models/${id}`).then((response) => humps(response.data) as CarModel);
  };
}
