import { pick } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUser, selectIsCustomer } from '@components/Auth';
import { mergeInitialValues } from '@utils/formik';
import { getFieldKeys } from '@utils/yup';
import { useParseParams } from '@hooks/search-params';

import { FormValues, formValuesParamsSchema, userFormValuesParamsSchema } from '../CarQuoteBuyForm';

export const useInitialValues = () => {
  const currentUser = useSelector(selectCurrentUser);
  const isCustomer = useSelector(selectIsCustomer);
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);

  return useMemo(() => {
    if (!currentUser) return;

    if (isCustomer) {
      return mergeInitialValues(initialValues, pick(currentUser, getFieldKeys(userFormValuesParamsSchema)));
    }

    return initialValues;
  }, [currentUser]);
};
