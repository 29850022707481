import { createAction } from 'redux-actions';

export const policyFetch = createAction('USER_DASHBOARD_POLICY_CLAIM_CAR_POLICY_CLAIM_POLICY_FETCH');
export const policySet = createAction('USER_DASHBOARD_POLICY_CLAIM_CAR_POLICY_CLAIM_POLICY_SET');

export const carPolicyClaimCarBrandsFetch = createAction(
  'USER_DASHBOARD_CAR_POLICY_CLAIM_CAR_BRANDS_FETCH',
);
export const carPolicyClaimCarBrandsSet = createAction(
  'USER_DASHBOARD_CAR_POLICY_CLAIM_CAR_BRANDS_SET',
);

export const carPolicyClaimCarModelsFetch = createAction(
  'USER_DASHBOARD_CAR_POLICY_CLAIM_CAR_MODELS_FETCH',
);
export const carPolicyClaimCarModelsSet = createAction(
  'USER_DASHBOARD_CAR_POLICY_CLAIM_CAR_MODELS_SET',
);

export const carClaimCreate = createAction('USER_DASHBOARD_CAR_CLAIM_CREATE');
export const carClaimSet = createAction('USER_DASHBOARD_CAR_CLAIM_SET');
