import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { map, isUndefined } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { NoData, Loading } from '@components/Base';
import { Order, OrderableTypes } from '@models/Order';
import { CarPolicyCard } from './car-policy-card';
import { HealthPolicyCard } from './health-policy-card';
import { PersonalAccidentPolicyCard } from './personal-accident-policy-card';
import { CancerPolicyCard } from './cancer-policy-card';
import { ordersFetch } from './action';
import { selectDashboard } from './selector';

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orders } = useSelector(selectDashboard);
  const isLoaded = !isUndefined(orders);
  const hasOrders = isLoaded && orders.length > 0;

  useEffect(() => {
    dispatch(ordersFetch({ status: 'active' }));
  }, []);

  const renderOrder = useCallback((order: Order) => {
    switch (order.orderableType) {
      case OrderableTypes.Car:
        return <CarPolicyCard order={order} />;
      case OrderableTypes.Health:
        return <HealthPolicyCard order={order} />;
      case OrderableTypes.PersonalAccident:
        return <PersonalAccidentPolicyCard order={order} />;
      case OrderableTypes.Cancer:
        return <CancerPolicyCard {...order} />;
      default:
        return null;
    }
  }, []);

  return (
    <section>
      <h5 className="mb-4">{t('dashboard', { ns: 'common' })}</h5>
      {!isLoaded && <Loading />}
      {isLoaded && !hasOrders && <NoData />}
      {hasOrders && (
        <Row className="mb-4 gy-5" data-testid="dashboard-insurance-card-list">
          {map(orders, (order) => (
            <Col xl={9} key={`${order.orderableType}-${order.orderableId}`}>
              {renderOrder(order)}
            </Col>
          ))}
        </Row>
      )}
    </section>
  );
};

export { Dashboard };
