import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  size?: SizeProp;
  color?: string;
};

const UserAvatar = (props: Props) => {
  return <FontAwesomeIcon icon={faUserCircle as IconProp} size="3x" color="#2862ff" {...props} />;
};

export { UserAvatar };
