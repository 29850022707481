import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { BackButton } from '@components/Base';
import { useParamsCarOrderId } from '@hooks/params';

import { carOrderRenew, CarOrderRenewPayload } from './action';
import { useCarOrderPreview } from './hook';

const CarPolicyRenewal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carOrderId = useParamsCarOrderId();
  const carOrder = useCarOrderPreview();

  const handleBack = () => {
    navigate('/user-dashboard/policy', { replace: true });
  };

  const handleRenew = () => {
    const payload: CarOrderRenewPayload = { id: carOrderId };
    dispatch(carOrderRenew(payload));
  };

  if (!carOrder) return null;

  const {
    insurer: { carProductEnabled },
  } = carOrder;

  return (
    <section data-testid="car-policy-renewal-section">
      <BackButton onClick={handleBack} className="mb-4" />
      <h5 className="mb-4">{t('common:renewPolicy')}</h5>
      {carOrder && (
        <>
          {!carProductEnabled && (
            <Alert variant="warning">
              <Alert.Heading>{t('carInsuranceQuote.disabledTitle')}</Alert.Heading>
              <p>{t('carInsuranceQuote.disabledDesc')}</p>
            </Alert>
          )}

          <Card>
            <Card.Body>
              <Button disabled={!carProductEnabled} onClick={handleRenew}>
                Proceed to custom premium
              </Button>
            </Card.Body>
          </Card>
        </>
      )}
    </section>
  );
};

export { CarPolicyRenewal };
