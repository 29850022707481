import { takeLatest, put, call } from 'redux-saga/effects';
import { setAuthTokens, clearAuthTokens } from 'axios-jwt';

import { UserProfile } from '@models/User';
import { AuthApi } from '@apis/AuthApi';
import { SagaPayload } from '@utils/typescript';

import {
  currentUserLogout,
  accessTokenSet,
  currentUserFetch,
  currentUserOtpCodeRequest,
  currentUserSet,
} from './action';

function* handleAccessTokenSet({ payload }: SagaPayload<string>) {
  yield call(setAuthTokens, { accessToken: payload, refreshToken: payload });
  yield put(currentUserFetch());
}

function* handleCurrentUserLogout() {
  yield call(clearAuthTokens);
  yield put(currentUserSet(null));
}

function* handleCurrentUserFetch() {
  try {
    const currentUser = (yield call(AuthApi.getUserProfile)) as UserProfile;
    yield put(currentUserSet(currentUser));
  } catch {
    yield put(currentUserSet(null));
  }
}

function* handleCurrentUserOtpCodeRequest() {
  yield call(AuthApi.requestUserOtpCode);
}

export default function* () {
  yield takeLatest(accessTokenSet, handleAccessTokenSet);
  yield takeLatest(currentUserLogout, handleCurrentUserLogout);
  yield takeLatest(currentUserFetch, handleCurrentUserFetch);
  yield takeLatest(currentUserOtpCodeRequest, handleCurrentUserOtpCodeRequest);
}
