import React from 'react';
import { useTranslation } from 'react-i18next';

import { amountToUsd } from '@utils/currency';
import { CarQuote } from '@models/CarQuote';
import { InsurerLogo } from '@components/Feature/Insurer';

type Props = Pick<CarQuote, 'insurer' | 'totalPremium'>;

const CarQuoteItem = ({ insurer, totalPremium }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex justify-content-center align-items-center border bg-white p-1 rounded mb-1">
        <InsurerLogo insurer={insurer} fluid rounded />
      </div>
      <div>
        {t('carInsuranceQuote.premium')}: <span className="text-primary">{amountToUsd(totalPremium)}</span>
      </div>
    </div>
  );
};

export { CarQuoteItem };
