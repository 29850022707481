import { handleActions } from 'redux-actions';
import { Order } from '@models/Order';
import { Pagination } from '@models/Pagination';
import { ordersSet } from './action';

export interface State {
  orders?: Order[];
  pagination?: Pagination;
}

const initialState: State = {};

const reducer = handleActions(
  {
    [String(ordersSet)]: (state, { payload }) => {
      return {
        ...state,
        orders: payload.orders,
        pagination: payload.pagination,
      };
    },
  },

  initialState,
);

export default reducer;
