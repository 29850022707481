import { call, put, takeEvery } from 'redux-saga/effects';
import { Insurer } from '@models/Insurer';
import InsurerApi from '@apis/InsurerApi';
import { insurersFetch, insurersSet } from './action';

function* handleInsurersFetch() {
  try {
    const insurers = (yield call(InsurerApi.getAll)) as Insurer[];
    yield put(insurersSet({ insurers }));
  } catch (_) {
    yield put(insurersSet({ insurers: [] }));
  }
}

export default function* () {
  yield takeEvery(insurersFetch, handleInsurersFetch);
}
