import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames';

import style from './style.module.scss';

const CallToActionSection = () => {
  return (
    <section data-testid="cta" className={classNames(style.bg, 'py-md-5 py-4')}>
      <Container>
        <Row className="gy-5 align-items-center">
          <Col lg={{ offset: 2, span: 8 }}>
            <h1 className={classNames('fw-bold text-center mt-3 mb-4 lh-base', style.textPreLine)}>&nbsp;</h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { CallToActionSection };
