import { createActions } from 'redux-actions';

export const {
  carFetch,

  stateUpdate,
} = createActions(
  'CAR_FETCH',

  'STATE_UPDATE',
  {
    prefix: 'FEATURE_CAR',
  },
);
