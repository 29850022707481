import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined } from 'lodash';
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '@components/Base';
import { SupportCard } from '@components/Feature/Support/support-card';
import PersonalAccidentQuotePremiumCardBody from '@components/Feature/PersonalAccidentQuote/PersonalAccidentQuotePremiumCardBody';

import { personalAccidentOrderCreate } from '../action';
import { PersonalAccidentOrderCreatePayload } from '../personalAccidentOrderCreate';

import { FeatureCardBody } from './feature-card-body';
import { BenefitCardBody } from './benefit-card-body';
import { MemberCardBody } from './member-card-body';
import { usePersonalAccidentQuoteReview } from './hook';
import { selectIsFrontline } from '@components/Auth';

import { getPersonalAccidentQuoteBuyEditPageRoute } from '../route';

const PersonalAccidentQuoteReviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { personalAccidentQuote, values } = usePersonalAccidentQuoteReview();
  const isFrontline = useSelector(selectIsFrontline);

  const handlePay = () => {
    if (!personalAccidentQuote) return null;

    const payload: PersonalAccidentOrderCreatePayload = {
      personalAccidentQuoteId: personalAccidentQuote.personalAccidentOrderId,
    };
    dispatch(personalAccidentOrderCreate(payload));
  };

  const handleEdit = () => {
    if (!personalAccidentQuote) return null;

    navigate({
      ...getPersonalAccidentQuoteBuyEditPageRoute({
        personalAccidentQuoteId: personalAccidentQuote.personalAccidentOrderId,
      }),
      search: stringify({
        ...values,
        personalAccidentPlanId: personalAccidentQuote.personalAccidentPlanId,
      }),
    });
  };

  return (
    <>
      {isUndefined(personalAccidentQuote) && <Loading />}
      {personalAccidentQuote && (
        <Container className="my-5">
          <Row>
            <Col lg={7}>
              <Card>
                <Card.Header>{t('common:summary')}</Card.Header>
                <FeatureCardBody {...personalAccidentQuote} />
                <BenefitCardBody {...personalAccidentQuote} />
                <MemberCardBody {...personalAccidentQuote.people} onEdit={handleEdit} />
              </Card>
            </Col>
            <Col lg={5}>
              <Card className="mb-4">
                <PersonalAccidentQuotePremiumCardBody {...personalAccidentQuote} />

                <Card.Body>
                  {!personalAccidentQuote.isValidMedicalQuestionnaire && (
                    <Alert variant="warning">{t('common:policyUnderReview')}</Alert>
                  )}

                  {isFrontline && (
                    <div className="text-end">
                      <Link
                        to={`/invoices/${personalAccidentQuote.orderEncryptedId}.pdf`}
                        target="_blank"
                        className="d-inline-block my-3"
                      >
                        <FontAwesomeIcon icon={faDownload} className="me-2" />
                        {t('common:downloadInvoice')}
                      </Link>
                    </div>
                  )}

                  <Button
                    disabled={!personalAccidentQuote.isValidMedicalQuestionnaire}
                    onClick={handlePay}
                    className="btn-primary btn-lg w-100"
                    size="lg"
                  >
                    <span className="me-2">{t('common:payNow')}</span>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </Card.Body>
              </Card>

              <SupportCard />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export { PersonalAccidentQuoteReviewPage };
