import { replaceRouteParams } from '@utils/route';

export type PersonalAccidentOrderPaymentPageParams = {
  personalAccidentOrderId: number;
  billingId: number;
};

export const PERSONAL_ACCIDENT_ORDER_PAYMENT_PAGE_URL =
  '/personal-accident-insurance-quote-payment/:personalAccidentOrderId/:billingId';

export const getPersonalAccidentOrderPaymentPageRoute = (params: PersonalAccidentOrderPaymentPageParams) => {
  return {
    pathname: replaceRouteParams(PERSONAL_ACCIDENT_ORDER_PAYMENT_PAGE_URL, params),
  };
};
