import { call, takeLatest } from 'redux-saga/effects';
import FakePaymentApi, { CreateFakePaymentCredentialParams } from '@apis/FakePaymentApi';

import { fakePaymentCreate } from './action';
import { SagaPayload } from '@utils/typescript';
import { history } from '@utils/history';

function* handleFakePaymentCreate({ payload }: SagaPayload<CreateFakePaymentCredentialParams>) {
  try {
    yield call(FakePaymentApi.pay, payload);
    history.navigate('/thank-you');
  } catch {}
}

export default function* () {
  yield takeLatest(fakePaymentCreate, handleFakePaymentCreate);
}
