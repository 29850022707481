import i18n from 'i18next';
import { lowerCase, camelCase } from 'lodash';
import { initReactI18next } from 'react-i18next';

import en from '../locales/en';
import km from '../locales/km';

void i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === 'development',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  lng: window.location.pathname.match(/\/(en|km).*/)?.[1],
  resources: {
    en,
    km,
  },
});

export const tOrdinalItem = (count: number, itemKey: string) => {
  return i18n.t('ordinalItem', {
    ordinal: true,
    count,
    item: lowerCase(i18n.t(itemKey)),
  });
};

export const tEnum = (name: string, value: string) => {
  return i18n.t(`${camelCase(name)}.${camelCase(value)}`);
}

export default i18n;
