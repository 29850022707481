import { call, put, takeLatest } from 'redux-saga/effects';
import { Order } from '@models/Order';
import { OrderApi } from '@apis/OrderApi';
import { CancerClaim } from '@models/CancerClaim';
import { CancerClaimApi } from '@apis/CancerClaimApi';
import { SagaPayload } from '@utils/typescript';
import { history } from '@utils/history';

import { policyFetch, cancerClaimCreate, stateUpdate, StateUpdatePayload } from './action';
import { PolicyFetchPayload } from './policyFetch';
import { CancerClaimCreatePayload } from './cancerClaimCreate';

function* handlePolicyFetch({ payload }: SagaPayload<PolicyFetchPayload>) {
  const state: StateUpdatePayload = { policy: null };
  try {
    state.policy = (yield call(OrderApi.get, payload)) as Order;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCancerClaimCreate({ payload }: SagaPayload<CancerClaimCreatePayload>) {
  const state: StateUpdatePayload = { cancerClaim: null };
  try {
    state.cancerClaim = (yield call(CancerClaimApi.create, payload)) as CancerClaim;
    yield put(stateUpdate(state));
    history.navigate('/user-dashboard/claim', { replace: true });
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(policyFetch, handlePolicyFetch);
  yield takeLatest(cancerClaimCreate, handleCancerClaimCreate);
}
