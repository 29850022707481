import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Footer from '@components/Footer';
import HeaderPrimary from '@components/HeaderPrimary';

export const NotfoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderPrimary />

      <div className="d-flex flex-column align-items-center justify-content-center vh-100">
        <h1 className="display-1 fw-bold">404</h1>

        <p className="fs-3">{t('notFoundPage.title')}</p>
        <p className="lead">{t('notFoundPage.desc')}</p>

        <Link to="/" className="btn btn-primary">
          {t('common:goHome')}
        </Link>
      </div>

      <Footer />
    </>
  );
};
