import humps from 'lodash-humps-ts';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';
import { PersonalAccidentOrder } from '@models/PersonalAccidentOrder';

export type CreatePersonalAccidentOrderParams = {
  personalAccidentQuoteId: number;
};

export type GetPersonalAccidentOrderParams = {
  id: number;
};

export class PersonalAccidentOrderApi {
  static create = async (params: CreatePersonalAccidentOrderParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/personal_accident_orders`, snakes(params))
      .then((response) => humps(response.data) as PersonalAccidentOrder);
  };

  static get = async (params: GetPersonalAccidentOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/personal_accident_orders/${params.id}`)
      .then((response) => humps(response.data) as PersonalAccidentOrder);
  };
}
