import { chain } from 'lodash';

import { HealthQuote } from '@models/HealthQuote';

export type HealthQuoteGroup = {
  insurer: HealthQuote['insurer'];
  healthQuotes: HealthQuote[];
};

export function groupHealthQuotes(healthQuotes: HealthQuote[]): HealthQuoteGroup[] {
  return chain(healthQuotes)
    .groupBy('insurer.id')
    .map(
      (mappedHealthQuotes: HealthQuote[]): HealthQuoteGroup => ({
        insurer: mappedHealthQuotes[0].insurer,
        healthQuotes: mappedHealthQuotes,
      }),
    )
    .value();
}
