import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormGroupControl } from '@components/Base';

const CompletedClaimForm = () => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{`${t('carInsuranceClaimForm.completedClaimForm.title')}*`}</Card.Title>
      <Row>
        <Col>
          <FormGroupControl
            name="completedClaimForm"
            type="file"
            label={t('carInsuranceClaimForm.completedClaimForm.label')}
            required
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
    </Card.Body>
  );
};

export { CompletedClaimForm };
