import { put, call, takeLatest } from 'redux-saga/effects';

import { CancerQuoteApi } from '@apis/CancerQuoteApi';
import { CancerQuote } from '@models/CancerQuote';
import { SagaPayload } from '@utils/typescript';

import { cancerQuotesFetch, CancerQuotesFetchPayload, stateUpdate, StateUpdatePayload } from './action';

function* handleCancerQuotesFetch({ payload }: SagaPayload<CancerQuotesFetchPayload>) {
  const state: StateUpdatePayload = { cancerQuotes: null };
  try {
    state.cancerQuotes = (yield call(CancerQuoteApi.getAll, payload)) as CancerQuote[];
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(cancerQuotesFetch, handleCancerQuotesFetch);
}
