import { handleActions } from 'redux-actions';
import { Insurer } from '@models/Insurer';
import { insurersSet } from './action';

interface State {
  insurers?: Insurer[]
}

const initialState: State = {
};

const reducer = handleActions(
  {
    [String(insurersSet)]: (state, { payload }) => {
      return {
        ...state,
        insurers: payload.insurers,
      };
    },
  },
  initialState,
);

export default reducer;
