import React, { useMemo } from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import { map, times } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Title } from '../title';

const BuyConsiderationSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(8, (n) => ({
      label: t(`carInsurancePage.buyConsiderationSection.item${n + 1}.label`),
      desc: t(`carInsurancePage.buyConsiderationSection.item${n + 1}.desc`),
    }));
  }, []);

  return (
    <section className="py-5">
      <Title title={t('carInsurancePage.buyConsiderationSection.title')} />
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <Accordion flush>
              {map(items, ({ label, desc }) => (
                <Accordion.Item eventKey={label} key={label}>
                  <Accordion.Header>
                    <span className="fw-bold">{label}</span>
                  </Accordion.Header>
                  <Accordion.Body>{desc}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { BuyConsiderationSection };
