import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { map, times } from 'lodash';
import { useTranslation } from 'react-i18next';

const BenefitSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <Container>
        <h2 className="text-center">{t('cancerInsurancePage.benefitSection.title')}</h2>
        <p className="text-center text-secondary w-75 mx-auto">{t('cancerInsurancePage.benefitSection.subTitle')}</p>
        <Row className="gy-4 mt-5">
          {map(times(8), (idx) => (
            <Col md={6} xl={3} key={`benefitItem${idx + 1}`}>
              <Card className="shadow bg-body border-0 h-100">
                <Card.Body>
                  <Card.Title className="my-4 text-center">
                    <h4 className="fw-bold">{t(`cancerInsurancePage.benefitSection.item${idx + 1}.label`)}</h4>
                  </Card.Title>
                  <Card.Text>{t(`cancerInsurancePage.benefitSection.item${idx + 1}.desc`)}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export { BenefitSection };
