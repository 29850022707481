import React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Card, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { FormGroupCheck, StepFormProps } from '@components/Base';

import { FormValues } from '../schema';
import { CardHeader } from './card-header';
import { MemberList } from './member-list';
import { MemberAnswerList } from './member-answer-list';

import { selectCancerQuoteBuyRoute } from '../../selector';

const QuestionForm = ({ onPrev, onNext }: StepFormProps) => {
  const { t } = useTranslation();
  const { isValid } = useFormikContext<FormValues>();
  const { cancerQuote } = useSelector(selectCancerQuoteBuyRoute);

  return (
    <Card>
      <CardHeader onClick={() => onPrev?.()} title="" />
      <Card.Body className="d-grid gap-3">
        {map(cancerQuote?.cancerPlanQuestions, ({ question }, idx) => (
          <Card key={`${question}-${idx}`} data-testid="question-item">
            <Card.Body>
              <p>{question}</p>
              <MemberList questionIdx={idx} />
              <MemberAnswerList questionIdx={idx} />
            </Card.Body>
          </Card>
        ))}

        <Card.Body>
          <Card.Title className="text-warning">{t('common:declaration')}</Card.Title>
          <Alert variant="warning" className="mb-0">
            <FormGroupCheck
              name="isConfirmedDeclaration"
              label={cancerQuote?.productDocument?.occupationDeclarationDescription}
            />
          </Alert>
        </Card.Body>

        <Button size="lg" disabled={!isValid} onClick={() => onNext()}>
          {t('common:summary')} & {t('common:review')}
        </Button>
      </Card.Body>
    </Card>
  );
};

export { QuestionForm };
