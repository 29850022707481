import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { isNull, isUndefined } from 'lodash';

import CarQuotePremiumCardBody from '@components/Feature/CarQuote/CarQuotePremiumCardBody';
import { useCurrentUser } from '@components/Auth';
import { Loading, LoadingError } from '@components/Base';
import { SupportCard } from '@components/Feature/Support/support-card';

import CarQuoteForm, { FormValues } from '../CarQuoteBuyForm';
import { carQuoteCreate, CarQuoteCreatePayload } from '../action';
import { useCarQuote, useCarQuoteBuyNewPageParams } from '../hook';
import { useInitialValues } from './hook';

const CarQuoteBuyNewPage = () => {
  const dispatch = useDispatch();
  useCurrentUser();
  const params = useCarQuoteBuyNewPageParams();
  const carQuote = useCarQuote();
  const isLoading = isUndefined(carQuote);
  const isError = isNull(carQuote);
  const initialValues = useInitialValues();

  const handleSubmit = (values: FormValues) => {
    const payload: CarQuoteCreatePayload = { ...values, ...params };
    dispatch(carQuoteCreate(payload));
  };

  return (
    <>
      {isLoading && <Loading />}
      {isError && <LoadingError />}
      {carQuote && (
        <Container className="my-3">
          <Row className="gy-5">
            <Col lg={8}>{initialValues && <CarQuoteForm initialValues={initialValues} onSubmit={handleSubmit} />}</Col>
            <Col lg={4}>
              <Card className="mb-4">
                <CarQuotePremiumCardBody {...carQuote} />
              </Card>
              <SupportCard />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export { CarQuoteBuyNewPage };
