import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import HeaderNavbarBrand from '@components/HeaderNavbarBrand';

const HeaderSecondary = () => {
  return (
    <Navbar className="border-bottom">
      <Container>
        <HeaderNavbarBrand />
      </Container>
    </Navbar>
  );
};

export { HeaderSecondary };
