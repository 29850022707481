import humps from 'lodash-humps-ts';
import { snakes } from '@utils/lodash';
import { AbaPaywayCredential } from '@models/AbaPaywayCredential';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';

export type CreateFakePaymentCredentialParams = {
  paymentTransactionId: number;
};

type FakePaymentCredential = AbaPaywayCredential;

const SUCCESS_STATUS = 0;

export default class FakePaymentApi {
  static pay = async (params: CreateFakePaymentCredentialParams) => {
    return await authAxios
      .post(
        `${API_V1_BASE}/fake_payment/payment_transactions/${params.paymentTransactionId}`,
        snakes({ status: SUCCESS_STATUS }),
      )
      .then((response) => humps(response.data) as FakePaymentCredential);
  };
}
