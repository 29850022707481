import { call, put, takeLatest } from 'redux-saga/effects';
import { Order } from '@models/Order';
import { OrderApi } from '@apis/OrderApi';
import { PersonalAccidentClaim } from '@models/PersonalAccidentClaim';
import { PersonalAccidentClaimApi } from '@apis/PersonalAccidentClaimApi';
import { SagaPayload } from '@utils/typescript';
import { history } from '@utils/history';

import { policyFetch, personalAccidentClaimCreate, stateUpdate, StateUpdatePayload } from './action';
import { PolicyFetchPayload } from './policyFetch';
import { PersonalAccidentClaimCreatePayload } from './personalAccidentClaimCreate';

function* handlePolicyFetch({ payload }: SagaPayload<PolicyFetchPayload>) {
  const state: StateUpdatePayload = { policy: null };
  try {
    state.policy = (yield call(OrderApi.get, payload)) as Order;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handlePersonalAccidentClaimCreate({ payload }: SagaPayload<PersonalAccidentClaimCreatePayload>) {
  const state: StateUpdatePayload = { personalAccidentClaim: null };
  try {
    state.personalAccidentClaim = (yield call(PersonalAccidentClaimApi.create, payload)) as PersonalAccidentClaim;
    yield put(stateUpdate(state));
    history.navigate('/user-dashboard/claim', { replace: true });
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(policyFetch, handlePolicyFetch);
  yield takeLatest(personalAccidentClaimCreate, handlePersonalAccidentClaimCreate);
}
