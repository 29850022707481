import { InferType, object, string, number, boolean, mixed } from 'yup';
import { forEach } from 'lodash';
import moment from 'moment';
import i18n from '@components/i18n';

const MAX_SUPPORTING_DOCUMENTS = 10;
const FILE_SIZE = 5000000; //5MB
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export const formValuesSchema = object({
  policyHolder: string().default('').required(i18n.t('validation:required')),
  policyNumber: string().default('').required(i18n.t('validation:required')),
  policyOrderNumber: string().default('').required(i18n.t('validation:required')),
  policyExpiryDate: string()
    .default(() => moment(new Date()).format('YYYY-MM-DDTHH:mm'))
    .required(i18n.t('validation:required')),
  claimType: string().default('').required(i18n.t('validation:required')),
  completedClaimForm: mixed<File>()
    .default(null)
    .required(i18n.t('validation:required'))
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (file: File) => file && file.size < FILE_SIZE)
    .test(
      'fileFormat',
      i18n.t('validation:invalidFormat'),
      (file: File) => file && SUPPORTED_FORMATS.includes(file.type),
    ),
  supportingDocuments: mixed<File[]>()
    .default([])
    .test('required', i18n.t('validation:required'), (files) => files.length > 0)
    .test(
      'max',
      i18n.t('limitOutOfRange', { ns: 'validation', max: MAX_SUPPORTING_DOCUMENTS }),
      (files: File[]) => files.length <= MAX_SUPPORTING_DOCUMENTS,
    )
    .test('fileFormat', i18n.t('validation:invalidFormat'), (files: File[]) => {
      let isValid = false;

      forEach(files, (file) => {
        if (!SUPPORTED_FORMATS.includes(file.type)) {
          isValid = false;
          return;
        }

        isValid = true;
      });

      return isValid;
    })
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (files: File[]) => {
      let isValid = false;
      forEach(files, (file) => {
        if (file.size < FILE_SIZE) isValid = true;
      });
      return isValid;
    }),
  invoice: mixed<File>()
    .default(null)
    .required(i18n.t('validation:required'))
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (file: File) => file && file.size < FILE_SIZE)
    .test(
      'fileFormat',
      i18n.t('validation:invalidFormat'),
      (file: File) => file && SUPPORTED_FORMATS.includes(file.type),
    ),
  totalClaimedAmount: number()
    .default(0)
    .required(i18n.t('validation:required'))
    .positive(i18n.t('validation:positive')),
  acceptTermsAndConditions: boolean()
    .default(false)
    .test('required', i18n.t('validation:required'), (checked) => checked === true),
});

export type FormValues = InferType<typeof formValuesSchema>;
