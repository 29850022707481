import { handleActions } from 'redux-actions';
import { reject } from 'lodash';
import { toastShow, toastHide } from './action';
import { Toast } from '@models/Toast';
import { getUuid } from '@utils/uuid';

type State = { toasts: Toast[] };

const initialState: State = {
  toasts: [],
};

const reducer = handleActions(
  {
    [String(toastShow)]: (state, { payload }: { payload: Toast }) => {
      const id = getUuid();
      const { status, body } = payload;

      return { toasts: [...state.toasts, { id, status, body }] };
    },

    [String(toastHide)]: (state, { payload }: { payload: Toast }) => {
      return {
        toasts: reject(state.toasts, ({ id }) => id === payload.id),
      };
    },
  },

  initialState,
);

export default reducer;
