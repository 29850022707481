import { createActions } from 'redux-actions';
import { Order } from '@models/Order';
import { CancerClaim } from '@models/CancerClaim';

export type State = {
  policy: Order | null | undefined;
  cancerClaim: CancerClaim | null | undefined;
};
export type StateUpdatePayload = Partial<State>;

export const {
  policyFetch,
  cancerClaimCreate,

  stateUpdate,
} = createActions(
  'POLICY_FETCH',
  'CANCER_CLAIM_CREATE',

  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_POLICY_CLAIM_CANCER_POLICY_CLAIM',
  },
);
