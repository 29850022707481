import React from 'react';
import { Accordion } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import parse from 'html-react-parser';

import { HealthQuote } from '@models/HealthQuote';
import { HealthPlanBenefitTypes, tHealthPlanBenefitType } from '@models/HealthPlanBenefit';

const BenefitItem = ({
  benefitType,
  benefitSchedule,
}: {
  benefitType: HealthPlanBenefitTypes;
  benefitSchedule: string;
}) => {
  if (isEmpty(benefitSchedule)) return null;

  return (
    <Accordion.Item eventKey={benefitType}>
      <Accordion.Header>{tHealthPlanBenefitType(benefitType)}</Accordion.Header>
      <Accordion.Body>{parse(benefitSchedule)}</Accordion.Body>
    </Accordion.Item>
  );
};

const HealthQuoteBenefitAccordion = ({
  benefitScheduleIpdHtml,
  benefitScheduleOpdHtml,
  benefitScheduleHealthCheckHtml,
  benefitScheduleMaternityHtml,
  benefitScheduleVisionHtml,
  benefitScheduleDentalHtml,
  benefitScheduleOtherHtml,
}: HealthQuote) => {
  return (
    <Accordion flush alwaysOpen>
      <BenefitItem benefitType={HealthPlanBenefitTypes.Inpatient} benefitSchedule={benefitScheduleIpdHtml} />
      <BenefitItem benefitType={HealthPlanBenefitTypes.Outpatient} benefitSchedule={benefitScheduleOpdHtml} />
      <BenefitItem
        benefitType={HealthPlanBenefitTypes.HealthCheckup}
        benefitSchedule={benefitScheduleHealthCheckHtml}
      />
      <BenefitItem benefitType={HealthPlanBenefitTypes.Maternity} benefitSchedule={benefitScheduleMaternityHtml} />
      <BenefitItem benefitType={HealthPlanBenefitTypes.Vision} benefitSchedule={benefitScheduleVisionHtml} />
      <BenefitItem benefitType={HealthPlanBenefitTypes.Dental} benefitSchedule={benefitScheduleDentalHtml} />
      <BenefitItem benefitType={HealthPlanBenefitTypes.Others} benefitSchedule={benefitScheduleOtherHtml} />
    </Accordion>
  );
};

export { HealthQuoteBenefitAccordion };
