import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { array, boolean, number, object, string, lazy } from 'yup';
import { isLoggedIn } from 'axios-jwt';

import { selectCurrentUser } from '@components/Auth';
import { useParseParams } from '@hooks/search-params';
import { getAge } from '@utils/datetime';
import { mergeInitialValues } from '@utils/formik';
import { ADULT_AGE_MAX, ADULT_AGE_MIN, CHILD_AGE_MAX, CHILD_AGE_MIN } from '@config';
import { StepType } from '@components/Base';
import { Genders } from '@models/User';

import { FormValues } from './type';

const useFormValuesParamsSchema = () => {
  const loggedIn = isLoggedIn();

  return useMemo(() => {
    let schema = object({
      me: object({
        checked: boolean().default(true),
        dob: string().default(''),
        age: string().default(''),
        gender: string().default(Genders.Male),
      }),
      spouse: object({
        checked: lazy((value, { parent }) => boolean().default(!!parent?.dob)),
        dob: string().default(''),
        age: string().default(''),
      }).default(undefined),
      sons: array().of(
        object({
          dob: string().default(''),
          age: string().default(''),
        })
      ).default(undefined),
      daughters: array().of(
        object({
          dob: string().default(''),
          age: string().default(''),
        })
      ).default(undefined),
    });

    if (!loggedIn) {
      schema = schema.concat(
        object({
          mobileNumber: string().default(''),
          fullName: string().default(''),
        })
      );
    }

    return schema;
  }, [loggedIn]);
}

const useFormValuesParams = () => {
  const schema = useFormValuesParamsSchema();
  return useParseParams<FormValues>(schema);
};

export const useInitialValues = () => {
  const params = useFormValuesParams();
  const currentUser = useSelector(selectCurrentUser);

  return useMemo(() => {
    if (!currentUser) return params;

    const { gender, dob } = currentUser;

    return mergeInitialValues(params, {
      me: {
        gender,
        dob,
        age: getAge(currentUser.dob),
      },
    });
  }, [currentUser]);
};

export const useSteps = () => {
  const { t } = useTranslation();
  const loggedIn = isLoggedIn();

  return useMemo<StepType[]>(() => {
    const children = object({
      dob: string().required(t('validation:required')),
      age: number().positive().integer().min(CHILD_AGE_MIN).max(CHILD_AGE_MAX),
    });

    const adultAge = number().positive().integer().min(ADULT_AGE_MIN).max(ADULT_AGE_MAX);

    let steps: StepType[] = [
      {
        pathname: 'members',
        validationSchema: object({
          me: object({
            checked: boolean().oneOf([true]),
            gender: string().required(t('validation:required')),
          }),
        }),
      },
      {
        pathname: 'contact',
        validationSchema: object({
          me: object({
            dob: string().required(t('validation:required')),
            age: adultAge,
          }),
          spouse: object({
            checked: boolean(),
            dob: string().when('checked', {
              is: true,
              then: (schema) => schema.required(t('validation:required')),
            }),
            age: adultAge,
          }),
          sons: array().of(children),
          daughters: array().of(children),
        }),
      },
    ];

    if (!loggedIn) {
      steps = [
        ...steps,
        {
          pathname: `user`,
          validationSchema: object({
            mobileNumber: string().required(t('validation:required')).phone('KH'),
            fullName: string().required(t('validation:required')),
          }),
        },
      ]
    }

    return steps;
  }, [loggedIn]);
};
