import React, { Fragment, useCallback } from 'react';
import { isUndefined, map } from 'lodash';
import { Article } from '@models/Article';
import { Loading, NoData } from '@components/Base';

import { ArticleItem } from './article-item';

interface Props {
  articles?: Article[];
}

const ArticleList = ({ articles }: Props) => {
  const isLoaded = !isUndefined(articles);
  const hasArticles = isLoaded && articles.length > 0;

  const renderItem = useCallback((article: Article) => {
    return (
      <Fragment key={article.id}>
        <ArticleItem article={article} />
        <hr />
      </Fragment>
    );
  }, []);

  return (
    <>
      {!isLoaded && <Loading />}
      {isLoaded && !hasArticles && <NoData />}
      {hasArticles && map(articles, renderItem)}
    </>
  );
};

export { ArticleList };
