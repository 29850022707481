import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';

import { formatDateTime } from '@utils/datetime';
import { Article } from '@models/Article';
import { tInsuranceType } from '@models/InsuranceType';

interface Props {
  article: Article;
}

const ArticleItem = ({ article }: Props) => {
  const { id, insuranceType, coverPhoto, title, publishedAt, shortDescription } = article;

  return (
    <Row className="gy-3" data-testid="article-item">
      <Col xs={12} lg={3}>
        <Image thumbnail src={coverPhoto} className="w-100" />
      </Col>
      <Col lg={6}>
        <h3>
          <Link to={`/articles/${insuranceType}/${id.toString()}`}>{title}</Link>
        </h3>
        <div>{tInsuranceType(insuranceType)}</div>
        <div>{formatDateTime(publishedAt)}</div>
        <p>{shortDescription}</p>
      </Col>
    </Row>
  );
};

export { ArticleItem };
