import { call, put, takeLatest } from 'redux-saga/effects';

import { BillingApi } from '@apis/BillingApi';
import { PaymentTransactionApi } from '@apis/PaymentTransactionApi';
import { PersonalAccidentOrderApi } from '@apis/PersonalAccidentOrderApi';
import { Billing } from '@models/Billing';
import { PaymentTransaction } from '@models/PaymentTransaction';
import { PersonalAccidentOrder } from '@models/PersonalAccidentOrder';
import { SagaPayload } from '@utils/typescript';

import {
  personalAccidentOrderFetch,
  paymentTransactionCreate,
  billingFetch,
  stateUpdate,
  StateUpdatePayload,
} from './action';
import { PersonalAccidentOrderFetchPayload } from './personalAccidentOrderFetch';
import { PaymentTransactionCreatePayload } from './paymentTransactionCreate';
import { BillingFetchPayload } from './billingFetch';

function* handlePersonalAccidentOrderFetch({ payload }: SagaPayload<PersonalAccidentOrderFetchPayload>) {
  const state: StateUpdatePayload = { personalAccidentOrder: null };
  try {
    state.personalAccidentOrder = (yield call(PersonalAccidentOrderApi.get, payload)) as PersonalAccidentOrder;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handlePaymentTransactionCreate({ payload }: SagaPayload<PaymentTransactionCreatePayload>) {
  const state: StateUpdatePayload = { paymentTransaction: null };
  try {
    state.paymentTransaction = (yield call(PaymentTransactionApi.create, payload)) as PaymentTransaction;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleBillingFetch({ payload }: SagaPayload<BillingFetchPayload>) {
  const state: StateUpdatePayload = { billing: null };
  try {
    state.billing = (yield call(BillingApi.get, payload)) as Billing;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(personalAccidentOrderFetch, handlePersonalAccidentOrderFetch);
  yield takeLatest(paymentTransactionCreate, handlePaymentTransactionCreate);
  yield takeLatest(billingFetch, handleBillingFetch);
}
