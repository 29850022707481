import React from 'react';
import { Row, Col, RowProps } from 'react-bootstrap';

import { NumericString } from '@utils/typescript';

export interface RowItemProps extends RowProps {
  label?: string | null;
  value?: string | number | NumericString | null;
}

const RowItem = ({ label, value, ...rowProps }: RowItemProps) => {
  return (
    <Row {...rowProps}>
      <Col xs={4} sm={4} md={3}>
        {label}
      </Col>
      <Col xs="auto">:</Col>
      <Col>{value}</Col>
    </Row>
  );
};

export { RowItem };
