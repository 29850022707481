import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { map, startCase } from 'lodash';

import { amountToUsd } from '@utils/currency';
import { InsurerLogo } from '@components/Feature/Insurer';
import { usePersonalAccidentQuoteResultPageParams } from '@components/PersonalAccidentQuoteResultPage';
import { getPersonalAccidentQuoteBuyNewPageRoute } from '@components/PersonalAccidentQuoteBuyRoute/route';

import { selectPersonalAccidentQuoteComparePage } from './selector';

const TableInsurer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = usePersonalAccidentQuoteResultPageParams();
  const { personalAccidentQuotes } = useSelector(selectPersonalAccidentQuoteComparePage);

  const handleBuy = (personalAccidentPlanId: number) => {
    navigate(getPersonalAccidentQuoteBuyNewPageRoute({ ...params, personalAccidentPlanId }));
  };

  return (
    <tr className="shadow-sm sticky-top table-light">
      <th className="w-25 py-4" />
      {map(personalAccidentQuotes, ({ insurer, totalPremium, personalAccidentPlanId, personalAccidentPlanName }) => (
        <th key={personalAccidentPlanId} className="py-4">
          <div className="mb-2" style={{ width: 200, height: 50 }}>
            <InsurerLogo insurer={insurer} rounded fluid />
          </div>

          <span className="d-block mb-2">{personalAccidentPlanName}</span>

          <Button onClick={() => handleBuy(personalAccidentPlanId)} variant="primary" size="lg" style={{ width: 200 }}>
            <small>{startCase(t('common:buyNow'))}</small>
            <span className="fw-bold d-block">{amountToUsd(totalPremium)}</span>
          </Button>
        </th>
      ))}
    </tr>
  );
};

export { TableInsurer };
