import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HeaderSecondary from '@components/HeaderSecondary';

import CancerQuoteBuyNewPage from './CancerQuoteBuyNewPage';
import CancerQuoteReviewPage from './CancerQuoteReviewPage';
import CancerQuoteBuyEditPage from './CancerQuoteBuyEditPage';

const CancerQuoteBuyRoute = () => {
  return (
    <>
      <HeaderSecondary />
      <Routes>
        <Route path="/*" element={<CancerQuoteBuyNewPage />} />
        <Route path=":cancerQuoteId/edit/*" element={<CancerQuoteBuyEditPage />} />
        <Route path=":cancerQuoteId/review" element={<CancerQuoteReviewPage />} />
      </Routes>
    </>
  );
};

export { CancerQuoteBuyRoute };
