import React, { useMemo } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { map, times } from 'lodash';
import { useTranslation } from 'react-i18next';

export interface Props {
  label: string;
  desc: string;
}

const NoCoverCard = ({ label, desc }: Props) => {
  return (
    <Card className="h-100">
      <Card.Body>
        <Card.Title className="text-center">
          <h4 className="fw-bold">{label}</h4>
        </Card.Title>
        <Card.Text>{desc}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const NoCoverSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(9, (n) => ({
      label: t(`carInsurancePage.noCoverSection.item${n + 1}.label`),
      desc: t(`carInsurancePage.noCoverSection.item${n + 1}.desc`),
    }));
  }, []);

  return (
    <section className="py-5">
      <h2 className="text-center">{t('carInsurancePage.noCoverSection.title')}</h2>
      <p className="text-center text-secondary w-75 mx-auto">{t('carInsurancePage.noCoverSection.subTitle')}</p>
      <Container>
        <Row className="gy-4 mt-5">
          {map(items, (item) => (
            <Col lg={4} key={item.label}>
              <NoCoverCard {...item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export { NoCoverSection };
