import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { StepFormProps } from '@components/Base';
import { selectCurrentUser } from '@components/Auth';
import { GenderFormSelect } from '@components/Feature/User/gender-form-select';

import { CardHeader } from './card-header';
import { PersonCard } from './person-card';
import { FormikToggleButton } from './formik-toggle-button';
import { FormikToggleButtonWithCount } from './formik-toggle-button-with-count';

const SONS_MAX = 3;
const DAUGHTERS_MAX = 3;

const MembersStepForm = ({ onNext }: StepFormProps) => {
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const { isValid } = useFormikContext();

  return (
    <section>
      <Card className="shadow">
        <CardHeader title={t('healthQuote.members.title')} />
        <Card.Body className="d-grid gap-3">
          <GenderFormSelect name="me.gender" required disabled={!!currentUser?.gender} />
        </Card.Body>

        <Card.Body>
          <div className="d-grid gap-3">
            <Row xs={1} sm={2} className="gy-4">
              <Col>
                <PersonCard>
                  <FormikToggleButton name="me" label={t('common:myself')} />
                </PersonCard>
              </Col>
              <Col>
                <PersonCard>
                  <FormikToggleButton name="spouse" label={t('common:spouse')} />
                </PersonCard>
              </Col>
            </Row>

            <Row xs={1} sm={2} className="gy-4">
              <Col>
                <PersonCard testID="sons-card">
                  <FormikToggleButtonWithCount name="sons" label={t('common:son')} max={SONS_MAX} />
                </PersonCard>
              </Col>
              <Col>
                <PersonCard testID="daughters-card">
                  <FormikToggleButtonWithCount name="daughters" label={t('common:daughter')} max={DAUGHTERS_MAX} />
                </PersonCard>
              </Col>
            </Row>
          </div>
        </Card.Body>
        <Card.Footer>
          <div className="d-grid">
            <Button size="lg" disabled={!isValid} onClick={() => onNext()}>
              {t('common:next')}
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </section>
  );
};

export { MembersStepForm };
