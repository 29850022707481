import humps from 'lodash-humps-ts';
import { API_V1_BASE } from '@config';
import { authAxios } from '@components/Auth';
import { CarBrand } from '@models/CarBrand';

export type GetCarBrandParams = Pick<CarBrand, 'id'>;

export class CarBrandApi {
  static getAll = async () => {
    return await authAxios.get(`${API_V1_BASE}/car_brands`).then((response) => humps(response.data) as CarBrand[]);
  };

  static get = async ({ id }: GetCarBrandParams) => {
    return await authAxios.get(`${API_V1_BASE}/car_brands/${id}`).then((response) => humps(response.data) as CarBrand);
  };
}
