import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { Insurer } from '@models/Insurer';
import { API_V1_BASE } from '@config';

export default class InsurerApi {
  static getAll = async () => {
    return await authAxios.get(`${API_V1_BASE}/insurers`).then((response) => humps(response.data) as Insurer[]);
  };
}
