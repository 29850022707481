import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterContext } from './context';
import { useFilterParams } from './hook';
import { each } from 'lodash';
import { FilterType } from './type';

const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterParams = useFilterParams();
  const [filters, setFilters] = useState(filterParams);

  const updateFilters = (values: Partial<FilterType>) => {
    const newFilters: FilterType = { ...filters, ...values };

    setFilters(newFilters);
    each(newFilters, (v, k) => searchParams.set(k.toString(), v.toString()));
    setSearchParams(searchParams);
  };

  return <FilterContext.Provider value={{ filters, updateFilters }}>{children}</FilterContext.Provider>;
};

export { FilterProvider };
