import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useParseParams } from '@hooks/search-params';
import { mergeInitialValues } from '@utils/formik';

import { formValuesParamsSchema, FormValues } from '../HealthQuoteBuyForm';
import { selectHealthQuoteBuyRoute } from '../selector';

export const useInitialValues = () => {
  const { healthQuote } = useSelector(selectHealthQuoteBuyRoute);
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);

  return useMemo(() => {
    if (!healthQuote) return;

    const { people } = healthQuote;

    return mergeInitialValues(initialValues, people);
  }, []);
};
