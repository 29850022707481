import { useLocation, useNavigate } from 'react-router-dom';
import { findIndex, last, range, some } from 'lodash';
import { useEffect, useMemo } from 'react';
import { stringify } from 'qs';
import { useFormikContext } from 'formik';

import { StepType } from './type';

const useActiveStep = (steps: StepType[]) => {
  const { pathname } = useLocation();
  const activePathname = last(pathname.split('/'));

  const activeStepIdx = useMemo(() => {
    const idx = findIndex(steps, (options) => options.pathname === activePathname);
    return idx === -1 ? 0 : idx;
  }, [steps, activePathname]);

  const isFirstStep = activeStepIdx === 0;
  const isLastStep = activeStepIdx === steps.length - 1;

  return {
    activeStepIdx,
    isFirstStep,
    isLastStep,
  };
};

export const useStepperForm = <T extends object>(steps: StepType[]) => {
  const navigate = useNavigate();
  const { activeStepIdx, isLastStep } = useActiveStep(steps);

  const next = (values: T) => {
    navigate({
      pathname: steps[activeStepIdx + 1].pathname,
      search: stringify(values),
    });
  };

  return {
    activeStepIdx,
    isLastStep,
    next,
  };
};

export const useStepperFormBody = <T extends object>(steps: StepType[]) => {
  const navigate = useNavigate();
  const { initialValues, validateForm, values, setValues, submitForm, setTouched } = useFormikContext<T>();
  const { activeStepIdx, isFirstStep, isLastStep } = useActiveStep(steps);

  const handleNext = (partialValues?: Partial<T>) => {
    if (partialValues) {
      setValues({ ...values, ...partialValues });
    }

    setTouched({});

    setTimeout(() => {
      (async () => {
        await submitForm();
      })();
    }, 1);
  };

  const handlePrev = (partialValues?: Partial<T>) => {
    if (partialValues) {
      setValues({ ...values, ...partialValues });
    }

    if (isFirstStep) return;

    navigate(
      {
        pathname: steps[activeStepIdx - 1].pathname,
        search: stringify({ ...values, ...partialValues }),
      },
      { replace: true },
    );
  };

  useEffect(() => {
    (async () => {
      await validateForm().then(() => setTouched({}));
    })();
  }, [activeStepIdx]);

  useEffect(() => {
    some(range(activeStepIdx), (idx) => {
      const { pathname, validationSchema } = steps[idx];

      if (validationSchema.isValidSync(initialValues)) return false;

      navigate(
        {
          pathname,
          search: stringify(initialValues),
        },
        { replace: true },
      );

      return true;
    });
  }, [initialValues]);

  return {
    handleNext,
    handlePrev,
    isLastStep,
  };
};
