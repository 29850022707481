import { camelCase } from 'lodash';
import i18n, { tEnum } from '@components/i18n';

export enum CarPlanTypes {
  Comprehensive = 'comprehensive',
  ThirdParty = 'third_party',
}

export const tCarPlanType = (type: CarPlanTypes) => tEnum('CarPlanTypes', type);

export const getCarPlanTypeCover = (planType: CarPlanTypes) => {
  return i18n.t(camelCase(`${planType}Cover`), { ns: 'common' });
};
