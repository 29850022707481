import { object, number } from 'yup';
import { stringify } from 'qs';

import {
  cancerQuoteResultPageParamsSchema,
  CancerQuoteResultPageParams,
} from '@components/CancerQuoteResultPage/route';

export type CancerQuoteDetailsPageParams = Required<Pick<CancerQuoteResultPageParams, 'me'>> &
Pick<CancerQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'> & {
  cancerPlanId: number;
};

export const cancerQuoteDetailsPageParamsSchema = cancerQuoteResultPageParamsSchema.concat(
  object({
    cancerPlanId: number(),
  }),
);

export const cancerQuoteSendEmailPageParamsSchema = cancerQuoteDetailsPageParamsSchema;

export const CANCER_QUOTE_DETAILS_ROUTE_URL = '/cancer-insurance-quote-details/*';

export const CANCER_QUOTE_DETAILS_PAGE_URL = '/cancer-insurance-quote-details';

export const getCancerQuoteDetailsPageRoute = (params: CancerQuoteDetailsPageParams) => {
  return {
    pathname: CANCER_QUOTE_DETAILS_PAGE_URL,
    search: stringify(params),
  };
};
