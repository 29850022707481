import React from 'react';

import MetaTag from '@components/MetaTag';
import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import CallToActionSection from './CallToActionSection';
import DefinitionSection from './DefinitionSection';
import CoverSection from './CoverSection';
import NoCoverSection from './NoCoverSection';
import AddOnCoverSection from './AddOnCoverSection';
import WhyNeedSection from './WhyNeedSection';
import KeyFeatureSection from './KeyFeatureSection';
import BuyConsiderationSection from './BuyConsiderationSection';
import WhichPlanSection from './WhichPlanSection';
import BuyReasonSection from './BuyReasonSection';
import HowToCompareSection from './HowToCompareSection';
import HowToBuySection from './HowToBuySection';
import HowToFileClaimSection from './HowToFileClaimSection';
import DocumentClaimSection from './DocumentClaimSection';
import FrequentlyAskedQuestionSection from './FrequentlyAskedQuestionSection';
import SupportSection from '@components/SupportSection';

const CarInsurancePage = () => {
  return (
    <>
      <MetaTag pageName="carInsurancePage" />
      <HeaderPrimary />
      <CallToActionSection />
      <DefinitionSection />
      <CoverSection />
      <NoCoverSection />
      <AddOnCoverSection />
      <WhyNeedSection />
      <KeyFeatureSection />
      <BuyConsiderationSection />
      <WhichPlanSection />
      <BuyReasonSection />
      <HowToCompareSection />
      <HowToBuySection />
      <HowToFileClaimSection />
      <DocumentClaimSection />
      <FrequentlyAskedQuestionSection />
      <SupportSection />
      <Footer />
    </>
  );
};

export { CarInsurancePage };
