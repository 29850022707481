import { createActions } from 'redux-actions';

export const {
  credentialCreate,
  stateUpdate,
} = createActions(
  {
    CREDENTIAL_CREATE: undefined,
    STATE_UPDATE: undefined,
  },
  {
    prefix: 'ABA_PAYWAY'
  }
)
