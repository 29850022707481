import { handleActions } from 'redux-actions';
import { Order } from '@models/Order';
import { ordersSet } from './action';

export interface State {
  orders?: Order[];
}

const initialState: State = {};

const reducer = handleActions(
  {
    [String(ordersSet)]: (state, { payload }) => {
      return {
        ...state,
        orders: payload.orders,
      };
    },
  },

  initialState,
);

export default reducer;
