import { createActions } from 'redux-actions';

import { CarOrder } from '@models/CarOrder';
import { PaymentTransaction } from '@models/PaymentTransaction';
import { CreatePaymentTransactionParams } from '@apis/PaymentTransactionApi';
import { GetCarOrderParams } from '@apis/CarOrderApi';
import { GetBillingParams } from '@apis/BillingApi';
import { Billing } from '@models/Billing';

export interface State {
  carOrder: CarOrder | null | undefined;
  paymentTransaction: PaymentTransaction | null | undefined;
  billing: Billing | null | undefined;
}

export type CarOrderFetchPayload = GetCarOrderParams;

export type PaymentTransactionCreatePayload = CreatePaymentTransactionParams;

export type BillingFetchPayload = GetBillingParams;

export type StateUpdatePayload = Partial<State>;

export const {
  carOrderFetch,

  paymentTransactionCreate,

  billingFetch,

  stateUpdate,
} = createActions(
  'CAR_ORDER_FETCH',

  'PAYMENT_TRANSACTION_CREATE',

  'BILLING_FETCH',

  'STATE_UPDATE',
  {
    prefix: 'CAR_ORDER_PAYMENT_PAGE',
  },
);
