import { call, takeLatest } from 'redux-saga/effects';
import { ReviewUserCarParams, UserCarApi } from '@apis/UserCarApi';
import { userCarReview } from './action';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';

function* handleUserCarsReview({ payload }: SagaPayload<ReviewUserCarParams>) {
  try {
    yield call(UserCarApi.review, payload);
    history.navigate(`/user-dashboard/vehicle/${payload.id}`, { replace: true });
  } catch {}
}

export default function* () {
  yield takeLatest(userCarReview, handleUserCarsReview);
}
