import React, { ReactElement, useEffect, useCallback } from 'react';
import { Row, Col, Card, Button, Image, Alert } from 'react-bootstrap';
import { useParams, useNavigate, useHref } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined, isNull, includes, startCase } from 'lodash';
import { useTranslation } from 'react-i18next';

import { BackButton, DocumentBadge } from '@components/Base';
import { UserDocumentStatuses, getUserDocumentType, UserDocument } from '@models/UserDocument';

import { userDocumentFetch } from './action';
import { selectDocumentDetails } from './selector';

interface RowItemProps {
  label?: string;
  value?: string | number | ReactElement;
}

const RowItem = ({ label, value }: RowItemProps) => {
  return (
    <Row className="mb-2">
      <Col xs={4} sm={4} md={3}>
        {label}
      </Col>
      <Col xs="auto">:</Col>
      <Col>{value}</Col>
    </Row>
  );
};

export const canEdit = (document: UserDocument) => {
  return includes([UserDocumentStatuses.Unverified, UserDocumentStatuses.Rejected], document.status);
};

const DocumentDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editHref = useHref('edit');
  const { id } = useParams();
  const { userDocument } = useSelector(selectDocumentDetails);
  const isLoaded = !isUndefined(userDocument);
  const hasUserDocument = isLoaded && !isNull(userDocument);

  const shouldRenderEdit = hasUserDocument && canEdit(userDocument);
  const shouldRenderRejectedReason = hasUserDocument && userDocument.status === UserDocumentStatuses.Rejected;

  useEffect(() => {
    dispatch(userDocumentFetch({ id }));
  }, [id]);

  const handleBackClick = useCallback(() => {
    navigate('./../', { replace: true });
  }, []);

  return (
    <>
      <BackButton onClick={handleBackClick} className="mb-4" />
      {hasUserDocument && (
        <Card>
          <Card.Header className="d-flex flex-row justify-content-between align-items-center bg-white">
            <h5 className="mb-0">{startCase(getUserDocumentType(userDocument.documentType))}</h5>
            {shouldRenderEdit && <Button href={editHref}>{t('common:edit')}</Button>}
          </Card.Header>
          <Card.Body>
            <RowItem label={t('common:status')} value={<DocumentBadge status={userDocument.status} />} />
            <RowItem
              label={t('common:document')}
              value={<Image data-testid="user-document-image" src={userDocument.document} thumbnail />}
            />
            {shouldRenderRejectedReason && (
              <RowItem
                label={t('common:rejectedReason')}
                value={<Alert variant="danger">{userDocument.rejectedReason}</Alert>}
              />
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export { DocumentDetails };
