import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useOtpCodeSchema = () => {
  const { t } = useTranslation();

  return Yup.string()
    .required(t('required', { ns: 'validation' }))
    .length(4, t('length', { ns: 'validation' }))
    .matches(/\d/, t('notANumber', { ns: 'validation' }));
};
