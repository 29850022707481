import React, { useCallback } from 'react';
import { isUndefined, isNull } from 'lodash';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Loading, LoadingError, NoData, BackButton } from '@components/Base';
import SupportSection from '@components/SupportSection';
import { useCancerQuoteResultPageParams } from '@components/CancerQuoteResultPage';
import { getCancerQuoteResultPageRoute } from '@components/CancerQuoteResultPage/route';

import HeaderSection from '../HeaderSection';
import { FeatureSection } from './feature-section';
import { BenefitSection } from './benefit-section';
import { HospitalSection } from './hospital-section';
import { MemberSection } from './member-section';
import { useCancerQuoteFetch } from '../hook';

const CancerQuoteDetailsPage = () => {
  const navigate = useNavigate();
  const params = useCancerQuoteResultPageParams();
  const cancerQuote = useCancerQuoteFetch();
  const isLoaded = !isUndefined(cancerQuote);
  const isError = isNull(cancerQuote);
  const hasCancerQuote = isLoaded && !isNull(cancerQuote);

  const handleBackClick = useCallback(() => {
    navigate(getCancerQuoteResultPageRoute(params), { replace: true });
  }, []);

  return (
    <>
      {!isLoaded && <Loading />}
      {isError && <LoadingError />}
      {isLoaded && !hasCancerQuote && <NoData />}

      {hasCancerQuote && (
        <>
          <Container className="mt-4">
            <BackButton onClick={handleBackClick} className="mb-4" />
          </Container>

          <HeaderSection {...cancerQuote} />
          <FeatureSection {...cancerQuote} />
          <BenefitSection {...cancerQuote} />
          <HospitalSection {...cancerQuote} />
          <MemberSection />
        </>
      )}

      <SupportSection />
    </>
  );
};

export { CancerQuoteDetailsPage };
