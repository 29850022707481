import { replaceRouteParams } from '@utils/route';

export type CarOrderPaymentPageParams = {
  carOrderId: number;
  billingId: number;
};

export const CAR_ORDER_PAYMENT_PAGE_URL = '/car-insurance-quote-payment/:carOrderId/:billingId';

export const getCarOrderPaymentPageRoute = (params: CarOrderPaymentPageParams) => {
  return {
    pathname: replaceRouteParams(CAR_ORDER_PAYMENT_PAGE_URL, params),
  };
}
