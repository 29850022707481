import { stringify } from 'qs';
import { PeopleParams, peopleParamsSchema } from '@components/HealthQuoteResultPage/route';

export type PersonalAccidentQuoteResultPageParams = PeopleParams;

export const personalAccidentQuoteResultPageParamsSchema = peopleParamsSchema;

export const PERSONAL_ACCIDENT_QUOTE_RESULT_PAGE_URL = '/personal-accident-insurance-quote-results';

export const getPersonalAccidentQuoteResultPageRoute = (params: PersonalAccidentQuoteResultPageParams) => {
  return {
    pathname: PERSONAL_ACCIDENT_QUOTE_RESULT_PAGE_URL,
    search: stringify(params),
  };
};
