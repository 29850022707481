import { all, fork } from 'redux-saga/effects';

import { DashboardSaga } from './Dashboard';
import { billingSaga } from './Billing';
import { billingDetailsSaga } from './BillingDetails';
import { passwordSaga } from './Password';
import { PolicySaga } from './Policy';
import { ClaimSaga } from './Claim';
import { ClaimDetailsSaga } from './ClaimDetails';
import { PolicyClaimSaga } from './PolicyClaim';
import { PolicyDetailsSaga } from './PolicyDetails';
import { policyRenewalSaga } from './PolicyRenewal';
import { VehicleSaga } from './Vehicle';
import { VehicleDetailsSaga } from './VehicleDetails';
import { VehicleFormSaga } from './VehicleForm';
import { DocumentSaga } from './Document';
import { DocumentNewSaga } from './DocumentNew';
import { DocumentDetailsSaga } from './DocumentDetails';
import { DocumentEditSaga } from './DocumentEdit';
import { personalInfoEditSaga } from './PersonalInfoEdit';

export default function* () {
  yield all([
    fork(DashboardSaga),
    fork(billingSaga),
    fork(billingDetailsSaga),
    fork(passwordSaga),
    fork(personalInfoEditSaga),
    fork(PolicySaga),
    fork(ClaimSaga),
    fork(ClaimDetailsSaga),
    fork(PolicyClaimSaga),
    fork(PolicyDetailsSaga),
    fork(policyRenewalSaga),
    fork(VehicleSaga),
    fork(VehicleDetailsSaga),
    fork(VehicleFormSaga),
    fork(DocumentSaga),
    fork(DocumentNewSaga),
    fork(DocumentDetailsSaga),
    fork(DocumentEditSaga),
  ]);
}
