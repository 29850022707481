import React from 'react';
import { useHref } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Order } from '@models/Order';
import { formatDateTime } from '@utils/datetime';
import { RowItem } from './row-item';
import { InsurerLogo } from '@components/Feature/Insurer';

type Props = Pick<
Order,
'orderableType' | 'orderableId' | 'policyNumber' | 'renewable' | 'claimable' | 'startedAt' | 'endedAt' | 'insurer'
>;

const CancerPolicyCard = ({
  orderableType,
  orderableId,
  policyNumber,
  renewable,
  claimable,
  startedAt,
  endedAt,
  insurer,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Header className="d-flex flex-row justify-content-between align-items-center bg-white">
        <h5 className="mb-0">{t('common:cancerInsurance')}</h5>
        <InsurerLogo insurer={insurer} fluid />
      </Card.Header>
      <Card.Body>
        <RowItem label={t('common:policyNumber')} value={policyNumber} />
        <RowItem
          label={t('common:periodOfInsurance')}
          value={`${formatDateTime(startedAt)} - ${formatDateTime(endedAt)}`}
        />

        <p>
          {t('common:emergencyCall')}: {insurer.hotlinesCancer}
        </p>

        <div className="d-sm-grid d-md-flex flex-row mt-3 gap-2">
          <Button href={useHref(`/user-dashboard/policy/${orderableType}/${orderableId}`)} variant="outline-primary">
            {t('common:viewDetails')}
          </Button>

          {claimable && (
            <Button
              href={useHref(`/user-dashboard/policy/${orderableType}/${orderableId}/claim`)}
              variant="outline-primary"
            >
              {t('common:claim')}
            </Button>
          )}

          {renewable && (
            <Button
              href={useHref(`/user-dashboard/policy/personal-accident-insurance/${orderableId}/renewal`)}
              variant="outline-primary"
            >
              {t('common:renew')}
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export { CancerPolicyCard };
