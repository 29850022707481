import React from 'react';
import { Card } from 'react-bootstrap';
import { BackButton } from '@components/Base';

type Props = {
  onClick?: () => void;
  title: string;
};

const CardHeader = ({ onClick, title }: Props) => {
  const shouldRenderBackButton = !!onClick;

  return (
    <Card.Header className="d-flex justify-content-start align-items-center bg-white">
      {shouldRenderBackButton && <BackButton className="me-3" onClick={onClick} />}
      <h3 className="my-3">{title}</h3>
    </Card.Header>
  );
};

export { CardHeader };
