import { handleActions } from 'redux-actions';
import { passwordUpdateSuccess, passwordUpdateError } from './action';
import { State } from './type';

const initialState: State = {
  isSuccess: false,
  errors: {
    password: '',
    passwordConfirmation: '',
    otpCode: '',
  },
};

const reducer = handleActions(
  {
    [String(passwordUpdateSuccess)]: (state) => {
      return {
        ...state,
        isSuccess: true,
        errors: initialState.errors,
      };
    },

    [String(passwordUpdateError)]: (state, { payload }) => {
      return {
        ...state,
        isSuccess: false,
        errors: payload.errors,
      };
    },
  },

  initialState,
);

export default reducer;
