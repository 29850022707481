import { createActions } from 'redux-actions';

import { HealthOrder } from '@models/HealthOrder';
import { PaymentTransaction } from '@models/PaymentTransaction';
import { Billing } from '@models/Billing';
import { CreatePaymentTransactionParams } from '@apis/PaymentTransactionApi';
import { GetHealthOrderParams } from '@apis/HealthOrderApi';
import { GetBillingParams } from '@apis/BillingApi';

export type State = {
  healthOrder: HealthOrder | null | undefined;
  paymentTransaction: PaymentTransaction | null | undefined;
  billing: Billing | null | undefined;
}

export type HealthOrderFetchPayload = GetHealthOrderParams;

export type PaymentTransactionCreatePayload = CreatePaymentTransactionParams;

export type BillingFetchPayload = GetBillingParams;

export type StateUpdatePayload = Partial<State>;

export const {
  healthOrderFetch,

  paymentTransactionCreate,

  billingFetch,

  stateUpdate,
} = createActions(
  'HEALTH_ORDER_FETCH',

  'PAYMENT_TRANSACTION_CREATE',

  'BILLING_FETCH',

  'STATE_UPDATE',
  {
    prefix: 'HEALTH_ORDER_PAYMENT_PAGE',
  },
);
