import { call, put, takeLatest } from 'redux-saga/effects';
import { CarBrand } from '@models/CarBrand';
import { CarModel } from '@models/CarModel';
import { CarClaim } from '@models/CarClaim';
import { Order } from '@models/Order';
import { CarBrandApi } from '@apis/CarBrandApi';
import { CarModelApi, GetAllCarModelParams } from '@apis/CarModelApi';
import { CarClaimApi, CreateCarClaimParams } from '@apis/CarClaimApi';
import { OrderApi, GetOrderParams } from '@apis/OrderApi';
import {
  policyFetch,
  policySet,
  carPolicyClaimCarBrandsFetch,
  carPolicyClaimCarBrandsSet,
  carPolicyClaimCarModelsFetch,
  carPolicyClaimCarModelsSet,
  carClaimCreate,
  carClaimSet,
} from './action';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';

function* handlePolicyFetch({ payload }: SagaPayload<GetOrderParams>) {
  try {
    const policy = (yield call(OrderApi.get, payload)) as Order;
    yield put(policySet({ policy }));
  } catch {
    yield put(policySet({ policy: null }));
  }
}

function* handleCarClaimCarBrandsFetch() {
  try {
    const carBrands = (yield call(CarBrandApi.getAll)) as CarBrand[];
    yield put(carPolicyClaimCarBrandsSet({ carBrands }));
  } catch {
    yield put(carPolicyClaimCarBrandsSet({ carBrands: [] }));
  }
}

function* handleCarClaimCarModelsFetch({ payload }: SagaPayload<GetAllCarModelParams>) {
  try {
    const carModels = (yield call(CarModelApi.getAll, payload)) as CarModel[];
    yield put(carPolicyClaimCarModelsSet({ carModels }));
  } catch {
    yield put(carPolicyClaimCarModelsSet({ carModels: [] }));
  }
}

function* handleCarClaimCreate({ payload }: SagaPayload<CreateCarClaimParams>) {
  try {
    const carClaim = (yield call(CarClaimApi.create, payload)) as CarClaim;
    yield put(carClaimSet({ carClaim }));
    history.navigate('/user-dashboard/claim', { replace: true });
  } catch {
    yield put(carClaimSet({ carClaim: null }));
  }
}

export default function* () {
  yield takeLatest(policyFetch, handlePolicyFetch);
  yield takeLatest(carPolicyClaimCarBrandsFetch, handleCarClaimCarBrandsFetch);
  yield takeLatest(carPolicyClaimCarModelsFetch, handleCarClaimCarModelsFetch);
  yield takeLatest(carClaimCreate, handleCarClaimCreate);
}
