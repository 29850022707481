import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';

import financialProtectionAccidentalDeathImg from './1.png';
import financialProtectionAccidentalDisabilityImg from './2.png';
import reimbursementMedicalExpenseImg from './3.png';
import peacefulMindImg from './4.png';

const IMAGES = [
  financialProtectionAccidentalDeathImg,
  financialProtectionAccidentalDisabilityImg,
  reimbursementMedicalExpenseImg,
  peacefulMindImg,
];

const WhyNeedSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5 bg-light">
      <Container>
        <h2 className="text-center">{t('personalAccidentInsurancePage.whyNeedSection.title')}</h2>
        <Row className="gy-4 mt-5">
          {map(IMAGES, (image, idx) => (
            <Col md={6} xl={3} key={`whyNeedItem${idx}`}>
              <Card className="shadow bg-body border-0 h-100">
                <div className="text-center pt-3">
                  <Card.Img
                    src={image as string}
                    className="w-50"
                    alt="MGA Cover personal accident insurance benefits"
                  />
                </div>
                <Card.Body>
                  <Card.Title className="my-4 text-center">
                    <h4 className="fw-bold">
                      {t(`personalAccidentInsurancePage.whyNeedSection.item${idx + 1}.label`)}
                    </h4>
                  </Card.Title>
                  <Card.Text>{t(`personalAccidentInsurancePage.whyNeedSection.item${idx + 1}.desc`)}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export { WhyNeedSection };
