import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row, Button, Stack } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import CarQuotePremiumCardBody from '@components/Feature/CarQuote/CarQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';
import { FormGroupCheck, FormGroupControl } from '@components/Base';

import { useCarQuoteEdit } from '../hook';
import { carQuotePayByCash, CarQuotePayByCashPayload } from '../action';
import { getCarQuoteBuyReviewPageRoute } from '../route';
import { useInitialValues, useValidationSchema } from './hook';
import { FormValues } from './type';

const GROUP_PROPS = { className: 'mb-3' };

const CarQuoteBuyPayByCashPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carQuote = useCarQuoteEdit();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  if (!carQuote) return null;

  const { carOrderId } = carQuote;

  const handleCancel = () => {
    navigate(getCarQuoteBuyReviewPageRoute({ carOrderId }), { replace: true });
  };

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuotePayByCashPayload = { carOrderId, ...values };
    dispatch(carQuotePayByCash(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          {initialValues && (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {() => (
                <Form>
                  <Card>
                    <Card.Body>
                      <FormGroupCheck name="agree" groupProps={GROUP_PROPS} label="Mark as paid" />

                      <FormGroupControl name="proof" type="file" label={t('common:proof')} groupProps={GROUP_PROPS} />

                      <p>Notice: A receipt email will be sent to customer.</p>
                    </Card.Body>

                    <Card.Footer className="bg-white border-0 mx-auto my-4">
                      <Stack direction="horizontal" gap={3}>
                        <Button onClick={handleCancel} variant="outline-primary" size="lg">
                          {t('common:cancel')}
                        </Button>

                        <Button type="submit" variant="primary" size="lg">
                          Pay By Cash
                        </Button>
                      </Stack>
                    </Card.Footer>
                  </Card>
                </Form>
              )}
            </Formik>
          )}
        </Col>

        <Col lg={4}>
          <Card className="mb-4">
            <CarQuotePremiumCardBody {...carQuote} />
          </Card>
          <SupportCard />
        </Col>
      </Row>
    </Container>
  );
};

export { CarQuoteBuyPayByCashPage };
