import React, { useCallback } from 'react';
import { includes, map, snakeCase } from 'lodash';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';
import { UserDocumentTypes } from '@models/UserDocument';
import { BackButton, FormGroupControl, FormGroupSelect } from '@components/Base';
import { ServerError } from '@components/Helper';
import { userDocumentCreate } from './action';
import { selectDocumentNew } from './selector';
import i18n from '@components/i18n';

export const FILE_SIZE = 5000000; //5MB
export const DOCUMENT_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export type FormValues = {
  documentType: string;
  document: File | null;
};

const initialValues: FormValues = {
  documentType: '',
  document: null,
};

const validationSchema = Yup.object({
  documentType: Yup.string().required(i18n.t('validation:required')),
  document: Yup.mixed()
    .required(i18n.t('validation:required'))
    .test(
      'fileFormat',
      i18n.t('validation:invalidFormat'),
      (file: File) => file && includes(DOCUMENT_SUPPORTED_FORMATS, file.type),
    )
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (file: File) => file && file.size < FILE_SIZE),
});

const groupProps = { className: 'mb-3' };

const DocumentNew = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errors } = useSelector(selectDocumentNew);

  const handleBack = useCallback(() => {
    navigate('/user-dashboard/document');
  }, []);

  const handleSubmit = useCallback((values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    dispatch(userDocumentCreate(values));
    setSubmitting(false);
  }, []);

  return (
    <>
      <BackButton onClick={handleBack} className="mb-4" />
      <Card>
        <Card.Body>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <FormGroupSelect name="documentType" label={t('common:documentType')} required groupProps={groupProps}>
                  <option></option>
                  {map(UserDocumentTypes, (value, key) => (
                    <option key={key} value={snakeCase(key)}>
                      {value}
                    </option>
                  ))}
                </FormGroupSelect>
                <FormGroupControl
                  type="file"
                  name="document"
                  label={t('common:document')}
                  required
                  groupProps={groupProps}
                />

                <Button type="submit" disabled={isSubmitting}>
                  {t('common:submit')}
                </Button>
                <ServerError errors={errors} />
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};

export { DocumentNew };
