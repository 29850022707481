import React from 'react';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import { lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';

import { amountToUsd, amountToKhr } from '@utils/currency';
import { CarPlanTypes, tCarPlanType } from '@models/CarPlanType';
import { CarQuote, CarQuotePremium } from '@models/CarQuote';

import style from './style.module.scss';
import { InsurerLogo } from '@components/Feature/Insurer';
import { AmountWithDiscount } from '@components/Base';

type Props = CarQuotePremium &
  Pick<CarQuote, 'idvAmount' | 'planType' | 'insurer' | 'accidentToDriver' | 'passengerLiability'>;

const CarQuotePremiumCardBody = ({
  insurer,
  planType,
  idvAmount,

  thirdPartyAmount,
  thirdPartyDiscountAmount,
  thirdPartyFinalAmount,

  ownDamageAmount,
  ownDamageDiscountAmount,
  ownDamageFinalAmount,

  accidentToDriver,
  accidentToDriverAmount,
  accidentToDriverDiscountAmount,
  accidentToDriverFinalAmount,

  passengerLiability,
  passengerLiabilityAmount,
  passengerLiabilityDiscountAmount,
  passengerLiabilityFinalAmount,

  adminFeeFinalAmount,

  finalPremium,

  totalPremium,
  totalPremiumKhr,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <div className={classNames('position-relative mb-4', style.insurerLogoWrapper)}>
        <InsurerLogo
          insurer={insurer}
          className={classNames('position-absolute top-50 start-0 translate-middle-y', style.insurerLogo)}
        />
      </div>

      <ul className={classNames('list-unstyled pb-2 border-bottom', style.borderBottomDash)}>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:policyTerm')}:</span>
          <span>1 {t('common:year')}</span>
        </li>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:premiumPaymentTerm')}:</span>
          <span>1 {t('common:year')}</span>
        </li>
      </ul>

      <ul className={classNames('list-unstyled pb-2 border-bottom', style.borderBottomDash)}>
        {planType === CarPlanTypes.Comprehensive && (
          <li className="d-flex justify-content-between">
            <span className="fw-bold">{t('common:coverValueIdv')}:</span>
            <span>{amountToUsd(idvAmount)}</span>
          </li>
        )}

        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:planType')}:</span>
          <span>{tCarPlanType(planType)}</span>
        </li>
      </ul>

      <ul className={classNames('list-unstyled pb-2 border-bottom', style.borderBottomDash)}>
        {planType === CarPlanTypes.Comprehensive && (
          <li className="d-flex justify-content-between">
            <span className="fw-bold">
              {t('common:od')} / {t('common:theft')}:
            </span>
            <AmountWithDiscount
              amount={ownDamageAmount}
              discountAmount={ownDamageDiscountAmount}
              finalAmount={ownDamageFinalAmount}
            />
          </li>
        )}

        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:tpl')}:</span>
          <AmountWithDiscount
            amount={thirdPartyAmount}
            discountAmount={thirdPartyDiscountAmount}
            finalAmount={thirdPartyFinalAmount}
          />
        </li>

        {accidentToDriver && (
          <li className="d-flex justify-content-between">
            <span className="fw-bold">{t('common:ad')}:</span>
            <AmountWithDiscount
              amount={accidentToDriverAmount}
              discountAmount={accidentToDriverDiscountAmount}
              finalAmount={accidentToDriverFinalAmount}
            />
          </li>
        )}

        {passengerLiability && (
          <li className="d-flex justify-content-between">
            <span className="fw-bold">{t('common:pl')}:</span>
            <AmountWithDiscount
              amount={passengerLiabilityAmount}
              discountAmount={passengerLiabilityDiscountAmount}
              finalAmount={passengerLiabilityFinalAmount}
            />
          </li>
        )}
      </ul>

      <ul className={classNames('list-unstyled pb-2 border-bottom', style.borderBottomDash)}>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:premium')}:</span>
          <AmountWithDiscount finalAmount={finalPremium} />
        </li>

        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:adminFee')}:</span>
          <AmountWithDiscount finalAmount={adminFeeFinalAmount} />
        </li>
      </ul>

      <ul className={classNames('list-unstyled pb-2 mb-0')}>
        <li className="d-flex justify-content-between">
          <span className="fw-bold">{t('common:youWillPay')}:</span>
          <span>
            {amountToUsd(totalPremium)}/{lowerCase(t('common:year'))}
          </span>
        </li>
        <li className="d-flex justify-content-between">
          <span>&nbsp;</span>
          <span>
            {amountToKhr(totalPremiumKhr)}/{lowerCase(t('common:year'))}
          </span>
        </li>
      </ul>
    </Card.Body>
  );
};

export { CarQuotePremiumCardBody };
