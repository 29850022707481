import {
  FACEBOOK_MESSENGER_URL,
  MGA_PHONE_NUMBER_LINE_1,
  MGA_PHONE_NUMBER_LINE_2,
  MGA_SUPPORT_EMAIL,
  TELEGRAM_URL,
} from '@config';
import { faFacebookMessenger, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhoneAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { omitBy } from 'lodash';

type SupportItem = {
  type: 'social' | 'phone' | 'email';
  href: string;
  value: string;
  icon: IconDefinition;
  label: string;
};

export const SUPPORTS: Record<string, SupportItem> = {
  phone1: {
    type: 'phone',
    href: `tel:${MGA_PHONE_NUMBER_LINE_1}`,
    value: MGA_PHONE_NUMBER_LINE_1,
    icon: faPhoneAlt,
    label: MGA_PHONE_NUMBER_LINE_1,
  },
  phone2: {
    type: 'phone',
    href: `tel:${MGA_PHONE_NUMBER_LINE_2}`,
    value: MGA_PHONE_NUMBER_LINE_2,
    icon: faPhoneAlt,
    label: MGA_PHONE_NUMBER_LINE_2,
  },
  messenger: {
    type: 'social',
    href: FACEBOOK_MESSENGER_URL,
    value: FACEBOOK_MESSENGER_URL,
    icon: faFacebookMessenger,
    label: 'Messenger',
  },
  telegram: {
    type: 'social',
    href: TELEGRAM_URL,
    value: TELEGRAM_URL,
    icon: faTelegram,
    label: 'Telegram',
  },
  email: {
    type: 'email',
    href: `mailto:${MGA_SUPPORT_EMAIL}`,
    value: MGA_SUPPORT_EMAIL,
    icon: faEnvelope,
    label: MGA_SUPPORT_EMAIL,
  },
};

export const SUPPORTS_WITHOUT_EMAIL = omitBy(SUPPORTS, ({ type }: SupportItem) => type === 'email');
