import React from 'react';
import { Accordion, Card, useAccordionButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import pluralize from 'pluralize';

import { CarQuote } from '@models/CarQuote';

const Toggle = ({ children, eventKey }: { children: React.ReactNode; eventKey: string }) => {
  const handleClick = useAccordionButton(eventKey);

  return (
    <Card.Link className="c-pointer" onClick={handleClick}>
      <Card.Title>{children}</Card.Title>
    </Card.Link>
  );
};

const CarQuoteExclusionCardBody = ({ exclusionHtml }: CarQuote) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Accordion flush>
        <Toggle eventKey="0">{pluralize(t('common:exclusion'), 2)}</Toggle>
        <Accordion.Collapse eventKey="0">
          <>{parse(exclusionHtml)}</>
        </Accordion.Collapse>
      </Accordion>
    </Card.Body>
  );
};

export { CarQuoteExclusionCardBody };
