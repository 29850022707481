import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import classNames from 'classnames';

import { Expand, StepFormProps } from '@components/Base';
import { getOptionYears } from '@utils/datetime';

import { FormValues } from './type';
import { CardHeader } from './card-header';
import { Item } from './item';

const ManufacturedYearStepForm = ({ onPrev, onNext }: StepFormProps) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();
  const [expanded, setExpanded] = useState(false);
  const manufacturedYears = useMemo(() => getOptionYears(), []);

  const handleItemClick = useCallback((manufacturedYear: number) => {
    onNext({ manufacturedYear });
  }, []);

  const renderItem = useCallback((manufacturedYear: string) => {
    const selected = values.manufacturedYear === Number(manufacturedYear);

    return (
      <Col xs={12} sm={6} md={12} lg={6} xl={4} key={manufacturedYear} className="mb-3">
        <Item
          testID={classNames('manufactured-year-item', { selected })}
          selected={selected}
          onClick={() => handleItemClick(Number(manufacturedYear))}
        >
          {manufacturedYear}
        </Item>
      </Col>
    );
  }, []);

  useEffect(() => {
    if (values.manufacturedYear) {
      setExpanded(true);
    }
  }, []);

  return (
    <Card>
      <CardHeader
        title={t('carInsuranceQuote.carManufacturedYear.title')}
        onClick={() => onPrev?.({ manufacturedYear: undefined })}
      />
      <Card.Body>
        <Expand
          expand={expanded}
          wrapper={Row}
          wrapperProps={{ className: 'mb-n3' }}
          items={manufacturedYears}
          renderItem={renderItem}
          onExpand={() => setExpanded(true)}
        />
      </Card.Body>
    </Card>
  );
};

export { ManufacturedYearStepForm };
