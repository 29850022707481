import { createActions } from 'redux-actions';
import { Order } from '@models/Order';
import { PersonalAccidentClaim } from '@models/PersonalAccidentClaim';

export type State = {
  policy: Order | null | undefined;
  personalAccidentClaim: PersonalAccidentClaim | null | undefined;
};
export type StateUpdatePayload = Partial<State>;

export const {
  policyFetch,
  personalAccidentClaimCreate,

  stateUpdate,
} = createActions(
  'POLICY_FETCH',
  'PERSONAL_ACCIDENT_CLAIM_CREATE',

  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_POLICY_CLAIM_PERSONAL_ACCIDENT_POLICY_CLAIM',
  },
);
