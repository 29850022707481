import { object, string } from "yup";

import i18n from "@components/i18n";

export const validationSchema = object({
  firstName: string().required(i18n.t('validation:required')),
  lastName: string().required(i18n.t('validation:required')),
  firstNameKm: string(),
  lastNameKm: string(),
  gender: string().required(i18n.t('validation:required')),
  dob: string().required(i18n.t('validation:required')),
  personalCode: string(),
  occupation: string(),
  email: string().email(),
  mobileNumber: string().required(i18n.t('validation:required')),
  address: string(),
});
