import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useHeaderNavLinks = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        label: t('common:renewPolicy'),
        href: '/user-dashboard/policy',
      },
      {
        label: t('common:claim'),
        href: '/user-dashboard/claim',
      },
      {
        label: t('common:contactUs'),
        href: '/contact-us',
      },
    ],
    [],
  );
};
