import { createActions } from 'redux-actions';

import { HealthQuote } from '@models/HealthQuote';
import { GetAllHealthQuoteParams } from '@apis/HealthQuoteApi';
import { HealthQuoteGroup } from '@models/HealthQuoteGroup';

export type State = {
  totalQuotes: number | null | undefined;
  healthQuoteGroups: HealthQuoteGroup[] | null | undefined;
  comparableHealthQuotes: HealthQuote[];
};

export type HealthQuotesFetchPayload = Omit<GetAllHealthQuoteParams, 'healthPlanIds'>;

export type CompareAddPayload = { healthQuote: HealthQuote };
export type CompareRemovePayload = CompareAddPayload;

export type StateUpdatePayload = Partial<State>;

export const {
  healthQuotesFetch,

  compareAdd,
  compareRemove,
  compareRemoveAll,

  stateUpdate,
  stateReset,
} = createActions(
  'HEALTH_QUOTES_FETCH',

  'COMPARE_ADD',
  'COMPARE_REMOVE',
  'COMPARE_REMOVE_ALL',

  'STATE_UPDATE',
  'STATE_RESET',
  {
    prefix: 'HEALTH_QUOTE_RESULT_PAGE',
  },
);
