import { createActions } from 'redux-actions';

export const {
  carFetch,

  carBrandFetch,
  carBrandsFetch,

  carModelFetch,
  carModelsFetch,
  carModelsSet,

  carVariantFetch,
  carVariantsFetch,
  carVariantsSet,

  stateUpdate,
} = createActions(
  'CAR_FETCH',

  'CAR_BRAND_FETCH',
  'CAR_BRANDS_FETCH',

  'CAR_MODEL_FETCH',
  'CAR_MODELS_FETCH',
  'CAR_MODELS_SET',

  'CAR_VARIANT_FETCH',
  'CAR_VARIANTS_FETCH',
  'CAR_VARIANTS_SET',

  'STATE_UPDATE',
  {
    prefix: 'CAR_QUOTE_PAGE',
  },
);
