import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { map, isUndefined } from 'lodash';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import { NoData, Loading, Pagination, ClaimBadge } from '@components/Base';
import { ClaimList } from '@models/Claim';
import { formatDateTime } from '@utils/datetime';
import { isPaginated } from '@utils/pagination';
import { productName } from '@utils/product';
import { claimsFetch } from './action';
import { selectClaim } from './selector';

const Claim = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { claims, pagination } = useSelector(selectClaim);
  const isLoaded = !isUndefined(claims);
  const hasClaims = isLoaded && claims && claims.length > 0;

  useEffect(() => {
    dispatch(claimsFetch());
  }, []);

  const handleViewDetails = useCallback(({ claimableId, claimableType }: ClaimList) => {
    navigate(`/user-dashboard/claim/${claimableType}/${claimableId}`);
  }, []);

  const handleNewClaim = useCallback(() => {
    navigate('/user-dashboard/policy');
  }, []);

  return (
    <section>
      <div className="d-flex justify-content-between mb-4">
        <h5>{t('common:claim')}</h5>
        <Button variant="primary" size="sm" onClick={handleNewClaim}>
          {t('common:newClaim')}
        </Button>
      </div>
      <Card>
        <Card.Body>
          {!isLoaded && <Loading />}
          {isLoaded && !hasClaims && <NoData />}

          {hasClaims && (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>{t('common:policyNumber')}</th>
                    <th>{t('common:orderNumber')}</th>
                    <th>{pluralize.singular(t('common:insuranceProducts'))}</th>
                    <th>{t('common:claimedDate')}</th>
                    <th>{t('common:status')}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {map(claims, (claim) => {
                    const { id, orderNumber, policyNumber, claimableType, status, createdAt } = claim;

                    return (
                      <tr key={id} data-testid="claim-item">
                        <td>{policyNumber}</td>
                        <td>{orderNumber}</td>
                        <td>{productName(claimableType)}</td>
                        <td>{formatDateTime(createdAt)}</td>
                        <td>
                          <ClaimBadge {...{ status }} />
                        </td>
                        <td>
                          <Button onClick={() => handleViewDetails(claim)} variant="outline-primary" size="sm">
                            {t('common:viewDetails')}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {isPaginated(pagination) && (
                <div className="d-flex justify-content-center">
                  <br />
                  <Pagination
                    url="/user-dashboard/claim"
                    page={pagination?.page ?? 1}
                    totalPages={pagination?.totalPages ?? 1}
                  />
                </div>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </section>
  );
};

export { Claim };
