import { createActions } from 'redux-actions';

import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { PersonalAccidentOrder } from '@models/PersonalAccidentOrder';

export type State = {
  personalAccidentQuote: PersonalAccidentQuote | null | undefined;
  personalAccidentOrder: PersonalAccidentOrder | null | undefined;
};

export type StateUpdatePayload = Partial<State>;

export const {
  personalAccidentQuoteFetch,
  personalAccidentQuoteCreate,
  personalAccidentQuoteEdit,
  personalAccidentQuoteUpdate,

  personalAccidentOrderCreate,

  stateUpdate,
} = createActions(
  'PERSONAL_ACCIDENT_QUOTE_FETCH',
  'PERSONAL_ACCIDENT_QUOTE_CREATE',
  'PERSONAL_ACCIDENT_QUOTE_EDIT',
  'PERSONAL_ACCIDENT_QUOTE_UPDATE',

  'PERSONAL_ACCIDENT_ORDER_CREATE',

  'STATE_UPDATE',
  {
    prefix: 'PERSONAL_ACCIDENT_QUOTE_BUY_PAGE',
  },
);
