import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { selectPersonalAccidentQuoteComparePage } from './selector';
import { TableRowItem } from './table-row-item';

const TableGeographicCoverage = () => {
  const { t } = useTranslation();
  const { personalAccidentQuotes } = useSelector(selectPersonalAccidentQuoteComparePage);

  return (
    <TableRowItem title={t('personalAccidentQuote.geographicCoverage')}>
      {map(personalAccidentQuotes, ({ personalAccidentPlanId, geographicCoverage }) => (
        <td key={personalAccidentPlanId}>{geographicCoverage}</td>
      ))}
    </TableRowItem>
  );
};

export { TableGeographicCoverage };
