import React, { useRef } from 'react';
import classNames from 'classnames';
import { useHover } from 'usehooks-ts';

interface Props {
  onClick: () => void;
  selected: boolean;
  children: React.ReactNode;
  testID?: string;
}

const Item = ({ children, onClick, selected, testID }: Props) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const style = classNames(
    'd-flex flex-column align-items-center justify-content-center text-center border p-2 c-pointer',
    {
      'border-primary text-primary': selected || isHovered,
    },
  );

  return (
    <div ref={hoverRef} onClick={onClick} className={style} data-testid={testID}>
      {children}
    </div>
  );
};

export { Item };
