import { createActions } from 'redux-actions';

export const { passwordVerify, passwordVerifyError } = createActions(
  {
    PASSWORD_VERIFY: undefined,
    PASSWORD_VERIFY_ERROR: undefined,
  },
  {
    prefix: 'USER_REGISTRATION',
  }
);
