import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useField } from 'formik';
import { FormGroupControl } from '@components/Base';

type Props = {
  member: string;
  title: string;
  disabled?: boolean;
};

const ContactFormControl = ({ member, title, disabled = false }: Props) => {
  const { t } = useTranslation();
  const [, , helpers] = useField(`${member}.age`);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const dob = moment(event.target.value);

    if (dob.isValid()) {
      const age = moment().diff(dob, 'years');
      helpers.setValue(age, true);
    }
  };

  return (
    <Card className="mb-3" data-testid={member}>
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <FormGroupControl
              type="date"
              name={`${member}.dob`}
              label={t('user.dob')}
              onBlur={handleBlur}
              required
              disabled={disabled}
            />
          </Col>

          <Col>
            <FormGroupControl type="number" name={`${member}.age`} label={t('user.age')} disabled required />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export { ContactFormControl };
