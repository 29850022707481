import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { CarOrder } from '@models/CarOrder';
import { API_V1_BASE } from '@config';

export type CreateBillingCarOrderParams = {
  id: number;
};
export type GetCarOrderParams = CreateBillingCarOrderParams;

export type PreviewCarOrderParams = GetCarOrderParams;

export type RenewCarOrderParams = PreviewCarOrderParams;

export class CarOrderApi {
  static createBilling = async ({ id }: CreateBillingCarOrderParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/car_orders/${id}/billing`)
      .then((response) => humps(response.data) as CarOrder);
  };

  static get = async ({ id }: GetCarOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/car_orders/${id}`)
      .then((response) => humps(response.data) as CarOrder);
  };

  static preview = async ({ id }: PreviewCarOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/car_orders/${id}/renewal`)
      .then((response) => humps(response.data) as CarOrder);
  };

  static renew = async ({ id }: RenewCarOrderParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/car_orders/${id}/renewal`)
      .then((response) => humps(response.data) as CarOrder);
  };
}
