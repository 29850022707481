import { stringify } from 'qs';
import { keys } from 'lodash';

export const replaceRouteParams = (url: string, params: Record<string, string | number>): string => {
  let resultUrl = url;
  keys(params).forEach((param) => {
    resultUrl = resultUrl.replace(`:${param}`, params[param].toString());
  });
  return resultUrl;
};

export const getRoute = <T extends object>(pathname: string, params: T) => {
  return {
    pathname,
    search: stringify(params),
  };
};
