import React from 'react';
import { Container, Row, Col, Accordion, ListGroup } from 'react-bootstrap';
import { map, slice, take } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { BusinessPartner } from '@models/BusinessPartner';

import { Title } from './title';

const LIMIT = 5;

const HospitalItem = ({ name, fullAddress, telNumber }: BusinessPartner) => {
  return (
    <ListGroup.Item key={name}>
      <h4>{name}</h4>
      <address>{fullAddress}</address>
      <span>{telNumber}</span>
    </ListGroup.Item>
  );
};

const HospitalSection = ({ hospitals }: PersonalAccidentQuote) => {
  const { t } = useTranslation();
  const shouldRenderViewMore = hospitals.length > LIMIT;

  return (
    <section className="py-5">
      <Title>{t('healthQuote.cashlessHospitals')}</Title>
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <ListGroup variant="flush">
              {map(take(hospitals, LIMIT), (hospital) => (
                <HospitalItem key={hospital.name} {...hospital} />
              ))}
            </ListGroup>

            {shouldRenderViewMore && (
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="w-25 mx-auto">{t('common:viewMore')}</Accordion.Header>
                  <Accordion.Body className="mt-3 px-0">
                    <ListGroup variant="flush">
                      {map(slice(hospitals, LIMIT), (hospital) => (
                        <HospitalItem key={hospital.name} {...hospital} />
                      ))}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { HospitalSection };
