import React, { useCallback, useEffect } from 'react';
import { includes, isNull, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import { selectVehicleDetails, userCarFetch } from '../VehicleDetails';
import * as Yup from 'yup';
import { Formik, FormikHelpers, Form } from 'formik';
import { BackButton, FormGroupControl, Loading, NoData } from '@components/Base';
import i18n from '@components/i18n';

import { userCarReview } from './action';

const FILE_SIZE = 5_000_000; //5MB

type FormValues = {
  identificationCard?: File;
};

const initialValues: FormValues = {};

const validationSchema = Yup.object({
  identificationCard: Yup.mixed()
    .required(i18n.t('validation:required'))
    .test('fileFormat', i18n.t('validation:invalidFormat'), (file: File) => file && includes(file.type, 'image'))
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (file: File) => file && file.size < FILE_SIZE),
});

const VehicleForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vehicleId } = useParams();
  const { userCar } = useSelector(selectVehicleDetails);
  const isLoaded = !isUndefined(userCar);
  const hasUserCar = isLoaded && !isNull(userCar);

  const handleBack = useCallback(() => {
    navigate('./../', { replace: true });
  }, []);

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    dispatch(
      userCarReview({
        ...values,
        id: vehicleId,
      }),
    );
    setSubmitting(false);
  };

  useEffect(() => {
    if (!userCar) dispatch(userCarFetch({ id: vehicleId }));
  }, []);

  return (
    <>
      <BackButton onClick={handleBack} className="mb-4" />
      {!isLoaded && <Loading />}
      {isLoaded && !hasUserCar && <NoData />}
      {hasUserCar && (
        <Card>
          <Card.Header className="fs-5 fw-bold">{userCar.name}</Card.Header>
          <Card.Body>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ isSubmitting }) => (
                <Form>
                  <FormGroupControl
                    type="file"
                    name="identificationCard"
                    label={t('car.identificationCard')}
                    required
                    groupProps={{ className: 'mb-3' }}
                  />
                  <Button type="submit" disabled={isSubmitting}>
                    {t('common:update')}
                  </Button>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export { VehicleForm };
