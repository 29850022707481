import React from 'react';
import { FormGroup, FormGroupProps, FormText, FormTextProps } from 'react-bootstrap';
import { FormikCheck, FormikCheckProps } from '../';

type FormGroupCheckProps = FormikCheckProps & {
  name: string;
  feedback?: string;
  help?: string;
  groupProps?: FormGroupProps;
  helpProps?: FormTextProps;
};

const FormGroupCheck = ({ help, groupProps, helpProps, ...props }: FormGroupCheckProps) => {
  return (
    <FormGroup {...groupProps}>
      <FormikCheck {...props} />
      {help && (
        <FormText muted {...helpProps}>
          {help}
        </FormText>
      )}
    </FormGroup>
  );
};

export { FormGroupCheck, FormGroupCheckProps };
