import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/search-params';

import { selectCancerQuoteDetailsPage } from './selector';
import { cancerQuoteFetch, CancerQuoteFetchPayload } from './action';
import { CancerQuoteDetailsPageParams, cancerQuoteDetailsPageParamsSchema } from './route';

export const useCancerQuoteDetailsPageParams = () =>
  useParseParams<CancerQuoteDetailsPageParams>(cancerQuoteDetailsPageParamsSchema);

export const useCancerQuoteFetch = () => {
  const dispatch = useDispatch();
  const payload: CancerQuoteFetchPayload = useCancerQuoteDetailsPageParams();
  const { cancerQuote } = useSelector(selectCancerQuoteDetailsPage);

  useEffect(() => {
    dispatch(cancerQuoteFetch(payload));
  }, []);

  return cancerQuote;
};
