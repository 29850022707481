import React, { useState, useEffect } from 'react';
import { useHref, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, Stack, ToggleButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { some, isEmpty } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { HealthQuote } from '@models/HealthQuote';
import { amountToUsd } from '@utils/currency';
import { tHealthPlanBenefitType, HealthPlanBenefitTypes, HealthPlanBenefit } from '@models/HealthPlanBenefit';
import { getHealthQuoteDetailsPageRoute } from '@components/HealthQuoteDetailsRoute/route';
import { NumericString } from '@utils/typescript';

import { compareAdd, compareRemove } from '../action';
import { selectHealthQuoteResultPage } from '../selector';
import { useHealthQuoteResultPageParams } from '../hook';

const BenefitItem = ({
  benefitType,
  amount,
  items,
}: {
  benefitType: HealthPlanBenefitTypes;
  amount: NumericString;
  items: HealthPlanBenefit[];
}) => {
  if (isEmpty(items)) return null;
  if (!Number(amount)) return null;

  return (
    <Card.Text>
      <strong>{tHealthPlanBenefitType(benefitType)} - </strong>
      {amountToUsd(amount)}
    </Card.Text>
  );
};

interface Props {
  healthQuote: HealthQuote;
}

const HealthQuoteItem = ({ healthQuote }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useHealthQuoteResultPageParams();
  const { comparableHealthQuotes } = useSelector(selectHealthQuoteResultPage);
  const {
    healthPlanId,
    healthPlanName,
    geographicCoverage,
    annualLimitAmount,
    inpatientAnnualLimitAmount,
    inpatientBenefits,
    outpatientAnnualLimitAmount,
    outpatientBenefits,
    healthCheckupAnnualLimitAmount,
    healthCheckupBenefits,
    maternityAnnualLimitAmount,
    maternityBenefits,
    visionAnnualLimitAmount,
    visionBenefits,
    dentalAnnualLimitAmount,
    dentalBenefits,
    copayAnnualLimitAmount,
    copayBenefits,
    othersAnnualLimitAmount,
    othersBenefits,
    totalPremium,
  } = healthQuote;
  const healthQuoteDetailsPageHref = useHref(getHealthQuoteDetailsPageRoute({ ...params, healthPlanId }));
  const [checked, setChecked] = useState(false);

  const handleBuyNowClick = () => {
    navigate(getHealthQuoteDetailsPageRoute({ ...params, healthPlanId }));
  };

  const handleCompare = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    if (currentTarget.checked) {
      dispatch(compareAdd({ healthQuote: healthQuote }));
    } else {
      dispatch(compareRemove({ healthQuote: healthQuote }));
    }
  };

  useEffect(() => {
    setChecked(some(comparableHealthQuotes, { healthPlanId }));
  }, [comparableHealthQuotes]);

  return (
    <Row>
      <Col lg={4}>
        <Card.Title>{healthPlanName}</Card.Title>
        <Card.Text>
          <strong>{t('healthQuote.geographicCoverage')} - </strong>
          {geographicCoverage}
        </Card.Text>
        <Card.Text>
          <strong>{t('healthQuote.annualLimit')} - </strong>
          {amountToUsd(annualLimitAmount)}
        </Card.Text>
        <Card.Text>
          <ToggleButton
            id={`quote-${healthQuote.healthPlanId}`}
            type="checkbox"
            variant="outline-secondary"
            value={healthQuote.healthPlanId}
            checked={checked}
            onChange={handleCompare}
          >
            {t('common:compare')}
          </ToggleButton>
        </Card.Text>
      </Col>

      <Col lg={5}>
        <BenefitItem
          benefitType={HealthPlanBenefitTypes.Inpatient}
          amount={inpatientAnnualLimitAmount}
          items={inpatientBenefits}
        />
        <BenefitItem
          benefitType={HealthPlanBenefitTypes.Outpatient}
          amount={outpatientAnnualLimitAmount}
          items={outpatientBenefits}
        />
        <BenefitItem
          benefitType={HealthPlanBenefitTypes.HealthCheckup}
          amount={healthCheckupAnnualLimitAmount}
          items={healthCheckupBenefits}
        />
        <BenefitItem
          benefitType={HealthPlanBenefitTypes.Maternity}
          amount={maternityAnnualLimitAmount}
          items={maternityBenefits}
        />
        <BenefitItem
          benefitType={HealthPlanBenefitTypes.Vision}
          amount={visionAnnualLimitAmount}
          items={visionBenefits}
        />
        <BenefitItem
          benefitType={HealthPlanBenefitTypes.Dental}
          amount={dentalAnnualLimitAmount}
          items={dentalBenefits}
        />
        <BenefitItem benefitType={HealthPlanBenefitTypes.Copay} amount={copayAnnualLimitAmount} items={copayBenefits} />
        <BenefitItem
          benefitType={HealthPlanBenefitTypes.Others}
          amount={othersAnnualLimitAmount}
          items={othersBenefits}
        />
      </Col>

      <Col lg={3}>
        <Stack gap={3} className="text-center">
          <Card.Text className="mb-0">
            <strong>
              {t('common:premium')}: <span className="fs-5">{amountToUsd(totalPremium)}</span>
            </strong>
          </Card.Text>
          <Button variant="primary" size="lg" className="w-100" onClick={handleBuyNowClick}>
            {t('common:buyNow')}
          </Button>
          <Card.Link href={healthQuoteDetailsPageHref} className="text-decoration-none">
            {t('healthQuote.planDetails')}
            <FontAwesomeIcon icon={faArrowRight} size="xs" className="ms-2" />
          </Card.Link>
        </Stack>
      </Col>
    </Row>
  );
};

export { HealthQuoteItem };
