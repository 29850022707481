import humps from 'lodash-humps-ts';
import { omit } from 'lodash';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';
import { Photo } from '@models/Photo';

export type GetAllPhotoParams = {
  recordId: number;
  recordType: string;
  name: string;
};

export type UploadPhotosParams = GetAllPhotoParams & {
  photos: File[];
};

export type DeletePhotoParams = {
  id: number;
};

export class PhotoApi {
  static upload = async (params: UploadPhotosParams) => {
    return await authAxios
      .postForm(`${API_V1_BASE}/photos`, {
        ...omit(snakes(params), ['photos']),
        'photos[]': params.photos,
      })
      .then((response) => humps(response.data) as Photo[]);
  };

  static getAll = async (params = {} as GetAllPhotoParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/photos`, { params: snakes(params) })
      .then((response) => humps(response.data) as Photo[]);
  };

  static delete = async ({ id }: DeletePhotoParams) => {
    return await authAxios.delete(`${API_V1_BASE}/photos/${id}`).then((response) => humps(response.data) as Photo);
  };
}
