import { call, put, takeLatest } from 'redux-saga/effects';
import { UserDocument } from '@models/UserDocument';
import { GetUserDocumentParams, UserDocumentApi } from '@apis/UserDocumentApi';
import { userDocumentFetch, userDocumentSet } from './action';
import { SagaPayload } from '@utils/typescript';

function* handleUserDocumentFetch({ payload }: SagaPayload<GetUserDocumentParams>) {
  try {
    const userDocument = (yield call(UserDocumentApi.get, payload)) as UserDocument;
    yield put(userDocumentSet({ userDocument }));
  } catch {
    yield put(userDocumentSet({ userDocument: null }));
  }
}

export default function* () {
  yield takeLatest(userDocumentFetch, handleUserDocumentFetch);
}
