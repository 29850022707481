import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map, snakeCase } from 'lodash';
import { FormGroupSelect, FormGroupControl } from '@components/Base';
import { PersonalAccidentClaimTypes, getPersonalAccidentClaimType } from '@models/PersonalAccidentClaim';

const GROUP_PROPS = { className: 'mb-3' };

const ClaimCardBody = () => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{t('common:claim')}</Card.Title>

      <Row>
        <Col>
          <FormGroupSelect name="claimType" label={t('claimForm.claimType.label')} required groupProps={GROUP_PROPS}>
            <option></option>
            {map(PersonalAccidentClaimTypes, (value, key) => (
              <option key={key} value={snakeCase(key)}>
                {getPersonalAccidentClaimType(key)}
              </option>
            ))}
          </FormGroupSelect>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="completedClaimForm"
            type="file"
            label={t('claimForm.completedClaimForm.label')}
            required
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="supportingDocuments"
            type="file"
            multiple
            label={t('claimForm.supportingDocuments.label')}
            required
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            type="file"
            name="invoice"
            label={t('claimForm.invoice.label')}
            required
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="totalClaimedAmount"
            label={t('claimForm.totalClaimedAmount.label')}
            required
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>
    </Card.Body>
  );
};

export { ClaimCardBody };
