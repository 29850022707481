import humps from 'lodash-humps-ts';
import { omit } from 'lodash';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { CancerClaim } from '@models/CancerClaim';
import { InsuranceTypes } from '@models/InsuranceType';
import { API_V1_BASE } from '@config';

export interface CreateCancerClaimParams {
  cancerOrderId: number;
  insuranceType: InsuranceTypes;
  completedClaimForm: File;
  supportingDocuments: File[];
  invoice: File;
  totalClaimedAmount: number;
  acceptTermsAndConditions: boolean;
}

export interface GetCancerClaimParams {
  id: number;
}

export class CancerClaimApi {
  static create = async (params: CreateCancerClaimParams) => {
    return await authAxios
      .postForm(`${API_V1_BASE}/user/cancer_claims`, {
        ...omit(snakes(params), ['supporting_documents']),
        'supporting_documents[]': params.supportingDocuments,
      })
      .then((response) => humps(response.data) as CancerClaim);
  };

  static get = async ({ id }: GetCancerClaimParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/cancer_claims/${id}`)
      .then((response) => humps(response.data) as CancerClaim);
  };
}
