import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const HowItWorksSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5 bg-light">
      <Container>
        <h2 className="text-center">{t('cancerInsurancePage.howItWorksSection.title')}</h2>
        <p className="text-center text-secondary w-75 mx-auto">
          {t('cancerInsurancePage.howItWorksSection.description')}
        </p>
      </Container>
    </section>
  );
};

export { HowItWorksSection };
