import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { UserProfile } from '@models/User';
import { history } from '@utils/history';
import { getResponseError } from '@utils/error';
import { CamDxApi, GetCamDxRegisterParams } from '@apis/CamDxApi';
import { authAccessTokenSet } from '@components/Auth';
import { camDxVerify, camDxVerifyError } from './action';
import { SagaPayload } from '@utils/typescript';
import { AFTER_LOGIN_PATH } from '@config';

function* handleCamDxVerify({ payload }: SagaPayload<GetCamDxRegisterParams>) {
  try {
    const { accessToken } = (yield call(CamDxApi.register, payload)) as UserProfile;
    yield put(authAccessTokenSet(accessToken));
    history.navigate(AFTER_LOGIN_PATH);
  } catch (e) {
    const { error } = getResponseError(e as AxiosError);
    yield put(camDxVerifyError({ errors: { camDx: error } }));
  }
}

export default function* () {
  yield takeLatest(camDxVerify, handleCamDxVerify);
}
