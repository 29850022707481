import { chain } from 'lodash';

import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';

export type PersonalAccidentQuoteGroup = {
  insurer: PersonalAccidentQuote['insurer'];
  personalAccidentQuotes: PersonalAccidentQuote[];
};

export function groupPersonalAccidentQuotes(
  personalAccidentQuotes: PersonalAccidentQuote[],
): PersonalAccidentQuoteGroup[] {
  return chain(personalAccidentQuotes)
    .groupBy('insurer.id')
    .map(
      (mappedPersonalAccidentQuotes: PersonalAccidentQuote[]): PersonalAccidentQuoteGroup => ({
        insurer: mappedPersonalAccidentQuotes[0].insurer,
        personalAccidentQuotes: mappedPersonalAccidentQuotes,
      }),
    )
    .value();
}
