import { createActions } from 'redux-actions';

export const {
  userLeadCreate,
} = createActions(
  'USER_LEAD_CREATE',
  {
    prefix: 'USER_LEAD',
  },
);
