import { object, string } from 'yup';

import { UserFormControlValues, userFormControlValuesParamsSchema } from '@components/Feature/User/UserFormControl';

type UserFormValues = Omit<UserFormControlValues, 'weight' | 'height' | 'age'>;

type CarFormValues = {
  chassisNumber: string;
  engineNumber: string;
  registrationNumber: string;
  registrationPlace: string;
};

export type FormValues = UserFormValues & CarFormValues;

export const userFormValuesParamsSchema = userFormControlValuesParamsSchema.omit(['weight', 'height', 'age']);

export const carFormValuesParamsSchema = object({
  chassisNumber: string().default(''),
  engineNumber: string().default(''),
  registrationNumber: string().default(''),
  registrationPlace: string().default('Phnom Penh'),
});

export const carOrderFormValuesParamsSchema = object({
  activationDate: string().default(''),
  frontlineNote: string().default(''),
});

export const formValuesParamsSchema = userFormValuesParamsSchema.concat(
  carFormValuesParamsSchema.concat(carOrderFormValuesParamsSchema),
);
