import React from 'react';
import { useTranslation } from 'react-i18next';

const NoData = () => {
  const { t } = useTranslation();

  return <div className="text-center">{t('common:noData')}</div>;
};

export { NoData };
