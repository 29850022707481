import { useParseParams } from '@hooks/search-params';

import {
  carQuoteResultPageParamsSchema,
  carQuotesFilterParamsSchema,
} from './route';
import {
  CarQuoteResultPageParams,
  CarQuotesFilterParams,
} from './type';

export const useCarQuotesFilterParams = () => useParseParams<CarQuotesFilterParams>(carQuotesFilterParamsSchema);

export const useCarQuotesResultPageParams = () =>
  useParseParams<Required<CarQuoteResultPageParams>>(carQuoteResultPageParamsSchema);
