import React, { useMemo } from 'react';
import { map, times } from 'lodash';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import accidentToDriverImg from './accident-to-driver.png';
import passengerLiabilityImg from './passenger-liability.png';

const IMAGES = [accidentToDriverImg, passengerLiabilityImg];

export interface Props {
  label: string;
  desc: string;
  image: string;
}

const AddOnCoverCard = ({ label, desc, image }: Props) => {
  return (
    <Card className="shadow bg-body border-0 h-100">
      <div className="text-center pt-3">
        <Card.Img src={image} className="w-25" alt="MGA Cover car insurance addon covers" />
      </div>
      <Card.Body>
        <Card.Title className="my-4 text-center">
          <h4 className="fw-bold">{label}</h4>
        </Card.Title>
        <Card.Text>{desc}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const AddOnCoverSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(2, (n) => ({
      label: t(`carInsurancePage.addOnCoverSection.item${n + 1}.label`),
      desc: t(`carInsurancePage.addOnCoverSection.item${n + 1}.desc`),
      image: IMAGES[n] as string,
    }));
  }, []);

  return (
    <section className="py-5 bg-light">
      <h2 className="text-center">{t('carInsurancePage.addOnCoverSection.title')}</h2>
      <p className="text-center text-secondary w-75 mx-auto">{t('carInsurancePage.addOnCoverSection.subTitle')}</p>
      <Container>
        <Row className="gy-4 mt-5">
          {map(items, (item) => (
            <Col lg={6} key={item.label}>
              <AddOnCoverCard {...item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export { AddOnCoverSection };
