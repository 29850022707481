import React from 'react';
import { ListGroup, Card, Row, Col } from 'react-bootstrap';
import { map } from 'lodash';

import { CancerQuote } from '@models/CancerQuote';

export const RowItem = ({ label, value }: { label: string; value?: React.ReactNode }) => {
  return (
    <Row className="mb-2">
      <Col xs={6} sm={6} md={6}>
        <strong>{label}</strong>
      </Col>
      <Col className="align-self-center">
        <div className="d-flex justify-content-end">{value}</div>
      </Col>
    </Row>
  );
};

const CancerQuoteBenefitCard = ({ cancerPlanBenefits }: Pick<CancerQuote, 'cancerPlanBenefits'>) => {
  return (
    <Card>
      <Card.Body>
        <ListGroup variant="flush">
          {map(cancerPlanBenefits, ({ title, description }) => (
            <ListGroup.Item key={title}>
              <RowItem label={title} value={description} />
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export { CancerQuoteBenefitCard };
