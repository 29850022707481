import React from 'react';
import { useSelector } from 'react-redux';

import { selectCarQuoteComparePage } from './selector';

type Props = {
  header: string
};

const TableRowHeader = ({ header }: Props) => {
  const { carQuotes } = useSelector(selectCarQuoteComparePage);

  if (!carQuotes) return null;

  return (
    <tr className="table-secondary">
      <th colSpan={carQuotes.length + 1}>{header}</th>
    </tr>
  );
}

export { TableRowHeader };
