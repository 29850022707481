import { createActions } from 'redux-actions';

import { CancerQuote } from '@models/CancerQuote';
import { GetAllCancerQuoteParams } from '@apis/CancerQuoteApi';

export type State = {
  cancerQuotes: CancerQuote[] | null | undefined;
};

export type CancerQuotesFetchPayload = Required<Omit<GetAllCancerQuoteParams, 'spouse' | 'sons' | 'daughters'>> &
Pick<GetAllCancerQuoteParams, 'spouse' | 'sons' | 'daughters'>;

export type CancerQuotesFetchParams = CancerQuotesFetchPayload;

export type StateUpdatePayload = Partial<State>;

export const {
  cancerQuotesFetch,

  stateUpdate,
} = createActions(
  'CANCER_QUOTES_FETCH',

  'STATE_UPDATE',
  {
    prefix: 'CANCER_QUOTE_COMPARE_PAGE',
  },
);
