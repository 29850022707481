import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import parse from 'html-react-parser';

import { selectCarQuoteComparePage } from './selector';
import { TableRowHeader } from './table-row-header';
import { TableRowItem } from './table-row-item';

const TableExclusion = () => {
  const { t } = useTranslation();
  const { carQuotes } = useSelector(selectCarQuoteComparePage);

  if (!carQuotes) return null;

  return (
    <>
      <TableRowHeader header={t('common:exclusion')} />

      <TableRowItem>
        {map(carQuotes, ({ carPlanId, exclusionHtml }) => (
          <td key={carPlanId}>{parse(exclusionHtml)}</td>
        ))}
      </TableRowItem>
    </>
  );
};

export { TableExclusion };
