import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faTelegram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { history } from '@utils/history';
import { TELEGRAM_URL, MGA_PHONE_NUMBER_LINE_1, MGA_PHONE_NUMBER_LINE_2, FACEBOOK_MESSENGER_URL } from '@config';

const CarUnknownModal = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(true);

  const handleHide = () => {
    setShow(false);
    searchParams.set('planType', 'third_party');
  };

  useEffect(() => {
    if (!show) {
      setSearchParams(searchParams);
      history.navigate(0);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={handleHide} data-testid="car-not-found-modal" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t('carUnknown.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{t('carUnknown.description')}</p>

        <ListGroup variant="flush">
          <ListGroup.Item>
            <FontAwesomeIcon icon={faTelegram} className="me-2" />
            <Link to={TELEGRAM_URL} target="_blank" rel="noreferrer">
              {t('common:contactViaTelegram')}
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <FontAwesomeIcon icon={faFacebook} className="me-2" />
            <Link to={FACEBOOK_MESSENGER_URL} target="_blank" rel="noreferrer">
              {t('common:contactViaFacebook')}
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <FontAwesomeIcon icon={faPhone} className="me-2" />
            <Link to={`tel:${MGA_PHONE_NUMBER_LINE_1}`}>{MGA_PHONE_NUMBER_LINE_1}</Link>
            <span className="mx-2">|</span>
            <Link to={`tel:${MGA_PHONE_NUMBER_LINE_2}`}>{MGA_PHONE_NUMBER_LINE_2}</Link>
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export { CarUnknownModal };
