import React, { useCallback } from 'react';
import { Container, Row, Col, Card, Button, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { lowerCase } from 'lodash';
import { useHref, useNavigate } from 'react-router-dom';

import { amountToUsd } from '@utils/currency';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { usePersonalAccidentQuoteResultPageParams } from '@components/PersonalAccidentQuoteResultPage';
import { getPersonalAccidentQuoteBuyNewPageRoute } from '@components/PersonalAccidentQuoteBuyRoute/route';
import { getPersonalAccidentQuoteSendEmailPageRoute } from '../route';

type Props = Pick<
PersonalAccidentQuote,
'personalAccidentPlanId' | 'insurer' | 'personalAccidentPlanName' | 'annualLimitAmount' | 'totalPremium'
>;

const HeaderSection = ({
  personalAccidentPlanId,
  insurer,
  personalAccidentPlanName,
  annualLimitAmount,
  totalPremium,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = usePersonalAccidentQuoteResultPageParams();
  const personalAccidentQuoteSendEmailHref = useHref(
    getPersonalAccidentQuoteSendEmailPageRoute({ ...params, personalAccidentPlanId }),
  );

  const handleBuyNowClick = useCallback(() => {
    navigate(getPersonalAccidentQuoteBuyNewPageRoute({ ...params, personalAccidentPlanId }));
  }, []);

  return (
    <section>
      <Container>
        <Card className="shadow">
          <Card.Body>
            <Row className="align-items-center">
              <Col lg={2}>
                <Card.Img src={insurer.logo} className="w-75 rounded img-fluid" />
              </Col>
              <Col lg={10}>
                <Row>
                  <Col lg={4}>
                    <Stack gap={3}>
                      <Card.Title className="mb-0">{personalAccidentPlanName}</Card.Title>
                      <Card.Text>{insurer.name}</Card.Text>
                    </Stack>
                  </Col>
                  <Col lg={4}>
                    <Stack gap={3} className="text-center">
                      <Card.Text className="mb-0">{t('healthQuoteDetailsPage.coverAmount')}</Card.Text>
                      <Card.Text className="fs-5 fw-bold">{amountToUsd(annualLimitAmount)}</Card.Text>
                    </Stack>
                  </Col>
                  <Col lg={4}>
                    <Button variant="primary" size="lg" className="w-100" onClick={handleBuyNowClick}>
                      <span className="d-block">{t('common:buyNow')}</span>
                      <strong className="fs-5 ms-3">
                        {amountToUsd(totalPremium)}/{lowerCase(t('common:year'))}
                      </strong>
                    </Button>
                    <Button
                      variant="link"
                      href={personalAccidentQuoteSendEmailHref}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {t('common:sendQuoteToMyEmail')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};

export { HeaderSection };
