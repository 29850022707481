import { takeLatest, put, call } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { authAccessTokenSet } from '@components/Auth';
import { UserProfile } from '@models/User';
import { getResponseError } from '@utils/error';
import { history } from '@utils/history';
import { AuthApi, RequestOtpCodeAuthParams, VerifyWithOtpCodeAuthParams } from '@apis/AuthApi';
import { otpCodeRequest, otpCodeVerify, otpCodeVerifyError } from './action';
import { SagaPayload } from '@utils/typescript';
import { AFTER_LOGIN_PATH } from '@config';

function* handleOtpCodeRequest({ payload }: SagaPayload<RequestOtpCodeAuthParams>) {
  yield call(AuthApi.requestOtpCode, payload);
}

function* handleOtpCodeVerify({ payload }: SagaPayload<VerifyWithOtpCodeAuthParams>) {
  try {
    const { accessToken } = (yield call(AuthApi.verifyWithOtpCode, payload)) as UserProfile;
    yield put(authAccessTokenSet(accessToken));
    const from = history.location.state?.from;
    const path = from ? `${from.pathname}${from.search}` : AFTER_LOGIN_PATH;
    history.navigate(path);
  } catch (e) {
    const { error } = getResponseError(e as AxiosError);
    yield put(otpCodeVerifyError({ errors: { otpCode: error } }));
  }
}

export default function* () {
  yield takeLatest(otpCodeRequest, handleOtpCodeRequest);
  yield takeLatest(otpCodeVerify, handleOtpCodeVerify);
}
