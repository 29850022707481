import React from 'react';
import { Card, useAccordionButton, Accordion, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';
import parse from 'html-react-parser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { HealthQuote } from '@models/HealthQuote';
import { HealthQuoteBenefitAccordion } from '@components/Feature/HealthQuote/health-quote-benefit-accordion';

const Toggle = ({ children, eventKey }: { children: React.ReactNode; eventKey: string }) => {
  const handleClick = useAccordionButton(eventKey);

  return (
    <a className="c-pointer link-info" onClick={handleClick}>
      {children}
    </a>
  );
};

const DocumentItem = ({ name, fileUrl }: { name: string; fileUrl?: string }) => {
  return (
    <a href={fileUrl} className="link-dark text-decoration-none" target="_blank" rel="noreferrer">
      <span className="d-inline-block">
        <FontAwesomeIcon icon={faDownload} size="xl" className="text-primary me-2" />
        {name}
      </span>
    </a>
  );
};

const BenefitCardBody = (healthQuote: HealthQuote) => {
  const { t } = useTranslation();
  const { exclusionHtml, productDocument } = healthQuote;

  return (
    <>
      <Card.Body>
        <Card.Title>{pluralize(t('common:benefit'), 2)}</Card.Title>
        <HealthQuoteBenefitAccordion {...healthQuote} />
      </Card.Body>

      <Accordion flush alwaysOpen>
        <Card.Body>
          <Card.Title>
            <Toggle eventKey="exclusions">{pluralize(t('common:exclusion'), 2)}</Toggle>
          </Card.Title>

          <Accordion.Collapse eventKey="exclusions">
            <>{parse(exclusionHtml)}</>
          </Accordion.Collapse>
        </Card.Body>

        <Card.Body>
          <Card.Title>
            <Toggle eventKey="documents">{pluralize(t('common:document'), 2)}</Toggle>
          </Card.Title>

          <Accordion.Collapse eventKey="documents">
            <Stack direction="horizontal" gap={3}>
              {productDocument?.policyWordingFile && (
                <DocumentItem
                  name={t('healthQuoteDetailsPage.policyWording')}
                  fileUrl={productDocument?.policyWordingFile}
                />
              )}
              {productDocument?.brochureFile && (
                <DocumentItem name={t('healthQuoteDetailsPage.planBrochure')} fileUrl={productDocument?.brochureFile} />
              )}
              {productDocument?.termsAndConditionsFile && (
                <DocumentItem name={t('common:termsAndConditions')} fileUrl={productDocument?.termsAndConditionsFile} />
              )}
            </Stack>
          </Accordion.Collapse>
        </Card.Body>
      </Accordion>
    </>
  );
};

export { BenefitCardBody };
