import { object, number, string } from 'yup';

export const formValuesParamsSchema = object({
  thirdPartyAmount: number(),
  thirdPartyDiscountAmount: number(),

  thirdPartyDeductibleAmount: number(),
  thirdPartyDeductibleDiscountAmount: number(),

  ownDamageAmount: number(),
  ownDamageDiscountAmount: number(),

  ownDamageDeductibleAmount: number(),
  ownDamageDeductibleDiscountAmount: number(),

  theftDeductibleAmount: number(),
  theftDeductibleDiscountAmount: number(),

  accidentToDriverAmount: number(),
  accidentToDriverDiscountAmount: number(),

  passengerLiabilityAmount: number(),
  passengerLiabilityDiscountAmount: number(),

  adminFeeAmount: number(),
  idvAmount: number(),
  premium: number(),
  noClaimDiscountPercentage: number(),
  loyaltyDiscountPercentage: number(),

  customType: string().default('no_custom'),
  note: string().default(''),
});
