import { handleActions } from 'redux-actions';
import { CarBrand } from '@models/CarBrand';
import { CarModel } from '@models/CarModel';
import { CarClaim } from '@models/CarClaim';
import { Order } from '@models/Order';
import {
  policySet,
  carPolicyClaimCarBrandsSet,
  carPolicyClaimCarModelsSet,
  carClaimSet,
} from './action';

export interface State {
  policy?: Order;
  carBrands: CarBrand[];
  carModels: CarModel[];
  carClaim?: CarClaim;
}

const initialState: State = {
  carBrands: [],
  carModels: [],
};

const reducer = handleActions(
  {
    [String(policySet)]: (state, { payload }) => {
      return {
        ...state,
        policy: payload.policy,
      };
    },

    [String(carPolicyClaimCarBrandsSet)]: (state, { payload }) => {
      return {
        ...state,
        carBrands: payload.carBrands,
      };
    },

    [String(carPolicyClaimCarModelsSet)]: (state, { payload }) => {
      return {
        ...state,
        carModels: payload.carModels,
      };
    },

    [String(carClaimSet)]: (state, { payload }) => {
      return {
        ...state,
        carClaim: payload.carClaim,
      };
    },
  },

  initialState,
);

export default reducer;
