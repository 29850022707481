import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useParseParams } from '@hooks/search-params';
import { mergeInitialValues } from '@utils/formik';

import { formValuesParamsSchema, FormValues } from '../CancerQuoteBuyForm';
import { selectCancerQuoteBuyRoute } from '../selector';

export const useInitialValues = () => {
  const { cancerQuote } = useSelector(selectCancerQuoteBuyRoute);
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);

  return useMemo(() => {
    if (!cancerQuote) return;

    const { people } = cancerQuote;

    return mergeInitialValues(initialValues, people);
  }, [cancerQuote]);
};
