import React from 'react';
import { Card } from 'react-bootstrap';

export interface Props {
  title: string;
  desc: string;
  imgPath: string;
}

const BenefitCard = ({ title, desc, imgPath }: Props) => {
  return (
    <Card className="shadow bg-body border-0 h-100">
      <div className="text-center pt-3">
        <Card.Img src={imgPath} className="w-50" />
      </div>
      <Card.Body>
        <Card.Title className="my-4 text-center">
          <h4 className="fw-bold">{title}</h4>
        </Card.Title>
        <Card.Text>{desc}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export { BenefitCard };
