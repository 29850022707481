import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import { Pagination } from '@components/Base';
import { InsuranceTypes } from '@models/InsuranceType';
import { ArticleBreadcrumb } from '@components/Feature/Article/article-breadcrumb';

import { selectArticlePage } from './selector';
import { articlesFetch } from './action';
import { ArticlesFetchPayload } from './action';
import { ArticleList } from './article-list';

const ArticlePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { insuranceType } = useParams<{ insuranceType: InsuranceTypes }>();
  const [searchParams] = useSearchParams();
  const { articles, pagination } = useSelector(selectArticlePage);

  useEffect(() => {
    const payload: ArticlesFetchPayload = {
      page: searchParams.get('page'),
      insuranceType,
    };
    dispatch(articlesFetch(payload));
  }, []);

  return (
    <>
      <HeaderPrimary />
      <Container>
        <ArticleBreadcrumb />

        <h1 className="mt-5 mb-5">{t('common:articles')}</h1>
        <ArticleList articles={articles} />
        {pagination && (
          <div className="my-5 d-flex justify-content-center justify-content-lg-start">
            <Pagination url="/articles" page={pagination?.page ?? 1} totalPages={pagination?.totalPages ?? 1} />
          </div>
        )}
      </Container>
      <Footer />
    </>
  );
};

export { ArticlePage };
