import { combineReducers } from 'redux';

import { DashboardReducer } from './Dashboard';
import { billingReducer } from './Billing';
import { billingDetailsReducer } from './BillingDetails';
import { passwordReducer } from './Password';
import { PolicyReducer } from './Policy';
import { PolicyDetailsReducer } from './PolicyDetails';
import { ClaimReducer } from './Claim';
import { ClaimDetailsReducer } from './ClaimDetails';
import { PolicyClaimReducer } from './PolicyClaim';
import { policyRenewalReducer } from './PolicyRenewal';
import { VehicleReducer } from './Vehicle';
import { VehicleDetailsReducer } from './VehicleDetails';
import { DocumentNewReducer } from './DocumentNew';
import { DocumentReducer } from './Document';
import { DocumentDetailsReducer } from './DocumentDetails';
import { personalInfoEditReducer } from './PersonalInfoEdit';

export default combineReducers({
  dashboard: DashboardReducer,
  billingReducer,
  billingDetailsReducer,
  passwordReducer,
  policy: PolicyReducer,
  policyDetails: PolicyDetailsReducer,
  claim: ClaimReducer,
  claimDetails: ClaimDetailsReducer,
  policyClaim: PolicyClaimReducer,
  policyRenewalReducer,
  vehicle: VehicleReducer,
  vehicleDetails: VehicleDetailsReducer,
  documentNew: DocumentNewReducer,
  document: DocumentReducer,
  documentDetails: DocumentDetailsReducer,
  personalInfoEditReducer,
});
