import React from 'react';
import { useParams } from 'react-router-dom';
import { NoData } from '@components/Base';
import { OrderableTypes } from '@models/Order';
import CarPolicyClaim from './CarPolicyClaim';
import HealthPolicyClaim from './HealthPolicyClaim';
import PersonalAccidentPolicyClaim from './PersonalAccidentPolicyClaim';
import CancerPolicyClaim from './CancerPolicyClaim';

const PolicyClaim = () => {
  const { orderableType } = useParams();

  switch (orderableType) {
    case OrderableTypes.Car:
      return <CarPolicyClaim />;
    case OrderableTypes.Health:
      return <HealthPolicyClaim />;
    case OrderableTypes.PersonalAccident:
      return <PersonalAccidentPolicyClaim />;
    case OrderableTypes.Cancer:
      return <CancerPolicyClaim />;
    default:
      return <NoData />;
  }
};

export { PolicyClaim };
