import { handleActions } from 'redux-actions';
import { State, stateUpdate } from './action';

const initialState: State = {
  billings: undefined,
  pagination: undefined,
};

const reducer = handleActions(
  {
    [String(stateUpdate)]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },

  initialState,
);

export default reducer;
