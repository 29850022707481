import { useMemo } from "react";

export const useLocales = () => {
  return useMemo(
    () => [
      {
        locale: 'km',
        label: 'ខ្មែរ',
      },
      {
        locale: 'en',
        label: 'English',
      },
    ],
    [],
  );
}
