import React from 'react';
import { map } from 'lodash';
import { Card, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import { useTranslation } from 'react-i18next';

import { Testimonial } from '@models/Testimonial';
import { Rating } from 'react-simple-star-rating';
import '@components/Base/CustomOwlCarousel/style.scss';

import testimonials from './testimonials';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faUser } from '@fortawesome/free-solid-svg-icons';
import { renderToString } from 'react-dom/server';

const TestimonialCard = ({ name, description, rating }: Testimonial) => {
  return (
    <Card className="p-4 m-4">
      <Rating allowHalfIcon initialValue={rating} ratingValue={0} readonly={true} className="mb-3" />
      <Card.Text>{description}</Card.Text>
      <div className="d-flex flex-row align-items-center">
        <FontAwesomeIcon icon={faUser} className="me-2" />
        <h6 className="m-0">{name}</h6>
      </div>
    </Card>
  );
};

const options = {
  loop: true,
  center: true,
  items: 3,
  margin: 0,
  autoplay: false,
  dots: true,
  autoplayTimeout: 8500,
  smartSpeed: 450,
  nav: true,
  navText: [
    renderToString(<FontAwesomeIcon icon={faChevronLeft} size="2x" />),
    renderToString(<FontAwesomeIcon icon={faChevronRight} size="2x" />),
  ],
  responsive: {
    0: {
      items: 1,
    },
    992: {
      items: 3,
    },
  },
};

const TestimonialOwlCarousel = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <Container>
        <h2 className="text-center mb-4">{t('testimonial.header')}</h2>
        <OwlCarousel className="custom-owl-carousel" {...options}>
          {map(testimonials, (testimonial) => (
            <TestimonialCard key={testimonial.id} {...testimonial} />
          ))}
        </OwlCarousel>
      </Container>
    </section>
  );
};

export { TestimonialOwlCarousel };
