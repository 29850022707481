import { createActions } from 'redux-actions';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { PersonalAccidentQuoteGroup } from '@models/PersonalAccidentQuoteGroup';

export type State = {
  totalQuotes: number | null | undefined;
  personalAccidentQuoteGroups: PersonalAccidentQuoteGroup[] | null | undefined;
  comparablePersonalAccidentQuotes: PersonalAccidentQuote[];
};

export type CompareAddPayload = { personalAccidentQuote: PersonalAccidentQuote };
export type CompareRemovePayload = CompareAddPayload;

export type StateUpdatePayload = Partial<State>;

export const {
  personalAccidentQuotesFetch,

  compareAdd,
  compareRemove,
  compareRemoveAll,

  stateUpdate,
} = createActions(
  'PERSONAL_ACCIDENT_QUOTES_FETCH',

  'COMPARE_ADD',
  'COMPARE_REMOVE',
  'COMPARE_REMOVE_ALL',

  'STATE_UPDATE',
  {
    prefix: 'PERSONAL_ACCIDENT_QUOTE_RESULT_PAGE',
  },
);
