import { call, put, takeLatest } from 'redux-saga/effects';
import { UserCar } from '@models/UserCar';
import { Pagination } from '@models/Pagination';
import { GetAllUserCarParams, UserCarApi } from '@apis/UserCarApi';
import { userCarsFetch, userCarsSet } from './action';
import { SagaPayload } from '@utils/typescript';

function* handleUserCarsFetch({ payload }: SagaPayload<GetAllUserCarParams>) {
  try {
    const [userCars, pagination] = (yield call(UserCarApi.getAll, payload)) as [UserCar[], Pagination];
    yield put(userCarsSet({ userCars, pagination }));
  } catch {
    yield put(userCarsSet({ userCars: [], pagination: null }));
  }
}

export default function* () {
  yield takeLatest(userCarsFetch, handleUserCarsFetch);
}
