import React, { useMemo } from 'react';
import { map, times } from 'lodash';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import accidentDamageImg from './accidental-damage.png';
import fireImg from './fire.png';
import theftImg from './theft.png';
import thirdPartyImg from './third-party.png';

const IMAGES = [accidentDamageImg, fireImg, theftImg, thirdPartyImg];

export interface CardProps {
  label: string;
  desc: string;
  image: string;
}

const CoverCard = ({ label, desc, image }: CardProps) => {
  return (
    <Card className="shadow bg-body border-0 h-100">
      <div className="text-center pt-3">
        <Card.Img src={image} className="w-50" alt="MGA Cover car insurance covers" />
      </div>
      <Card.Body>
        <Card.Title className="my-4 text-center">
          <h4 className="fw-bold">{label}</h4>
        </Card.Title>
        <Card.Text>{desc}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const CoverSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(4, (n) => ({
      label: t(`carInsurancePage.coverSection.item${n + 1}.label`),
      desc: t(`carInsurancePage.coverSection.item${n + 1}.desc`),
      image: IMAGES[n] as string,
    }));
  }, []);

  return (
    <section className="py-5 bg-light">
      <Container>
        <h2 className="text-center">{t('carInsurancePage.coverSection.title')}</h2>
        <p className="text-center text-secondary w-75 mx-auto">{t('carInsurancePage.coverSection.subTitle')}</p>
        <Row className="gy-4 mt-5">
          {map(items, (item) => (
            <Col lg={3} key={item.label}>
              <CoverCard {...item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export { CoverSection };
