import React, { useCallback } from 'react';
import { useNavigate, useHref } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { lowerCase } from 'lodash';

import { useCancerQuoteResultPageParams } from '@components/CancerQuoteResultPage';

import { CancerQuote } from '@models/CancerQuote';
import { amountToUsd } from '@utils/currency';

import { getCancerQuoteBuyNewPageRoute, getCancerQuoteSendEmailPageRoute } from '@components/CancerQuoteBuyRoute/route';

type Props = Pick<CancerQuote, 'insurer' | 'cancerPlanName' | 'annualLimitAmount' | 'totalPremium' | 'cancerPlanId'>;

const HeaderSection = ({ insurer, cancerPlanName, annualLimitAmount, totalPremium, cancerPlanId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useCancerQuoteResultPageParams();
  const cancerQuoteSendEmailHref = useHref(getCancerQuoteSendEmailPageRoute({ ...params, cancerPlanId: cancerPlanId }));

  const handleBuy = useCallback(() => {
    navigate(getCancerQuoteBuyNewPageRoute({ ...params, cancerPlanId }));
  }, []);

  return (
    <section>
      <Container>
        <Card className="shadow">
          <Card.Body>
            <Row className="align-items-center">
              <Col lg={2}>
                <Card.Img src={insurer.logo} className="w-75 rounded img-fluid" />
              </Col>
              <Col lg={10}>
                <Row>
                  <Col lg={4}>
                    <Stack gap={3}>
                      <Card.Title className="mb-0">{cancerPlanName}</Card.Title>
                      <Card.Text>{insurer.name}</Card.Text>
                    </Stack>
                  </Col>
                  <Col lg={4}>
                    <Stack gap={3} className="text-center">
                      <Card.Text className="mb-0">{t('cancerQuote.coverAmount')}</Card.Text>
                      <Card.Text className="fs-5 fw-bold">{amountToUsd(annualLimitAmount)}</Card.Text>
                    </Stack>
                  </Col>
                  <Col lg={4}>
                    <Button variant="primary" size="lg" className="w-100" onClick={handleBuy}>
                      <span className="d-block">{t('common:buyNow')}</span>
                      <strong className="fs-5 ms-3">
                        {amountToUsd(totalPremium)}/{lowerCase(t('common:year'))}
                      </strong>
                    </Button>
                    <Button variant="link" href={cancerQuoteSendEmailHref} target="_blank" rel="noreferrer noopener">
                      {t('common:sendQuoteToMyEmail')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};

export { HeaderSection };
