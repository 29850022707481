import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { FeaturedPersonalAccidentPlanBenefits } from '@models/PersonalAccidentPlanBenefit';
import { getPersonalAccidentPlanBenefitDescription } from '@utils/personal-accident-quote';
import { amountToUsd } from '@utils/currency';

import { Title } from './title';

interface RowItemProps {
  label: string;
  value?: string | number;
}

const RowItem = ({ label, value }: RowItemProps) => {
  return (
    <Row className="mb-2">
      <Col xs={6} sm={6} md={6}>
        <strong>{label}</strong>
      </Col>
      <Col>
        <div className="d-flex justify-content-end">{value}</div>
      </Col>
    </Row>
  );
};

const FeatureSection = ({
  geographicCoverage,
  personalAccidentPlanBenefits,
  annualLimitAmount,
}: PersonalAccidentQuote) => {
  const { t } = useTranslation();
  const permanentAndPartialDisablementDescription = getPersonalAccidentPlanBenefitDescription(
    personalAccidentPlanBenefits,
    FeaturedPersonalAccidentPlanBenefits.PermanentAndPartialDisablementDueToAccident,
  );

  return (
    <section className="py-5">
      <Title>{pluralize(t('common:feature'), 2)}</Title>
      <Container>
        <Row>
          <Col lg={8} className="mx-auto">
            <Row>
              <Col>
                <Card className="h-100">
                  <Card.Body>
                    <RowItem label={t('personalAccidentQuote.geographicCoverage')} value={geographicCoverage} />
                    <RowItem label={t('personalAccidentQuote.annualLimit')} value={amountToUsd(annualLimitAmount)} />
                    <RowItem
                      label={t('personalAccidentPlanBenefit.basicBenefitsDeathDueToAccident')}
                      value={getPersonalAccidentPlanBenefitDescription(
                        personalAccidentPlanBenefits,
                        FeaturedPersonalAccidentPlanBenefits.DeathDueToAccident,
                      )}
                    />
                    <RowItem
                      label={t('personalAccidentPlanBenefit.basicBenefitsPermanentAndTotalDisablementDueToAccident')}
                      value={getPersonalAccidentPlanBenefitDescription(
                        personalAccidentPlanBenefits,
                        FeaturedPersonalAccidentPlanBenefits.PermanentAndTotalDisablementDueToAccident,
                      )}
                    />
                    {!!permanentAndPartialDisablementDescription && (
                      <RowItem
                        label={t(
                          'personalAccidentPlanBenefit.basicBenefitsPermanentAndPartialDisablementDueToAccident',
                        )}
                        value={permanentAndPartialDisablementDescription}
                      />
                    )}
                    <RowItem
                      label={t('personalAccidentPlanBenefit.basicBenefitsMedicalExpenseDueToAccident')}
                      value={getPersonalAccidentPlanBenefitDescription(
                        personalAccidentPlanBenefits,
                        FeaturedPersonalAccidentPlanBenefits.MedicalExpenseDueToAccident,
                      )}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { FeatureSection };
