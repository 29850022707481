import React from 'react';
import { useParams } from 'react-router-dom';
import { NoData } from '@components/Base';
import { ClaimableTypes } from '@models/Claim';
import CarClaimDetails from './CarClaimDetails';
import PersonalAccidentClaimDetails from './PersonalAccidentClaimDetails';
import CancerClaimDetails from './CancerClaimDetails';

const ClaimDetails = () => {
  const { claimableType } = useParams();

  switch (claimableType) {
    case ClaimableTypes.Car:
      return <CarClaimDetails />;
    case ClaimableTypes.PersonalAccident:
      return <PersonalAccidentClaimDetails />;
    case ClaimableTypes.Cancer:
      return <CancerClaimDetails />;
    default:
      return <NoData />;
  }
};

export { ClaimDetails };
