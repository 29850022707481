import React from 'react';

import LicenseSection from './LicenseSection';
import { ContactSection } from './contact-section';
import { CopyrightSection } from './copyright-section';

const Footer = () => {
  return (
    <footer className="bg-primary-dark text-light">
      <LicenseSection />
      <ContactSection />
      <CopyrightSection />
    </footer>
  );
};

export { Footer };
