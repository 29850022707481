import { createActions } from 'redux-actions';

import { HealthQuote } from '@models/HealthQuote';
import { GetAllHealthQuoteParams } from '@apis/HealthQuoteApi';

export type State = {
  healthQuotes: HealthQuote[] | null | undefined;
};

export type HealthQuotesFetchPayload =  Required<Omit<GetAllHealthQuoteParams, 'spouse' | 'sons' | 'daughters'>> &
Pick<GetAllHealthQuoteParams, 'spouse' | 'sons' | 'daughters'>;

export type HealthQuotesFetchParams = HealthQuotesFetchPayload;

export type StateUpdatePayload = Partial<State>;

export const {
  healthQuotesFetch,

  stateUpdate,
} = createActions(
  'HEALTH_QUOTES_FETCH',

  'STATE_UPDATE',
  {
    prefix: 'HEALTH_QUOTE_COMPARE_PAGE',
  }
);
