import { AxiosError } from 'axios';
import humps from 'lodash-humps-ts';

interface Error {
  error?: string;
  errors?: object;
}
interface ResponseError {
  response?: {
    data: Error;
  };
}

export const getResponseError = (e: AxiosError) => {
  return humps((e as ResponseError).response?.data) as Error;
};
