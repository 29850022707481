import React from 'react';
import { SuperSEO } from 'react-super-seo';
import { useTranslation } from 'react-i18next';

import socialPreviewBanner from '@images/social-preview-banner.jpg';

interface Props {
  pageName: string;
  title?: string;
  description?: string;
  image?: string;
}

const MetaTag = ({ pageName, title, description, image }: Props) => {
  const { t } = useTranslation();
  const metaTitle = title ?? t(`superSeo.${pageName}.title`);
  const metaDesc = description ?? t(`superSeo.${pageName}.description`);
  const previewImage = image ?? (socialPreviewBanner as string);

  return (
    <SuperSEO
      title={metaTitle}
      description={metaDesc}
      openGraph={{
        ogUrl: window.location.href,
        ogImage: {
          ogImage: previewImage,
        },
      }}
      twitter={{
        twitterSummaryCard: {
          summaryCardTitle: metaTitle,
          summaryCardDescription: metaDesc,
          summaryCardImage: previewImage,
        },
      }}
    />
  );
};

export { MetaTag };
