import React, { useCallback, useEffect } from 'react';
import { Card, Button, Table } from 'react-bootstrap';
import { useNavigate, useHref } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isUndefined, map, startCase } from 'lodash';

import { NoData, Loading, DocumentBadge } from '@components/Base';
import { UserDocument, getUserDocumentType } from '@models/UserDocument';

import { selectDocument } from './selector';
import { userDocumentsFetch } from './action';

const DocumentTableItem = ({ id, documentType, status }: UserDocument) => {
  const { t } = useTranslation();

  return (
    <tr>
      <td>{startCase(getUserDocumentType(documentType))}</td>
      <td>
        <DocumentBadge status={status} />
      </td>
      <td>
        <Button href={useHref(`${id}`)} variant="outline-primary" size="sm">
          {t('common:viewDetails')}
        </Button>
      </td>
    </tr>
  );
};

const Document = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDocuments } = useSelector(selectDocument);
  const isLoaded = !isUndefined(userDocuments);
  const hasUserDocuments = isLoaded && userDocuments.length > 0;

  useEffect(() => {
    dispatch(userDocumentsFetch());
  }, []);

  const handleNewDocumentClick = useCallback(() => {
    navigate('/user-dashboard/document/new');
  }, []);

  return (
    <section>
      <div className="d-flex justify-content-between mb-4">
        <h5>{t('common:document')}</h5>
        <Button variant="primary" size="sm" onClick={handleNewDocumentClick}>
          {t('userDocument.new')}
        </Button>
      </div>
      <Card>
        <Card.Body>
          {!isLoaded && <Loading />}
          {isLoaded && !hasUserDocuments && <NoData />}
          {hasUserDocuments && (
            <Table responsive className="text-nowrap">
              <thead>
                <tr>
                  <th>{startCase(t('common:documentType'))}</th>
                  <th>{t('common:status')}</th>
                  <th colSpan={3}></th>
                </tr>
              </thead>
              <tbody>
                {map(userDocuments, (userDocument) => (
                  <DocumentTableItem key={userDocument.documentType} {...userDocument} />
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </section>
  );
};

export { Document };
