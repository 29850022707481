import React from 'react';
import { Pagination as BsPagination } from 'react-bootstrap';
import { map, range } from 'lodash';
import { Pagination as PaginationModel } from '@models/Pagination';

export interface Props extends Pick<PaginationModel, 'page' | 'totalPages'> {
  url: string;
}

const Pagination = ({ url, page, totalPages }: Props) => {
  return (
    <BsPagination>
      {map(range(1, totalPages + 1), (value) => (
        <BsPagination.Item key={value} active={value === page} href={`${url}?page=${value}`}>
          {value}
        </BsPagination.Item>
      ))}
    </BsPagination>
  );
};

export { Pagination };
