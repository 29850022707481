import axios, { AxiosError } from 'axios';
import { applyAuthTokenInterceptor } from 'axios-jwt';
import store from '@store';
import { currentUserLogout } from './action';

const handleError401 = (): void => {
  store.dispatch(currentUserLogout());
};

const axiosInstance = axios.create();

interface Error {
  status: number;
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    try {
      switch ((error.toJSON() as Error).status) {
        case 401: {
          handleError401();
          break;
        }
      }
    } catch {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    return Promise.reject(error);
  },
);

applyAuthTokenInterceptor(axiosInstance, { requestRefresh: async () => Promise.resolve('') });

export default axiosInstance;
