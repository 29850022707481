import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const LoadingError = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <p className="fs-3">{t('loadingError.title')}</p>
      <p className="lead">{t('loadingError.desc')}</p>

      <Link to="/" className="btn btn-primary">
        {t('common:goHome')}
      </Link>
    </div>
  );
};

export { LoadingError };
