import { call, put, takeLatest } from 'redux-saga/effects';
import { UserCar } from '@models/UserCar';
import { GetUserCarParams, UserCarApi } from '@apis/UserCarApi';
import { userCarFetch, userCarSet } from './action';
import { SagaPayload } from '@utils/typescript';

function* handleUserCarFetch({ payload }: SagaPayload<GetUserCarParams>) {
  try {
    const userCar = (yield call(UserCarApi.get, payload)) as UserCar;
    yield put(userCarSet({ userCar }));
  } catch {
    yield put(userCarSet({ userCar: null }));
  }
}

export default function* () {
  yield takeLatest(userCarFetch, handleUserCarFetch);
}
