import { takeLatest, put, call } from 'redux-saga/effects';
import { AxiosError } from 'axios';

import { User } from '@models/User';
import { getResponseError } from '@utils/error';
import { AuthApi, SignInWithOtpCodeAuthParams, SignInWithPasswordAuthParams } from '@apis/AuthApi';
import { history } from '@utils/history';
import { camDxLogin } from '@components/CamDx/action';
import { CamDxApi } from '@apis/CamDxApi';

import { otpCodeSignIn, passwordSignIn, passwordSignInError, camDxSignInError } from './action';
import { SagaPayload } from '@utils/typescript';

function* handleOtpCodeSignIn({ payload }: SagaPayload<SignInWithOtpCodeAuthParams>) {
  const { mobileNumber } = (yield call(AuthApi.signInWithOtpCode, payload)) as User;
  history.navigate(`/sign-in/otp-code/${mobileNumber}`, { state: history.location.state });
}

function* handlePasswordSignIn({ payload }: SagaPayload<SignInWithPasswordAuthParams>) {
  try {
    const { mobileNumber } = (yield call(AuthApi.signInWithPassword, payload)) as User;
    history.navigate(`/sign-in/password/${mobileNumber}`, { state: history.location.state });
  } catch (e) {
    const { error } = getResponseError(e as AxiosError);
    yield put(passwordSignInError({ errors: { mobileNumber: error } }));
  }
}

function* handleCamDxLogin() {
  try {
    const loginUrl = (yield call(CamDxApi.getSignInUrl)) as string;
    window.location.href = loginUrl;
  } catch ({ message }) {
    yield put(camDxSignInError({ errors: { camDx: message } }));
  }
}

export default function* () {
  yield takeLatest(camDxLogin, handleCamDxLogin);
  yield takeLatest(otpCodeSignIn, handleOtpCodeSignIn);
  yield takeLatest(passwordSignIn, handlePasswordSignIn);
}
