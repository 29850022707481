import { handleActions } from 'redux-actions';
import { State, occupationsSet } from './action';

const initialState: State = {
  occupations: [],
};

const reducer = handleActions(
  {
    [String(occupationsSet)]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  initialState,
);

export default reducer;
