import humps from 'lodash-humps-ts';
import { API_V1_BASE } from '@config';
import { snakes } from '@utils/lodash';
import { AbaPaywayCredential } from '@models/AbaPaywayCredential';
import { authAxios } from '@components/Auth';

export type CreateAbaPaywayCredentialParams = {
  paymentTransactionId: number;
};

export default class AbaPaywayApi {
  static createCredential = async (params: CreateAbaPaywayCredentialParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/aba_payway/credential`, snakes(params))
      .then((response) => humps(response.data) as AbaPaywayCredential);
  };
}
