import React from 'react';

import HeaderSecondary from '@components/HeaderSecondary';
import SubHeader from '@components/HealthQuoteResultPage/SubHeader';
import CancerQuoteList from './CancerQuoteList';
import ComparableCancerQuote from './ComparableCancerQuote';

const CancerQuoteResultPage = () => {
  return (
    <>
      <HeaderSecondary />
      <SubHeader />
      <CancerQuoteList />
      <ComparableCancerQuote />
    </>
  );
};

export { CancerQuoteResultPage };
