import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Collapse, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { getCarQuoteComparePageRoute } from '@components/CarQuoteComparePage/route';

import { useCarQuotesResultPageParams } from '../hook';
import { carQuoteComparableRemoveAll } from '../action';
import { selectCarQuoteResultPage } from '../selector';
import { CarQuoteItem } from './car-quote-item';

const ComparableCarQuote = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useCarQuotesResultPageParams();
  const { comparableCarQuotes: carQuotes } = useSelector(selectCarQuoteResultPage);
  const hasCarQuotes = carQuotes.length > 0;
  const [open, setOpen] = useState(true);

  const handleCompare = () => {
    navigate(
      getCarQuoteComparePageRoute({
        ...params,
        carPlanIds: map(carQuotes, 'carPlanId'),
      }),
    );
  };

  useEffect(() => {
    if (!hasCarQuotes) setOpen(true);
  }, [hasCarQuotes]);

  const handleClearAll = () => {
    dispatch(carQuoteComparableRemoveAll());
  };

  if (!hasCarQuotes) return null;

  return (
    <div className="w-100 fixed-bottom shadow-lg bg-light">
      <h6 className="m-0 p-3 text-white shadow text-center c-pointer bg-info" onClick={() => setOpen(!open)}>
        <span className="me-2">
          {t('common:compareQuotes')} ({carQuotes.length})
        </span>
        <FontAwesomeIcon icon={open ? faChevronDown : faChevronUp} />
      </h6>
      <Container>
        <Collapse in={open}>
          <Row className="mt-3 pb-3">
            {map(carQuotes, ({ carPlanId, insurer, totalPremium }) => (
              <Col className="d-flex align-items-center border-end" key={carPlanId}>
                <CarQuoteItem {...{ insurer, totalPremium }} />
              </Col>
            ))}

            {carQuotes.length === 1 && (
              <Col>
                <div className="d-flex flex-column justify-content-center align-items-center h-100 border-end">
                  <span className="text-danger">{t('common:compareGuide', { max: 2 })}</span>
                </div>
              </Col>
            )}

            <Col lg={2}>
              <div className="d-flex flex-column">
                <Button className="mb-2" onClick={handleCompare}>
                  {t('common:compare')}
                </Button>
                <Button variant="secondary" onClick={handleClearAll}>
                  {t('common:removeAll')}
                </Button>
              </div>
            </Col>
          </Row>
        </Collapse>
      </Container>
    </div>
  );
};

export { ComparableCarQuote };
