import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentYear } from '@utils/datetime';

const CopyrightSection = () => {
  const { t } = useTranslation();

  return (
    <section className="border-top border-dark py-3 mt-5">
      <div className="text-center">
        <span className="d-block text-secondary">
          {t('brokerLicense.label', { ns: 'common' })}: {t('brokerLicense.number', { ns: 'common' })}
        </span>
        <span className="text-secondary">
          © {getCurrentYear()} MGA Asia Insurance Brokers. {t('allRightsReserved', { ns: 'common' })}.
        </span>
      </div>
    </section>
  );
};

export { CopyrightSection };
