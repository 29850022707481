import { createActions } from 'redux-actions';

import { CancerQuote } from '@models/CancerQuote';
import { CancerOrder } from '@models/CancerOrder';

export type State = {
  cancerQuote: CancerQuote | null | undefined;
  cancerOrder: CancerOrder | null | undefined;
};

export type StateUpdatePayload = Partial<State>;

export const {
  cancerQuoteFetch,
  cancerQuoteCreate,
  cancerQuoteEdit,
  cancerQuoteUpdate,

  cancerOrderCreate,

  stateUpdate,
} = createActions(
  'CANCER_QUOTE_FETCH',
  'CANCER_QUOTE_EDIT',
  'CANCER_QUOTE_CREATE',
  'CANCER_QUOTE_UPDATE',

  'CANCER_ORDER_CREATE',

  'STATE_UPDATE',
  {
    prefix: 'CANCER_QUOTE_BUY_PAGE',
  },
);
