import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { selectCancerQuoteComparePage } from './selector';
import { TableRowItem } from './table-row-item';

const TableGeographicCoverage = () => {
  const { t } = useTranslation();
  const { cancerQuotes } = useSelector(selectCancerQuoteComparePage);

  if (!cancerQuotes) return null;

  return (
    <>
      <TableRowItem title={t('cancerQuote.geographicCoverage')}>
        {map(cancerQuotes, ({ id, geographicCoverage }) => (
          <td key={id}>{geographicCoverage}</td>
        ))}
      </TableRowItem>
    </>
  );
};

export { TableGeographicCoverage };
