import { KeysToCamelCase } from '@utils/typescript';

export enum FeaturedPersonalAccidentPlanBenefits {
  DeathDueToAccident = 'basic_benefits_death_due_to_accident',
  PermanentAndTotalDisablementDueToAccident = 'basic_benefits_permanent_and_total_disablement_due_to_accident',
  PermanentAndPartialDisablementDueToAccident = 'basic_benefits_permanent_and_partial_disablement_due_to_accident',
  MedicalExpenseDueToAccident = 'basic_benefits_medical_expense_due_to_accident',
}

export interface PersonalAccidentPlanBenefitResponse {
  id: number;
  benefit_type: string;
  description: string;
}

export type PersonalAccidentPlanBenefit = KeysToCamelCase<PersonalAccidentPlanBenefitResponse>;
