import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderSecondary from '@components/HeaderSecondary';
import MobileNumberCard from './MobileNumberCard';
import OtpCodeCard from './OtpCodeCard';
import PasswordCard from './PasswordCard';

const UserRegistration = () => {
  return (
    <>
      <HeaderSecondary />
      <Container className="mt-4">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Routes>
              <Route index element={<MobileNumberCard />} />
              <Route path="otp-code/:mobileNumber" element={<OtpCodeCard />} />
              <Route path="password/:mobileNumber" element={<PasswordCard />} />
              <Route path="*" element={<Navigate to="" />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { UserRegistration };
