import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Container, Row } from 'react-bootstrap';

import CarQuotePremiumCardBody from '@components/Feature/CarQuote/CarQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';

import { useCarQuoteEdit } from '../hook';
import { carQuoteUpdate, CarQuoteUpdatePayload } from '../action';
import CarQuoteBuyForm, { FormValues } from '../CarQuoteBuyForm';
import { useInitialValues } from './hook';

const CarQuoteBuyEditPage = () => {
  const dispatch = useDispatch();
  const carQuote = useCarQuoteEdit();
  const initialValues = useInitialValues();

  if (!carQuote) return null;

  const handleSubmit = (values: FormValues) => {
    const payload: CarQuoteUpdatePayload = {
      ...values,
      carOrderId: carQuote.carOrderId,
    };
    dispatch(carQuoteUpdate(payload));
  };

  return (
    <>
      <Container className="my-3">
        <Row className="gy-5">
          <Col lg={8}>{initialValues && <CarQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} disabled={{ noMobileNumber: true }} />}</Col>
          <Col lg={4}>
            <Card className="mb-4">
              <CarQuotePremiumCardBody {...carQuote} />
            </Card>
            <SupportCard />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { CarQuoteBuyEditPage };
