import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/search-params';

import { selectCarQuoteComparePage } from './selector';
import { carQuotesFetch, CarQuotesFetchPayload } from './action';
import { CarQuoteComparePageParams, carQuoteComparePageParamsSchema } from './route';

export const useCarQuotesComparePageParams = () =>
  useParseParams<CarQuoteComparePageParams>(carQuoteComparePageParamsSchema);

export const useCarQuotes = () => {
  const dispatch = useDispatch();
  const payload: CarQuotesFetchPayload = useCarQuotesComparePageParams();
  const { carQuotes } = useSelector(selectCarQuoteComparePage);

  useEffect(() => {
    dispatch(carQuotesFetch(payload));
  }, []);

  return carQuotes;
};
