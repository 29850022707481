import React from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import { map, times } from 'lodash';
import { useTranslation } from 'react-i18next';

const ExtraBenefitSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <h2 className="text-center">{t('personalAccidentInsurancePage.extraBenefitSection.title')}</h2>
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <Accordion flush>
              {map(times(7), (idx) => (
                <Accordion.Item
                  eventKey={t(`personalAccidentInsurancePage.extraBenefitSection.item${idx + 1}.label`)}
                  key={`extraBenefit${idx}`}
                >
                  <Accordion.Header>
                    <span className="fw-bold">
                      {t(`personalAccidentInsurancePage.extraBenefitSection.item${idx + 1}.label`)}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {t(`personalAccidentInsurancePage.extraBenefitSection.item${idx + 1}.desc`)}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { ExtraBenefitSection };
