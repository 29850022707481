import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map, snakeCase } from 'lodash';

import { selectAbaPayway } from './selector';
import { credentialCreate } from './action';
import { AbaPaywayCredentialCreatePayload } from './type';

type Props = {
  paymentTransactionId: number;
}

const AbaPayway = ({ paymentTransactionId }: Props) => {
  const dispatch = useDispatch();
  const { credential } = useSelector(selectAbaPayway);
  const { checkoutUrl, ...inputs } = credential || {};

  useEffect(() => {
    if (paymentTransactionId) {
      const payload: AbaPaywayCredentialCreatePayload = { paymentTransactionId };
      dispatch(credentialCreate(payload));
    }
  }, [paymentTransactionId]);

  return (
    // Aba Payway name id and className using snakeCase and kebabCase, not camelCase
    <div id="aba_main_modal" className="aba-modal">
      <div className="aba-modal-content">
        <form method="POST" target="aba_webservice" action={checkoutUrl} id="aba_merchant_request">
          {map(inputs, (value, key) => (
            <input key={key} type="hidden" name={snakeCase(key)} defaultValue={value} id={key} />
          ))}
        </form>
      </div>
    </div>
  );
};

export { AbaPayway };
