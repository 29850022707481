import React, { useMemo } from 'react';
import { Col, Container, Row, ListGroup } from 'react-bootstrap';
import { map, times } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const NoCoverSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(7, (n) => t(`cancerInsurancePage.noCoverSection.item${n + 1}`));
  }, []);

  return (
    <section className="py-5">
      <Container>
        <h2 className="text-center">{t('cancerInsurancePage.noCoverSection.title')}</h2>
        <p className="text-center text-secondary w-75 mx-auto">{t('cancerInsurancePage.noCoverSection.subTitle')}</p>
        <Row>
          <Col lg={10} className="mx-auto">
            <ListGroup variant="flush">
              {map(items, (item) => (
                <ListGroup.Item key={item} className="p-3">
                  <FontAwesomeIcon icon={faXmark} size="lg" className="text-danger" />
                  <span className="ms-3">{item}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>

            <p className="text-secondary" style={{ whiteSpace: 'pre-line' }}>
              {t('cancerInsurancePage.noCoverSection.note')}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { NoCoverSection };
