import { combineReducers } from 'redux';
import { CarPolicyClaimReducer } from './CarPolicyClaim';
import { PersonalAccidentPolicyClaimReducer } from './PersonalAccidentPolicyClaim';
import { CancerPolicyClaimReducer } from './CancerPolicyClaim';

export default combineReducers({
  carPolicyClaim: CarPolicyClaimReducer,
  personalAccidentPolicyClaim: PersonalAccidentPolicyClaimReducer,
  cancerPolicyClaim: CancerPolicyClaimReducer,
});
