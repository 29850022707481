import { call, put, takeLatest } from 'redux-saga/effects';
import { Billing } from '@models/Billing';
import { BillingApi } from '@apis/BillingApi';
import { SagaPayload } from '@utils/typescript';
import { billingFetch, BillingFetchPayload, stateUpdate, StateUpdatePayload } from './action';

function* handleBillingFetch({ payload }: SagaPayload<BillingFetchPayload>) {
  const state: StateUpdatePayload = { billing: null };
  try {
    state.billing = (yield call(BillingApi.get, payload)) as Billing;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(billingFetch, handleBillingFetch);
}
