import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { isUndefined, isNull } from 'lodash';
import { InsuranceTypes } from '@models/InsuranceType';
import { BackButton, FormGroupControl, Loading, NoData, LoadingError } from '@components/Base';

import { PolicyOverviewCardBody } from './policy-overview-card-body';
import { ClaimCardBody } from './claim-card-body';

import { policyFetch, cancerClaimCreate } from './action';
import { CancerClaimCreatePayload } from './cancerClaimCreate';
import { selectCancerPolicyClaim } from './selector';
import { FormValues, formValuesSchema } from './schema';
import { useInitialValues } from './hook';

const CancerPolicyClaim = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { orderableId, orderableType } = useParams();
  const { policy } = useSelector(selectCancerPolicyClaim);
  const initialValues = useInitialValues();
  const isLoaded = !isUndefined(policy);
  const isError = isNull(policy);
  const hasPolicy = isLoaded && !isNull(policy);

  const handleSubmit = useCallback((values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CancerClaimCreatePayload = {
      cancerOrderId: Number(orderableId),
      insuranceType: InsuranceTypes.Cancer,
      ...values,
    };
    dispatch(cancerClaimCreate(payload));
    setSubmitting(false);
  }, []);

  const handleBack = useCallback(() => {
    navigate('/user-dashboard/policy');
  }, []);

  useEffect(() => {
    dispatch(policyFetch({ orderableId, orderableType }));
  }, [orderableId, orderableType]);

  return (
    <>
      <BackButton onClick={handleBack} className="mb-4" />
      {!isLoaded && <Loading />}
      {isError && <LoadingError />}
      {isLoaded && !hasPolicy && <NoData />}

      {hasPolicy && initialValues && (
        <Card>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={formValuesSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Card.Header className="fs-5 fw-bold">{t('common:claimForm')}</Card.Header>
                <Card.Body>
                  <PolicyOverviewCardBody />
                  <ClaimCardBody />

                  <Card.Body>
                    <FormGroupControl
                      type="checkbox"
                      name="acceptTermsAndConditions"
                      label={t('cancerPolicyClaim.termsAndConditions')}
                      bsPrefix="form-check-input"
                      groupProps={{ className: 'form-check form-check-inline' }}
                    />
                  </Card.Body>
                </Card.Body>

                <Card.Footer className="bg-white">
                  <div className="text-center my-4">
                    <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                      {t('common:submit')}
                    </Button>
                  </div>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      )}
    </>
  );
};

export { CancerPolicyClaim };
