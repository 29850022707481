import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParamsHealthQuoteId } from '@hooks/params';
import { useParseParams } from '@hooks/search-params';

import { healthQuoteEdit, HealthQuoteEditPayload, healthQuoteFetch, HealthQuoteFetchPayload } from './action';
import { selectHealthQuoteBuyRoute } from './selector';
import { HealthQuoteBuyNewPageParams, healthQuoteBuyNewPageParamsSchema } from './route';

export const useHealthQuoteBuyNewPageParams = () =>
  useParseParams<HealthQuoteBuyNewPageParams>(healthQuoteBuyNewPageParamsSchema);

export const useHealthQuote = () => {
  const dispatch = useDispatch();
  const payload: HealthQuoteFetchPayload = useHealthQuoteBuyNewPageParams();
  const { healthQuote } = useSelector(selectHealthQuoteBuyRoute);

  useEffect(() => {
    dispatch(healthQuoteFetch(payload));
  }, []);

  return healthQuote;
};

export const useHealthQuoteEdit = () => {
  const healthQuoteId = useParamsHealthQuoteId();
  const dispatch = useDispatch();
  const { healthQuote } = useSelector(selectHealthQuoteBuyRoute);

  useEffect(() => {
    const payload: HealthQuoteEditPayload = { id: healthQuoteId };
    dispatch(healthQuoteEdit(payload));
  }, []);

  return healthQuote;
};
