import { createActions } from 'redux-actions';

export const {
  carQuoteFetch,
  carQuoteSendEmail,

  stateUpdate,
} = createActions(
  'CAR_QUOTE_FETCH',
  'CAR_QUOTE_SEND_EMAIL',

  'STATE_UPDATE',
  {
    prefix: 'CAR_QUOTE_DETAILS_ROUTE',
  },
);
