import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { isUndefined, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'usehooks-ts';
import { selectInsurerPartner } from './selector';
import { insurersFetch } from './action';
import { Title } from '../title';

const InsurerPartner = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { insurers } = useSelector(selectInsurerPartner);
  const hasInsurers = !isUndefined(insurers) && insurers.length > 0;

  useEffectOnce(() => {
    dispatch(insurersFetch());
  });

  return (
    <section className="py-5 bg-primary text-white">
      <Title title={t('partner.header')} />
      <Container>
        {hasInsurers && (
          <Row className="gy-4 justify-content-center align-items-center">
            {map(insurers, ({ id, logo, name }) => (
              <Col key={id} xs={4} md={3} lg={2}>
                <Image src={logo} alt={`${name} official logo`} fluid />
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </section>
  );
};

export { InsurerPartner };
