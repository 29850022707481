import humps from 'lodash-humps-ts';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { PaymentTransaction } from '@models/PaymentTransaction';
import { API_V1_BASE } from '@config';

export type CreatePaymentTransactionParams = Pick<PaymentTransaction, 'agreeToTermsAndConditions' | 'paymentMethod'> & {
  billingId: number;
}

export class PaymentTransactionApi {
  static create = async (params: CreatePaymentTransactionParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/payment_transactions`, snakes(params))
      .then((response) => humps(response.data) as PaymentTransaction);
  };
}
