import { call, put, takeLatest } from 'redux-saga/effects';
import { CancerClaimApi } from '@apis/CancerClaimApi';
import { CancerClaim } from '@models/CancerClaim';
import { cancerClaimFetch, stateUpdate, StateUpdatePayload } from './action';
import { CancerClaimFetchPayload } from './cancerClaimFetch';
import { SagaPayload } from '@utils/typescript';

function* handleCancerClaimFetch({ payload }: SagaPayload<CancerClaimFetchPayload>) {
  const state: StateUpdatePayload = { cancerClaim: null };
  try {
    state.cancerClaim = (yield call(CancerClaimApi.get, payload)) as CancerClaim;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(cancerClaimFetch, handleCancerClaimFetch);
}
