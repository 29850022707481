import React, { useMemo } from 'react';
import { Col, Container, Row, Image, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map, times } from 'lodash';
import compareImg from './compare.png';

const HowToCompareSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(4, (n) => ({
      label: t(`carInsurancePage.howToCompareSection.item${n + 1}.label`),
      desc: t(`carInsurancePage.howToCompareSection.item${n + 1}.desc`),
    }));
  }, []);

  return (
    <section className="py-5">
      <Container>
        <h2 className="text-center">{t('carInsurancePage.howToCompareSection.title')}</h2>
        <p className="text-center text-secondary w-75 mx-auto">{t('carInsurancePage.howToCompareSection.subTitle')}</p>
        <Row className="gy-5 align-items-center mt-5">
          <Col lg={{ span: 6, order: 2 }}>
            <Image src={compareImg as string} fluid alt="MGA Cover car insurance compare quotes" />
          </Col>
          <Col lg={{ span: 6, order: 1 }}>
            <Accordion flush>
              {map(items, ({ label, desc }, index) => (
                <Accordion.Item eventKey={label} key={label}>
                  <Accordion.Header>
                    <div className="position-relative">
                      <span
                        className="position-absolute top-50 start-0 translate-middle btn btn-sm btn-primary rounded-pill"
                        style={{ width: '2rem', height: '2rem' }}
                      >
                        {++index}
                      </span>
                      <span className="d-block fs-5 fw-bold ms-4">{label}</span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>{desc}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { HowToCompareSection };
