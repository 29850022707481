import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormGroupControl } from '@components/Base';

const GROUP_PROPS = { className: 'mb-3' };

const PolicyOverviewCardBody = () => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{t('common:policyOverview')}</Card.Title>
      <Row>
        <Col>
          <FormGroupControl
            name="policyHolder"
            label={t('common:theInsuredPolicyholder')}
            required
            disabled
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="policyNumber"
            label={t('common:policyNumber')}
            required
            disabled
            groupProps={GROUP_PROPS}
          />
        </Col>
        <Col>
          <FormGroupControl
            name="policyOrderNumber"
            label={t('common:orderNumber')}
            required
            disabled
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            type="datetime-local"
            name="policyExpiryDate"
            label={t('common:expiryDate')}
            required
            disabled
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>
    </Card.Body>
  );
};

export { PolicyOverviewCardBody };
