import React, { useEffect } from 'react';
import { isUndefined, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { Loading, NoData, Pagination } from '@components/Base';
import { selectVehicle } from './selector';
import { userCarsFetch } from './action';
import { isPaginated } from '@utils/pagination';
import VehicleItem from './VehicleItem';

const Vehicle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { userCars, pagination } = useSelector(selectVehicle);
  const isLoaded = !isUndefined(userCars);
  const hasUserCars = isLoaded && userCars.length > 0;

  useEffect(() => {
    dispatch(userCarsFetch({ page: searchParams.get('page') }));
  }, []);

  return (
    <section>
      <h5 className="mb-4">{t('vehicle', { ns: 'common' })}</h5>
      {!isLoaded && <Loading />}
      {isLoaded && !hasUserCars && <NoData />}
      {hasUserCars && (
        <>
          <Row className="mb-4 gy-5">
            {map(userCars, (userCar) => {
              return (
                <Col lg={9} key={userCar.id}>
                  <VehicleItem userCar={userCar} />
                </Col>
              );
            })}
          </Row>
          {isPaginated(pagination) && (
            <div className="d-flex justify-content-center">
              <br />
              <Pagination
                url="/user-dashboard/vehicle"
                page={pagination?.page ?? 1}
                totalPages={pagination?.totalPages ?? 1}
              />
            </div>
          )}
        </>
      )}
    </section>
  );
};

export { Vehicle };
