import { handleActions } from 'redux-actions';
import { Photo } from '@models/Photo';
import { photoManagerDialogPhotosSet, photoManagerDialogTabsActiveKeySet, photoManagerDialogRefresh } from './action';

export enum Tabs {
  Photos = 'photos',
  Upload = 'upload',
}

export interface State {
  photos?: Photo[];
  activeKey?: Tabs;
}

const initialState: State = {
  activeKey: Tabs.Photos,
};

const reducer = handleActions(
  {
    [String(photoManagerDialogPhotosSet)]: (state, { payload }) => {
      return {
        ...state,
        photos: payload.photos,
      };
    },
    [String(photoManagerDialogTabsActiveKeySet)]: (state, { payload }) => {
      return {
        ...state,
        activeKey: payload.activeKey,
      };
    },
    [String(photoManagerDialogRefresh)]: () => initialState,
  },

  initialState,
);

export default reducer;
