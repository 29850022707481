import React from 'react';
import { map } from 'lodash';
import { Row, Col, OverlayTrigger, Popover, FormCheck } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import { FilterType, useFilterContext } from '../FilterProvider';

type Item = keyof Pick<FilterType, 'accidentToDriver' | 'passengerLiability'>;

const items: Item[] = ['accidentToDriver', 'passengerLiability'];

const FilterAddonCover = () => {
  const { t } = useTranslation();
  const { filters, updateFilters } = useFilterContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    updateFilters({ [name]: checked });
  };

  return (
    <div className="py-2">
      <div className="py-1">
        <span className="pe-1 fw-bold">{t('carInsuranceQuote.addons')}</span>
      </div>

      <Row className="pt-2">
        {map(items, (item) => (
          <Col md={6} key={item}>
            <FormCheck
              id={item}
              name={item}
              value={filters[item].toString()}
              checked={filters[item]}
              onChange={handleChange}
              label={
                <>
                  <span className="me-2">{t(`carInsuranceQuote.${item}.name`)}</span>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Popover>
                        <Popover.Body>{t(`carInsuranceQuote.${item}.description`)}</Popover.Body>
                      </Popover>
                    }
                  >
                    <FontAwesomeIcon icon={faCircleInfo} size="sm" />
                  </OverlayTrigger>
                </>
              }
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export { FilterAddonCover };
