import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { map, isUndefined } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { NoData, Loading } from '@components/Base';
import { PersonalAccidentQuoteGroup } from '@models/PersonalAccidentQuoteGroup';

import { PersonalAccidentQuoteGroupItem } from './personal-accident-quote-group-item';
import { personalAccidentQuotesFetch } from '../action';
import { PersonalAccidentQuotesFetchPayload } from '../personalAccidentQuotesFetch';
import { usePersonalAccidentQuoteResultPageParams } from '../hook';
import { selectPersonalAccidentQuoteResultPage } from '../selector';

const PersonalAccidentQuoteList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = usePersonalAccidentQuoteResultPageParams();
  const { totalQuotes, personalAccidentQuoteGroups } = useSelector(selectPersonalAccidentQuoteResultPage);

  useEffect(() => {
    const payload: PersonalAccidentQuotesFetchPayload = { ...params };
    dispatch(personalAccidentQuotesFetch(payload));
  }, []);

  return (
    <Container className="mt-5">
      {isUndefined(personalAccidentQuoteGroups) && <Loading />}
      {personalAccidentQuoteGroups?.length === 0 && <NoData />}

      {!!totalQuotes && <h4 className="mb-5">{t('personalAccidentQuote.totalPlans', { count: totalQuotes })}</h4>}

      {personalAccidentQuoteGroups && (
        <>
          {map(personalAccidentQuoteGroups, (quoteGroup: PersonalAccidentQuoteGroup) => (
            <Row key={quoteGroup.insurer.id} className="mb-4" data-testid="personal-accident-group-item">
              <Col>
                <PersonalAccidentQuoteGroupItem {...quoteGroup} />
              </Col>
            </Row>
          ))}

          <div className="my-5">&nbsp;</div>
        </>
      )}
    </Container>
  );
};

export { PersonalAccidentQuoteList };
