import { createActions } from 'redux-actions';
import { PersonalAccidentClaim } from '@models/PersonalAccidentClaim';

export type State = {
  personalAccidentClaim: PersonalAccidentClaim | null | undefined;
};
export type StateUpdatePayload = Partial<State>;

export const {
  personalAccidentClaimFetch,

  stateUpdate,
} = createActions(
  'PERSONAL_ACCIDENT_CLAIM_FETCH',

  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_CLAIM_DETAILS_PERSONAL_ACCIDENT_CLAIM_DETAILS',
  },
);
