import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const BetaAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert variant="info">{t('betaAlert.info')}</Alert>
  );
};

export { BetaAlert }
