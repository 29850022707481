import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { map, isUndefined } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { NoData, Loading } from '@components/Base';
import { CancerQuoteGroup } from '@models/CancerQuoteGroup';

import { selectCancerQuoteResultPage } from '../selector';
import { useCancerQuoteResultPageParams } from '../hook';
import { cancerQuotesFetch } from '../action';
import { CancerQuotesFetchPayload } from '../cancerQuotesFetch';
import { CancerQuoteGroupItem } from './cancer-quote-group-item';

const CancerQuoteList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useCancerQuoteResultPageParams();
  const { totalQuotes, cancerQuoteGroups } = useSelector(selectCancerQuoteResultPage);

  useEffect(() => {
    const payload: CancerQuotesFetchPayload = { ...params };
    dispatch(cancerQuotesFetch(payload));
  }, []);

  return (
    <Container className="mt-5">
      {isUndefined(cancerQuoteGroups) && <Loading />}
      {cancerQuoteGroups?.length === 0 && <NoData />}

      {!!totalQuotes && <h4 className="mb-5">{t('cancerQuoteResultPage.totalPlans', { count: totalQuotes })}</h4>}

      {cancerQuoteGroups && (
        <>
          {map(cancerQuoteGroups, (quoteGroup: CancerQuoteGroup) => (
            <Row key={quoteGroup.insurer.id} className="mb-4" data-testid="cancer-group-item">
              <Col>
                <CancerQuoteGroupItem {...quoteGroup} />
              </Col>
            </Row>
          ))}

          <div className="my-5">&nbsp;</div>
        </>
      )}
    </Container>
  );
};

export { CancerQuoteList };
