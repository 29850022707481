import { handleActions } from 'redux-actions';
import { UserDocument } from '@models/UserDocument';
import { userDocumentSet } from './action';

export interface State {
  userDocument?: UserDocument;
}

const initialState: State = {};

const reducer = handleActions(
  {
    [String(userDocumentSet)]: (state, { payload }) => {
      return {
        ...state,
        userDocument: payload.userDocument,
      };
    },
  },

  initialState,
);

export default reducer;
