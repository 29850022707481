import React, { useMemo } from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import { map, times } from 'lodash';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import style from './style.module.scss';
import { Title } from '../title';

const FrequentlyAskedQuestion = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(14, (n) => ({
      question: t(`faq.q${n + 1}`),
      answer: t(`faq.a${n + 1}`),
    }));
  }, []);

  return (
    <section className="py-5">
      <Title title={t('faq.header')} />
      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <Accordion flush>
              {map(items, ({ question, answer }) => (
                <Accordion.Item eventKey={question} key={question}>
                  <Accordion.Header>
                    <span className="fw-bold">{question}</span>
                  </Accordion.Header>
                  <Accordion.Body className={classNames(style.textPreLine)}>{answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { FrequentlyAskedQuestion };
