import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParamsBillingId, useParamsPersonalAccidentOrderId } from '@hooks/params';

import { personalAccidentOrderFetch, billingFetch } from './action';
import { PersonalAccidentOrderFetchPayload } from './personalAccidentOrderFetch';
import { BillingFetchPayload } from './billingFetch';
import { selectPersonalAccidentOrderPaymentPage } from './selector';

export const usePersonalAccidentOrderFetch = () => {
  const dispatch = useDispatch();
  const personalAccidentOrderId = useParamsPersonalAccidentOrderId();
  const { personalAccidentOrder } = useSelector(selectPersonalAccidentOrderPaymentPage);

  useEffect(() => {
    const payload: PersonalAccidentOrderFetchPayload = { id: personalAccidentOrderId };
    dispatch(personalAccidentOrderFetch(payload));
  }, []);

  return personalAccidentOrder;
};

export const useBillingFetch = () => {
  const dispatch = useDispatch();
  const billingId = useParamsBillingId();
  const { billing } = useSelector(selectPersonalAccidentOrderPaymentPage);

  useEffect(() => {
    const payload: BillingFetchPayload = { id: billingId };
    dispatch(billingFetch(payload));
  }, []);

  return billing;
};
