import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Button, InputGroup, FormGroup, FormLabel, FormControl, FormText } from 'react-bootstrap';
import Countdown, { CountdownRenderProps } from 'react-countdown';

const OTP_CODE_REQUEST_DURATION = 60000; // 60seconds;

interface Props {
  onRequest: () => void;
}

const OtpCodeInputGroup = ({ onRequest }: Props) => {
  const { t } = useTranslation();
  const [countdownOptions, setCountdownOptions] = useState({ key: 0, date: Date.now() });
  const [field, meta] = useField('otpCode');
  const { name } = field;
  const { error, touched } = meta;
  const isInvalid = touched && Boolean(error);
  const isValid = touched && !error;
  const shouldRenderHelp = useMemo(() => countdownOptions.key > 0, [countdownOptions.key]);

  const handleRequest = useCallback(() => {
    const date = Date.now();

    setCountdownOptions({ key: date, date: date + OTP_CODE_REQUEST_DURATION });
    onRequest();
  }, []);

  const renderer = useCallback(
    ({ seconds, completed }: CountdownRenderProps) => {
      const formattedSeconds = seconds === 0 ? 60 : seconds;

      if (completed) {
        return (
          <Button variant="outline-primary" onClick={handleRequest}>
            {t('resendOTP', { ns: 'common' })}
          </Button>
        );
      }

      return <InputGroup.Text>{`${formattedSeconds}s`}</InputGroup.Text>;
    },
    [handleRequest],
  );

  return (
    <FormGroup className="mb-3" controlId={name}>
      <FormLabel>OTP code</FormLabel>
      <InputGroup hasValidation>
        <FormControl {...field} {...{ isValid, isInvalid }} />
        <Countdown renderer={renderer} {...countdownOptions} />
        <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
      </InputGroup>
      {shouldRenderHelp && <FormText muted>Please check your phone for otp code.</FormText>}
    </FormGroup>
  );
};

export { OtpCodeInputGroup };
