import { call, put, takeLatest } from 'redux-saga/effects';
import { CarClaimApi, GetCarClaimParams } from '@apis/CarClaimApi';
import { CarClaim } from '@models/CarClaim';
import { carClaimFetch, carClaimSet } from './action';
import { SagaPayload } from '@utils/typescript';

function* handleCarClaimFetch({ payload }: SagaPayload<GetCarClaimParams>) {
  try {
    const carClaim = (yield call(CarClaimApi.get, payload)) as CarClaim;
    yield put(carClaimSet({ carClaim }));
  } catch {
    yield put(carClaimSet({ carClaim: null }));
  }
}

export default function* () {
  yield takeLatest(carClaimFetch, handleCarClaimFetch);
}
