import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import PhotoList from './PhotoList';
import Uploader from './Uploader';
import { selectPhotoManagerDialog } from './selector';
import { photoManagerDialogTabsActiveKeySet } from './action';
import { PhotoManagerDialogProps } from './type';

const PhotoManagerDialog = ({ recordId, recordType, name, limit, show, onHide }: PhotoManagerDialogProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { activeKey } = useSelector(selectPhotoManagerDialog);

  return (
    <Modal size="xl" show={show} onHide={onHide} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{t('photoManagerDialog.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          activeKey={activeKey}
          onSelect={(key) => dispatch(photoManagerDialogTabsActiveKeySet({ activeKey: key }))}
          className="mb-3"
        >
          <Tab eventKey="photos" title={t('photoManagerDialog.tabs.photos.title')}>
            <PhotoList {...{ recordId, recordType, name }} />
          </Tab>
          <Tab eventKey="upload" title={t('photoManagerDialog.tabs.upload.title')}>
            <Uploader {...{ recordId, recordType, name, limit }} />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export { PhotoManagerDialog };
