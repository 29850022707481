import { handleActions } from 'redux-actions';
import { Order } from '@models/Order';
import { policySet } from './action';

export interface State {
  policy?: Order;
}

const initialState: State = {};

const reducer = handleActions(
  {
    [String(policySet)]: (state, { payload }) => {
      return {
        ...state,
        policy: payload.policy,
      };
    },
  },

  initialState,
);

export default reducer;
