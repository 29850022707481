import { call, put, takeLatest } from 'redux-saga/effects';
import { AbaPaywayCredential } from '@models/AbaPaywayCredential';
import AbaPaywayApi from '@apis/AbaPaywayApi';

import { credentialCreate, stateUpdate } from './action';
import { AbaPaywayCredentialCreatePayload, StateUpdatePayload } from './type';
import { SagaPayload } from '@utils/typescript';

function* handleCredentialCreate({ payload }: SagaPayload<AbaPaywayCredentialCreatePayload>) {
  const state: StateUpdatePayload = { credential: null };
  try {
    state.credential = (yield call(AbaPaywayApi.createCredential, payload)) as AbaPaywayCredential;
    yield put(stateUpdate(state));
    AbaPayway.checkout();
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(credentialCreate, handleCredentialCreate);
}
