import React from 'react';
import { useSelector } from 'react-redux';

import { selectCancerQuoteComparePage } from './selector';

type Props = {
  header: string;
};

const TableRowHeader = ({ header }: Props) => {
  const { cancerQuotes } = useSelector(selectCancerQuoteComparePage);

  if (!cancerQuotes) return null;

  return (
    <tr className="table-secondary">
      <th colSpan={cancerQuotes.length + 1}>{header}</th>
    </tr>
  );
};

export { TableRowHeader };
