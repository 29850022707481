import React, { useMemo } from 'react';
import { map, times } from 'lodash';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ThirdPartyAndComprehensiveSection from './ThirdPartyAndComprehensiveSection';

import thirdPartyLiabilityImg from './third-party-liability.png';
import comprehensiveImg from './comprehensive-standard.png';

import { Title } from '../title';

const IMAGES = [thirdPartyLiabilityImg, comprehensiveImg];

export interface Props {
  label: string;
  desc: string;
  image: string;
}

const PlanCard = ({ label, desc, image }: Props) => {
  return (
    <Card className="shadow border-0 h-100 bg-primary text-white">
      <div className="text-center pt-3">
        <Card.Img src={image} className="w-50" alt="MGA Cover car insurance plans" />
      </div>
      <Card.Body>
        <Card.Title className="my-4 text-center">
          <h4 className="fw-bold">{label}</h4>
        </Card.Title>
        <Card.Text>{desc}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const WhichPlanSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(2, (n) => ({
      label: t(`carInsurancePage.whichPlanSection.item${n + 1}.label`),
      desc: t(`carInsurancePage.whichPlanSection.item${n + 1}.desc`),
      image: IMAGES[n] as string,
    }));
  }, []);

  return (
    <section className="py-5 bg-light">
      <Title title={t('carInsurancePage.whichPlanSection.title')} />
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <Row className="gy-4">
              {map(items, (item) => (
                <Col lg={6} key={item.label}>
                  <PlanCard {...item} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={10} className="mx-auto">
            <ThirdPartyAndComprehensiveSection />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { WhichPlanSection };
