import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { useFormikContext } from 'formik';
import { tOrdinalItem } from '@components/i18n';

import { FormValues } from '../schema';
import { MemberItem } from './member-item';

type Props = {
  questionIdx: number;
};

const MemberList = ({ questionIdx }: Props) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();

  return (
    <ListGroup horizontal className="justify-content-center">
      <MemberItem
        age={values.me.age}
        label={t('common:myself')}
        name={`me.medicalQuestionnaire.${questionIdx}.answer`}
      />

      {!!values.spouse && (
        <MemberItem
          age={values.spouse.age}
          label={t('common:spouse')}
          name={`spouse.medicalQuestionnaire.${questionIdx}.answer`}
        />
      )}

      {!!values.sons &&
        map(values.sons, (son, idx) => (
          <MemberItem
            key={idx}
            age={son.age}
            label={tOrdinalItem(idx + 1, 'common:son')}
            name={`sons.${idx}.medicalQuestionnaire.${questionIdx}.answer`}
          />
        ))}

      {!!values.daughters &&
        map(values.daughters, (daughter, idx) => (
          <MemberItem
            key={idx}
            age={daughter.age}
            label={tOrdinalItem(idx + 1, 'common:daughter')}
            name={`daughters.${idx}.medicalQuestionnaire.${questionIdx}.answer`}
          />
        ))}
    </ListGroup>
  );
};

export { MemberList };
