import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import healthImg from './health-insurance.png';

const DefinitionSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <Row className="gy-5 align-items-center">
              <Col lg={5} className="text-center order-lg-2">
                <Image src={healthImg as string} fluid alt="MGA Cover health insurance" className="w-75" />
              </Col>
              <Col lg={7} className="p-5 order-lg-1 text-center text-lg-start">
                <h2>{t('healthInsurancePage.definitionSection.title')}</h2>
                <p>{t('healthInsurancePage.definitionSection.description')}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { DefinitionSection };
