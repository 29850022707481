import { combineReducers } from 'redux';

import { homeReducer as homeReducer } from '@components/Home';

import { carQuotePageReducer } from '@components/CarQuotePage';
import { carQuoteResultPageReducer } from '@components/CarQuoteResultPage';
import { carQuoteComparePageReducer } from '@components/CarQuoteComparePage';
import { carQuoteDetailsRouteReducer } from '@components/CarQuoteDetailsRoute';
import { carQuoteBuyRouteReducer } from '@components/CarQuoteBuyRoute';
import { carOrderPaymentPageReducer } from '@components/CarOrderPaymentPage';

import { healthQuoteResultPageReducer } from '@components/HealthQuoteResultPage';
import { healthQuoteComparePageReducer } from '@components/HealthQuoteComparePage';
import { healthQuoteDetailsRouteReducer } from '@components/HealthQuoteDetailsRoute';
import { healthQuoteBuyRouteReducer } from '@components/HealthQuoteBuyRoute';
import { healthOrderPaymentPageReducer } from '@components/HealthOrderPaymentPage';

import { personalAccidentQuoteResultPageReducer } from '@components/PersonalAccidentQuoteResultPage';
import { personalAccidentQuoteDetailsPageReducer } from '@components/PersonalAccidentQuoteDetailsRoute';
import { personalAccidentQuoteBuyRouteReducer } from '@components/PersonalAccidentQuoteBuyRoute';
import { personalAccidentOrderPaymentPageReducer } from '@components/PersonalAccidentOrderPaymentPage';
import { personalAccidentQuoteComparePageReducer } from '@components/PersonalAccidentQuoteComparePage';

import { cancerQuoteResultPageReducer } from '@components/CancerQuoteResultPage';
import { cancerQuoteDetailsRouteReducer } from '@components/CancerQuoteDetailsRoute';
import { cancerQuoteBuyRouteReducer } from '@components/CancerQuoteBuyRoute';
import { cancerOrderPaymentPageReducer } from '@components/CancerOrderPaymentPage';
import { cancerQuoteComparePageReducer } from '@components/CancerQuoteComparePage';

import { userRegistrationReducer } from '@components/UserRegistration';
import { userDashboardReducer } from '@components/UserDashboard';
import { authReducer } from '@components/Auth';
import { abaPaywayReducer } from '@components/AbaPayway';
import { camDxReducer } from '@components/CamDx';
import { articlePageReducer } from '@components/ArticlePage';
import { articleDetailsPageReducer } from '@components/ArticleDetailsPage';
import { ToastProviderReducer } from '@components/ToastProvider';
import { photoManagerDialogReducer } from '@components/PhotoManagerDialog';
import { occupationReducer } from '@components/Occupation';

import { featureCarReducer } from '@components/Feature/Car';

const rootReducer = combineReducers({
  carQuotePageReducer,
  carQuoteResultPageReducer,
  carQuoteComparePageReducer,
  carQuoteDetailsRouteReducer,
  carQuoteBuyRouteReducer,
  carOrderPaymentPageReducer,

  healthQuoteResultPageReducer,
  healthQuoteComparePageReducer,
  healthQuoteDetailsRouteReducer,
  healthQuoteBuyRouteReducer,
  healthOrderPaymentPageReducer,

  personalAccidentQuoteResultPageReducer,
  personalAccidentQuoteDetailsPageReducer,
  personalAccidentQuoteBuyRouteReducer,
  personalAccidentOrderPaymentPageReducer,
  personalAccidentQuoteComparePageReducer,

  cancerQuoteResultPageReducer,
  cancerQuoteDetailsRouteReducer,
  cancerQuoteBuyRouteReducer,
  cancerOrderPaymentPageReducer,
  cancerQuoteComparePageReducer,

  homeReducer,
  userRegistrationReducer,
  userDashboardReducer,
  authReducer,
  abaPaywayReducer,
  camDxReducer,
  articlePageReducer,
  articleDetailsPageReducer,
  ToastProviderReducer,
  photoManagerDialogReducer,
  occupationReducer,

  featureCarReducer,
});

type RootState = ReturnType<typeof rootReducer>;

export { RootState };

export default rootReducer;
