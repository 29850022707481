import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';

import accidentalDeathImg from './accidental-death.png';
import permanentTotalDisabilityImg from './permanent-total-disability.png';
import permanentPartialDisabilityImg from './permanent-partial-disability.png';
import temporaryDisabilityImg from './temporary-disability.png';
import medicalExpenseImg from './medical-expense.png';

const IMAGES = [
  accidentalDeathImg,
  permanentTotalDisabilityImg,
  permanentPartialDisabilityImg,
  temporaryDisabilityImg,
  medicalExpenseImg,
];

const CoverSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <h2 className="text-center">{t('personalAccidentInsurancePage.coverSection.title')}</h2>
      <Container className="my-5">
        <Row className="gy-4">
          {map(IMAGES, (image, idx) => (
            <Col key={`coverItem${idx}`} md={6} lg={4}>
              <Card className="h-100 text-md-center text-lg-start">
                <div className="text-center pt-3">
                  <Card.Img
                    src={image as string}
                    className="w-50"
                    alt="MGA Cover personal accident insurance coverages"
                  />
                </div>
                <Card.Body>
                  <Card.Title className="text-center">
                    <h4 className="fw-bold">{t(`personalAccidentInsurancePage.coverSection.item${idx + 1}.label`)}</h4>
                  </Card.Title>
                  <Card.Text>{t(`personalAccidentInsurancePage.coverSection.item${idx + 1}.desc`)}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export { CoverSection };
