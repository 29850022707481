import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fakePaymentCreate } from './action';
import { FakePaywayCredentialCreatePayload } from './type';

type Props = {
  paymentTransactionId: number;
};

const FakePayment = ({ paymentTransactionId }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (paymentTransactionId) {
      const payload: FakePaywayCredentialCreatePayload = { paymentTransactionId };
      dispatch(fakePaymentCreate(payload));
    }
  }, [paymentTransactionId]);

  return <h1>fake payment</h1>;
};

export { FakePayment };
