import { ref, string, object } from 'yup';

import i18n from '@components/i18n';

export const validationSchema = object({
  password: string().required(i18n.t('validation:required')).min(6, i18n.t('validation:min')),
  passwordConfirmation: string()
    .required(i18n.t('validation:required'))
    .oneOf([ref('password')], 'passwordConfirmation not match'),
  otpCode: string().required(i18n.t('validation:required')).length(4, i18n.t('validation:length')),
});
