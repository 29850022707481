import React, { ReactElement } from 'react';
import { Col, Row } from 'react-bootstrap';

interface Props {
  label: string;
  value?: string | number | ReactElement;
}

const RowItem = ({ label, value }: Props) => {
  return (
    <Row className="mb-2">
      <Col xs={7} sm={7} md={4}>
        {label}
      </Col>
      <Col xs="auto">:</Col>
      <Col>{value}</Col>
    </Row>
  );
};

export { RowItem };
