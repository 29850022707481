import { includes } from 'lodash';
import { OrderableTypes } from '@models/Order';
import { ClaimableTypes } from '@models/Claim';
import i18n from '@components/i18n';

export type ProductType = OrderableTypes | ClaimableTypes;

export const productName = (type: ProductType) => {
  let name = '';

  if (includes([OrderableTypes.Car], type) || includes([ClaimableTypes.Car], type))
    name = i18n.t('common:carInsurance');
  else if (includes([OrderableTypes.PersonalAccident], type) || includes([ClaimableTypes.PersonalAccident], type))
    name = i18n.t('common:personalAccidentInsurance');
  else if (includes([OrderableTypes.Cancer], type) || includes([ClaimableTypes.Cancer], type))
    name = i18n.t('common:cancerInsurance');

  return name;
};
