import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { chain, find, includes, isEmpty, map, startsWith } from 'lodash';

import { BenefitGroupTypes, tBenefitGroupType, tBenefitType } from '@models/PersonalAccidentPlanBenefitGroup';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { PersonalAccidentPlanBenefit } from '@models/PersonalAccidentPlanBenefit';

import { selectPersonalAccidentQuoteComparePage } from './selector';
import { TableRowHeader } from './table-row-header';
import { TableRowItem } from './table-row-item';

type Props = {
  groupType: BenefitGroupTypes;
  excludeTypes?: string[];
};

const TableBenefit = ({ groupType, excludeTypes = [] }: Props) => {
  const { personalAccidentQuotes } = useSelector(selectPersonalAccidentQuoteComparePage);
  const benefitTypes = useMemo(() => {
    return chain(personalAccidentQuotes)
      .flatMap(({ personalAccidentPlanBenefits }: PersonalAccidentQuote) => personalAccidentPlanBenefits)
      .map(({ benefitType }: PersonalAccidentPlanBenefit) => benefitType)
      .uniq()
      .filter((benefitType) => startsWith(benefitType, groupType) && !includes(excludeTypes, benefitType))
      .value();
  }, [personalAccidentQuotes]);

  const findType = (personalAccidentPlanBenefits: PersonalAccidentPlanBenefit[], type: string) => {
    return find(personalAccidentPlanBenefits, ({ benefitType }) => benefitType === type);
  };

  if (isEmpty(benefitTypes)) return null;

  return (
    <>
      <TableRowHeader header={tBenefitGroupType(groupType)} />

      {map(benefitTypes, (type) => (
        <TableRowItem key={type} title={tBenefitType(type)}>
          {map(personalAccidentQuotes, ({ personalAccidentPlanId, personalAccidentPlanBenefits }) => (
            <td key={personalAccidentPlanId}>{findType(personalAccidentPlanBenefits, type)?.description}</td>
          ))}
        </TableRowItem>
      ))}
    </>
  );
};

export { TableBenefit };
