import React from 'react';
import { useSelector } from 'react-redux';

import { selectPersonalAccidentQuoteComparePage } from './selector';

type Props = {
  header: string;
};

const TableRowHeader = ({ header }: Props) => {
  const { personalAccidentQuotes } = useSelector(selectPersonalAccidentQuoteComparePage);

  if (!personalAccidentQuotes) return null;

  return (
    <tr className="table-secondary">
      <th colSpan={personalAccidentQuotes.length + 1}>{header}</th>
    </tr>
  );
};

export { TableRowHeader };
