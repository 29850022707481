import { Location, NavigateFunction } from 'react-router-dom';

// custom history object to allow navigation outside react components
interface HistoryLocation extends Location {
  state: null | {
    from?: Location;
  };
}

interface History {
  navigate: NavigateFunction;
  location: HistoryLocation;
}

export const history = {} as History;
