import { createActions } from 'redux-actions';

import { PreviewCarOrderParams, RenewCarOrderParams } from '@apis/CarOrderApi';
import { CarOrder } from '@models/CarOrder';

export type State = {
  carOrder: CarOrder | undefined | null;
};

export type CarOrderPreviewPayload = PreviewCarOrderParams;
export type CarOrderRenewPayload = RenewCarOrderParams;

export type StateUpdatePayload = Partial<State>;

export const {
  carOrderPreview,
  carOrderRenew,

  stateUpdate,
} = createActions(
  'CAR_ORDER_PREVIEW',
  'CAR_ORDER_RENEW',

  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_POLICY_RENEWAL',
  },
);
