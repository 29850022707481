import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/search-params';

import { personalAccidentQuoteFetch, personalAccidentQuoteEdit } from './action';
import { PersonalAccidentQuoteFetchPayload } from './personalAccidentQuoteFetch';
import { PersonalAccidentQuoteEditPayload } from './personalAccidentQuoteEdit';
import { selectPersonalAccidentQuoteBuyRoute } from './selector';
import { PersonalAccidentQuoteBuyNewPageParams, personalAccidentQuoteBuyNewPageParamsSchema } from './route';
import { useParamsPersonalAccidentQuoteId } from '@hooks/params';

export const usePersonalAccidentQuoteBuyNewPageParams = () =>
  useParseParams<PersonalAccidentQuoteBuyNewPageParams>(personalAccidentQuoteBuyNewPageParamsSchema);

export const usePersonalAccidentQuote = () => {
  const dispatch = useDispatch();
  const payload: PersonalAccidentQuoteFetchPayload = usePersonalAccidentQuoteBuyNewPageParams();
  const { personalAccidentQuote } = useSelector(selectPersonalAccidentQuoteBuyRoute);

  useEffect(() => {
    dispatch(personalAccidentQuoteFetch(payload));
  }, []);

  return personalAccidentQuote;
};

export const usePersonalAccidentQuoteEdit = () => {
  const personalAccidentQuoteId = useParamsPersonalAccidentQuoteId();
  const dispatch = useDispatch();
  const { personalAccidentQuote } = useSelector(selectPersonalAccidentQuoteBuyRoute);

  useEffect(() => {
    const payload: PersonalAccidentQuoteEditPayload = { id: personalAccidentQuoteId };
    dispatch(personalAccidentQuoteEdit(payload));
  }, []);

  return personalAccidentQuote;
};
