import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isUndefined, isNull } from 'lodash';

import { useCurrentUser } from '@components/Auth';
import PersonalAccidentQuotePremiumCardBody from '@components/Feature/PersonalAccidentQuote/PersonalAccidentQuotePremiumCardBody';
import { Loading, LoadingError } from '@components/Base';
import { SupportCard } from '@components/Feature/Support/support-card';

import PersonalAccidentQuoteBuyForm from '../PersonalAccidentQuoteBuyForm';
import { FormValues } from '../PersonalAccidentQuoteBuyForm/schema';
import { usePersonalAccidentQuote } from '../hook';
import { useInitialValues } from './hook';
import { PersonalAccidentQuoteCreatePayload } from '../personalAccidentQuoteCreate';
import { personalAccidentQuoteCreate } from '../action';

const PersonalAccidentQuoteBuyNewPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const personalAccidentQuote = usePersonalAccidentQuote();
  useCurrentUser();
  const isLoading = isUndefined(personalAccidentQuote);
  const isError = isNull(personalAccidentQuote);
  const initialValues = useInitialValues();

  const handleSubmit = (values: FormValues) => {
    if (personalAccidentQuote) {
      const payload: PersonalAccidentQuoteCreatePayload = {
        ...values,
        personalAccidentPlanId: personalAccidentQuote.personalAccidentPlanId,
      };

      dispatch(personalAccidentQuoteCreate(payload));
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {isError && <LoadingError />}
      {personalAccidentQuote && (
        <Container className="my-5">
          <Row>
            <h2 className="mb-3">{t('personalAccidentQuoteBuyPage.title')}</h2>
            <p>{t('personalAccidentQuoteBuyPage.subTitle')}</p>
            <Col lg={7}>
              {initialValues && <PersonalAccidentQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} />}
            </Col>
            <Col lg={5}>
              <Card className="mb-4">
                <PersonalAccidentQuotePremiumCardBody {...personalAccidentQuote} />
              </Card>
              <SupportCard />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export { PersonalAccidentQuoteBuyNewPage };
