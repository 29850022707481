import React from 'react';
import { useSelector } from 'react-redux';

import { selectHealthQuoteComparePage } from './selector';

type Props = {
  header: string;
};

const TableRowHeader = ({ header }: Props) => {
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  if (!healthQuotes) return null;

  return (
    <tr className="table-secondary">
      <th colSpan={healthQuotes.length + 1}>{header}</th>
    </tr>
  );
};

export { TableRowHeader };
