import { combineReducers } from 'redux';
import { MobileNumberReducer as mobileNumber } from './MobileNumberCard';
import { OtpCodeReducer as otpCode } from './OtpCodeCard';
import { passwordReducer as password } from './PasswordCard';

export default combineReducers({
  mobileNumber,
  otpCode,
  password,
});
