import { put, call, takeLatest } from 'redux-saga/effects';

import { CancerQuoteApi } from '@apis/CancerQuoteApi';
import { CancerQuote } from '@models/CancerQuote';
import { SagaPayload } from '@utils/typescript';
import { groupCancerQuotes } from '@models/CancerQuoteGroup';

import { cancerQuotesFetch, stateUpdate, StateUpdatePayload } from './action';
import { CancerQuotesFetchPayload } from './cancerQuotesFetch';

function* handleCancerQuotesFetch({ payload }: SagaPayload<CancerQuotesFetchPayload>) {
  const state: StateUpdatePayload = { totalQuotes: null, cancerQuoteGroups: null };
  try {
    const cancerQuotes = (yield call(CancerQuoteApi.getAll, payload)) as CancerQuote[];
    state.totalQuotes = cancerQuotes.length;
    state.cancerQuoteGroups = groupCancerQuotes(cancerQuotes);
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(cancerQuotesFetch, handleCancerQuotesFetch);
}
