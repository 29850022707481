import humps from 'lodash-humps-ts';
import { API_V1_BASE } from '@config';
import { authAxios } from '@components/Auth';
import { CarSubModel } from '@models/CarSubModel';
import { CarModel } from '@models/CarModel';

export type GetAllCarSubModelParams = {
  carModelId: CarModel['id'];
};

export type GetCarSubModelParams = Pick<CarSubModel, 'id'>;

export class CarSubModelApi {
  static getAll = async ({ carModelId }: GetAllCarSubModelParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/car_models/${carModelId}/car_sub_models`)
      .then((response) => humps(response.data) as CarSubModel[]);
  };

  static get = async ({ id }: GetCarSubModelParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/car_sub_models/${id}`)
      .then((response) => humps(response.data) as CarSubModel);
  };
}
