import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { isUndefined, map } from 'lodash';
import { useTranslation } from 'react-i18next';

import { NoData, Pagination, Loading } from '@components/Base';
import { amountToUsd } from '@utils/currency';
import { BillingList } from '@models/Billing';
import { isPaginated } from '@utils/pagination';
import { useParamsPage } from '@hooks/params';

import { billingsFetch, BillingsFetchPayload } from './action';
import { selectBilling } from './selector';

const Billing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const page = useParamsPage();
  const { billings, pagination } = useSelector(selectBilling);
  const isLoaded = !isUndefined(billings);
  const hasBillings = billings && billings.length > 0;

  useEffect(() => {
    const payload: BillingsFetchPayload = { page };
    dispatch(billingsFetch(payload));
  }, []);

  const handleClick = ({ id }: BillingList) => {
    navigate(`/user-dashboard/billing/${id}`);
  };

  return (
    <section>
      <h5 className="mb-4">{t('common:billing')}</h5>
      <Card>
        <Card.Body>
          {!isLoaded && <Loading />}
          {isLoaded && !hasBillings && <NoData />}
          {hasBillings && (
            <>
              <Table className="align-middle">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Order no.</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {map(billings, (billing) => {
                    const { invoiceNumber, status, chargeAmount, orderNumber } = billing;

                    return (
                      <tr key={invoiceNumber} data-testid="billing-item">
                        <td>{invoiceNumber}</td>
                        <td>{orderNumber}</td>
                        <td>{status}</td>
                        <td>{amountToUsd(chargeAmount)}</td>
                        <td>
                          <Button onClick={() => handleClick(billing)} variant="outline-primary" size="sm">
                            {t('common:viewDetails')}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {isPaginated(pagination) && (
                <div className="d-flex justify-content-center">
                  <br />
                  <Pagination
                    url="/user-dashboard/billing"
                    page={pagination?.page ?? 1}
                    totalPages={pagination?.totalPages ?? 1}
                  />
                </div>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </section>
  );
};

export { Billing };
