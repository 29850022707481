import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { CancerQuote } from '@models/CancerQuote';
import { amountToUsd } from '@utils/currency';

import { Title } from './title';

export const RowItem = ({ label, value }: { label: string; value?: React.ReactNode }) => {
  return (
    <Row className="mb-2">
      <Col xs={6} sm={6} md={6}>
        <strong>{label}</strong>
      </Col>
      <Col>
        <div className="d-flex justify-content-end">{value}</div>
      </Col>
    </Row>
  );
};

const FeatureSection = ({ annualLimitAmount, geographicCoverage, hospitals }: CancerQuote) => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <Title>{pluralize(t('common:feature'), 2)}</Title>
      <Container>
        <Row>
          <Col lg={8} className="mx-auto">
            <Row>
              <Col>
                <Card className="h-100">
                  <Card.Body>
                    <RowItem label={t('cancerQuote.annualLimit')} value={amountToUsd(annualLimitAmount)} />
                    <RowItem label={t('cancerQuote.geographicCoverage')} value={geographicCoverage} />
                    <RowItem label={t('cancerQuote.cashlessHospitals')} value={hospitals.length} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { FeatureSection };
