import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { CarQuote } from '@models/CarQuote';
import { AmountWithDiscount } from '@components/Base';

const CarQuoteDeductibleCardBody = ({
  thirdPartyDeductibleAmount,
  thirdPartyDeductibleDiscountAmount,
  thirdPartyDeductibleFinalAmount,
  thirdPartyDeductibleDescription,
  ownDamageDeductibleAmount,
  ownDamageDeductibleDiscountAmount,
  ownDamageDeductibleFinalAmount,
  ownDamageDeductibleDescription,
  theftDeductibleAmount,
  theftDeductibleDiscountAmount,
  theftDeductibleFinalAmount,
  theftDeductibleDescription,
}: CarQuote) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{t('carInsuranceQuote.deductibleTitle')}</Card.Title>
      <small className="text-secondary">{t('carInsuranceQuote.deductibleDescription')}</small>
      <ListGroup variant="flush">
        {!!Number(thirdPartyDeductibleAmount) && (
          <ListGroup.Item>
            <AmountWithDiscount
              amount={thirdPartyDeductibleAmount}
              discountAmount={thirdPartyDeductibleDiscountAmount}
              finalAmount={thirdPartyDeductibleFinalAmount}
            />{' '}
            {thirdPartyDeductibleDescription}
          </ListGroup.Item>
        )}

        {!!Number(ownDamageDeductibleAmount) && (
          <ListGroup.Item>
            <AmountWithDiscount
              amount={ownDamageDeductibleAmount}
              discountAmount={ownDamageDeductibleDiscountAmount}
              finalAmount={ownDamageDeductibleFinalAmount}
            />{' '}
            {ownDamageDeductibleDescription}
          </ListGroup.Item>
        )}

        {!!Number(theftDeductibleAmount) && (
          <ListGroup.Item>
            <AmountWithDiscount
              amount={theftDeductibleAmount}
              discountAmount={theftDeductibleDiscountAmount}
              finalAmount={theftDeductibleFinalAmount}
            />{' '}
            {theftDeductibleDescription}
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card.Body>
  );
};

export { CarQuoteDeductibleCardBody };
