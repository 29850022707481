import React from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { FormGroupControl } from '@components/Base';
import { GenderFormSelect } from '@components/Feature/User/gender-form-select';

import { FormValues } from './schema';

const GROUP_PROPS = { className: 'mb-3' };

type FormValuesKeys = keyof Omit<FormValues, 'personalAccidentPlanId'>;

type Props = {
  name: FormValuesKeys | `${FormValuesKeys}.${number}`;
  label: string;
};

const ContactFormControl = ({ name, label }: Props) => {
  const { t } = useTranslation();
  const { initialValues } = useFormikContext<FormValues>();

  return (
    <Accordion.Item eventKey={name} key={name} data-testid={name}>
      <Accordion.Header>
        <span className="fw-bold">{label}</span>
      </Accordion.Header>
      <Accordion.Body className="d-grid gap-3">
        <Row>
          <Col>
            <FormGroupControl
              required
              name={`${name}.lastName`}
              label={t('user.lastName')}
              groupProps={GROUP_PROPS}
              disabled={name === 'me' && !!initialValues.me.lastName}
            />
          </Col>
          <Col>
            <FormGroupControl
              required
              name={`${name}.firstName`}
              label={t('user.firstName')}
              groupProps={GROUP_PROPS}
              disabled={name === 'me' && !!initialValues.me.firstName}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroupControl
              name={`${name}.lastNameKm`}
              label={t('user.lastNameKm')}
              groupProps={GROUP_PROPS}
              disabled={name === 'me' && !!initialValues.me.lastNameKm}
            />
          </Col>
          <Col>
            <FormGroupControl
              name={`${name}.firstNameKm`}
              label={t('user.firstNameKm')}
              groupProps={GROUP_PROPS}
              disabled={name === 'me' && !!initialValues.me.firstNameKm}
            />
          </Col>
        </Row>

        <Row>
          {name === 'me' && (
            <Col>
              <GenderFormSelect
                required
                name={`${name}.gender`}
                groupProps={GROUP_PROPS}
                disabled={name === 'me' && !!initialValues.me.gender}
              />
            </Col>
          )}

          <Col>
            <FormGroupControl
              required
              type="date"
              name={`${name}.dob`}
              label={t('user.dob')}
              groupProps={GROUP_PROPS}
              disabled
            />
          </Col>
        </Row>

        <FormGroupControl
          name={`${name}.personalCode`}
          label={t('user.personalCode')}
          groupProps={GROUP_PROPS}
          disabled={name === 'me' && !!initialValues.me.personalCode}
        />

        <FormGroupControl
          required
          name={`${name}.occupation`}
          label={t('user.occupation')}
          groupProps={GROUP_PROPS}
          disabled={name === 'me' && !!initialValues.me.occupation}
        />

        {name === 'me' && (
          <>
            <FormGroupControl
              required
              name={`${name}.email`}
              label={t('user.email')}
              groupProps={GROUP_PROPS}
              disabled={!!initialValues.me.email}
            />
            <FormGroupControl
              required
              name={`${name}.mobileNumber`}
              label={t('user.mobileNumber')}
              groupProps={GROUP_PROPS}
              disabled={!!initialValues.me.mobileNumber}
            />
            <FormGroupControl
              required
              name={`${name}.address`}
              label={t('user.address')}
              groupProps={GROUP_PROPS}
              disabled={!!initialValues.me.address}
            />
          </>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export { ContactFormControl };
