import { takeLatest, put, call } from 'redux-saga/effects';

import OccupationApi from '@apis/OccupationApi';
import { Occupation } from '@models/Occupation';

import { occupationsFetch, occupationsSet } from './action';

function* handleOccupationsFetch() {
  try {
    const occupations = (yield call(OccupationApi.getAll)) as Occupation[];
    yield put(occupationsSet({ occupations }));
  } catch (_) {
    yield put(occupationsSet({ occupations: [] }));
  }
}

export default function* () {
  yield takeLatest(occupationsFetch, handleOccupationsFetch);
}
