import { object, boolean, mixed } from 'yup';
import { useTranslation } from 'react-i18next';
import { includes } from 'lodash';

import { FormValues } from './type';

const FILE_SIZE = 5_000_000; //5MB
export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export const useInitialValues = () => {
  const values: FormValues = {
    agree: false,
  }

  return values;
};

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    agree: boolean().oneOf([true], 'You must agree'),
    proof: mixed()
      .optional()
      .test(
        'fileSize',
        t('validation:fileSizeOutOfRange'),
        (file: File) => !file || (file && file.size < FILE_SIZE),
      )
      .test(
        'fileFormat',
        t('validation:invalidFormat'),
        (file: File) => !file || (file && includes(SUPPORTED_FORMATS, file.type)),
      ),
  });
};
