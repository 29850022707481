import React from 'react';
import { Button } from 'react-bootstrap';
import { useCopyToClipboard } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';

const CopyLinkButton = () => {
  const { t } = useTranslation();
  const [copiedText, copy] = useCopyToClipboard();

  const handleCopy = () => () => {
    copy(window.location.href)
      .then(() => console.log('Copied link!'))
      .catch((error) => console.error('Failed to copy!', error));
  };

  return (
    <>
      {copiedText && <small className="d-inline-block me-2">{t('common:copied')}!</small>}
      <Button variant="outline-primary" size="sm" onClick={handleCopy()}>
        {t('common:copyLink')}
      </Button>
    </>
  );
};

export { CopyLinkButton };
