import { createActions } from 'redux-actions';
import { ClaimList } from '@models/Claim';
import { Pagination } from '@models/Pagination';

export type State = {
  claims: ClaimList[] | undefined | null;
  pagination: Pagination | undefined | null;
};

export type StateUpdatePayload = Partial<State>;

export const {
  claimsFetch,

  stateUpdate,
} = createActions(
  'CLAIMS_FETCH',

  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_CLAIM',
  },
);
