import React from 'react';
import { useTranslation } from 'react-i18next';

const DisclaimerSection = () => {
  const { t } = useTranslation();

  return (
    <section className="pt-5">
      <p className="text-center text-secondary">{t('healthInsurancePage.disclaimer')}</p>
    </section>
  );
};

export { DisclaimerSection };
