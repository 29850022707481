import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { amountToUsd } from '@utils/currency';

import { selectPersonalAccidentQuoteComparePage } from './selector';
import { TableRowItem } from './table-row-item';

const TableAnnualLimit = () => {
  const { t } = useTranslation();
  const { personalAccidentQuotes } = useSelector(selectPersonalAccidentQuoteComparePage);

  return (
    <>
      <TableRowItem title={t('personalAccidentQuote.annualLimit')}>
        {map(personalAccidentQuotes, ({ personalAccidentPlanId, annualLimitAmount }) => (
          <td key={personalAccidentPlanId}>{amountToUsd(annualLimitAmount)}</td>
        ))}
      </TableRowItem>
    </>
  );
};

export { TableAnnualLimit };
