import { chain } from 'lodash';

import { CancerQuote } from '@models/CancerQuote';

export type CancerQuoteGroup = {
  insurer: CancerQuote['insurer'];
  cancerQuotes: CancerQuote[];
};

export function groupCancerQuotes(cancerQuotes: CancerQuote[]): CancerQuoteGroup[] {
  return chain(cancerQuotes)
    .groupBy('insurer.id')
    .map(
      (mappedCancerQuotes: CancerQuote[]): CancerQuoteGroup => ({
        insurer: mappedCancerQuotes[0].insurer,
        cancerQuotes: mappedCancerQuotes,
      }),
    )
    .value();
}
