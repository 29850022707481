import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { BackButton, CopyLinkButton } from '@components/Base';
import HeaderSecondary from '@components/HeaderSecondary';
import { useHealthQuoteResultPageParams } from '@components/HealthQuoteResultPage';
import { getHealthQuoteResultPageRoute } from '@components/HealthQuoteResultPage/route';

import { useHealthQuotesFetch } from './hook';
import { TableInsurer } from './table-insurer';
import { TableAnnualLimit } from './table-annual-limit';
import { TableGeographicCoverage } from './table-geographic-coverage';
import {
  TableInpatientBenefit,
  TableOutpatientBenefit,
  TableHealthCheckupBenefit,
  TableMaternityBenefit,
  TableVisionBenefit,
  TableDentalBenefit,
  TableCopayBenefit,
  TableOthersBenefit,
} from './table-benefit';

const HealthQuoteComparePage = () => {
  const navigate = useNavigate();
  const params = useHealthQuoteResultPageParams();
  const healthQuotes = useHealthQuotesFetch();

  const handleBack = () => {
    navigate(getHealthQuoteResultPageRoute(params), { replace: true });
  };

  if (!healthQuotes) return null;

  return (
    <>
      <HeaderSecondary />

      <Container className="mt-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <BackButton onClick={handleBack} />
          <CopyLinkButton />
        </div>

        <Table>
          <tbody>
            <TableInsurer />
            <TableAnnualLimit />
            <TableInpatientBenefit />
            <TableOutpatientBenefit />
            <TableHealthCheckupBenefit />
            <TableMaternityBenefit />
            <TableVisionBenefit />
            <TableDentalBenefit />
            <TableCopayBenefit />
            <TableOthersBenefit />
            <TableGeographicCoverage />
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export { HealthQuoteComparePage };
