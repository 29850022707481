import { createActions } from 'redux-actions';

export const {
  userUpdate,

  stateUpdate,
} = createActions(
  'USER_UPDATE',

  'STATE_UPDATE',
  {
    prefix: 'PERSONAL_INFO_EDIT',
  },
);
