import React from 'react';
import { Col, Container, Row, ListGroup, Tabs, Tab } from 'react-bootstrap';
import { map, times } from 'lodash';
import { useTranslation } from 'react-i18next';

const WhoShouldGetSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <Container>
        <h2 className="text-center">{t('personalAccidentInsurancePage.whoShouldGetSection.title')}</h2>
        <p className="text-center text-secondary w-75 mx-auto">
          {t('personalAccidentInsurancePage.whoShouldGetSection.subTitle')}
        </p>
        <Row className="mt-5">
          <Col lg={10} className="mx-auto">
            <Tabs
              defaultActiveKey="lowRiskOccupations"
              variant="pills"
              className="d-flex flex-column flex-lg-row justify-content-center mb-5"
            >
              <Tab
                eventKey="lowRiskOccupations"
                title={t('personalAccidentInsurancePage.whoShouldGetSection.tab1.title')}
              >
                <ListGroup variant="flush">
                  {map(times(9), (idx) => (
                    <ListGroup.Item key={`lowRiskOccupation${idx}`}>
                      {t(`personalAccidentInsurancePage.whoShouldGetSection.tab1.item${idx + 1}`)}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Tab>
              <Tab
                eventKey="highRiskOccupations"
                title={t('personalAccidentInsurancePage.whoShouldGetSection.tab2.title')}
              >
                <ListGroup variant="flush">
                  {map(times(9), (idx) => (
                    <ListGroup.Item key={`highRiskOccupation${idx}`}>
                      {t(`personalAccidentInsurancePage.whoShouldGetSection.tab2.item${idx + 1}`)}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Tab>
              <Tab
                eventKey="veryHighRiskOccupations"
                title={t('personalAccidentInsurancePage.whoShouldGetSection.tab3.title')}
              >
                <ListGroup variant="flush">
                  {map(times(6), (idx) => (
                    <ListGroup.Item key={`veryHighRiskOccupation${idx}`}>
                      {t(`personalAccidentInsurancePage.whoShouldGetSection.tab3.item${idx + 1}`)}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { WhoShouldGetSection };
