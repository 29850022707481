import humps from 'lodash-humps-ts';
import { omitBy, isArray } from 'lodash';
import { stringify } from 'qs';

import { API_V1_BASE } from '@config';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { User } from '@models/User';
import { CancerQuote, CancerQuoteMember } from '@models/CancerQuote';

export type MedicalQuestionnaire = {
  question: string;
  answer: 'yes' | 'no';
  remark: string;
};

type MePersonalDetails = Omit<User, 'id' | 'fullName' | 'fullNameKm'> & {
  age: number | '';
  medicalQuestionnaire: MedicalQuestionnaire[];
};

type MemberPersonalDetails = Omit<MePersonalDetails, 'gender' | 'email' | 'mobileNumber' | 'address'>;

export type CancerQuotePeopleParams = {
  me: MePersonalDetails;
  spouse?: MemberPersonalDetails;
  sons?: MemberPersonalDetails[];
  daughters?: MemberPersonalDetails[];
};

export type GetAllCancerQuoteParams = CancerQuoteMember & {
  cancerPlanIds?: number[];
};

export type GetCancerQuoteParams = CancerQuoteMember & {
  cancerPlanId: number;
};

export type CreateCancerQuoteParams = Pick<GetCancerQuoteParams, 'cancerPlanId'> & CancerQuotePeopleParams;

export type EditCancerQuoteParams = {
  id: number;
};

export type UpdateCancerQuoteParams = EditCancerQuoteParams & CancerQuotePeopleParams;

export type SendEmailCancerQuoteParams = GetCancerQuoteParams & {
  firstName: string;
  lastName: string;
  email: string;
};

export class CancerQuoteApi {
  static getAll = async (params: GetAllCancerQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/cancer_quotes`, {
        params: snakes(params),
        paramsSerializer: (parameters) => stringify(parameters, { arrayFormat: 'brackets' }),
      })
      .then((response) => humps(response.data) as CancerQuote[]);
  };

  static get = async (params: GetCancerQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/cancer_quotes/find`, {
        params: snakes(params),
        paramsSerializer: (parameters) => stringify(parameters, { arrayFormat: 'brackets' }),
      })
      .then((response) => humps(response.data) as CancerQuote);
  };

  static create = async (params: CreateCancerQuoteParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/cancer_quotes`, {
        ...omitBy(snakes(params), (value) => isArray(value) && value.length === 0),
      })
      .then((response) => humps(response.data) as CancerQuote);
  };

  static edit = async ({ id }: EditCancerQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/cancer_quotes/${id}`)
      .then((response) => humps(response.data) as CancerQuote);
  };

  static update = async (params: UpdateCancerQuoteParams) => {
    const { id, ...updateParams } = params;

    return await authAxios
      .put(`${API_V1_BASE}/user/cancer_quotes/${id}`, {
        ...omitBy(snakes(updateParams), (value) => isArray(value) && value.length === 0),
      })
      .then((response) => humps(response.data) as CancerQuote);
  };

  static sendEmail = async (params: SendEmailCancerQuoteParams) => {
    return await authAxios.post(`${API_V1_BASE}/cancer_quotes/send_email`, snakes(params));
  };
}
