import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { selectHealthQuoteComparePage } from './selector';
import { TableRowHeader } from './table-row-header';
import { TableRowItem } from './table-row-item';

const TableGeographicCoverage = () => {
  const { t } = useTranslation();
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  if (!healthQuotes) return null;

  return (
    <>
      <TableRowHeader header={t('healthQuote.geographicCoverage')} />

      <TableRowItem>
        {map(healthQuotes, ({ healthPlanId, geographicCoverage }) => (
          <td key={healthPlanId}>{geographicCoverage}</td>
        ))}
      </TableRowItem>
    </>
  );
};

export { TableGeographicCoverage };
