import { ArticleApi, GetAllArticleParams } from '@apis/ArticleApi';
import { Article } from '@models/Article';
import { Pagination } from '@models/Pagination';
import { SagaPayload } from '@utils/typescript';
import { call, put, takeLatest } from 'redux-saga/effects';

import { articlesFetch, articlesSet } from './action';
import { ArticlesFetchPayload } from './type';

function* handleArticlesFetch({ payload }: SagaPayload<ArticlesFetchPayload>) {
  try {
    const params: GetAllArticleParams = payload;
    const [articles, pagination] = (yield call(ArticleApi.getAll, params)) as [Article[], Pagination];
    yield put(articlesSet({ articles, pagination }));
  } catch {
    yield put(articlesSet({ articles: [], pagination: null }));
  }
}

export default function* () {
  yield takeLatest(articlesFetch, handleArticlesFetch);
}
