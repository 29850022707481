import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isEmpty, map } from 'lodash';

import { CarQuote } from '@models/CarQuote';

const CarQuoteEndorsementCardBody = ({ policyWording, endorsements }: CarQuote) => {
  const { t } = useTranslation();
  const hasPolicyWording = !isEmpty(policyWording);
  const hasEndorsements = !isEmpty(endorsements);

  return (
    <Card.Body>
      <Card.Title>{t('carInsuranceQuote.learnMore')}</Card.Title>

      {hasEndorsements && (
        <ListGroup variant="flush" className="mb-3">
          {map(endorsements, (endorsement) => (
            <ListGroup.Item key={endorsement}>{endorsement}</ListGroup.Item>
          ))}
        </ListGroup>
      )}

      <Card.Subtitle className="my-3">{t('carInsuranceQuote.readDetailAttachment')}</Card.Subtitle>

      {hasPolicyWording && (
        <ListGroup variant="flush">
          <ListGroup.Item className="d-flex justify-content-lg-between flex-column flex-lg-row mb-3">
            <span>{policyWording.description}</span>
            <Card.Link href={policyWording.file} target="_blank" rel="noreferrer">
              {t('carInsuranceQuote.policyWording')}
            </Card.Link>
          </ListGroup.Item>
        </ListGroup>
      )}
    </Card.Body>
  );
};

export { CarQuoteEndorsementCardBody };
