import React from 'react';
import { Row, Col, Card, Accordion, Stack } from 'react-bootstrap';
import { map, slice } from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CancerQuoteGroup } from '@models/CancerQuoteGroup';
import { CancerQuote } from '@models/CancerQuote';

import { CancerQuoteItem } from './cancer-quote-item';

const CancerQuoteGroupItem = ({ insurer, cancerQuotes }: CancerQuoteGroup) => {
  const { t } = useTranslation();
  const mainQuote = cancerQuotes[0];
  const moreQuotes: CancerQuote[] = slice(cancerQuotes, 1);
  const shouldRenderMoreQuotes = moreQuotes.length > 0;

  return (
    <Card className="shadow">
      <Card.Body>
        <Row>
          <Col lg={2}>
            <Stack direction="horizontal" gap={2}>
              <Card.Img src={insurer.logo} className="rounded img-fluid" />
            </Stack>
          </Col>
          <Col lg={10}>
            <CancerQuoteItem cancerQuote={mainQuote} />
          </Col>
        </Row>
      </Card.Body>
      <Card.Body>
        {shouldRenderMoreQuotes && (
          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="w-25 mx-auto">
                {t('healthQuote.viewMore', { count: moreQuotes.length })}
              </Accordion.Header>
              <Accordion.Body className="mt-3 px-0">
                {map(moreQuotes, (quote, index) => {
                  const shouldRenderBorderBottom = moreQuotes.length > 1 && index !== moreQuotes.length - 1;

                  return (
                    <Row key={quote.cancerPlanId} className="mb-4">
                      <Col
                        lg={{ span: 10, offset: 2 }}
                        className={classNames('pb-4', { 'border-bottom': shouldRenderBorderBottom })}
                      >
                        <CancerQuoteItem cancerQuote={quote} />
                      </Col>
                    </Row>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </Card.Body>
    </Card>
  );
};

export { CancerQuoteGroupItem };
