import { replaceRouteParams } from '@utils/route';

export type CancerOrderPaymentPageParams = {
  cancerOrderId: number;
  billingId: number;
};

export const CANCER_ORDER_PAYMENT_PAGE_URL = '/cancer-insurance-quote-payment/:cancerOrderId/:billingId';

export const getCancerOrderPaymentPageRoute = (params: CancerOrderPaymentPageParams) => {
  return {
    pathname: replaceRouteParams(CANCER_ORDER_PAYMENT_PAGE_URL, params),
  };
};
