import React, { useMemo } from 'react';
import { Col, Container, Row, ListGroup } from 'react-bootstrap';
import { map, times } from 'lodash';
import { useTranslation } from 'react-i18next';

const HowToBuySection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(8, (n) => t(`carInsurancePage.howToBuySection.item${n + 1}`));
  }, []);

  return (
    <section className="py-5 bg-light">
      <Container>
        <h2 className="text-center">{t('carInsurancePage.howToBuySection.title')}</h2>
        <p className="text-center text-secondary w-75 mx-auto">{t('carInsurancePage.howToBuySection.subTitle')}</p>
        <Row className="mt-5">
          <Col lg={10} className="mx-auto">
            <ListGroup variant="flush">
              {map(items, (item, index) => (
                <ListGroup.Item key={item} className="position-relative p-3">
                  <span
                    className="position-absolute top-50 start-0 translate-middle-y btn btn-sm btn-primary rounded-pill pe-none ms-3"
                    style={{ width: '2rem', height: '2rem' }}
                  >
                    {++index}
                  </span>
                  <span className="d-block ms-5">{item}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { HowToBuySection };
