import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { FormGroupSelect } from '@components/Base';
import { Genders, tGender } from '@models/User';

const GenderFormSelect = (props: ComponentProps<typeof FormGroupSelect>) => {
  const { t } = useTranslation();

  return (
    <FormGroupSelect label={t('user.gender')} {...props}>
      <option></option>
      <option value={Genders.Female}>{tGender(Genders.Female)}</option>
      <option value={Genders.Male}>{tGender(Genders.Male)}</option>
    </FormGroupSelect>
  );
}

export { GenderFormSelect };
