import { put, call, takeLatest } from 'redux-saga/effects';

import { PersonalAccidentQuoteApi } from '@apis/PersonalAccidentQuoteApi';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { groupPersonalAccidentQuotes } from '@models/PersonalAccidentQuoteGroup';
import { SagaPayload } from '@utils/typescript';

import { personalAccidentQuotesFetch, stateUpdate, StateUpdatePayload } from './action';
import { PersonalAccidentQuotesFetchPayload } from './personalAccidentQuotesFetch';

function* handlePersonalAccidentQuotesFetch({ payload }: SagaPayload<PersonalAccidentQuotesFetchPayload>) {
  const state: StateUpdatePayload = { totalQuotes: null, personalAccidentQuoteGroups: null };

  try {
    const personalAccidentQuotes = (yield call(PersonalAccidentQuoteApi.getAll, payload)) as PersonalAccidentQuote[];
    state.totalQuotes = personalAccidentQuotes.length;
    state.personalAccidentQuoteGroups = groupPersonalAccidentQuotes(personalAccidentQuotes);
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(personalAccidentQuotesFetch, handlePersonalAccidentQuotesFetch);
}
