import { API_V1_BASE } from '@config';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';

export type CreateUserLeadParams = {
  mobileNumber: string;
  fullName: string;
  message: string;
};

export class UserLeadApi {
  static create = async (params: CreateUserLeadParams) => {
    return await authAxios.post(`${API_V1_BASE}/user_leads`, snakes(params));
  };
}
