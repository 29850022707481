import React from 'react';
import { useHref } from 'react-router-dom';
import { NavbarBrand } from 'react-bootstrap';
import logo from '@images/logo.png';

const HeaderNavbarBrand = () => {
  return (
    <NavbarBrand href={useHref('/')}>
      <img
        alt="MGA Cover Offcial Logo"
        src={logo as string}
        width="60"
        height="48"
        className="d-inline-block align-middle"
      />
      <span className="d-inline-block align-middle">MGA Cover</span>
    </NavbarBrand>
  );
}

export { HeaderNavbarBrand };
