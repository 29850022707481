import { call, put, takeLatest } from 'redux-saga/effects';
import { PersonalAccidentClaimApi } from '@apis/PersonalAccidentClaimApi';
import { PersonalAccidentClaim } from '@models/PersonalAccidentClaim';
import { personalAccidentClaimFetch, stateUpdate, StateUpdatePayload } from './action';
import { PersonalAccidentClaimFetchPayload } from './personalAccidentClaimFetch';
import { SagaPayload } from '@utils/typescript';

function* handlePersonalAccidentClaimFetch({ payload }: SagaPayload<PersonalAccidentClaimFetchPayload>) {
  const state: StateUpdatePayload = { personalAccidentClaim: null };
  try {
    state.personalAccidentClaim = (yield call(PersonalAccidentClaimApi.get, payload)) as PersonalAccidentClaim;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(personalAccidentClaimFetch, handlePersonalAccidentClaimFetch);
}
