import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { map, times } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import HealthQuoteForm from '@components/HealthQuoteForm';
import { BetaAlert } from '@components/Feature/beta-alert';

import personalAccidentInsuranceImg from './personal-accident-insurance-header.png';
import style from './style.module.scss';

export const HeaderSection = () => {
  const { t } = useTranslation();

  return (
    <section className={classNames(style.bg, 'py-5')}>
      <Container>
        <BetaAlert />
        <Row className="gy-4">
          <Col lg={5} xl={6} className="text-center">
            <Image
              src={personalAccidentInsuranceImg as string}
              fluid
              alt="MGA Cover personal accident insurance"
              className="w-50"
            />
            <h1 className="mt-5 mb-3">{t('personalAccidentInsurancePage.headerSection.title')}</h1>
            <p className="mb-0">{t('personalAccidentInsurancePage.headerSection.subTitle')}</p>
            <Row className="mt-5 align-items-center">
              {map(times(3), (idx) => (
                <Col key={`headerItem${idx}`} md={4} lg={12} xl={4}>
                  <div className="d-flex flex-row align-items-center justify-content-md-center justify-content-lg-start justify-content-xl-center fs-5 mb-2">
                    <FontAwesomeIcon icon={faCircleCheck} size="sm" className="me-3 text-primary" />
                    <span className="d-block">{t(`personalAccidentInsurancePage.headerSection.item${idx + 1}`)}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col lg={7} xl={6}>
            <HealthQuoteForm />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
