import { call, put, takeLatest } from 'redux-saga/effects';

import { HealthQuote } from '@models/HealthQuote';
import { HealthOrder } from '@models/HealthOrder';
import { HealthQuoteApi } from '@apis/HealthQuoteApi';
import { HealthOrderApi } from '@apis/HealthOrderApi';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';
import { getHealthOrderPaymentPageRoute } from '@components/HealthOrderPaymentPage/route';

import {
  healthQuoteFetch,
  HealthQuoteFetchPayload,
  healthQuoteCreate,
  HealthQuoteCreatePayload,
  healthQuoteEdit,
  HealthQuoteEditPayload,
  healthQuoteUpdate,
  HealthQuoteUpdatePayload,
  healthOrderCreate,
  HealthOrderCreatePayload,
  stateUpdate,
  StateUpdatePayload,
  healthQuoteCustom,
  HealthQuoteCustomPayload,
} from './action';
import { getHealthQuoteBuyReviewPageRoute } from './route';

function* handleHealthQuoteFetch({ payload }: SagaPayload<HealthQuoteFetchPayload>) {
  const state: StateUpdatePayload = { healthQuote: null };
  try {
    state.healthQuote = (yield call(HealthQuoteApi.get, payload)) as HealthQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleHealthQuoteCreate({ payload }: SagaPayload<HealthQuoteCreatePayload>) {
  const state: StateUpdatePayload = { healthQuote: null };
  try {
    state.healthQuote = (yield call(HealthQuoteApi.create, payload)) as HealthQuote;
    yield put(stateUpdate(state));
    history.navigate(getHealthQuoteBuyReviewPageRoute({ healthQuoteId: state.healthQuote.healthOrderId }));
  } catch {}
}

function* handleHealthQuoteEdit({ payload }: SagaPayload<HealthQuoteEditPayload>) {
  const state: StateUpdatePayload = { healthQuote: null };
  try {
    state.healthQuote = (yield call(HealthQuoteApi.edit, payload)) as HealthQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleHealthQuoteUpdate({ payload }: SagaPayload<HealthQuoteUpdatePayload>) {
  const state: StateUpdatePayload = { healthQuote: null };
  try {
    state.healthQuote = (yield call(HealthQuoteApi.update, payload)) as HealthQuote;
    yield put(stateUpdate(state));
    history.navigate(getHealthQuoteBuyReviewPageRoute({ healthQuoteId: state.healthQuote.healthOrderId }));
  } catch {}
}

function* handleHealthOrderCreate({ payload }: SagaPayload<HealthOrderCreatePayload>) {
  const state: StateUpdatePayload = { healthOrder: null };
  try {
    state.healthOrder = (yield call(HealthOrderApi.create, payload)) as HealthOrder;
    yield put(stateUpdate(state));
    history.navigate(
      getHealthOrderPaymentPageRoute({ healthOrderId: state.healthOrder.id, billingId: state.healthOrder.billingId }),
    );
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleHealthQuoteCustom({ payload }: SagaPayload<HealthQuoteCustomPayload>) {
  const state: StateUpdatePayload = { healthQuote: null };
  try {
    state.healthQuote = (yield call(HealthQuoteApi.custom, payload)) as HealthQuote;
    yield put(stateUpdate(state));
    history.navigate(getHealthQuoteBuyReviewPageRoute({ healthQuoteId: state.healthQuote.healthOrderId }));
  } catch {}
}

export default function* () {
  yield takeLatest(healthQuoteFetch, handleHealthQuoteFetch);
  yield takeLatest(healthQuoteCreate, handleHealthQuoteCreate);
  yield takeLatest(healthQuoteEdit, handleHealthQuoteEdit);
  yield takeLatest(healthQuoteUpdate, handleHealthQuoteUpdate);
  yield takeLatest(healthOrderCreate, handleHealthOrderCreate);
  yield takeLatest(healthQuoteCustom, handleHealthQuoteCustom);
}
