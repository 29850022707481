import React from 'react';
import { useField } from 'formik';
import { FormSelect, FormControl, FormSelectProps } from 'react-bootstrap';

type FormikSelectProps = FormSelectProps & {
  name: string;
  feedback?: string;
};

const FormikSelect = ({ name, feedback, ...props }: FormikSelectProps) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;
  const isInvalid = touched && !!error;
  const isValid = touched && !error;

  return (
    <>
      <FormSelect {...field} {...props} {...{ isValid, isInvalid }} />
      {feedback && <FormControl.Feedback type="valid">{feedback}</FormControl.Feedback>}
      {error && <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>}
    </>
  );
};

export { FormikSelect, FormikSelectProps };
