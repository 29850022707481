import { handleActions } from 'redux-actions';
import { reject } from 'lodash';

import { CancerQuote } from '@models/CancerQuote';

import { compareAdd, compareRemove, compareRemoveAll, stateReset, stateUpdate, State } from './action';
import { CompareAddPayload } from './compareAdd';
import { CompareRemovePayload } from './compareRemove';

const initialState: State = {
  totalQuotes: undefined,
  cancerQuoteGroups: undefined,
  comparableCancerQuotes: [],
};

const reducer = handleActions(
  {
    [String(compareAdd)]: (state, { payload }) => {
      const { cancerQuote } = payload as unknown as CompareAddPayload;

      return {
        ...state,
        comparableCancerQuotes: [...state.comparableCancerQuotes, cancerQuote] as CancerQuote[],
      };
    },

    [String(compareRemove)]: (state, { payload }) => {
      const { cancerQuote } = payload as unknown as CompareRemovePayload;

      return {
        ...state,
        comparableCancerQuotes: reject(state.comparableCancerQuotes, {
          cancerPlanId: cancerQuote.cancerPlanId,
        }),
      };
    },

    [String(compareRemoveAll)]: (state) => {
      return {
        ...state,
        comparableCancerQuotes: [],
      };
    },

    [String(stateUpdate)]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },

    [String(stateReset)]: () => {
      return initialState;
    },
  },

  initialState,
);

export default reducer;
