import { takeLatest, put, call } from 'redux-saga/effects';
import { getResponseError } from '@utils/error';
import { AxiosError } from 'axios';

import { UserProfile } from '@models/User';
import { AuthApi, VerifyWithPasswordAuthParams } from '@apis/AuthApi';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';
import { authAccessTokenSet } from '@components/Auth';
import i18n from '@components/i18n';
import { toastShow } from '@components/ToastProvider';

import { passwordVerify, passwordVerifyError } from './action';
import { AFTER_LOGIN_PATH } from '@config';

function* handlePasswordVerify({ payload }: SagaPayload<VerifyWithPasswordAuthParams>) {
  try {
    const { accessToken } = (yield call(AuthApi.verifyWithPassword, payload)) as UserProfile;
    yield put(authAccessTokenSet(accessToken));
    const from = history.location.state?.from;
    const path = from ? `${from.pathname}${from.search}` : AFTER_LOGIN_PATH;
    history.navigate(path);
    yield put(toastShow({ status: 'success', body: i18n.t('toast.signedIn') }));
  } catch (e) {
    const { error } = getResponseError(e as AxiosError);
    yield put(passwordVerifyError({ errors: { password: error } }));
  }
}

export default function* () {
  yield takeLatest(passwordVerify, handlePasswordVerify);
}
