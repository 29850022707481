import { stringify } from 'qs';
import { array, number, object } from 'yup';

import {
  CancerQuoteResultPageParams,
  cancerQuoteResultPageParamsSchema,
} from '@components/CancerQuoteResultPage/route';

export type CancerQuoteComparePageParams = Required<
Omit<CancerQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'>
> &
Pick<CancerQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'> & {
  cancerPlanIds: number[];
};

export const CANCER_QUOTE_COMPARE_PAGE_URL = '/cancer-insurance-quote-compare';

export const CancerQuoteComparePageParamsSchema = cancerQuoteResultPageParamsSchema.concat(
  object({
    cancerPlanIds: array().of(number()),
  }),
);

export const getCancerQuoteComparePageRoute = (params: CancerQuoteComparePageParams) => {
  return {
    pathname: CANCER_QUOTE_COMPARE_PAGE_URL,
    search: stringify(params),
  };
};
