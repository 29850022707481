import React from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface ConfirmModalProps extends ModalProps {
  onConfirm: () => void;
  title?: string;
  desc?: string;
  cancel?: string;
  confirm?: string;
};

const ConfirmModal = ({ onConfirm, title, desc, cancel, confirm, ...modalProps }: ConfirmModalProps) => {
  const { t } = useTranslation();
  const titleText = title ?? t('confirmModal.title');
  const descText = desc ?? t('confirmModal.desc');
  const cancelText = cancel ?? t('confirmModal.cancel');
  const confirmText = confirm ?? t('confirmModal.confirm');

  return (
    <Modal {...modalProps}>
      <Modal.Header closeButton>
        <Modal.Title>{titleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{descText}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={modalProps.onHide}>
          {cancelText}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ConfirmModal };
