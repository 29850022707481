import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row, Button, Stack } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import HealthQuotePremiumCardBody from '@components/HealthQuote/HealthQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';
import { FormGroupControl } from '@components/Base';
import { tOrdinalItem } from '@components/i18n';
import { useHealthQuoteEdit } from '../hook';
import { healthQuoteCustom, HealthQuoteCustomPayload } from '../action';
import { FormValues } from './type';
import { useInitialValues, useValidationSchema } from './hook';
import { getHealthQuoteBuyReviewPageRoute } from '../route';

const GROUP_PROPS = { className: 'mb-3' };

const HealthQuoteBuyCustomPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const healthQuote = useHealthQuoteEdit();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  if (!healthQuote) return null;

  const { healthOrderId } = healthQuote;

  const handleCancel = () => {
    if (!healthQuote) return null;
    navigate(getHealthQuoteBuyReviewPageRoute({ healthOrderId }), { replace: true });
  };

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: HealthQuoteCustomPayload = {
      ...values,
      id: healthOrderId,
    };

    dispatch(healthQuoteCustom(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          {initialValues && (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ isSubmitting, values }) => (
                <Form>
                  <Card>
                    <Card.Body>
                      <FormGroupControl
                        required
                        name="me.chargeAmount"
                        label={t('common:myself')}
                        type="number"
                        groupProps={GROUP_PROPS}
                      />

                      {!!values.spouse && (
                        <FormGroupControl
                          required
                          name="spouse.chargeAmount"
                          label={t('common:spouse')}
                          type="number"
                          groupProps={GROUP_PROPS}
                        />
                      )}

                      {!!values.sons &&
                        map(values.sons, (_, idx: number) => (
                          <FormGroupControl
                            required
                            key={idx}
                            name={`sons.${idx}.chargeAmount`}
                            label={tOrdinalItem(idx + 1, 'common:son')}
                            type="number"
                            groupProps={GROUP_PROPS}
                          />
                        ))}

                      {!!values.daughters &&
                        map(values.daughters, (_, idx: number) => (
                          <FormGroupControl
                            required
                            key={idx}
                            name={`daughters.${idx}.chargeAmount`}
                            label={tOrdinalItem(idx + 1, 'common:daughter')}
                            type="number"
                            groupProps={GROUP_PROPS}
                          />
                        ))}
                    </Card.Body>

                    <Card.Footer className="bg-white border-0 mx-auto my-4">
                      <Stack direction="horizontal" gap={3}>
                        <Button onClick={handleCancel} variant="outline-primary" size="lg">
                          {t('common:cancel')}
                        </Button>

                        <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                          {t('common:update')}
                        </Button>
                      </Stack>
                    </Card.Footer>
                  </Card>
                </Form>
              )}
            </Formik>
          )}
        </Col>

        <Col lg={4}>
          <Card className="mb-4">
            <HealthQuotePremiumCardBody {...healthQuote} />
          </Card>
          <SupportCard />
        </Col>
      </Row>
    </Container>
  );
};

export { HealthQuoteBuyCustomPage };
