import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormGroupControl } from '@components/Base';

const Insured = () => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{t('theInsured', { ns: 'common' })}</Card.Title>
      <Row>
        <Col>
          <FormGroupControl
            name="insuredPolicyHolder"
            label={t('theInsuredPolicyholder', { ns: 'common' })}
            required
            disabled
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="policyNumber"
            label={t('policyNumber', { ns: 'common' })}
            disabled
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
        <Col>
          <FormGroupControl
            name="carOrderId"
            label={t('orderNumber', { ns: 'common' })}
            required
            disabled
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="expiryDate"
            label={t('expiryDate', { ns: 'common' })}
            required
            disabled
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
    </Card.Body>
  );
};

export { Insured };
