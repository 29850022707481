import { createActions } from 'redux-actions';

import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { GetAllPersonalAccidentQuoteParams } from '@apis/PersonalAccidentQuoteApi';

export type State = {
  personalAccidentQuotes: PersonalAccidentQuote[] | null | undefined;
};

export type PersonalAccidentQuotesFetchPayload = Required<
Omit<GetAllPersonalAccidentQuoteParams, 'spouse' | 'sons' | 'daughters'>
> &
Pick<GetAllPersonalAccidentQuoteParams, 'spouse' | 'sons' | 'daughters'>;

export type PersonalAccidentQuotesFetchParams = PersonalAccidentQuotesFetchPayload;

export type StateUpdatePayload = Partial<State>;

export const {
  personalAccidentQuotesFetch,

  stateUpdate,
} = createActions(
  'PERSONAL_ACCIDENT_QUOTES_FETCH',

  'STATE_UPDATE',
  {
    prefix: 'PERSONAL_ACCIDENT_QUOTE_COMPARE_PAGE',
  },
);
