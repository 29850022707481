import React, { useMemo } from 'react';
import { map, times } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BenefitCard from '@components/Home/Benefit/BenefitCard';
import instantQuoteImg from './instant-quote.png';
import affordablePremiumImg from './affordable-premium.png';
import getCoveredImg from './get-covered.png';
import { Title } from '../title';

const imgPaths = [instantQuoteImg, affordablePremiumImg, getCoveredImg];

const Benefit = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(3, (n) => ({
      title: t(`benefit.title${n + 1}`),
      desc: t(`benefit.desc${n + 1}`),
      imgPath: imgPaths[n] as string,
    }));
  }, []);

  return (
    <section className="py-5">
      <Title title={t('benefit.header')}></Title>
      <Container>
        <Row className="mb-n3">
          {map(items, (item) => (
            <Col key={item.title} md={4} className="mb-3">
              <BenefitCard {...item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export { Benefit };
