import { call, put, takeLatest } from 'redux-saga/effects';
import { GetAllPhotoParams, UploadPhotosParams, DeletePhotoParams, PhotoApi } from '@apis/PhotoApi';
import { Photo } from '@models/Photo';
import {
  photoManagerDialogPhotosFetch,
  photoManagerDialogPhotosSet,
  photoManagerDialogPhotosUpload,
  photoManagerDialogPhotoDelete,
  photoManagerDialogRefresh,
} from './action';
import { SagaPayload } from '@utils/typescript';

function* handlePhotosFetch({ payload }: SagaPayload<GetAllPhotoParams>) {
  try {
    const photos = (yield call(PhotoApi.getAll, payload)) as Photo[];
    yield put(photoManagerDialogPhotosSet({ photos }));
  } catch {
    yield put(photoManagerDialogPhotosSet({ photos: [] }));
  }
}

function* handlePhotosUpload({ payload }: SagaPayload<UploadPhotosParams>) {
  try {
    yield call(PhotoApi.upload, payload);
    yield put(photoManagerDialogRefresh());
  } catch {}
}

function* handlePhotoDelete({ payload }: SagaPayload<DeletePhotoParams>) {
  try {
    yield call(PhotoApi.delete, payload);
    yield put(photoManagerDialogRefresh());
  } catch {}
}

export default function* () {
  yield takeLatest(photoManagerDialogPhotosFetch, handlePhotosFetch);
  yield takeLatest(photoManagerDialogPhotosUpload, handlePhotosUpload);
  yield takeLatest(photoManagerDialogPhotoDelete, handlePhotoDelete);
}
