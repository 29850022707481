import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { object, number, array } from 'yup';
import { pick } from 'lodash';

import { mergeInitialValues } from '@utils/formik';
import { getFieldKeys } from '@utils/yup';
import { selectHealthQuoteBuyRoute } from '../selector';
import { formValuesParamsSchema } from './route';

export const useInitialValues = () => {
  const { healthQuote } = useSelector(selectHealthQuoteBuyRoute);

  return useMemo(() => {
    if (!healthQuote) return null;

    const { me, spouse, sons, daughters } = pick(healthQuote.people, getFieldKeys(formValuesParamsSchema));
    const chargeAmount = (person: { chargeAmount: number }) => ({ chargeAmount: person.chargeAmount });

    const people = {
      me: me && { chargeAmount: me.chargeAmount },
      spouse: spouse ? chargeAmount(spouse) : null,
      sons: sons ? sons.map(chargeAmount) : [],
      daughters: daughters ? daughters.map(chargeAmount) : [],
    };

    return mergeInitialValues({}, people);
  }, [healthQuote]);
};

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    me: object({ chargeAmount: number().required(t('validation:required')) }),
    spouse: object({ chargeAmount: number().nullable(true) }),
    sons: array().of(object({ chargeAmount: number().required(t('validation:required')) })),
    daughters: array().of(object({ chargeAmount: number().required(t('validation:required')) })),
  });
};
