import { handleActions } from 'redux-actions';
import { userDocumentsSet } from './action';
import { UserDocument } from '@models/UserDocument';

export interface State {
  userDocuments?: UserDocument[];
}

const initialState: State = {};

const reducer = handleActions(
  {
    [String(userDocumentsSet)]: (state, { payload }) => {
      return {
        ...state,
        userDocuments: payload.userDocuments,
      };
    },
  },

  initialState,
);

export default reducer;
