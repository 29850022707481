import { createActions } from 'redux-actions';

import { GetCancerQuoteParams, SendEmailCancerQuoteParams } from '@apis/CancerQuoteApi';
import { CancerQuote } from '@models/CancerQuote';

export type State = {
  cancerQuote: CancerQuote | null | undefined;
};

export type CancerQuoteFetchPayload = GetCancerQuoteParams;
export type CancerQuoteSendEmailPayload = SendEmailCancerQuoteParams;

export type StateUpdatePayload = Partial<State>;

export const {
  cancerQuoteFetch,
  cancerQuoteSendEmail,

  stateUpdate,
} = createActions(
  'CANCER_QUOTE_FETCH',
  'CANCER_QUOTE_SEND_EMAIL',

  'STATE_UPDATE',
  {
    prefix: 'CANCER_QUOTE_DETAILS_PAGE',
  },
);
