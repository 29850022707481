import React from 'react';
import { map, startCase } from 'lodash';
import { useHref, useLocation } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';

import { useHeaderNavLinks } from './hook';

const HeaderNavLink = () => {
  const location = useLocation();
  const navLinks = useHeaderNavLinks();

  return (
    <>
      {map(navLinks, ({ label, href }) => (
        <NavLink key={label} eventKey={href} href={useHref(href)} active={location.pathname === href}>
          {startCase(label)}
        </NavLink>
      ))}
    </>
  );
};

export { HeaderNavLink };
