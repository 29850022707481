import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { toastShow } from '@components/ToastProvider';
import { Toast } from '@models/Toast';

type ShowParams = Pick<Toast, 'body' | 'status'>;
type ActionParams = Pick<Toast, 'body'>;

export const useToast = () => {
  const dispatch = useDispatch();

  return useMemo(() => {
    const show = ({ body, status }: ShowParams) => {
      dispatch(toastShow({ body, status }));
    };
    const success = ({ body }: ActionParams) => show({ body, status: 'success' });
    const warning = ({ body }: ActionParams) => show({ body, status: 'warning' });
    const danger = ({ body }: ActionParams) => show({ body, status: 'danger' });

    return { show, success, warning, danger };
  }, []);
};
