import { createActions } from 'redux-actions';

export const {
  accessTokenSet,

  currentUserLogout,
  currentUserFetch,
  currentUserSet,
  currentUserOtpCodeRequest,
} =
  createActions(
    'ACCESS_TOKEN_SET',

    'CURRENT_USER_LOGOUT',
    'CURRENT_USER_FETCH',
    'CURRENT_USER_SET',
    'CURRENT_USER_OTP_CODE_REQUEST',
    {
      prefix: 'AUTH',
    },
  );
