import { camelCase } from 'lodash';
import i18n from '@components/i18n';
import { PersonalAccidentPlanBenefit } from '@models/PersonalAccidentPlanBenefit';

export enum BenefitGroupTypes {
  BasicBenefits = 'basic_benefits',
  Extensions = 'extensions',
  Iema = 'iema',
}

export const tBenefitGroupType = (groupType: BenefitGroupTypes) => {
  return i18n.t(`personalAccidentPlanBenefitGroupTypes.${camelCase(groupType)}`);
};

export const tBenefitType = (type: string) => {
  return i18n.t(`personalAccidentPlanBenefit.${camelCase(type)}`);
};

export type BenefitGroupTypesKeys = keyof typeof BenefitGroupTypes;

export type PersonalAccidentPlanBenefitGroup = Record<BenefitGroupTypes, PersonalAccidentPlanBenefit[]>;
