import { call, put, takeLatest } from 'redux-saga/effects';

import { CancerOrderApi } from '@apis/CancerOrderApi';
import { BillingApi } from '@apis/BillingApi';
import { PaymentTransactionApi } from '@apis/PaymentTransactionApi';
import { CancerOrder } from '@models/CancerOrder';
import { Billing } from '@models/Billing';
import { PaymentTransaction } from '@models/PaymentTransaction';
import { SagaPayload } from '@utils/typescript';

import { cancerOrderFetch, paymentTransactionCreate, billingFetch, stateUpdate, StateUpdatePayload } from './action';
import { CancerOrderFetchPayload } from './cancerOrderFetch';
import { PaymentTransactionCreatePayload } from './paymentTransactionCreate';
import { BillingFetchPayload } from './billingFetch';

function* handleCancerOrderFetch({ payload }: SagaPayload<CancerOrderFetchPayload>) {
  const state: StateUpdatePayload = { cancerOrder: null };
  try {
    state.cancerOrder = (yield call(CancerOrderApi.get, payload)) as CancerOrder;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handlePaymentTransactionCreate({ payload }: SagaPayload<PaymentTransactionCreatePayload>) {
  const state: StateUpdatePayload = { paymentTransaction: null };
  try {
    state.paymentTransaction = (yield call(PaymentTransactionApi.create, payload)) as PaymentTransaction;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleBillingFetch({ payload }: SagaPayload<BillingFetchPayload>) {
  const state: StateUpdatePayload = { billing: null };
  try {
    state.billing = (yield call(BillingApi.get, payload)) as Billing;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(cancerOrderFetch, handleCancerOrderFetch);
  yield takeLatest(paymentTransactionCreate, handlePaymentTransactionCreate);
  yield takeLatest(billingFetch, handleBillingFetch);
}
