import { object, number } from 'yup';
import { stringify } from 'qs';

import { healthQuoteResultPageParamsSchema, HealthQuoteResultPageParams } from '@components/HealthQuoteResultPage/route';

export type HealthQuoteDetailsPageParams = Required<Omit<HealthQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'>> &
  Pick<HealthQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'> & {
    healthPlanId: number;
  };

export type HealthQuoteSendEmailPageParams = HealthQuoteDetailsPageParams;

export const healthQuoteDetailsPageParamsSchema = healthQuoteResultPageParamsSchema.concat(
  object({
    healthPlanId: number(),
  }),
);

export const healthQuoteSendEmailPageParamsSchema = healthQuoteDetailsPageParamsSchema;

export const HEALTH_QUOTE_DETAILS_ROUTE_URL = '/health-insurance-quote-details/*';

export const HEALTH_QUOTE_DETAILS_PAGE_URL = '/health-insurance-quote-details';

export const HEALTH_QUOTE_SEND_MAIL_PAGE = '/health-insurance-quote-details/send-email';

export const getHealthQuoteDetailsPageRoute = (params: HealthQuoteDetailsPageParams) => {
  return {
    pathname: HEALTH_QUOTE_DETAILS_PAGE_URL,
    search: stringify(params),
  };
}

export const getHealthQuoteSendEmailPageRoute = (params: HealthQuoteSendEmailPageParams) => {
  return {
    pathname: HEALTH_QUOTE_SEND_MAIL_PAGE,
    search: stringify(params),
  };
}
