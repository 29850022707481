import humps from 'lodash-humps-ts';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { Order, OrderableTypes } from '@models/Order';
import { API_V1_BASE } from '@config';
import { paginate } from '@utils/pagination';
import { Pagination, PaginationParams } from '@models/Pagination';

export interface GetAllOrderParams extends PaginationParams {
  status?: string;
}

export interface GetOrderParams {
  orderableId: number;
  orderableType: OrderableTypes;
}

export class OrderApi {
  static getAll = async (params = {} as GetAllOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/orders`, { params: snakes(params) })
      .then((response) => paginate(response) as [Order[], Pagination]);
  };

  static get = async ({ orderableId, orderableType }: GetOrderParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/orders/${orderableType}/${orderableId}`)
      .then((response) => humps(response.data) as Order);
  };
}
