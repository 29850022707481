import React, { useEffect, useCallback } from 'react';
import { Card, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BackButton, RowItem } from '@components/Base';
import { DATETIME_FORMAT, formatDateTime } from '@utils/datetime';
import { amountToUsd, amountToKhr } from '@utils/currency';
import { useParamsBillingId } from '@hooks/params';

import { billingFetch, BillingFetchPayload } from './action';
import { selectBillingDetails } from './selector';

const BillingDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const billingId = useParamsBillingId();
  const { billing } = useSelector(selectBillingDetails);

  useEffect(() => {
    const payload: BillingFetchPayload = { id: billingId };
    dispatch(billingFetch(payload));
  }, [billingId]);

  const handleClick = useCallback(() => {
    navigate('/user-dashboard/billing');
  }, []);

  return (
    <>
      <BackButton onClick={handleClick} className="mb-4" />
      <Card>
        <Card.Header>{t('common:details')}</Card.Header>
        {billing && (
          <Card.Body>
            <RowItem label={t('common:status')} value={billing.status} className="mb-2" />
            <RowItem label={t('common:orderNumber')} value={billing.orderNumber} className="mb-2" />
            <RowItem label={t('common:insurer')} value={billing.insurerName} className="mb-2" />
            {billing.status === 'paid' && (
              <RowItem
                label={t('common:paidDate')}
                value={formatDateTime(billing.updatedAt, DATETIME_FORMAT.LONG)}
              ></RowItem>
            )}
          </Card.Body>
        )}
        {billing && (
          <Card.Body>
            <Alert variant="secondary">
              <RowItem label="Total Amount (USD)" value={amountToUsd(billing.chargeAmount)} className="mb-2" />
              <RowItem label="Total Amount (KHR)" value={amountToKhr(billing.chargeAmountKhr)} className="mb-2" />
            </Alert>
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export { BillingDetails };
