import { createActions } from 'redux-actions';

import { GetHealthQuoteParams, SendEmailHealthQuoteParams } from '@apis/HealthQuoteApi';
import { HealthQuote } from '@models/HealthQuote';

export type State = {
  healthQuote: HealthQuote | null | undefined;
};

export type HealthQuoteFetchPayload = GetHealthQuoteParams;
export type HealthQuoteSendEmailPayload = SendEmailHealthQuoteParams;

export type StateUpdatePayload = Partial<State>;

export const {
  healthQuoteFetch,
  healthQuoteSendEmail,

  stateUpdate,
} = createActions(
  'HEALTH_QUOTE_FETCH',
  'HEALTH_QUOTE_SEND_EMAIL',

  'STATE_UPDATE',
  {
    prefix: 'HEALTH_QUOTE_DETAILS_PAGE',
  },
);
