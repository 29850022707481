import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row, Button, Stack } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import CarQuotePremiumCardBody from '@components/Feature/CarQuote/CarQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';
import { FormGroupControl, FormGroupSelect } from '@components/Base';
import { CarPlanTypes } from '@models/CarPlanType';

import { useCarQuoteEdit } from '../hook';
import { carQuoteCustom, CarQuoteCustomPayload } from '../action';
import { FormValues } from './type';
import { useInitialValues, useValidationSchema } from './hook';
import { getCarQuoteBuyReviewPageRoute } from '../route';

const GROUP_PROPS = { className: 'mb-3' };

const AmountControl = ({ name, title, disabled }: { name: string; title: string; disabled?: boolean }) => {
  return (
    <fieldset className="mb-4 pb-2 border-bottom">
      <legend className="h6">{title}</legend>

      <Row>
        <Col>
          <FormGroupControl
            name={`${name}Amount`}
            label="Premium"
            required
            groupProps={GROUP_PROPS}
            type="number"
            disabled={disabled}
          />
        </Col>
        <Col>
          <FormGroupControl
            name={`${name}DiscountAmount`}
            label="Discount"
            required
            groupProps={GROUP_PROPS}
            type="number"
            disabled={disabled}
          />
        </Col>
      </Row>
    </fieldset>
  );
};

const CarQuoteBuyCustomPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carQuote = useCarQuoteEdit();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  if (!carQuote) return null;

  const { planType, accidentToDriver, passengerLiability, carOrderId } = carQuote;

  const handleCancel = () => {
    navigate(getCarQuoteBuyReviewPageRoute({ carOrderId }), { replace: true });
  };

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuoteCustomPayload = {
      ...values,
      carOrderId,
    };
    dispatch(carQuoteCustom(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          {initialValues && (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ isSubmitting, values }) => (
                <Form>
                  <Card>
                    <Card.Body>
                      <FormGroupSelect name="customType" label="Custom type" required groupProps={GROUP_PROPS}>
                        <option value="no_custom">No Custom</option>
                        <option value="custom">Custom</option>
                        <option value="lump_sum">Lump sum</option>
                      </FormGroupSelect>

                      <AmountControl
                        name="thirdParty"
                        title={t('common:tpl')}
                        disabled={values.customType !== 'custom'}
                      />

                      {planType === CarPlanTypes.Comprehensive && (
                        <AmountControl
                          name="ownDamage"
                          title={t('common:od')}
                          disabled={values.customType !== 'custom'}
                        />
                      )}

                      <AmountControl
                        name="thirdPartyDeductible"
                        title="Third-party deductible"
                        disabled={values.customType === 'no_custom'}
                      />

                      {planType === CarPlanTypes.Comprehensive && (
                        <>
                          <AmountControl
                            name="ownDamageDeductible"
                            title="Own damage deductible"
                            disabled={values.customType === 'no_custom'}
                          />
                          <AmountControl
                            name="theftDeductible"
                            title="Theft deductible"
                            disabled={values.customType === 'no_custom'}
                          />
                        </>
                      )}

                      {accidentToDriver && (
                        <AmountControl
                          name="accidentToDriver"
                          title={t('common:ad')}
                          disabled={values.customType !== 'custom'}
                        />
                      )}

                      {passengerLiability && (
                        <AmountControl
                          name="passengerLiability"
                          title={t('common:pl')}
                          disabled={values.customType !== 'custom'}
                        />
                      )}

                      {planType === CarPlanTypes.Comprehensive && (
                        <FormGroupControl
                          name="idvAmount"
                          label="IDV Amount"
                          required
                          groupProps={GROUP_PROPS}
                          type="number"
                          disabled={values.customType === 'no_custom'}
                        />
                      )}

                      <FormGroupControl
                        name="premium"
                        label="Premium"
                        required
                        groupProps={GROUP_PROPS}
                        type="number"
                        disabled={values.customType !== 'lump_sum'}
                      />

                      <FormGroupControl
                        name="adminFeeAmount"
                        label="Admin fee"
                        required
                        groupProps={GROUP_PROPS}
                        type="number"
                        disabled={values.customType === 'no_custom'}
                      />

                      <FormGroupControl
                        required
                        name="noClaimDiscountPercentage"
                        label="No claim discount (%)"
                        groupProps={GROUP_PROPS}
                        type="number"
                      />

                      <FormGroupControl
                        required
                        name="loyaltyDiscountPercentage"
                        label="Loyalty discount (%)"
                        groupProps={GROUP_PROPS}
                        type="number"
                      />

                      <FormGroupControl name="proof" type="file" label={t('common:proof')} groupProps={GROUP_PROPS} />

                      <FormGroupControl name="note" label="Note" groupProps={GROUP_PROPS} as="textarea" rows={4} />
                    </Card.Body>

                    <Card.Footer className="bg-white border-0 mx-auto my-4">
                      <Stack direction="horizontal" gap={3}>
                        <Button onClick={handleCancel} variant="outline-primary" size="lg">
                          {t('common:cancel')}
                        </Button>

                        <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                          {t('common:update')}
                        </Button>
                      </Stack>
                    </Card.Footer>
                  </Card>
                </Form>
              )}
            </Formik>
          )}
        </Col>

        <Col lg={4}>
          <Card className="mb-4">
            <CarQuotePremiumCardBody {...carQuote} />
          </Card>
          <SupportCard />
        </Col>
      </Row>
    </Container>
  );
};

export { CarQuoteBuyCustomPage };
