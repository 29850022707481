import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { camelCase, map } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { CarQuote } from '@models/CarQuote';
import { CarPlanTypes, tCarPlanType } from '@models/CarPlanType';

type Props = Pick<CarQuote, 'planType' | 'addonCovers' | 'thirdPartyCovers' | 'comprehensiveCovers'>;

const CarQuoteCoverCardBody = ({ planType, addonCovers, thirdPartyCovers, comprehensiveCovers }: Props) => {
  const { t } = useTranslation();
  const hasAddonCovers = addonCovers.length > 0;

  return (
    <Card.Body>
      <Card.Title>
        {t('carInsuranceQuote.coversOn', {
          planTypeName: tCarPlanType(planType),
        })}
      </Card.Title>

      {planType === CarPlanTypes.Comprehensive && (
        <>
          <ListGroup variant="flush">
            {map(comprehensiveCovers, ({ coverageType, description }) => (
              <ListGroup.Item key={coverageType}>
                <FontAwesomeIcon icon={faAngleRight} size="xs" className="me-3" />
                {t(`carInsuranceQuote.${camelCase(coverageType)}`)}: {description}
              </ListGroup.Item>
            ))}
          </ListGroup>

          <Card.Subtitle className="mt-3">
            <FontAwesomeIcon icon={faCircleCheck} size="sm" className="me-3" color="#2962FF" />
            {tCarPlanType(CarPlanTypes.ThirdParty)}
          </Card.Subtitle>
        </>
      )}

      <ListGroup variant="flush">
        {map(thirdPartyCovers, ({ coverageType, description }) => (
          <ListGroup.Item key={coverageType}>
            <FontAwesomeIcon icon={faAngleRight} size="xs" className="me-3" />
            {t(`carInsuranceQuote.${camelCase(coverageType)}`)}: {description}
          </ListGroup.Item>
        ))}
      </ListGroup>

      {hasAddonCovers && (
        <>
          <Card.Subtitle className="mt-3 fw-bold">
            <FontAwesomeIcon icon={faCircleCheck} size="sm" className="me-3" color="#2962FF" />
            {t('carInsuranceQuote.addons')}
          </Card.Subtitle>

          <ListGroup variant="flush">
            {map(addonCovers, ({ coverageType, description }) => (
              <ListGroup.Item key={description}>
                <div className="d-flex flex-column flex-lg-row">
                  <span className="pt-2 text-nowrap">
                    <FontAwesomeIcon icon={faAngleRight} size="xs" className="me-3" />
                    {t(`carInsuranceQuote.${camelCase(coverageType)}.name`)}:&nbsp;
                  </span>
                  <div className="flex-grow-1">
                    <ListGroup variant="flush">
                      <ListGroup.Item className="ps-0">{description}</ListGroup.Item>
                    </ListGroup>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </>
      )}
    </Card.Body>
  );
};

export { CarQuoteCoverCardBody };
