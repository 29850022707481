import React, { forwardRef, Ref } from 'react';
import { FormGroup, FormLabel, FormText } from 'react-bootstrap';
import { FormGroupBaseProps, useControlId, useLabel } from '../FormGroupBase';
import { FormikTypeahead, FormikTypeaheadProps, RefFormikTypeahead } from '../';

type FormGroupTypeaheadProps = FormGroupBaseProps & FormikTypeaheadProps;

// eslint-disable-next-line react/display-name
const FormGroupTypeahead = forwardRef(
  (
    { label, help, labelProps, helpProps, groupProps, ...props }: FormGroupTypeaheadProps,
    ref: Ref<RefFormikTypeahead>,
  ) => {
    const { name, required } = props;
    const controlId = useControlId(name);
    const labelText = useLabel(label, required);

    return (
      <FormGroup {...groupProps} controlId={controlId}>
        {labelText && <FormLabel {...labelProps}>{labelText}</FormLabel>}
        <FormikTypeahead {...props} ref={ref} />
        {help && (
          <FormText muted {...helpProps}>
            {help}
          </FormText>
        )}
      </FormGroup>
    );
  },
);

export { FormGroupTypeahead, FormGroupTypeaheadProps };
