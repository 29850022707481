import React, { useCallback } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Loading, NoData, BackButton } from '@components/Base';
import SupportSection from '@components/SupportSection';
import { useHealthQuoteResultPageParams } from '@components/HealthQuoteResultPage';
import { getHealthQuoteResultPageRoute } from '@components/HealthQuoteResultPage/route';

import HeaderSection from '../HeaderSection';
import { FeatureSection } from './feature-section';
import { BenefitSection } from './benefit-section';
import { HospitalSection } from './hospital-section';
import { MemberSection } from './member-section';
import { useHealthQuoteFetch } from '../hook';

const HealthQuoteDetailsPage = () => {
  const navigate = useNavigate();
  const params = useHealthQuoteResultPageParams();
  const healthQuote = useHealthQuoteFetch();
  const isLoaded = !isUndefined(healthQuote);
  const hasHealthQuote = isLoaded && !isEmpty(healthQuote);

  const handleBackClick = useCallback(() => {
    navigate(getHealthQuoteResultPageRoute(params), { replace: true });
  }, []);

  return (
    <>
      {!isLoaded && <Loading />}
      {isLoaded && !hasHealthQuote && <NoData />}
      {hasHealthQuote && (
        <>
          <Container className="mt-4">
            <BackButton onClick={handleBackClick} className="mb-4" />
          </Container>

          <HeaderSection {...healthQuote} />
          <FeatureSection {...healthQuote} />
          <BenefitSection {...healthQuote} />
          <HospitalSection {...healthQuote} />
          <MemberSection />
        </>
      )}

      <SupportSection />
    </>
  );
};

export { HealthQuoteDetailsPage };
