import humps from 'lodash-humps-ts';
import { AxiosResponse } from 'axios';
import { toNumber } from 'lodash';
import { Pagination } from '@models/Pagination';

/* eslint-disable */
export const paginate = (response: AxiosResponse) => {
  const { xNextPage, xPrevPage, xPerPage, xPage, xOffset, xTotal, xTotalPages } = humps(response.headers);
  const pagination: Pagination = {
    nextPage: toNumber(xNextPage),
    prevPage: toNumber(xPrevPage),
    perPage: toNumber(xPerPage),
    page: toNumber(xPage),
    offset: toNumber(xOffset),
    total: toNumber(xTotal),
    totalPages: toNumber(xTotalPages),
  };
  const data = humps(response.data);

  return [data, pagination];
};
/* eslint-enable */

export const isPaginated = (pagination?: Pagination | null) => (pagination?.totalPages as number) > 1;
