import { handleActions } from 'redux-actions';
import { reject } from 'lodash';

import {
  compareAdd,
  compareRemove,
  compareRemoveAll,
  CompareAddPayload,
  CompareRemovePayload,
  stateUpdate,
  State,
} from './action';

const initialState: State = {
  totalQuotes: undefined,
  personalAccidentQuoteGroups: undefined,
  comparablePersonalAccidentQuotes: [],
};

const reducer = handleActions(
  {
    [String(compareAdd)]: (state, { payload }) => {
      const { personalAccidentQuote } = payload as unknown as CompareAddPayload;

      return {
        ...state,
        comparablePersonalAccidentQuotes: [...state.comparablePersonalAccidentQuotes, personalAccidentQuote],
      };
    },

    [String(compareRemove)]: (state, { payload }) => {
      const { personalAccidentQuote } = payload as unknown as CompareRemovePayload;

      return {
        ...state,
        comparablePersonalAccidentQuotes: reject(state.comparablePersonalAccidentQuotes, personalAccidentQuote),
      };
    },

    [String(compareRemoveAll)]: (state) => {
      return {
        ...state,
        comparablePersonalAccidentQuotes: [],
      };
    },

    [String(stateUpdate)]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },

  initialState,
);

export default reducer;
