import { handleActions } from 'redux-actions';
import {
  stateUpdate,
  State,
} from './action';

const initialState: State = {
  carQuotes: [],
};

const reducer = handleActions(
  {
    [String(stateUpdate)]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },

  initialState,
);

export default reducer;
