import { createActions } from 'redux-actions';

import { Billing } from '@models/Billing';
import { GetBillingParams } from '@apis/BillingApi';

export type State = {
  billing: Billing | undefined | null;
};

export type BillingFetchPayload = GetBillingParams;

export type StateUpdatePayload = Partial<State>;

export const {
  billingFetch,

  stateUpdate,
} = createActions(
  'BILLING_FETCH',

  'STATE_UPDATE',
  {
    prefix: 'USER_DASHBOARD_BILLING_DETAILS_',
  },
);
