import { useLocation, matchPath } from 'react-router-dom';

import i18n from '@components/i18n';
import { getHealthQuoteResultPageRoute, HealthQuoteResultPageParams, healthQuoteResultPageParamsSchema } from './route';
import { useParseParams } from '@hooks/search-params';
import { useParseSearchParams } from '@hooks/search-params';
import { getPersonalAccidentQuoteResultPageRoute } from '@components/PersonalAccidentQuoteResultPage/route';
import { getCancerQuoteResultPageRoute } from '@components/CancerQuoteResultPage/route';

export const useHealthQuoteResultPageParams = () =>
  useParseParams<Required<HealthQuoteResultPageParams>>(healthQuoteResultPageParamsSchema);

type InsurancePage = {
  current: { name: string; href: string; homePath: string };
  others?: { name: string; href: string; homePath: string }[];
};

export const useInsurancePage = (): InsurancePage => {
  const { pathname } = useLocation();
  const search = useParseSearchParams<HealthQuoteResultPageParams>();

  const toPath = (path: { pathname: string; search: string }) => `${path.pathname}?${path.search}`;

  const healthPage = {
    name: i18n.t('common:healthInsurance'),
    href: toPath(getHealthQuoteResultPageRoute(search)),
    homePath: '/health-insurance/members',
  };
  const personalAccidentPage = {
    name: i18n.t('common:personalAccidentInsurance'),
    href: toPath(getPersonalAccidentQuoteResultPageRoute(search)),
    homePath: '/personal-accident-insurance/members',
  };
  const cancerPage = {
    name: i18n.t('common:cancerInsurance'),
    href: toPath(getCancerQuoteResultPageRoute(search)),
    homePath: '/cancer-insurance/members',
  };

  if (matchPath('/health-insurance-quote-results/*', pathname)) {
    return { current: healthPage, others: [personalAccidentPage, cancerPage] };
  } else if (matchPath('/personal-accident-insurance-quote-results/*', pathname)) {
    return { current: personalAccidentPage, others: [healthPage, cancerPage] };
  } else if (matchPath('/cancer-insurance-quote-results/*', pathname)) {
    return { current: cancerPage, others: [healthPage, personalAccidentPage] };
  } else {
    return { current: { name: '', href: '', homePath: '' }, others: [] };
  }
};
