import React from 'react';
import { Card } from 'react-bootstrap';
import { BackButton } from '@components/Base';

interface Props {
  onClick?: () => void;
  title: string;
}

const CardHeader = ({ title, onClick }: Props) => {
  const shouldRenderBackButton = !!onClick;

  return (
    <Card.Header className="bg-white d-flex flex-row align-items-center" style={{ minHeight: '65px' }}>
      {shouldRenderBackButton && <BackButton className="me-3" onClick={onClick} />}
      <h5 className="m-0">{title}</h5>
    </Card.Header>
  );
};

export { CardHeader };
