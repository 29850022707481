import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isUndefined, isNull } from 'lodash';

import { useCurrentUser } from '@components/Auth';
import { Loading, LoadingError } from '@components/Base';
import { SupportCard } from '@components/Feature/Support/support-card';
import CancerQuotePremiumCardBody from '@components/Feature/CancerQuote/CancerQuotePremiumCardBody';

import CancerQuoteBuyForm from '../CancerQuoteBuyForm';
import { FormValues } from '../CancerQuoteBuyForm/schema';

import { cancerQuoteCreate } from '../action';
import { CancerQuoteCreatePayload } from '../cancerQuoteCreate';

import { useCancerQuote } from '../hook';

import { useInitialValues } from './hook';

const CancerQuoteBuyNewPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cancerQuote = useCancerQuote();
  useCurrentUser();

  const isLoading = isUndefined(cancerQuote);
  const isError = isNull(cancerQuote);

  const initialValues = useInitialValues();

  const handleSubmit = (values: FormValues) => {
    if (cancerQuote) {
      const payload: CancerQuoteCreatePayload = {
        ...values,
        cancerPlanId: cancerQuote.cancerPlanId,
      };

      dispatch(cancerQuoteCreate(payload));
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {isError && <LoadingError />}

      {cancerQuote && (
        <Container className="my-5">
          <Row>
            <h2>{t('cancerQuoteBuyPage.title')}</h2>
            <p>{t('cancerQuoteBuyPage.subTitle')}</p>
            <Col lg={7}>
              {initialValues && <CancerQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} />}
            </Col>
            <Col lg={5}>
              <Card className="mb-4">
                <CancerQuotePremiumCardBody {...cancerQuote} />
              </Card>
              <SupportCard />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export { CancerQuoteBuyNewPage };
