import React, { useState } from 'react';
import { toNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Modal, ListGroup, Form, FormCheck, Button, ModalProps, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import { amountToUsd } from '@utils/currency';

type Props = Pick<ModalProps, 'show' | 'onHide'> & {
  lowestAmount: number;
  highestAmount: number;
  onConfirm: (idvAmount: number) => void;
};

type Focus = 'customAmount' | 'insurerCarPrice';

const InsuredDeclaredValueModal = ({ show, lowestAmount, highestAmount, onHide, onConfirm }: Props) => {
  const { t } = useTranslation();
  const [idvAmount, setIdvAmount] = useState(0);
  const [focus, setFocus] = useState<Focus>('insurerCarPrice');
  const isValidIdvAmount = (focus === 'insurerCarPrice') || (focus === 'customAmount' && idvAmount > lowestAmount);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFocus(e.target.name as Focus);
    setIdvAmount(toNumber(e.target.value));
  };

  const handleConfirm = () => {
    onConfirm(idvAmount);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t('carInsuranceQuote.insuredDeclaredValue.modalTitle')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ListGroup variant="flush">
          <ListGroup.Item className="d-flex align-items-start px-0 border-0">
            <FormCheck
              type="radio"
              id="insurerCarPrice"
              name={'insurerCarPrice'}
              value="insurerCarPrice"
              checked={focus === 'insurerCarPrice'}
              onChange={handleChange}
              label={t('carInsuranceQuote.insuredDeclaredValue.insurerCarPrice')}
            />
          </ListGroup.Item>

          <ListGroup.Item className="d-flex align-items-start px-0 border-0">
            <FormCheck
              type="radio"
              id="customAmount"
              name={'customAmount'}
              value={0.0}
              checked={focus === 'customAmount'}
              onChange={handleChange}
            />
            <div className="w-100">
              <FormCheck.Label htmlFor="customAmount" className="ms-2">
                {t('carInsuranceQuote.insuredDeclaredValue.customAmount')}
              </FormCheck.Label>
              <Form.Control
                type="number"
                name="customCurrentIdv"
                value={focus === 'customAmount' && idvAmount > 0 ? idvAmount : ''}
                disabled={focus != 'customAmount'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setIdvAmount(toNumber(e.target.value));
                }}
              />
              <Form.Text className="text-muted">
                {t('carInsuranceQuote.insuredDeclaredValue.note', {
                  lowestAmount: amountToUsd(lowestAmount),
                  highestAmount: amountToUsd(highestAmount),
                })}
              </Form.Text>
              {' '}
              <Form.Text>{t('carInsuranceQuote.insuredDeclaredValue.noteWithoutRecommendedAmount')}</Form.Text>
            </div>
          </ListGroup.Item>
        </ListGroup>

        {!isValidIdvAmount && (
          <>
            <br />
            <Alert variant="warning">
              <Alert.Heading>
                <FontAwesomeIcon icon={faCircleExclamation} />{' '}
                {t('carInsuranceQuote.insuredDeclaredValue.emptyIdvWarningHeader')}
              </Alert.Heading>
              <p>{t('carInsuranceQuote.insuredDeclaredValue.emptyIdvWarningDesc')}</p>
            </Alert>
          </>
        )}

        <br />
        <Alert variant="secondary">
          <span className="d-block mb-3">{t('carInsuranceQuote.insuredDeclaredValue.whatIdv')}</span>
          <p>{t('carInsuranceQuote.insuredDeclaredValue.description')}</p>
        </Alert>
      </Modal.Body>

      <Modal.Footer>
        <div className="mt-2 text-end">
          <Button variant="primary" disabled={!isValidIdvAmount} onClick={handleConfirm}>
            {t('common:update')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export { InsuredDeclaredValueModal };
