import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import HeaderSecondary from '@components/HeaderSecondary';

import HealthQuoteDetailsPage from './HealthQuoteDetailsPage';
import HealthQuoteSendEmailPage from './HealthQuoteSendEmailPage';

const HealthQuoteDetailsRoute = () => {
  return (
    <>
      <HeaderSecondary />
      <Routes>
        <Route index element={<HealthQuoteDetailsPage />} />
        <Route path="send-email" element={<HealthQuoteSendEmailPage />} />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </>
  );
};

export { HealthQuoteDetailsRoute };
