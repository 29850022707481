import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { map, times } from 'lodash';
import { useTranslation } from 'react-i18next';

const NoCoverSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5 bg-light">
      <h2 className="text-center">{t('healthInsurancePage.noCoverSection.title')}</h2>
      <p className="text-center text-secondary w-75 mx-auto">{t('healthInsurancePage.noCoverSection.subTitle')}</p>
      <Container className="my-5">
        <Row className="gy-4">
          {map(times(6), (idx) => (
            <Col key={`noCoverItem${idx + 1}`} md={6} lg={4}>
              <Card className="h-100 text-md-center text-lg-start">
                <Card.Body>
                  <Card.Title className="text-center">
                    <h4 className="fw-bold">{t(`healthInsurancePage.noCoverSection.item${idx + 1}.label`)}</h4>
                  </Card.Title>
                  <Card.Text>{t(`healthInsurancePage.noCoverSection.item${idx + 1}.desc`)}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <p className="text-center text-secondary w-75 mx-auto">{t('healthInsurancePage.noCoverSection.notice')}</p>
    </section>
  );
};

export { NoCoverSection };
