import { isBoolean } from 'lodash';
import React, { ReactElement } from 'react';
import { Col, Row } from 'react-bootstrap';

interface Props {
  label: string;
  value?: string | number | ReactElement | boolean;
}

const RowItem = ({ label, value }: Props) => {
  const displayedValue = isBoolean(value) ? value.toString() : value;

  return (
    <Row className="mb-2">
      <Col xs={7} sm={7} md={4}>
        {label}
      </Col>
      <Col xs="auto">:</Col>
      <Col>{displayedValue}</Col>
    </Row>
  );
};

export { RowItem };
