import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { forEach, union } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import { BackButton, FormGroupControl } from '@components/Base';
import { selectCurrentUser } from '@components/Auth';
import i18n from '@components/i18n';

import {
  Insured,
  VehicleInsured,
  PassengersThirdParties,
  CompletedClaimForm,
  SupportingDocuments,
  AccidentDetails,
} from './Forms';
import { FormValues } from './type';
import { policyFetch, carClaimCreate } from './action';
import { selectCarPolicyClaim } from './selector';

const MAX_PHOTOS = 5;
const FILE_SIZE = 5000000; //5MB
const PHOTO_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const MAX_UPLOADED_FILES = 5;

const validationSchema = Yup.object({
  insuredPolicyHolder: Yup.string().required(i18n.t('validation:required')),
  carOrderId: Yup.string().required(i18n.t('validation:required')),
  expiryDate: Yup.string().required(i18n.t('validation:required')),
  vehicleMake: Yup.string().required(i18n.t('validation:required')),
  vehicleModel: Yup.string().required(i18n.t('validation:required')),
  vehicleYearOfManufacture: Yup.string().required(i18n.t('validation:required')),
  vehicleEngineNumber: Yup.string().required(i18n.t('validation:required')),
  vehicleChassisNumber: Yup.string().required(i18n.t('validation:required')),
  vehicleRegistrationNumber: Yup.string().required(i18n.t('validation:required')),
  accidentHappenedAt: Yup.date().required(i18n.t('validation:required')),
  accidentPhotos: Yup.mixed()
    .test('required', i18n.t('validation:required'), (files: File[]) => files.length > 0)
    .test(
      'max',
      i18n.t('limitOutOfRange', { ns: 'validation', max: MAX_PHOTOS }),
      (files: File[]) => files.length <= MAX_PHOTOS,
    )
    .test('fileFormat', i18n.t('validation:invalidFormat'), (files: File[]) => {
      let isValid = false;
      forEach(files, (file) => {
        if (PHOTO_SUPPORTED_FORMATS.includes(file.type)) isValid = true;
      });
      return isValid;
    })
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (files: File[]) => {
      let isValid = false;
      forEach(files, (file) => {
        if (file.size < FILE_SIZE) isValid = true;
      });
      return isValid;
    }),
  passengersAndThirdPartiesPoliceReport: Yup.mixed()
    .required(i18n.t('validation:required'))
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (file: File) => file && file.size < FILE_SIZE)
    .test(
      'fileFormat',
      i18n.t('validation:invalidFormat'),
      (file: File) => file && ['application/pdf'].includes(file.type),
    ),
  completedClaimForm: Yup.mixed()
    .required(i18n.t('validation:required'))
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (file: File) => file && file.size < FILE_SIZE)
    .test(
      'fileFormat',
      i18n.t('validation:invalidFormat'),
      (file: File) => file && ['application/pdf'].includes(file.type),
    ),
  supportingDocuments: Yup.mixed()
    .test(
      'max',
      i18n.t('limitOutOfRange', { ns: 'validation', max: MAX_UPLOADED_FILES }),
      (files: File[]) => files.length <= MAX_UPLOADED_FILES,
    )
    .test('fileFormat', i18n.t('validation:invalidFormat'), (files: File[]) => {
      let isValid = false;
      forEach(files, (file) => {
        if (union(['application/pdf'], PHOTO_SUPPORTED_FORMATS).includes(file.type)) isValid = true;
      });
      return isValid;
    })
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (files: File[]) => {
      let isValid = false;
      forEach(files, (file) => {
        if (file.size < FILE_SIZE) isValid = true;
      });
      return isValid;
    }),
  acceptTermsAndConditions: Yup.boolean().test(
    'required',
    i18n.t('validation:required'),
    (checked) => checked === true,
  ),
});

const CarPolicyClaim = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { orderableId, orderableType } = useParams();
  const currentUser = useSelector(selectCurrentUser);
  const { policy } = useSelector(selectCarPolicyClaim);

  const initialValues: FormValues = useMemo(() => {
    return {
      insuredPolicyHolder: currentUser?.fullName ?? '',
      carOrderId: policy?.orderableId ?? 0,
      policyNumber: policy?.policyNumber ?? '',
      expiryDate: policy?.expiryDate ?? '',
      vehicleMake: policy?.userCar?.carBrandName ?? '',
      vehicleModel: policy?.userCar?.carModelName ?? '',
      vehicleYearOfManufacture: policy?.userCar?.manufacturedYear ?? '',
      vehicleEngineNumber: policy?.userCar?.engineCapacity ?? '',
      vehicleChassisNumber: policy?.userCar?.chassisNumber ?? '',
      vehicleRegistrationNumber: policy?.userCar?.registrationNumber ?? '',
      accidentHappenedAt: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
      accidentPhotos: [],
      passengersAndThirdPartiesPoliceReport: null,
      completedClaimForm: null,
      supportingDocuments: [],
      acceptTermsAndConditions: false,
    };
  }, [currentUser, policy]);

  const handleSubmit = useCallback((values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setSubmitting(false);
    dispatch(carClaimCreate(values));
  }, []);

  const handleBack = useCallback(() => {
    navigate('/user-dashboard/policy');
  }, []);

  useEffect(() => {
    dispatch(policyFetch({ orderableId, orderableType }));
  }, [orderableId, orderableType]);

  return (
    <>
      <BackButton onClick={handleBack} className="mb-4" />
      <Card>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Card.Header className="fs-5 fw-bold">{t('common:claimForm')}</Card.Header>
            <Insured />
            <VehicleInsured />
            <AccidentDetails />
            <Card.Body>
              <Card.Title>{t('common:claims')}</Card.Title>
            </Card.Body>
            <PassengersThirdParties />
            <CompletedClaimForm />
            <SupportingDocuments />
            <Card.Body>
              <FormGroupControl
                type="checkbox"
                name="acceptTermsAndConditions"
                label={t('carInsuranceClaimForm.acceptTermsAndConditions')}
                bsPrefix="form-check-input"
                groupProps={{ className: 'form-check form-check-inline' }}
              />
            </Card.Body>

            <Card.Footer className="bg-white">
              <div className="text-center my-4">
                <Button type="submit" variant="primary" size="lg">
                  {t('common:submit')}
                </Button>
              </div>
            </Card.Footer>
          </Form>
        </Formik>
      </Card>
    </>
  );
};

export { CarPolicyClaim };
