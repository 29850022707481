import { authAxios } from '@components/Auth';
import { snakes } from '@utils/lodash';
import { Claim } from '@models/Claim';
import { API_V1_BASE } from '@config';
import { paginate } from '@utils/pagination';
import { Pagination, PaginationParams } from '@models/Pagination';

export type GetAllClaimParams = PaginationParams;

export class ClaimApi {
  static getAll = async (params = {} as GetAllClaimParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/claims`, { params: snakes(params) })
      .then((response) => paginate(response) as [Claim[], Pagination]);
  };
}
