import React from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import { map, times } from 'lodash';
import { useTranslation } from 'react-i18next';

const FrequentlyAskedQuestionSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5 bg-light">
      <h2 className="text-center">{t('personalAccidentInsurancePage.frequentlyAskedQuestionSection.title')}</h2>
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <Accordion flush>
              {map(times(6), (idx) => (
                <Accordion.Item
                  eventKey={t(`personalAccidentInsurancePage.frequentlyAskedQuestionSection.q${idx + 1}`)}
                  key={`fqa${idx}`}
                >
                  <Accordion.Header>
                    <span className="fw-bold">
                      {t(`personalAccidentInsurancePage.frequentlyAskedQuestionSection.q${idx + 1}`)}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body style={{ whiteSpace: 'pre-line' }}>
                    {t(`personalAccidentInsurancePage.frequentlyAskedQuestionSection.a${idx + 1}`)}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { FrequentlyAskedQuestionSection };
