import React, { useMemo } from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import style from './style.module.scss';
import { Title } from '../title';

const FrequentlyAskedQuestionSection = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return [
      {
        question: t(`faq.q3`),
        answer: t(`faq.a3`),
      },
      {
        question: t(`faq.q4`),
        answer: t(`faq.a4`),
      },
      {
        question: t(`faq.q10`),
        answer: t(`faq.a10`),
      },
      {
        question: t(`faq.q11`),
        answer: t(`faq.a11`),
      },
      {
        question: t(`faq.q12`),
        answer: t(`faq.a12`),
      },
    ];
  }, []);

  return (
    <section className="py-5">
      <Title title={t('faq.header')} />
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <Accordion flush>
              {map(items, ({ question, answer }) => (
                <Accordion.Item eventKey={question} key={question}>
                  <Accordion.Header>
                    <span className="fw-bold">{question}</span>
                  </Accordion.Header>
                  <Accordion.Body className={classNames(style.textPreLine)}>{answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { FrequentlyAskedQuestionSection };
