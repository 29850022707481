import { useMemo } from 'react';
import { object, date, mixed } from 'yup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { pick, keys, includes } from 'lodash';

import { mergeInitialValues } from '@utils/formik';

import { FormValues } from './schema';
import { selectCarQuoteBuy } from '../selector';

const FILE_SIZE = 5_000_000; //5MB
export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export const useInitialValues = () => {
  const { carQuote } = useSelector(selectCarQuoteBuy);
  const initialValues: FormValues = {
    activationDate: '',
    customerConsent: undefined
  };

  return useMemo(() => {
    if (!carQuote) return;

    return mergeInitialValues(initialValues, pick(carQuote, keys(initialValues)));
  }, [carQuote]);
};

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    activationDate: date()
      .required(t('validation:required'))
      .min(moment().subtract(1, 'year'), 'must not exceed 1 year ago')
      .max(moment().add(3, 'month'), 'must not exceed 3 months from now'),
    customerConsent: mixed<File>()
      .optional()
      .test(
        'fileSize',
        t('validation:fileSizeOutOfRange'),
        (file: File) => !file || (file && file.size < FILE_SIZE),
      )
      .test(
        'fileFormat',
        t('validation:invalidFormat'),
        (file: File) => !file || (file && includes(SUPPORTED_FORMATS, file.type)),
      ),
  });
};
