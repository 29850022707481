import React from 'react';
import { Image, ImageProps } from 'react-bootstrap';

import { Insurer } from '@models/Insurer';

type Props = ImageProps & { insurer: Pick<Insurer, 'logo' | 'name'> };

const InsurerLogo = ({ insurer, ...imageProps }: Props) => {
  const { logo, name } = insurer;

  return (
    <Image src={logo} alt={`${name} logo`} {...imageProps} />
  );
};

export { InsurerLogo };
