import { pick } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { mergeInitialValues } from '@utils/formik';
import { getFieldKeys } from '@utils/yup';
import { useParseParams } from '@hooks/search-params';

import { selectCarQuoteBuy } from '../selector';
import {
  formValuesParamsSchema,
  FormValues,
  userFormValuesParamsSchema,
  carFormValuesParamsSchema,
} from '../CarQuoteBuyForm';

export const useInitialValues = () => {
  const { carQuote } = useSelector(selectCarQuoteBuy);
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);

  return useMemo(() => {
    if (!carQuote) return;

    const { userCar, user, frontlineNote, activationDate } = carQuote;

    return mergeInitialValues(initialValues, {
      activationDate,
      frontlineNote,
      ...pick(userCar, getFieldKeys(carFormValuesParamsSchema)),
      ...pick(user, getFieldKeys(userFormValuesParamsSchema)),
    });
  }, [carQuote]);
};
