import { call, put, takeLatest } from 'redux-saga/effects';

import { CarOrderApi } from '@apis/CarOrderApi';
import { BillingApi } from '@apis/BillingApi';
import { PaymentTransactionApi } from '@apis/PaymentTransactionApi';
import { CarOrder } from '@models/CarOrder';
import { Billing } from '@models/Billing';
import { PaymentTransaction } from '@models/PaymentTransaction';

import { SagaPayload } from '@utils/typescript';
import {
  carOrderFetch,
  CarOrderFetchPayload,
  paymentTransactionCreate,
  PaymentTransactionCreatePayload,
  stateUpdate,
  billingFetch,
  BillingFetchPayload,
  StateUpdatePayload,
} from './action';

function* handleCarOrderFetch({ payload }: SagaPayload<CarOrderFetchPayload>) {
  const state: StateUpdatePayload = { carOrder: null };
  try {
    state.carOrder = (yield call(CarOrderApi.get, payload)) as CarOrder;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handlePaymentTransactionCreate({ payload }: SagaPayload<PaymentTransactionCreatePayload>) {
  const state: StateUpdatePayload = { paymentTransaction: null };
  try {
    state.paymentTransaction = (yield call(PaymentTransactionApi.create, payload)) as PaymentTransaction;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleBillingFetch({ payload }: SagaPayload<BillingFetchPayload>) {
  const state: StateUpdatePayload = { billing: null };
  try {
    state.billing = (yield call(BillingApi.get, payload)) as Billing;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(carOrderFetch, handleCarOrderFetch);
  yield takeLatest(paymentTransactionCreate, handlePaymentTransactionCreate);
  yield takeLatest(billingFetch, handleBillingFetch);
}
