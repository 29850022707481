import { combineReducers } from 'redux';
import { carClaimDetailsReducer } from './CarClaimDetails';
import { personalAccidentClaimDetailsReducer } from './PersonalAccidentClaimDetails';
import { cancerClaimDetailsReducer } from './CancerClaimDetails';

export default combineReducers({
  carClaimDetails: carClaimDetailsReducer,
  personalAccidentClaimDetails: personalAccidentClaimDetailsReducer,
  cancerClaimDetails: cancerClaimDetailsReducer,
});
