import React, { useCallback } from 'react';
import { map } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import i18n from '@components/i18n';
import { useLocales } from './hook';

const LocaleButtonGroup = () => {
  const location = useLocation();
  const locales = useLocales();

  const handleChangeLocale = useCallback((locale: string) => {
    window.location.href = `/${locale}${location.pathname}${location.search}`;
  }, []);

  return (
    <ButtonGroup className="w-100">
      {map(locales, ({ locale, label }) => (
        <Button
          key={locale}
          value={locale}
          active={i18n.language === locale}
          onClick={(): void => handleChangeLocale(locale)}
          variant="outline-primary"
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export { LocaleButtonGroup };
