import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { User } from '@models/User';
import { snakes } from '@utils/lodash';
import { API_V1_BASE } from '@config';

export type GetCamDxRegisterParams = { authToken: string };

export class CamDxApi {
  static getSignInUrl = async () => {
    return await authAxios.get(`${API_V1_BASE}/auth/cam_dx/sign_in_url`).then(({ data }) => humps(data) as string);
  };

  static register = async ({ authToken }: GetCamDxRegisterParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/auth/cam_dx/register`, snakes({ authToken }))
      .then(({ data }) => humps(data) as User);
  };
}
