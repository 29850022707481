import React from 'react';
import { Badge } from 'react-bootstrap';
import { tClaimStatus, getClaimStatusVariant, ClaimStatuses } from '@models/Claim';

const ClaimBadge = ({ status }: { status: ClaimStatuses }) => {
  const variant = getClaimStatusVariant(status);
  const text = tClaimStatus(status);

  return (
    <Badge data-testid={`claim-badge ${variant}`} bg={variant}>
      {text}
    </Badge>
  );
};

export { ClaimBadge };
