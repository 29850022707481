import { find, transform, keys, startsWith, forEach, isArray, isEmpty, map, merge } from 'lodash';
import { PersonalAccidentPlanBenefit } from '@models/PersonalAccidentPlanBenefit';
import {
  PersonalAccidentPlanBenefitGroup,
  BenefitGroupTypes,
  BenefitGroupTypesKeys,
} from '@models/PersonalAccidentPlanBenefitGroup';
import { PersonalAccidentPlanQuestion } from '@models/PersonalAccidentQuote';
import { PersonalAccidentQuotePeopleParams } from '@apis/PersonalAccidentQuoteApi';

export const getPersonalAccidentPlanBenefitDescription = (
  personalAccidentPlanBenefits: PersonalAccidentPlanBenefit[],
  type: string,
) => {
  return find(personalAccidentPlanBenefits, (benefit) => benefit.benefitType === type)?.description;
};

export const getPersonalAccidentPlanBenefitsByBenefitGroupTypes = (
  personalAccidentPlanBenefits: PersonalAccidentPlanBenefit[],
) => {
  return transform(
    personalAccidentPlanBenefits,
    (result, personalAccidentPlanBenefit) => {
      forEach(keys(BenefitGroupTypes), (groupType) => {
        const benefitGroupType = BenefitGroupTypes[groupType as BenefitGroupTypesKeys];

        if (startsWith(personalAccidentPlanBenefit.benefitType, benefitGroupType)) {
          (result[benefitGroupType] || (result[benefitGroupType] = [])).push(personalAccidentPlanBenefit);
        }
      });
    },
    {} as PersonalAccidentPlanBenefitGroup,
  );
};

export const appendPersonalAccidentPlanQuestionsToFormValues = (
  values: PersonalAccidentQuotePeopleParams,
  questions: PersonalAccidentPlanQuestion[],
) => {
  forEach(values, (value) => {
    if (!isEmpty(value)) {
      if (isArray(value)) {
        forEach(value, (item) => {
          merge(item, {
            medicalQuestionnaire: map(questions, ({ question }) => ({ question: question, answer: '', remark: '' })),
          });
        });
      } else {
        merge(value, {
          medicalQuestionnaire: map(questions, ({ question }) => ({ question: question, answer: '', remark: '' })),
        });
      }
    }
  });

  return values;
};
