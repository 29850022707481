import { Article } from '@models/Article';
import { Pagination } from '@models/Pagination';
import { handleActions } from 'redux-actions';
import { articlesSet } from './action';

export interface State {
  articles?: Article[];
  pagination?: Pagination;
}

const initialState: State = {};

const reducer = handleActions(
  {
    [String(articlesSet)]: (state, { payload }) => {
      return {
        ...state,
        articles: payload.articles,
        pagination: payload.pagination,
      };
    },
  },

  initialState,
);

export default reducer;
