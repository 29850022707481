import React, { useCallback } from 'react';
import { isNull, isUndefined } from 'lodash';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Loading, NoData, LoadingError, BackButton } from '@components/Base';
import SupportSection from '@components/SupportSection';

import { usePersonalAccidentQuoteResultPageParams } from '@components/PersonalAccidentQuoteResultPage';
import { getPersonalAccidentQuoteResultPageRoute } from '@components/PersonalAccidentQuoteResultPage/route';

import { HeaderSection } from './header-section';
import { FeatureSection } from './feature-section';
import { BenefitSection } from './benefit-section';
import { HospitalSection } from './hospital-section';
import { MemberSection } from './member-section';

import { usePersonalAccidentQuoteFetch } from '../hook';

const PersonalAccidentQuoteDetailsPage = () => {
  const navigate = useNavigate();
  const params = usePersonalAccidentQuoteResultPageParams();
  const personalAccidentQuote = usePersonalAccidentQuoteFetch();
  const isLoaded = !isUndefined(personalAccidentQuote);
  const isError = isNull(personalAccidentQuote);
  const hasPersonalAccidentQuote = isLoaded && !isNull(personalAccidentQuote);

  const handleBackClick = useCallback(() => {
    navigate(getPersonalAccidentQuoteResultPageRoute(params), { replace: true });
  }, []);

  return (
    <>
      {!isLoaded && <Loading />}
      {isError && <LoadingError />}
      {isLoaded && !hasPersonalAccidentQuote && <NoData />}
      {hasPersonalAccidentQuote && (
        <>
          <Container className="mt-4">
            <BackButton onClick={handleBackClick} className="mb-4" />
          </Container>

          <HeaderSection {...personalAccidentQuote} />
          <FeatureSection {...personalAccidentQuote} />
          <BenefitSection {...personalAccidentQuote} />
          <HospitalSection {...personalAccidentQuote} />
          <MemberSection />
        </>
      )}

      <SupportSection />
    </>
  );
};

export { PersonalAccidentQuoteDetailsPage };
