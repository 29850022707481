import i18n from '@components/i18n';
import { KeysToCamelCase } from '@utils/typescript';
import { BillingResponse } from '@models/Billing';
import { InsurerResponse } from '@models/Insurer';
import { InsuranceTypes } from '@models/InsuranceType';
import { ProductDocumentResponse } from '@models/ProductDocument';
import { UserCarResponse } from './UserCar';

export enum OrderableTypes {
  Car = 'CarOrder',
  Health = 'HealthOrder',
  PersonalAccident = 'PersonalAccidentOrder',
  Cancer = 'CancerOrder',
}

export enum OrderStatuses {
  Pending = 'pending',
  Expired = 'expired',
  Active = 'active',
  Ended = 'ended',
  Locked = 'locked',
}

export type OrderListResponse = {
  id: number;
  insurance_type: InsuranceTypes;
  orderable_id: number;
  orderable_type: OrderableTypes;
  status: OrderStatuses;
  policy_number?: string;
  order_number?: string;
  renewable: boolean;
  claimable: boolean;
  activation_date?: number;
  started_at?: number;
  ended_at?: number;
};

export type OrderList = KeysToCamelCase<OrderListResponse>;

export type OrderResponse = OrderListResponse & {
  certificate?: string;
  policy_schedule?: string;
  coverage_summary?: string;
  coverage_summary_km?: string;
  product_document?: ProductDocumentResponse;
  expiry_date: string;
  created_at: string;
  billing?: BillingResponse;
  insurer: InsurerResponse;
  user_car: UserCarResponse;
  insurance_certificate: string;
  insurance_certificate_km: string;
  invoice: string;
  invoice_km: string;
};

export type Order = KeysToCamelCase<OrderResponse>;

const ORDER_STATUSES_VARIANTS_MAPPING = {
  [OrderStatuses.Pending]: 'warning',
  [OrderStatuses.Expired]: 'secondary',
  [OrderStatuses.Active]: 'success',
  [OrderStatuses.Ended]: 'secondary',
  [OrderStatuses.Locked]: 'info',
};

export const getOrderStatusVariant = (status: OrderStatuses): string => {
  return ORDER_STATUSES_VARIANTS_MAPPING[status];
};

export const tOrderStatus = (status: OrderStatuses) => {
  return i18n.t(`orderStatuses.${status}`);
};

export const tOrderableType = (type: OrderableTypes) => {
  switch (type) {
    case OrderableTypes.Car:
      return i18n.t('common:autoPrivateVehicleInsurance');
    case OrderableTypes.Health:
      return i18n.t('common:healthInsurance');
    case OrderableTypes.PersonalAccident:
      return i18n.t('common:personalAccidentInsurance');
    case OrderableTypes.Cancer:
      return i18n.t('common:cancerInsurance');
    default:
      return null;
  }
};
