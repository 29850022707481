import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { each } from 'lodash';

import { useCarQuotesFilterParams } from '../hook';
import { FilterType } from './type';
import { FilterContext } from './context';

const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const carQuotesFilterParams = useCarQuotesFilterParams();
  const [filters, setFilters] = useState(carQuotesFilterParams);

  const updateFilters = (values: Partial<FilterType>) => {
    const newFilters: FilterType = { ...filters, ...values };
    setFilters(newFilters);
  };

  useEffect(() => {
    updateFilters({});
  }, []);

  useEffect(() => {
    each(filters, (v, k) => searchParams.set(k.toString(), v.toString()));
    setSearchParams(searchParams.toString());
  }, [filters]);

  return <FilterContext.Provider value={{ filters, updateFilters }}>{children}</FilterContext.Provider>;
};

export { FilterProvider };
