import { call, put, takeLatest } from 'redux-saga/effects';
import { Order } from '@models/Order';
import { OrderApi, GetAllOrderParams } from '@apis/OrderApi';
import { Pagination } from '@models/Pagination';
import { ordersFetch, ordersSet } from './action';
import { SagaPayload } from '@utils/typescript';

function* handleOrdersFetch({ payload }: SagaPayload<GetAllOrderParams>) {
  try {
    const [orders] = (yield call(OrderApi.getAll, payload)) as [Order[], Pagination];
    yield put(ordersSet({ orders }));
  } catch {
    yield put(ordersSet({ orders: [] }));
  }
}

export default function* () {
  yield takeLatest(ordersFetch, handleOrdersFetch);
}
