import { call, put, takeLatest } from 'redux-saga/effects';

import { CarQuote } from '@models/CarQuote';
import { CarQuoteApi } from '@apis/CarQuoteApi';
import { SagaPayload } from '@utils/typescript';

import { carQuoteFetch, carQuoteSendEmail, stateUpdate } from './action';
import { CarQuoteFetchPayload, CarQuoteSendEmailPayload, StateUpdatePayload } from './type';

function* handleCarQuoteFetch({ payload }: SagaPayload<CarQuoteFetchPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.get, payload)) as CarQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarQuoteSendEmail({ payload }: SagaPayload<CarQuoteSendEmailPayload>) {
  try {
    yield call(CarQuoteApi.sendEmail, payload);
  } catch {}
}

export default function* () {
  yield takeLatest(carQuoteFetch, handleCarQuoteFetch);
  yield takeLatest(carQuoteSendEmail, handleCarQuoteSendEmail);
}
