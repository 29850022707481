import i18n from '@components/i18n';
import { KeysToCamelCase } from '@utils/typescript';
import { InsuranceTypes } from '@models/InsuranceType';
import { OrderResponse } from './Order';

export enum ClaimStatuses {
  Submitted = 'submitted',
  Reviewing = 'reviewing',
  Nonpayable = 'non_payable',
  Paid = 'paid',
  Final = 'final',
}

export enum ClaimableTypes {
  Car = 'CarClaim',
  PersonalAccident = 'PersonalAccidentClaim',
  Cancer = 'CancerClaim',
}

type ClaimListResponse = {
  id: number;
  claimable_id: number;
  claimable_type: ClaimableTypes;
  insurance_type: InsuranceTypes;
  order_number: OrderResponse['order_number'];
  policy_number: string;
  status: ClaimStatuses;
  created_at: number;
};

export type ClaimList = KeysToCamelCase<ClaimListResponse>;

export type Claim = ClaimList & {
  note: string;
};

const CLAIM_STATUSES_VARIANTS_MAPPING = {
  [ClaimStatuses.Submitted]: 'info',
  [ClaimStatuses.Reviewing]: 'primary',
  [ClaimStatuses.Nonpayable]: 'danger',
  [ClaimStatuses.Paid]: 'success',
  [ClaimStatuses.Final]: 'secondary',
};

export const getClaimStatusVariant = (status: ClaimStatuses) => {
  return CLAIM_STATUSES_VARIANTS_MAPPING[status];
};

export const tClaimStatus = (status: ClaimStatuses) => {
  return i18n.t(`claimStatuses.${status}`);
};
