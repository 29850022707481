import React, { useEffect, useRef } from 'react';
import { map } from 'lodash';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Image, Alert } from 'react-bootstrap';
import { useField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

const FILE_SIZE = 5_000_000; //5MB

type FilePhoto = File & {
  preview: string;
};

type RefDropzone = {
  open: () => void;
};

interface FormikDropzoneProps {
  name: string;
  maxFiles: number;
}

interface PreviewContainerProps {
  photos: FilePhoto[];
}

const PreviewContainer = ({ photos }: PreviewContainerProps) => {
  return (
    <Container>
      <Row className="gy-4">
        {map(photos, (photo) => (
          <Col xs={6} md={4} key={photo.name}>
            <Image
              thumbnail
              src={photo.preview}
              // Revoke data uri after image is loaded
              onLoad={() => {
                URL.revokeObjectURL(photo.preview);
              }}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const FormikDropzone = ({ name, maxFiles }: FormikDropzoneProps) => {
  const { t } = useTranslation();
  const [_, meta, helpers] = useField(name);
  const photos = meta.value as FilePhoto[];
  const shouldRenderPreview = photos.length > 0;
  const ref = useRef<RefDropzone>(null);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => photos.forEach((photo) => URL.revokeObjectURL(photo.preview));
  }, [photos]);

  const openDialog = () => {
    ref.current?.open();
  };

  const handleDrop = (acceptedFiles: File[]) => {
    helpers.setValue(
      map(acceptedFiles, (file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  };

  return (
    <>
      <div className="d-flex justify-content-center mb-5 p-5 border border-1 rounded text-center">
        <Dropzone accept={{ 'image/*': [] }} onDrop={handleDrop} maxSize={FILE_SIZE} maxFiles={maxFiles}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} {...{ name }} />
              <span>{t('photoManagerDialog.tabs.upload.usageMsg')}</span>
              <Alert variant="light">
                <span className="d-block">{t('photoManagerDialog.tabs.upload.maxSizeMsg')}</span>
                <span className="d-block">
                  {t('photoManagerDialog.tabs.upload.maxFilesMsg', { maxFiles: maxFiles })}
                </span>
              </Alert>
              <Button onClick={openDialog} variant="outline-primary" className="w-100">
                <FontAwesomeIcon icon={faCloudArrowUp} size="2xl" className="fa-fw" />
              </Button>
            </div>
          )}
        </Dropzone>
      </div>
      {shouldRenderPreview && <PreviewContainer {...{ photos }} />}
    </>
  );
};

export { FormikDropzone };
