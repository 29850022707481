import React from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { CopyLinkButton } from '@components/Base';

import CarQuoteDetailsTabs from '../CarQuoteDetailsTabs';
import { useCarQuote } from '../hook';
import { CarQuoteDetailsHeader } from './header';

const CarQuoteDetailsPage = () => {
  const { t } = useTranslation();
  const carQuote = useCarQuote();

  if (!carQuote) return null;

  return (
    <>
      <div className="d-flex align-items-center justify-content-sm-end my-3">
        <CopyLinkButton />
      </div>

      <Card>
        <Card.Header>
          <CarQuoteDetailsHeader carQuote={carQuote} />
        </Card.Header>

        {carQuote.car && (
          <Card.Body>
            <Alert variant="secondary">
              <span className="d-block">{carQuote.car.name}</span>
              <span className="d-block fw-light">
                {t('common:privateCar')} . {carQuote.car.manufacturedYear}
              </span>
            </Alert>
          </Card.Body>
        )}

        <Card.Body>
          <CarQuoteDetailsTabs carQuote={carQuote} />
        </Card.Body>
      </Card>
    </>
  );
};

export { CarQuoteDetailsPage };
