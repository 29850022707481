import React from 'react';

type Props = {
  title?: string;
  children: React.ReactNode;
};

const TableRowItem = ({ title, children }: Props) => {
  return (
    <tr>
      <td className="ps-3">{title}</td>
      {children}
    </tr>
  );
};

export { TableRowItem };
