import { stringify } from 'qs';
import { array, number, object } from 'yup';

import {
  PersonalAccidentQuoteResultPageParams,
  personalAccidentQuoteResultPageParamsSchema,
} from '@components/PersonalAccidentQuoteResultPage/route';

export type PersonalAccidentQuoteComparePageParams = Required<
Omit<PersonalAccidentQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'>
> &
Pick<PersonalAccidentQuoteResultPageParams, 'spouse' | 'sons' | 'daughters'> & {
  personalAccidentPlanIds: number[];
};

export const PERSONAL_ACCIDENT_QUOTE_COMPARE_PAGE_URL = '/personal-accident-insurance-quote-compare';

export const PersonalAccidentQuoteComparePageParamsSchema = personalAccidentQuoteResultPageParamsSchema.concat(
  object({
    personalAccidentPlanIds: array().of(number()),
  }),
);

export const getPersonalAccidentQuoteComparePageRoute = (params: PersonalAccidentQuoteComparePageParams) => {
  return {
    pathname: PERSONAL_ACCIDENT_QUOTE_COMPARE_PAGE_URL,
    search: stringify(params),
  };
};
