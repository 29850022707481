import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParamsCancerQuoteId } from '@hooks/params';
import { useParseParams } from '@hooks/search-params';

import { cancerQuoteEdit, cancerQuoteFetch } from './action';
import { CancerQuoteFetchPayload } from './cancerQuoteFetch';
import { CancerQuoteEditPayload } from './cancerQuoteEdit';
import { selectCancerQuoteBuyRoute } from './selector';
import { CancerQuoteBuyNewPageParams, cancerQuoteBuyNewPageParamsSchema } from './route';

export const useCancerQuoteBuyNewPageParams = () =>
  useParseParams<CancerQuoteBuyNewPageParams>(cancerQuoteBuyNewPageParamsSchema);

export const useCancerQuote = () => {
  const dispatch = useDispatch();
  const payload: CancerQuoteFetchPayload = useCancerQuoteBuyNewPageParams();
  const { cancerQuote } = useSelector(selectCancerQuoteBuyRoute);

  useEffect(() => {
    dispatch(cancerQuoteFetch(payload));
  }, []);

  return cancerQuote;
};

export const useCancerQuoteEdit = () => {
  const cancerQuoteId = useParamsCancerQuoteId();
  const dispatch = useDispatch();
  const { cancerQuote } = useSelector(selectCancerQuoteBuyRoute);

  useEffect(() => {
    const payload: CancerQuoteEditPayload = { id: cancerQuoteId };
    dispatch(cancerQuoteEdit(payload));
  }, []);

  return cancerQuote;
};
