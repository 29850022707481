import React from 'react';
import { map, startCase } from 'lodash';
import { useHref } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { useInsuranceProduct } from '@components/InsuranceProduct';

const InsuranceNavDropdown = () => {
  const { title, items } = useInsuranceProduct();

  return (
    <NavDropdown title={startCase(title)}>
      {map(items, ({ label, href, beta }) => (
        <NavDropdown.Item key={label} href={useHref(href)}>
          {startCase(label)}{beta ? ' (beta)' : null}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

export { InsuranceNavDropdown };
