import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { map } from 'lodash';

import { CarPlanTypes } from '@models/CarPlanType';
import { AmountWithDiscount } from '@components/Base';
import { useCarQuotesResultPageParams } from '@components/CarQuoteResultPage';

import { TableRowHeader } from './table-row-header';
import { TableRowItem } from './table-row-item';
import { selectCarQuoteComparePage } from './selector';

const TableDeductible = () => {
  const { t } = useTranslation();
  const { planType } = useCarQuotesResultPageParams();
  const { carQuotes } = useSelector(selectCarQuoteComparePage);

  return (
    <>
      <TableRowHeader header={t('common:deductible')} />

      <TableRowItem>
        {map(
          carQuotes,
          ({
            insurer,
            thirdPartyDeductibleAmount,
            thirdPartyDeductibleDiscountAmount,
            thirdPartyDeductibleFinalAmount,
            thirdPartyDeductibleDescription,
          }) => (
            <td key={insurer.id}>
              {!!Number(thirdPartyDeductibleAmount) && (
                <>
                  <AmountWithDiscount
                    amount={thirdPartyDeductibleAmount}
                    discountAmount={thirdPartyDeductibleDiscountAmount}
                    finalAmount={thirdPartyDeductibleFinalAmount}
                  />{' '}
                  {thirdPartyDeductibleDescription}
                </>
              )}
            </td>
          ),
        )}
      </TableRowItem>

      {planType === CarPlanTypes.Comprehensive && (
        <>
          <TableRowItem>
            {map(
              carQuotes,
              ({
                insurer,
                ownDamageDeductibleAmount,
                ownDamageDeductibleDiscountAmount,
                ownDamageDeductibleFinalAmount,
                ownDamageDeductibleDescription,
              }) => (
                <td key={insurer.id}>
                  {!!Number(ownDamageDeductibleAmount) && (
                    <>
                      <AmountWithDiscount
                        amount={ownDamageDeductibleAmount}
                        discountAmount={ownDamageDeductibleDiscountAmount}
                        finalAmount={ownDamageDeductibleFinalAmount}
                      />{' '}
                      {ownDamageDeductibleDescription}
                    </>
                  )}
                </td>
              ),
            )}
          </TableRowItem>

          <TableRowItem>
            {map(
              carQuotes,
              ({
                insurer,
                theftDeductibleAmount,
                theftDeductibleDiscountAmount,
                theftDeductibleFinalAmount,
                theftDeductibleDescription,
              }) => (
                <td key={insurer.id}>
                  {!!Number(theftDeductibleAmount) && (
                    <>
                      <AmountWithDiscount
                        amount={theftDeductibleAmount}
                        discountAmount={theftDeductibleDiscountAmount}
                        finalAmount={theftDeductibleFinalAmount}
                      />{' '}
                      {theftDeductibleDescription}
                    </>
                  )}
                </td>
              ),
            )}
          </TableRowItem>
        </>
      )}
    </>
  );
};

export { TableDeductible };
