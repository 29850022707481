import { replaceRouteParams } from '@utils/route';

export type HealthOrderPaymentPageParams = {
  healthOrderId: number;
  billingId: number;
};

export const HEALTH_ORDER_PAYMENT_PAGE_URL = '/health-insurance-quote-payment/:healthOrderId/:billingId';

export const getHealthOrderPaymentPageRoute = (params: HealthOrderPaymentPageParams) => {
  return {
    pathname: replaceRouteParams(HEALTH_ORDER_PAYMENT_PAGE_URL, params),
  };
}
