import React from 'react';
import { FormGroup, FormGroupProps, FormLabel, FormLabelProps, FormText, FormTextProps } from 'react-bootstrap';
import { FormikControl, FormikControlProps } from '../';
import { useControlId, useLabel } from '../FormGroupBase';

type FormGroupControlProps = FormikControlProps & {
  label?: string;
  help?: string;
  labelProps?: FormLabelProps;
  groupProps?: FormGroupProps;
  helpProps?: FormTextProps;
};

const FormGroupControl = ({ label, help, labelProps, groupProps, helpProps, ...props }: FormGroupControlProps) => {
  const { required, name } = props;
  const controlId = useControlId(name);
  const labelText = useLabel(label, required);

  return (
    <FormGroup {...groupProps} controlId={controlId}>
      {labelText && <FormLabel {...labelProps}>{labelText}</FormLabel>}
      <FormikControl {...props} />
      {help && (
        <FormText muted {...helpProps}>
          {help}
        </FormText>
      )}
    </FormGroup>
  );
};

export { FormGroupControl, FormGroupControlProps };
