import humps from 'lodash-humps-ts';
import { omit } from 'lodash';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { PersonalAccidentClaim } from '@models/PersonalAccidentClaim';
import { InsuranceTypes } from '@models/InsuranceType';
import { API_V1_BASE } from '@config';

export interface CreatePersonalAccidentClaimParams {
  personalAccidentOrderId: number;
  insuranceType: InsuranceTypes;
  claimType: string;
  completedClaimForm: File;
  supportingDocuments: File[];
  invoice: File;
  totalClaimedAmount: number;
  acceptTermsAndConditions: boolean;
}

export interface GetPersonalAccidentClaimParams {
  id: number;
}

export class PersonalAccidentClaimApi {
  static create = async (params: CreatePersonalAccidentClaimParams) => {
    return await authAxios
      .postForm(`${API_V1_BASE}/user/personal_accident_claims`, {
        ...omit(snakes(params), ['supporting_documents']),
        'supporting_documents[]': params.supportingDocuments,
      })
      .then((response) => humps(response.data) as PersonalAccidentClaim);
  };

  static get = async ({ id }: GetPersonalAccidentClaimParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/personal_accident_claims/${id}`)
      .then((response) => humps(response.data) as PersonalAccidentClaim);
  };
}
