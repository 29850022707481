import React from 'react';
import { useField } from 'formik';
import { FormCheck, FormCheckProps } from 'react-bootstrap';

type FormikCheckProps = FormCheckProps &
  JSX.IntrinsicElements['input'] & {
    name: string;
    feedback?: string;
  };

const FormikCheck = ({ name, feedback: goodFeedback, ...props }: FormikCheckProps) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;
  const isInvalid = touched && !!error;
  const isValid = touched && !error;
  const feedback = isValid ? goodFeedback : isInvalid ? error : undefined;
  const feedbackType = isValid ? 'valid' : isInvalid ? 'invalid' : undefined;
  const id = props.type === 'radio' && props.value ? `${name}-${props.value.toString()}` : name;

  return <FormCheck {...field} {...props} {...{ id, feedback, feedbackType, isValid, isInvalid }} />;
};

export { FormikCheck, FormikCheckProps };
