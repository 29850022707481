import { createActions } from 'redux-actions';

import { CancerQuote } from '@models/CancerQuote';
import { CancerQuoteGroup } from '@models/CancerQuoteGroup';

export type State = {
  totalQuotes: number | null | undefined;
  cancerQuoteGroups: CancerQuoteGroup[] | null | undefined;
  comparableCancerQuotes: CancerQuote[];
};

export type StateUpdatePayload = Partial<State>;

export const {
  cancerQuotesFetch,

  compareAdd,
  compareRemove,
  compareRemoveAll,

  stateUpdate,
  stateReset,
} = createActions(
  'CANCER_QUOTES_FETCH',

  'COMPARE_ADD',
  'COMPARE_REMOVE',
  'COMPARE_REMOVE_ALL',

  'STATE_UPDATE',
  'STATE_RESET',
  {
    prefix: 'CANCER_QUOTE_RESULT_PAGE',
  },
);
