import React, { useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isNull, isUndefined } from 'lodash';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BackButton, NoData, Loading } from '@components/Base';
import { Order } from '@models/Order';
import { ProductDocument } from '@models/ProductDocument';
import { policyFetch } from '../action';
import { selectPolicyDetails } from '../selector';
import { DocumentListGroup } from '../document-list-group';

const PersonalAccidentPolicyDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { orderableType, orderableId } = useParams();
  const { policy } = useSelector(selectPolicyDetails);
  const isLoaded = !isUndefined(policy);
  const hasPolicy = isLoaded && !isNull(policy);
  const {
    coverageSummary,
    coverageSummaryKm,
    policySchedule,
    insuranceCertificate,
    insuranceCertificateKm,
    invoice,
    invoiceKm,
  } = (policy ?? {}) as Order;
  const { claimProcedureFile, policyWordingFile } = (policy?.productDocument ?? {}) as ProductDocument;

  useEffect(() => {
    dispatch(policyFetch({ orderableId, orderableType }));
  }, [orderableId, orderableType]);

  const handleBackClick = useCallback(() => {
    navigate('/user-dashboard/policy', { replace: true });
  }, []);

  return (
    <>
      <BackButton onClick={handleBackClick} className="mb-4" />
      {!isLoaded && <Loading />}
      {isLoaded && !hasPolicy && <NoData />}
      {hasPolicy && (
        <Card>
          <Card.Header>{t('policyDetails.title')}</Card.Header>
          <Card.Body>
            <DocumentListGroup
              {...{
                coverageSummary,
                coverageSummaryKm,
                policySchedule,
                insuranceCertificate,
                insuranceCertificateKm,
                invoice,
                invoiceKm,
                claimProcedureFile,
                policyWordingFile,
              }}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export { PersonalAccidentPolicyDetails };
