import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormGroupControl } from '@components/Base';

type Props = {
  name: string;
  label: string;
  age: number | '';
  isVisible: boolean;
};

const MemberAnswerItem = ({ name, label, age, isVisible }: Props) => {
  const { t } = useTranslation();

  if (!isVisible) return null;

  return (
    <FormGroupControl
      name={name}
      label={`${label} (${age} ${t('common:year')}), ${t('common:specifyProblem')}`}
      placeholder={t('common:enterDetail')}
      groupProps={{ className: 'my-3' }}
    />
  );
};

export { MemberAnswerItem }
