import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { amountToUsd } from '@utils/currency';
import { HealthPlanBenefitTypes, tHealthPlanBenefitType } from '@models/HealthPlanBenefit';

import { selectHealthQuoteComparePage } from './selector';
import { TableRowHeader } from './table-row-header';
import { TableRowItem } from './table-row-item';

const TableAnnualLimit = () => {
  const { t } = useTranslation();
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  if (!healthQuotes) return null;

  return (
    <>
      <TableRowHeader header={t('healthQuote.annualLimit')} />

      <TableRowItem title={t('healthQuote.annualLimit')}>
        {map(healthQuotes, ({ healthPlanId, annualLimitAmount }) => (
          <td key={healthPlanId}>{amountToUsd(annualLimitAmount)}</td>
        ))}
      </TableRowItem>

      <TableRowItem title={tHealthPlanBenefitType(HealthPlanBenefitTypes.Inpatient)}>
        {map(healthQuotes, ({ healthPlanId, inpatientAnnualLimitAmount }) => (
          <td key={healthPlanId}>{amountToUsd(inpatientAnnualLimitAmount)}</td>
        ))}
      </TableRowItem>

      <TableRowItem title={tHealthPlanBenefitType(HealthPlanBenefitTypes.Outpatient)}>
        {map(healthQuotes, ({ healthPlanId, outpatientAnnualLimitAmount }) => (
          <td key={healthPlanId}>{amountToUsd(outpatientAnnualLimitAmount)}</td>
        ))}
      </TableRowItem>

      <TableRowItem title={tHealthPlanBenefitType(HealthPlanBenefitTypes.HealthCheckup)}>
        {map(healthQuotes, ({ healthPlanId, healthCheckupAnnualLimitAmount }) => (
          <td key={healthPlanId}>{amountToUsd(healthCheckupAnnualLimitAmount)}</td>
        ))}
      </TableRowItem>

      <TableRowItem title={tHealthPlanBenefitType(HealthPlanBenefitTypes.Maternity)}>
        {map(healthQuotes, ({ healthPlanId, maternityAnnualLimitAmount }) => (
          <td key={healthPlanId}>{amountToUsd(maternityAnnualLimitAmount)}</td>
        ))}
      </TableRowItem>

      <TableRowItem title={tHealthPlanBenefitType(HealthPlanBenefitTypes.Vision)}>
        {map(healthQuotes, ({ healthPlanId, visionAnnualLimitAmount }) => (
          <td key={healthPlanId}>{amountToUsd(visionAnnualLimitAmount)}</td>
        ))}
      </TableRowItem>

      <TableRowItem title={tHealthPlanBenefitType(HealthPlanBenefitTypes.Dental)}>
        {map(healthQuotes, ({ healthPlanId, dentalAnnualLimitAmount }) => (
          <td key={healthPlanId}>{amountToUsd(dentalAnnualLimitAmount)}</td>
        ))}
      </TableRowItem>

      <TableRowItem title={tHealthPlanBenefitType(HealthPlanBenefitTypes.Copay)}>
        {map(healthQuotes, ({ healthPlanId, copayAnnualLimitAmount }) => (
          <td key={healthPlanId}>{amountToUsd(copayAnnualLimitAmount)}</td>
        ))}
      </TableRowItem>

      <TableRowItem title={tHealthPlanBenefitType(HealthPlanBenefitTypes.Others)}>
        {map(healthQuotes, ({ healthPlanId, othersAnnualLimitAmount }) => (
          <td key={healthPlanId}>{amountToUsd(othersAnnualLimitAmount)}</td>
        ))}
      </TableRowItem>
    </>
  );
};

export { TableAnnualLimit };
