import { stringify } from 'qs';
import { array, boolean, number, object, string } from 'yup';
import { CarQuoteResultPageParams, carQuoteResultPageParamsSchema } from '@components/CarQuoteResultPage/route';

export type CarQuoteComparePageParams = Required<CarQuoteResultPageParams> & {
  carPlanIds: number[]
};

export const CAR_QUOTE_COMPARE_PAGE_URL = '/car-insurance-quote-compare';

export const carQuoteComparePageParamsSchema = carQuoteResultPageParamsSchema.concat(
  object({
    idvAmount: number(),
    planType: string(),
    accidentToDriver: boolean(),
    passengerLiability: boolean(),
    promotionCode: string(),
    carPlanIds: array().of(number()),
  })
);

export const getCarQuoteComparePageRoute = (params: CarQuoteComparePageParams) => {
  return {
    pathname: CAR_QUOTE_COMPARE_PAGE_URL,
    search: stringify(params),
  };
}
