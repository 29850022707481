import React from 'react';
import { useSelector } from 'react-redux';
import { camelCase, map } from 'lodash';

import {
  HealthPlanBenefitTypes,
  tHealthPlanBenefitType,
  HealthPlanBenefit,
  HealthPlanIpdBenefitTypes,
  HealthPlanOpdBenefitTypes,
  HealthPlanOthersBenefitTypes,
} from '@models/HealthPlanBenefit';

import { selectHealthQuoteComparePage } from './selector';
import { TableRowHeader } from './table-row-header';
import { TableRowItem } from './table-row-item';

const ItemList = ({ itemType, items }: { itemType: string; items: HealthPlanBenefit[] }) => {
  return (
    <>
      {map(items, ({ benefitType, description }) => {
        return camelCase(itemType) == camelCase(benefitType) ? <p key={description}>{description}</p> : null;
      })}
    </>
  );
};

const TableInpatientBenefit = () => {
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  if (!healthQuotes) return null;

  return (
    <>
      <TableRowHeader header={tHealthPlanBenefitType(HealthPlanBenefitTypes.Inpatient)} />

      {map(HealthPlanIpdBenefitTypes, (type) => (
        <TableRowItem key={type} title={tHealthPlanBenefitType(type)}>
          {map(healthQuotes, ({ healthPlanId, inpatientBenefits }) => (
            <td key={healthPlanId}>
              <ItemList itemType={type} items={inpatientBenefits} />
            </td>
          ))}
        </TableRowItem>
      ))}
    </>
  );
};

const TableOutpatientBenefit = () => {
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  if (!healthQuotes) return null;

  return (
    <>
      <TableRowHeader header={tHealthPlanBenefitType(HealthPlanBenefitTypes.Outpatient)} />

      {map(HealthPlanOpdBenefitTypes, (type) => (
        <TableRowItem key={type} title={tHealthPlanBenefitType(type)}>
          {map(healthQuotes, ({ healthPlanId, outpatientBenefits }) => (
            <td key={healthPlanId}>
              <ItemList itemType={type} items={outpatientBenefits} />
            </td>
          ))}
        </TableRowItem>
      ))}
    </>
  );
};

const TableHealthCheckupBenefit = () => {
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  if (!healthQuotes) return null;

  return (
    <>
      <TableRowHeader header={tHealthPlanBenefitType(HealthPlanBenefitTypes.HealthCheckup)} />

      <TableRowItem>
        {map(healthQuotes, ({ healthPlanId, healthCheckupBenefits }) => (
          <td key={healthPlanId}>
            <ItemList itemType="healthCheckupBenefits" items={healthCheckupBenefits} />
          </td>
        ))}
      </TableRowItem>
    </>
  );
};

const TableMaternityBenefit = () => {
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  if (!healthQuotes) return null;

  return (
    <>
      <TableRowHeader header={tHealthPlanBenefitType(HealthPlanBenefitTypes.Maternity)} />

      <TableRowItem>
        {map(healthQuotes, ({ healthPlanId, maternityBenefits }) => (
          <td key={healthPlanId}>
            <ItemList itemType="maternityBenefits" items={maternityBenefits} />
          </td>
        ))}
      </TableRowItem>
    </>
  );
};

const TableVisionBenefit = () => {
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  if (!healthQuotes) return null;

  return (
    <>
      <TableRowHeader header={tHealthPlanBenefitType(HealthPlanBenefitTypes.Vision)} />

      <TableRowItem>
        {map(healthQuotes, ({ healthPlanId, visionBenefits }) => (
          <td key={healthPlanId}>
            <ItemList itemType="visionBenefits" items={visionBenefits} />
          </td>
        ))}
      </TableRowItem>
    </>
  );
};

const TableCopayBenefit = () => {
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  if (!healthQuotes) return null;

  return (
    <>
      <TableRowHeader header={tHealthPlanBenefitType(HealthPlanBenefitTypes.Copay)} />

      <TableRowItem>
        {map(healthQuotes, ({ healthPlanId, copayBenefits }) => (
          <td key={healthPlanId}>
            <ItemList itemType="copayBenefits" items={copayBenefits} />
          </td>
        ))}
      </TableRowItem>
    </>
  );
};

const TableDentalBenefit = () => {
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  if (!healthQuotes) return null;

  return (
    <>
      <TableRowHeader header={tHealthPlanBenefitType(HealthPlanBenefitTypes.Dental)} />

      <TableRowItem>
        {map(healthQuotes, ({ healthPlanId, dentalBenefits }) => (
          <td key={healthPlanId}>
            <ItemList itemType="dentalBenefits" items={dentalBenefits} />
          </td>
        ))}
      </TableRowItem>
    </>
  );
};

const TableOthersBenefit = () => {
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  if (!healthQuotes) return null;

  return (
    <>
      <TableRowHeader header={tHealthPlanBenefitType(HealthPlanBenefitTypes.Others)} />

      {map(HealthPlanOthersBenefitTypes, (type) => (
        <TableRowItem key={type} title={tHealthPlanBenefitType(type)}>
          {map(healthQuotes, ({ healthPlanId, othersBenefits }) => (
            <td key={healthPlanId}>
              <ItemList itemType={type} items={othersBenefits} />
            </td>
          ))}
        </TableRowItem>
      ))}
    </>
  );
};

export {
  TableInpatientBenefit,
  TableOutpatientBenefit,
  TableHealthCheckupBenefit,
  TableMaternityBenefit,
  TableVisionBenefit,
  TableDentalBenefit,
  TableCopayBenefit,
  TableOthersBenefit,
};
