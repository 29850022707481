import humps from 'lodash-humps-ts';
import { authAxios } from '@components/Auth';
import { API_V1_BASE } from '@config';
import { Occupation } from '@models/Occupation';

export default class OccupationApi {
  static getAll = async () => {
    return await authAxios.get(`${API_V1_BASE}/occupations`).then((response) => humps(response.data) as Occupation[]);
  };
}
