import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormGroupControl } from '@components/Base';

const AccidentDetails = () => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{t('carInsuranceClaimForm.accidentDetails.title')}</Card.Title>
      <Row>
        <Col>
          <FormGroupControl
            type="datetime-local"
            name="accidentHappenedAt"
            label={t('carInsuranceClaimForm.accidentHappenedAt.label')}
            required
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupControl
            name="accidentPhotos"
            type="file"
            multiple
            label={t('carInsuranceClaimForm.accidentPhotos.label')}
            required
            groupProps={{ className: 'mb-3' }}
          />
        </Col>
      </Row>
    </Card.Body>
  );
};

export { AccidentDetails };
