import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { amountToUsd } from '@utils/currency';

import { selectCancerQuoteComparePage } from './selector';
import { TableRowItem } from './table-row-item';

const TableAnnualLimit = () => {
  const { t } = useTranslation();
  const { cancerQuotes } = useSelector(selectCancerQuoteComparePage);

  if (!cancerQuotes) return null;

  return (
    <>
      <TableRowItem title={t('cancerQuote.annualLimit')}>
        {map(cancerQuotes, ({ id, annualLimitAmount }) => (
          <td key={id}>{amountToUsd(annualLimitAmount)}</td>
        ))}
      </TableRowItem>
    </>
  );
};

export { TableAnnualLimit };
