import humps from 'lodash-humps-ts';
import { API_V1_BASE } from '@config';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { Car } from '@models/Car';
import { CarSubModel } from '@models/CarSubModel';

export type GetCarParamsWithCarId = Pick<Car, 'manufacturedYear'> & {
  carId: Car['id'];
};

export type GetCarParamsWithoutCarId =
  Pick< Car, 'carBrandId' | 'carModelId' | 'carSubModelId' | 'engineCapacity' | 'manufacturedYear' > & {
    mobileNumber?: string;
    fullName?: string;
  };

export type GetManufacturedYearsCarParams = {
  carSubModelId: CarSubModel['id'];
};

export class CarApi {
  static getIdv = async (params: GetCarParamsWithCarId | GetCarParamsWithoutCarId) => {
    return await authAxios
      .get(`${API_V1_BASE}/car`, { params: snakes(params) })
      .then((response) => humps(response.data) as Car);
  };

  static getManufacturedYears = async ({ carSubModelId }: GetManufacturedYearsCarParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/car_sub_models/${carSubModelId}/cars/manufactured_years`)
      .then((response) => humps(response.data) as Pick<Car, 'id' | 'manufacturedYear'>[]);
  };
}
