import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectCurrentUser, selectIsFrontline } from '@components/Auth';

import { FormValues } from './type';

import { object, string } from 'yup';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    firstName: string().required(t('validation:required')),
    lastName: string().required(t('validation:required')),
    email: string().email().required(t('validation:required')),
    note: string().optional(),
  });
};

export const useInitialValues = () => {
  const currentUser = useSelector(selectCurrentUser);
  const isFrontline = useSelector(selectIsFrontline);

  return useMemo<FormValues>(() => {
    if (isFrontline || !currentUser) {
      return {
        firstName: '',
        lastName: '',
        email: '',
        note: '',
      };
    }

    return {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
      note: '',
    };
  }, [currentUser, isFrontline]);
};
