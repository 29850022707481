import React, { useEffect, useCallback, ReactElement } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Image } from 'react-bootstrap';
import { map, isUndefined, isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import { BackButton, ClaimBadge, Loading, LoadingError, NoData } from '@components/Base';
import { getPersonalAccidentClaimType } from '@models/PersonalAccidentClaim';
import { amountToUsd } from '@utils/currency';
import { getAttachmentType, isAttachmentImage, isAttachmentPdf } from '@utils/attachment';
import { selectPersonalAccidentClaim } from './selector';
import { personalAccidentClaimFetch } from './action';

interface RowItemProps {
  label: string;
  value?: string | number | ReactElement;
  list?: string[];
  renderItem?: (item: string) => void;
}

const RowItem = ({ label, value, list, renderItem }: RowItemProps) => {
  return (
    <Row className="mb-2">
      <Col xs={4} sm={4} md={3}>
        {label}
      </Col>
      <Col xs="auto">:</Col>
      <Col>
        <>
          {value && value}
          {list && renderItem && map(list, renderItem)}
        </>
      </Col>
    </Row>
  );
};

interface ClaimAttachmentItemProps {
  name: string;
  url: string;
}

const ClaimAttachmentItem = ({ name, url }: ClaimAttachmentItemProps) => {
  const { t } = useTranslation();
  const attachmentType = getAttachmentType(url);

  if (isAttachmentImage(attachmentType)) {
    return <Image src={url} thumbnail className="mb-3" data-testid={`${name}-image`} />;
  }

  if (isAttachmentPdf(attachmentType)) {
    return (
      <a href={url} className="d-block mb-3" target="_blank" rel="noreferrer" data-testid={`${name}-pdf`}>
        {t('common:clickToViewDetails')}
      </a>
    );
  }
};

const PersonalAccidentClaimDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { claimableId } = useParams();
  const { personalAccidentClaim } = useSelector(selectPersonalAccidentClaim);
  const isLoaded = !isUndefined(personalAccidentClaim);
  const isError = isNull(personalAccidentClaim);
  const hasPersonalAccidentClaim = isLoaded && !isNull(personalAccidentClaim);

  useEffect(() => {
    dispatch(personalAccidentClaimFetch({ id: Number(claimableId) }));
  }, [claimableId]);

  const handleBackClick = useCallback(() => {
    navigate('/user-dashboard/claim');
  }, []);

  return (
    <>
      <BackButton onClick={handleBackClick} className="mb-4" />
      {!isLoaded && <Loading />}
      {isError && <LoadingError />}
      {isLoaded && !hasPersonalAccidentClaim && <NoData />}

      {personalAccidentClaim && (
        <Card>
          <Card.Header>{t('common:details')}</Card.Header>
          <Card.Body>
            <RowItem label={t('common:status')} value={<ClaimBadge status={personalAccidentClaim.status} />} />
            <RowItem
              label={t('claimForm.claimType.label')}
              value={getPersonalAccidentClaimType(personalAccidentClaim.claimType)}
            />
            <RowItem
              label={t('claimForm.completedClaimForm.label')}
              value={<ClaimAttachmentItem name="completed-claim-form" url={personalAccidentClaim.completedClaimForm} />}
            />
            <RowItem
              label={t('claimForm.supportingDocuments.label')}
              list={personalAccidentClaim.supportingDocuments}
              renderItem={(url) => <ClaimAttachmentItem name="supporting-documents" url={url} />}
            />
            <RowItem
              label={t('claimForm.invoice.label')}
              value={<ClaimAttachmentItem name="invoice" url={personalAccidentClaim.invoice} />}
            />
            <RowItem
              label={t('claimForm.totalClaimedAmount.label')}
              value={amountToUsd(personalAccidentClaim.totalClaimedAmount)}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export { PersonalAccidentClaimDetails };
