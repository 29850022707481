import { keys } from 'lodash';
import { AnySchema } from 'yup';

export const getFields = (schema: AnySchema) => {
  return schema.describe().fields;
}

export const getFieldKeys = (schema: AnySchema) => {
  return keys(getFields(schema));
}

export const isRequired = (fields: any, fieldName: string): boolean => {
  const field = fields[fieldName];
  if (!field) throw new Error(`Field '${fieldName}' not found in schema`)
  return field.tests.some(({ name }) => name === 'required')
}
