import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { map, times } from 'lodash';
import { Row, Col, Container, ListGroup, Image } from 'react-bootstrap';
import howItWorksImg from './how-it-works.png';
import imageNumber1 from './number-1.png';
import imageNumber2 from './number-2.png';
import imageNumber3 from './number-3.png';
import { Title } from '../title';

const NUMBERED_LIST_IMAGES = [imageNumber1, imageNumber2, imageNumber3];

const HowItWorks = () => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return times(3, (n) => ({
      image: NUMBERED_LIST_IMAGES[n] as string,
      title: t(`howItWorks.title${n + 1}`),
      desc: t(`howItWorks.desc${n + 1}`),
    }));
  }, []);

  return (
    <section className="py-5">
      <Title title={t('howItWorks.header')} />
      <Container>
        <Row className="align-items-center">
          <Col sm={10} md={8} lg={5} className="mb-5 mb-lg-0 mx-auto">
            <Image src={howItWorksImg as string} fluid />
          </Col>
          <Col md={12} lg={7}>
            <ListGroup as="ol" variant="flush" className="mb-n3">
              {map(items, ({ image, title, desc }) => (
                <ListGroup.Item
                  key={title}
                  as="li"
                  className="d-flex justify-content-start align-items-start border-0 mb-3"
                >
                  <Image src={image} width={52} />
                  <div className="ms-2">
                    <h4 className="fw-bold">{title}</h4>
                    <p>{desc}</p>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { HowItWorks };
