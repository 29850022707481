import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { CarPlanTypes } from '@models/CarPlanType';
import { AmountWithDiscount } from '@components/Base';

import { selectCarQuoteComparePage } from './selector';
import { TableRowHeader } from './table-row-header';
import { TableRowItem } from './table-row-item';
import { useCarQuotesComparePageParams } from './hook';

const TablePremium = () => {
  const { t } = useTranslation();
  const { planType } = useCarQuotesComparePageParams();
  const { carQuotes } = useSelector(selectCarQuoteComparePage);

  if (!carQuotes) return null;

  return (
    <>
      <TableRowHeader header={t('common:premiumBreakdown')} />

      {planType === CarPlanTypes.Comprehensive && (
        <TableRowItem title={t('common:ownDamageTheftCoverPremium')}>
          {map(carQuotes, ({ carPlanId, ownDamageAmount, ownDamageDiscountAmount, ownDamageFinalAmount }) => (
            <td key={carPlanId}>
              <AmountWithDiscount
                amount={ownDamageAmount}
                discountAmount={ownDamageDiscountAmount}
                finalAmount={ownDamageFinalAmount}
              />
            </td>
          ))}
        </TableRowItem>
      )}

      <TableRowItem title={t('common:thirdPartyCoverPremium')}>
        {map(carQuotes, ({ carPlanId, thirdPartyAmount, thirdPartyDiscountAmount, thirdPartyFinalAmount }) => (
          <td key={carPlanId}>
            <AmountWithDiscount
              amount={thirdPartyAmount}
              discountAmount={thirdPartyDiscountAmount}
              finalAmount={thirdPartyFinalAmount}
            />
          </td>
        ))}
      </TableRowItem>

      {carQuotes[0]?.accidentToDriver && <TableRowItem title={t('carInsuranceQuote.accidentToDriver.name')}>
        {map(
          carQuotes,
          ({ carPlanId, accidentToDriverAmount, accidentToDriverDiscountAmount, accidentToDriverFinalAmount }) => (
            <td key={carPlanId}>
              <AmountWithDiscount
                amount={accidentToDriverAmount}
                discountAmount={accidentToDriverDiscountAmount}
                finalAmount={accidentToDriverFinalAmount}
              />
            </td>
          ),
        )}
      </TableRowItem>}

      {carQuotes[0]?.passengerLiability && <TableRowItem title={t('carInsuranceQuote.passengerLiability.name')}>
        {map(
          carQuotes,
          ({
            carPlanId,
            passengerLiabilityAmount,
            passengerLiabilityDiscountAmount,
            passengerLiabilityFinalAmount,
          }) => (
            <td key={carPlanId}>
              <AmountWithDiscount
                amount={passengerLiabilityAmount}
                discountAmount={passengerLiabilityDiscountAmount}
                finalAmount={passengerLiabilityFinalAmount}
              />
            </td>
          ),
        )}
      </TableRowItem>}
    </>
  );
};

export { TablePremium };
