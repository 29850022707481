import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import InsuredDeclaredValue from './InsuredDeclaredValue';
import PlanType from './PlanType';
import { FilterAddonCover } from './filter-addon-cover';

const FilterSection = () => {
  return (
    <Container className="shadow rounded">
      <Row>
        <Col lg={3}>
          <InsuredDeclaredValue />
        </Col>
        <Col lg={3}>
          <PlanType />
        </Col>
        <Col lg={6}>
          <FilterAddonCover />
        </Col>
      </Row>

      {/* <Row>
        <Col lg={3}>
          <Promotion />
        </Col>
      </Row> */}
    </Container>
  );
};

export { FilterSection };
